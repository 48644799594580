import React, { Component } from 'react';
import Banner from '../components/common/Banner';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import ModalImage from "react-modal-image";
import { doc, getDoc } from 'firebase/firestore';
import NoData from "../components/common/NoData";
import Loading from "../components/common/Loading";
import { firestore } from '../Config/FbConfig';

export default class Programmes extends Component {

  state = { Datas: [] }

  async componentDidMount() {
    //For Teaching
    const docRef = doc(firestore, "SwarasankulaOrganisation", "Programmes");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      var Data = []
      Object.entries(docSnap.data()).forEach(([key, value]) => {
        Data.push(Object.assign(
          {
            Id: key,
            Priority: value.Priority !== undefined ? value.Priority : "999",
            Video: value.Video === undefined || value.Video === "" ? "999" : value.Video,
            Image: value.Image,
            Header: value.Header,
            Date: value.Date
          }))
      });

      Data.length === 0 ? this.setState({ Datas: "NO_999" }) : this.setState({ Datas: Data.sort(function (a, b) { return a.Priority - b.Priority; }) })

    }

    else { this.setState({ Datas: "NO_999" }) }
  }

  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Programmes" image="banners/swarasankula03.jpg" />

        {/* Dynamic Data */}
        <div className="container">
          <div className="row paddingtop-2">
            {
              this.state.Datas.length === 0 ? <Loading /> :
                this.state.Datas === "NO_999" ? <NoData /> :
                  this.state.Datas.map((data, i) => (
                    <div className="col-lg-4 paddingtop-2" key={i}>
                      <div className="gallery-box">
                        <ModalImage className="swaraimage1" small={data.Image} large={data.Image} alt="" />
                      </div>
                      <h6 style={{ color: "maroon" }} className="paddingtop-2">
                        {data.Date.split("-")[2] + "-" + data.Date.split("-")[1] + "-" + data.Date.split("-")[0]}
                        {
                          data.Video !== "999" ?
                            <a target="_blank" href={data.Video} rel="noopener noreferrer"><small style={{ color: "black", fontWeight: "bold" }}> ( Video Link Click Here )</small></a> :
                            null
                        }
                      </h6><hr />
                      <div style={{ color: "black", textAlign:"justify" }} dangerouslySetInnerHTML={{__html: data.Header}} />
                    </div>
                  ))
            }
          </div>
          <br />
        </div>

        {/* Footer */}
        <Footer />
      </div>
    )
  }
}
