import React, { Component } from 'react';
import Header from '../components/common/Header';
import LanderBlock from '../components/LanderBlock';
import ImageGallery from '../components/ImageGallery';
import Footer from '../components/common/Footer';
import ModalImage from "react-modal-image";
import { doc, getDoc } from 'firebase/firestore';
import NoData from "../components/common/NoData";
import Loading from "../components/common/Loading";
import { firestore } from '../Config/FbConfig';

export default class Home extends Component {

    state = { Datas: [] }

    async componentDidMount() {

        //For Special Event
        const docRef = doc(firestore, "SwarasankulaOrganisation", "Next");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                Data.push(Object.assign(
                    {
                        Id: key,
                        Video: value.Video !== undefined || value.Video === "" ? value.Video : "999",
                        Image: value.Image,
                        Header: value.Header,
                        Date: value.Date
                    }))
            });

            Data.length === 0 ? this.setState({ Datas: "NO_999" }) : this.setState({ Datas: Data.sort(function (a, b) { return a.Priority - b.Priority; }) })

        }

        else { this.setState({ Datas: "NO_999" }) }

        document.getElementById("backgroundMusic").play().catch((error) => {
            document.addEventListener('click', () => {
                document.getElementById("backgroundMusic").play()
            }, { once: true })
        })
    }

    render() {
        return (
            <div>
                {/* Header */}
                <Header display="NO" />

                {/* Lander Slider New */}
                <LanderBlock />

                {/*Special News Section*/}
                <div className="container show-on-mob">
                    {
                        this.state.Datas.length === 0 ? <Loading /> :
                            this.state.Datas === "NO_999" ? <NoData /> :
                                this.state.Datas.map((data, i) => (
                                    <div className="row paddingtop-2" key={i}>
                                        <div className="col-lg-5">
                                            <div className="gallery-box">
                                                <ModalImage className="swaraimage1" small={data.Image} large={data.Image} alt="" />
                                            </div>
                                        </div>
                                        <div className="col-lg-7" style={{ margin: "auto" }}>
                                            <p className="paddingtop-1" style={{ color: "maroon", fontWeight: "bold", fontSize: "16px" }}>Next Event : {data.Date.split("-")[2] + "-" + data.Date.split("-")[1] + "-" + data.Date.split("-")[0]}</p>
                                            <p className="multi-line" style={{ color: "black" }} dangerouslySetInnerHTML={{ __html: data.Header }} />
                                            <hr />
                                        </div>
                                    </div>
                                ))
                    }
                </div>

                {/* Text Field */}
                <div className="container ttm-service-description">
                    <br /><br />
                    <div className="row">
                        <div className="col-md-4">
                            <div className="ttm_single_image-wrapper">
                                <img className="swaraimage img-fluid" src={process.env.PUBLIC_URL + "/assets/images/home/main1.jpg"} title="single-img-twenty" alt="single-img-twenty" />
                                <br /><br />
                                <audio controls allow="autoplay" style={{ width: "100%", paddingLeft: "1rem" }} id="backgroundMusic">
                                    <source src={process.env.PUBLIC_URL + "/assets/audio/swaraAudio.mp3"} type="audio/mpeg" />
                                </audio>
                            </div>
                        </div>
                        <div className="mob-only"><br /></div>
                        <div className="col-md-8 justify" style={{ margin: "auto" }}>
                            <div className="section-title with-desc text-left clearfix">
                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                    <h4 className="title">Hindustani Classical Music</h4>
                                </div>
                            </div>
                            <p className="swaratext1" data-wow-delay="0.3s">Bhatkhande project DVD release programme held at Nadabrahma sangeeta sabha, Mysore. Compilation of photos can be viewed <a className="swaratext1" style={{ color: "blue" }} target="_blank" href="https://www.youtube.com/watch?v=2eFDOMT38-Y" rel="noopener noreferrer">here</a></p><br />
                            <p className="swaratext1">Hindustani Classical Music is one of the many great contributions from India to the world of arts and culture. Mysore, the cultural capital of Karnataka, and the erstwhile capital city of Mysore Kingdom, has a legacy of patroninsing Hindustani classical music. Many stalwarts like Ustad Faiyaz Khan and Ustad Abdul Karim Khan have performed in Mysore under the patronage of Maharajas. Swarasankula Sangeetha Sabha is an initiative to sustain this tradition of Hindustani Classical Music in Mysore region.</p>
                        </div>
                    </div>
                    <br /><br />
                </div>

                {/* Image Gallery Area */}
                <ImageGallery />

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}