import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Banner from '../components/common/Banner';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import { Styles } from './styles/contact.js';

export default class Contact extends Component {
  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Contact" image="banners/swarasankula07.jpg" />

        {/*Conatct Info */}
        <Styles>
          <div className="main-wrapper contact-page">
            <section className="contact-area">
              <Container>
                <Row>
                  <Col md="4">
                    <div className="section-title with-desc text-left clearfix">
                      <div className="title-header" style={{ borderTopColor: "red" }}>
                        <h4 className="title">Contact Info</h4>
                      </div>
                    </div>
                    <div className="contact-icon-box d-flex">
                      <div className="icon">
                        <i className="fas fa-map-marker"></i>
                      </div>
                      <div className="box-content">
                        <h5>Our Location</h5>
                        <p>#1226, Gange Road, 3rd cross,</p>
                        <p>G & H Block,Kuvempunagara, Mysuru</p>
                        <p>Pincode - 570023.</p>
                      </div>
                    </div>
                    <div className="contact-icon-box d-flex">
                      <div className="icon">
                        <i className="fas fa-envelope-open"></i>
                      </div>
                      <div className="box-content">
                        <h5>Email Address</h5>
                        <p>bhaskarmuthathi@gmail.com</p>
                      </div>
                    </div>
                    <div className="contact-icon-box d-flex">
                      <div className="icon">
                        <i className="fas fa-phone"></i>
                      </div>
                      <div className="box-content">
                        <h5>Phone Number</h5>
                        <p>+91 99019 02969</p>
                      </div>
                    </div>
                  </Col>
                  <Col md="8">
                    <div className="contact-form">
                      <div className="section-title with-desc text-left clearfix">
                        <div className="title-header" style={{ borderTopColor: "red" }}>
                          <h4 className="title">Get In Touch</h4>
                        </div>
                      </div>
                      <div className="form-box">
                        <form id="form_contact" className="form">
                          <Row>
                            <Col md="6">
                              <p className="form-control">
                                <input type="text" placeholder="Full Name" id="contact_name" />
                                <span className="contact_input-msg"></span>
                              </p>
                            </Col>
                            <Col md="6">
                              <p className="form-control">
                                <input type="email" placeholder="Email Address" id="contact_email" />
                                <span className="contact_input-msg"></span>
                              </p>
                            </Col>
                            <Col md="12">
                              <p className="form-control">
                                <input type="text" placeholder="Subject" id="contact_subject" />
                                <span className="contact_input-msg"></span>
                              </p>
                            </Col>
                            <Col md="12">
                              <p className="form-control">
                                <textarea name="message" id="contact_message" placeholder="Enter Message"></textarea>
                                <span className="contact_input-msg"></span>
                              </p>
                            </Col>
                            <Col md="12">
                              <button>Send Message</button>
                            </Col>
                          </Row>
                        </form>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
        </Styles>

        {/* Footer */}
        <Footer />

      </div>
    )
  }
}
