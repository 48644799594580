import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import ScrollToTop from './helper/ScrollToTop';
import { GlobalStyle } from "./components/common/styles/global";
import { ParallaxProvider } from 'react-scroll-parallax';

import Home from "./pages/Home";

import Member from "./pages/Members";
import Histroy from "./pages/History";
import VS from "./pages/VS";

import Video from "./pages/VideoCoverage";
import Articles from "./pages/PressArticles";

import Programmes from "./pages/Programmes";

import BhatkhandeProject from "./pages/BhatkhandeProject";
import BhatkhandeAudio from "./pages/BhatkhandeAudio";
import BhatkhandeAudioSpecific from "./pages/BhatkhandeAudioSpecific";

import RaagDarshan from "./pages/RaagDarshan";

import TheSchool from "./pages/TheSchool";

import Contact from "./pages/Contact";

export default function App() {
    return (
        <ParallaxProvider>
            <BrowserRouter basename="/"> 
                <GlobalStyle />
                <ScrollToTop />
                <Switch>
                    <Route exact path={`${process.env.PUBLIC_URL + "/"}`} component={Home} />

                    <Route exact path={`${process.env.PUBLIC_URL + "/Members"}`} component={Member} />
                    <Route exact path={`${process.env.PUBLIC_URL + "/History"}`} component={Histroy} />
                    <Route exact path={`${process.env.PUBLIC_URL + "/V-S"}`} component={VS} />

                    <Route exact path={`${process.env.PUBLIC_URL + "/Video-Coverage"}`} component={Video} />
                    <Route exact path={`${process.env.PUBLIC_URL + "/Press-Articles"}`} component={Articles} />

                    <Route exact path={`${process.env.PUBLIC_URL + "/Programmes"}`} component={Programmes} />

                    <Route exact path={`${process.env.PUBLIC_URL + "/Bhatkhande-Project"}`} component={BhatkhandeProject} />
                    <Route exact path={`${process.env.PUBLIC_URL + "/Bhatkhande-Audio"}`} component={BhatkhandeAudio} />
                    <Route exact path={`${process.env.PUBLIC_URL + "/Bhatkhande-Audio-Specific/:SL/:RAAG/:TAAL/:STARTING_WORDS/:THAAT/:FILE_NAME"}`} component={BhatkhandeAudioSpecific} />

                    <Route exact path={`${process.env.PUBLIC_URL + "/Raag-Darshan"}`} component={RaagDarshan} />

                    <Route exact path={`${process.env.PUBLIC_URL + "/The-School"}`} component={TheSchool} />

                    <Route exact path={`${process.env.PUBLIC_URL + "/Contact"}`} component={Contact} />
                </Switch>
            </BrowserRouter>
        </ParallaxProvider>
    )
}