import React, { Component } from 'react';
import Banner from '../components/common/Banner';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';

export default class VS extends Component {
  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Vision & Mission" image="banners/swarasankula01.jpg" />

        <div className="container ttm-service-description">
          <br /><br />
          <div className="row">
            <div className="col-lg-12 justify">
              <div className="section-title with-desc text-left clearfix">
                <div className="title-header" style={{ borderTopColor: "red" }}>
                  <h4 className="title">Vision</h4>
                </div>
              </div>
              <p className="swaratext1">To nourish the  tradition of Hindustani Classical Music in Mysore and sustain the interest so created by serving as a forum for organising music concerts and also disseminating knowledge related to hindustani music.</p>
            </div>
            <div className="col-lg-12 justify paddingtop-2" style={{ margin: "auto" }}>
              <div className="section-title with-desc text-left clearfix">
                <div className="title-header" style={{ borderTopColor: "red" }}>
                  <h4 className="title">Mission</h4>
                </div>
              </div>
              <ui>
                <li className="swaratext1">To promote young and talented upcoming artists in the field of Hindustani music.</li>
                <li className="swaratext1">To organise performances of the maestros of Hindustani Classical Music.</li>
                <li className="swaratext1">To felicitate  senior artists every year in recognition of services rendered by them to the cause of music.</li>
                <li className="swaratext1">To organise workshops on different dimensions and themes in the field  of Hindustani Classical Music. To build or strengthen capacities of young learners to appreciate and imbibe the nuances of hindustani classical music.</li>
                <li className="swaratext1">To organise ‘Music Festivals’.</li>
                <li className="swaratext1">To create a data bank on different aspects of hindustani classical music.</li>
                <li className="swaratext1">Organsing concerts to honor the memory and contributions of great artists.</li>
              </ui>
            </div>
          </div>
          <br /><br />
        </div>

        {/* Footer */}
        <Footer />
      </div>
    )
  }
}
