import styled from "styled-components";
import { colors } from "../../components/common/element/elements.js";

export const Styles = styled.div`

    .bitHeader-area {        
        background-size    : cover;
        background-position: center;
        background-repeat  : no-repeat;
        padding            : 120px 0;
        position           : relative;

        &:before {
            position  : absolute;
            content   : '';
            opacity   : 0.5;
            width     : 100%;
            height    : 100%;
            top       : 0;
            left      : 0;
        }

        .Text-conent{
            margin-left : 1rem;
        }

        .left{
            .banner-img-1{
                height : 7rem;
            }
    
            h4{
                font-family:verdana;
                color: ${colors.white};
                font-weight: 600;
                padding-bottom: 10px;
                position: relative;
                padding   : 30px 45px 10px 0;
    
                @media(max-width: 767px) {
                    padding   : 150px 0;
                    font-size : 20px;
                }
            }
    
            h6{
                font-family:verdana;
                color: ${colors.white};
                font-weight: 600;
                position: relative;
            }
        }

        .right{
            text-align: right;
            .banner-img-1{
                height : 7rem;
            }
    
            h4{
                font-family:verdana;
                color: ${colors.white};
                font-weight: 600;
                padding-bottom: 10px;
                position: relative;
                padding   : 30px 0px 10px 0;
    
                @media(max-width: 767px) {
                    padding   : 150px 0;
                    font-size : 20px;
                }
            }
    
            h6{
                font-family:verdana;
                color: ${colors.white};
                font-weight: 600;
                position: relative;
            }
        }

        button {
            font-size  : 15px;
            color      : ${colors.border1};
            background-color: Transparent;
            margin-top : 1.5rem;
            width      : 12.5rem;
            height     : 40px;
            font-weight: 500;
            border:solid 2px ${colors.border1};
            border-radius : 15px;

            &:hover {
                background: ${colors.yellow1};
            }
        }
    }

    .bitHeader-area-1 {       
        background-size    : cover;
        background-position: center;
        background-repeat  : no-repeat;
        position           : relative;
        padding            : 15px 0;

        &:before {
            position  : absolute;
            content   : '';
            opacity   : 0.5;
            width     : 100%;
            height    : 100%;
            top       : 0;
            left      : 0;
        }

        .swaratitle{
            color : white;
            text-align: center;
            font-size : 1.75rem;
            padding-top : 20rem;
        }
    }
`;