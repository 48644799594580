import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/header.js";
import MobileMenu from './MobileMenu.js';
import ModalImage from "react-modal-image";
import { doc, getDoc } from 'firebase/firestore';
import NoData from "../../components/common/NoData";
import Loading from "../../components/common/Loading";
import { firestore } from '../../Config/FbConfig';

export default class Header extends Component {

    state = { Datas: [] }

    async componentDidMount() {
        //For Teaching
        const docRef = doc(firestore, "SwarasankulaOrganisation", "Next");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                Data.push(Object.assign(
                    {
                        Id: key,
                        Video: value.Video !== undefined || value.Video === "" ? value.Video : "999",
                        Image: value.Image,
                        Header: value.Header,
                        Date: value.Date
                    }))
            });

            Data.length === 0 ? this.setState({ Datas: "NO_999" }) : this.setState({ Datas: Data.sort(function (a, b) { return a.Priority - b.Priority; }) })
        }

        else { this.setState({ Datas: "NO_999" }) }
    }

    render() {
        return (
            <div>
                <Styles>
                    <section className="top-bar">
                        <Container>
                            <Row>
                                <Col lg="5">
                                    <div className="logo">
                                        <Link to={process.env.PUBLIC_URL + "/"}>
                                            <img alt="logo" src={process.env.PUBLIC_URL + "/assets/images/general/logo.png"} style={{ width: "32.5rem", marginTop: "1rem" }} />
                                        </Link>
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className="menu-box bar-right d-flex justify-content-center">
                                        <ul className="nav menu-nav">
                                            <li className="nav-item"><div className="nav-link" style={{ color: "white" }}><i className="fas fa-phone" />&nbsp;&nbsp;+91 99019 02969</div></li>
                                            <li className="nav-item"><div className="nav-link" style={{ color: "white" }}><i className="fas fa-envelope" />&nbsp;&nbsp;bhaskarmuthathi@gmail.com</div></li>
                                            <li className="nav-item"><div className="nav-link" style={{ color: "white" }}>|</div></li>
                                            <li className="nav-item"><div className="nav-link"><a style={{ color: "white" }} href="https://www.youtube.com/channel/UCjVdAok_K_KjVUsJUcIIR9g" rel="noopener noreferrer" target="_blank"><i className="fab fa-youtube" />&nbsp;&nbsp;Youtube</a></div></li>
                                        </ul>
                                    </div>
                                    {
                                        this.state.Datas.length === 0 ? <Loading /> :
                                            this.state.Datas === "NO_999" ? <NoData /> :
                                                this.state.Datas.map((data, i) => (
                                                    <div className="row paddingtop-1" key={i}>
                                                        <div className="col-lg-5">
                                                            <div className="gallery-box">
                                                                <ModalImage className="swaraimage1" small={data.Image} large={data.Image} alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-7" style={{ margin: "auto" }}>
                                                            <p className="paddingtop-1" style={{ color: "maroon", fontWeight: "bold", fontSize: "16px" }}>Next Event : {data.Date.split("-")[2] + "-" + data.Date.split("-")[1] + "-" + data.Date.split("-")[0]}</p>
                                                            <p className="multi-line" style={{ color: "black" }} dangerouslySetInnerHTML={{ __html: data.Header }} />
                                                            {
                                                                data.Video === 999 || this.props.display !== "NO" ?
                                                                    <div><hr /><a target="_blank" href={data.Video} rel="noopener noreferrer" style={{ color: "black", fontWeight: "bold" }}>More Info Click Here</a></div> :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                ))
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    {/* Logo Area */}
                    <section className="header-area">
                        <Container>

                        </Container>
                    </section>

                    <section className="top-bar-info">
                        <Container>
                            <div className="d-flex justify-content-center">
                                <ul className="nav menu-nav">
                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/"}>HOME</Link></li>
                                    <div className="line">|</div>
                                    <li className="nav-item dropdown">
                                        <a href="javascript:void(0)" className="nav-link">ABOUT US</a>
                                        <ul className="dropdown" style={{ width: "12.5rem" }}>
                                            <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + '/Members'}>Members</Link></li>
                                            <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + '/History'}>History</Link></li>
                                            <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + '/V-S'}>Vision and Mission</Link></li>
                                        </ul>
                                    </li>
                                    <div className="line">|</div>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link">Media</a>
                                        <ul className="dropdown" style={{ width: "12.5rem" }}>
                                            <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + '/Video-Coverage'}>Video Coverage</Link></li>
                                            <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + '/Press-Articles'}>Press Articles</Link></li>
                                        </ul>
                                    </li>
                                    <div className="line">|</div>
                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/Programmes"}>Programmes</Link></li>
                                    <div className="line">|</div>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link">Bhatkhande Project</a>
                                        <ul className="dropdown" style={{ width: "12.5rem" }}>
                                            <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + '/Bhatkhande-Project'}>About Project</Link></li>
                                            <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + '/Bhatkhande-Audio'}>Play Audio</Link></li>
                                        </ul>
                                    </li>
                                    <div className="line">|</div>
                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/Raag-Darshan"}>Raag Darshan</Link></li>
                                    <div className="line">|</div>
                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/The-School"}>The School</Link></li>
                                    <div className="line">|</div>
                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/Contact"}>Contact</Link></li>
                                </ul>
                            </div>
                        </Container>
                    </section>
                </Styles>
                <MobileMenu />
            </div>
        )
    }
}