import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Banner from '../components/common/Banner';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import { Styles } from './styles/members.js';

export default class Members extends Component {

  render() {

    const Datas = [
      {
        "id": "1",
        "memberImg": "member-1.jpg",
        "memberName": "Dr Bhaskar M S (President)",
        "memberdesc": "A senior consultant Neuro-Surgeon by profession and also a Hindustani classical vocalist. A dedicated  patron of art with a special flair for Hindustani Classical Music. Dr. Bhaskar learnt music from the late Pt. Ramarao V Naik, a stalwart of Agra Gharana. He is presently under tutelage of Pt. Indudhara Nirody.  Dr. Bhaskar presents a fine blend of musical and medical acumen.",
      },
      {
        "id": "2",
        "memberImg": "member-2.jpg",
        "memberName": "Pt Veerabhadaraih Hiremath (Secretary)",
        "memberdesc": "A Hindustani Classical Music Vocalist and also an accomplished Harmonium player. An alumnus of Veereshawara Punyasharama, Gadag, considered the citadel of Hindustani Guruparampara in Karnataka. Pt Veerabhadraiah hiremath disciple of Late Padmabhushan Pt. Puttaraja Gawai. He heads the Swarasankula Sangeetha Vidyalaya ,Mysore, a school of music offering various courses in Hindustani Classical Music. The school is carrying the tradition of guruprampara. Presently he is  under the tutelage of Pt. Indudhara Nirody.",
      },
      {
        "id": "3",
        "memberImg": "member-3.jpg",
        "memberName": "Shri S.K. Laxminaryan (Babu) (Treasurer)",
        "memberdesc": "An industrialist and an ace music organiser in Mysore . S.K. Lakshminarayana is proactive in the cultural world of Mysore. He has created a number of opportunities for young artists to showcase their  talents. A committed Rotarian, an avid golfer and an institution builder. S.K. Lakshminarayana  was the   honorary treasurer of National Institute of Enginerring , Mysore, a premiere institution of technical education in the country.",
      },
      {
        "id": "4",
        "memberImg": "member-4.jpg",
        "memberName": "Prof R Indira (Member)",
        "memberdesc": "Formerly professor of Sociology and director of The International centre at the University of Mysore. Dr. R. Indira, is a teacher, researcher and a writer. Member of the swarasankula trust, she is an ardent lover of music and has been closely working with organisations and people committed to the promotion of art and culture in Mysore, besides being part of many civil society groups involved in social development.",
      },
      {
        "id": "5",
        "memberImg": "member-5.jpg",
        "memberName": "Shri Venkatesh Bhat (Member)",
        "memberdesc": "An entrepreneur in the food industry and running a successful venture ‘ Surya Condiments’ in Mysore. He is a lover of Hindustani Classical Music and a tabla Artist, being interested in organising musical concerts in Mysore and has been involved in such initiatives for a long time.",
      }
    ]

    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Members" image="banners/swarasankula01.jpg" />

        <Styles>
          <div className="main-wrapper member-page">
            <section className="member-page-area">
              <Container>
                {
                  Datas.map((data, i) => (
                    <Row key={i}>
                      <div className="mob-only"><br /></div>
                      <Col xl="3" lg="4" md="0" style={{ display: data.id % 2 !== 0 ? "block" : "none", marginBottom: "5rem" }}>
                        <div className="member-img">
                          <img src={process.env.PUBLIC_URL + `/assets/images/member/${data.memberImg}`} alt="" className="swaraimage1 img-fluid" />
                        </div>
                      </Col>
                      <Col xl="9" lg="8" md="12" style={{ margin: "auto" }}>
                        <div className={data.id % 2 !== 0 ? "member-content" : "member-content-1"}>
                          <div className="content-box">
                            <div className="member-title"><h6>{data.memberName}</h6></div><hr />
                            <div className="member-desc"><p>{data.memberdesc}</p></div>
                          </div>
                        </div>
                      </Col>
                      <Col xl="3" lg="4" md="0" style={{ display: data.id % 2 !== 0 ? "none" : "block", marginBottom: "5rem" }}>
                        <div className="member-img">
                          <img src={process.env.PUBLIC_URL + `/assets/images/member/${data.memberImg}`} alt="" className="swaraimage1 img-fluid" />
                        </div>
                      </Col>
                      <div className="mob-only"><br /></div>
                    </Row>
                  ))
                }
              </Container>
            </section>
          </div>
        </Styles>

        {/* Footer */}
        <Footer />
      </div>
    )
  }
}
