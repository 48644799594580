import React, { Component } from 'react';
import Banner from '../components/common/Banner';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';

export default class RaagDarshan extends Component {
  render() {

    const Data_1 = {
      "dataList": [
        {
          "id": "1",
          "url": "https://www.youtube.com/watch?v=WUzXCatk1bg",
        },
        {
          "id": "2",
          "url": "https://www.youtube.com/watch?v=3FoGVwO_g80",
        },
        {
          "id": "3",
          "url": "https://www.youtube.com/watch?v=b0CFprUu46E",
        },
        {
          "id": "4",
          "url": "youtube.com/watch?v=ZCf2CHE_3Ro",
        },
        {
          "id": "5",
          "url": "https://www.youtube.com/watch?v=ZCf2CHE_3Ro",
        },
        {
          "id": "6",
          "url": "https://www.youtube.com/watch?v=e0sQ1sZsuWU",
        },
        {
          "id": "7",
          "url": "https://www.youtube.com/watch?v=GsmJ0H_PPOI",
        },
        {
          "id": "8",
          "url": "https://www.youtube.com/watch?v=aGKrEsJkkKM",
        },
        {
          "id": "9",
          "url": "https://www.youtube.com/watch?v=mOeaGfPQyJQ",
        },
        {
          "id": "10",
          "url": "https://www.youtube.com/watch?v=o5E5kHOOoZg",
        },
        {
          "id": "11",
          "url": "https://www.youtube.com/watch?v=EL1mQrS2j2c",
        },
        {
          "id": "12",
          "url": "https://www.youtube.com/watch?v=NO_LednxGIg",
        },
      ]
    }

    const Data_2 = {
      "dataList": [
        {
          "id": "1",
          "url": "https://www.youtube.com/watch?v=gMBIiYr0K8w",
        },
        {
          "id": "2",
          "url": "https://www.youtube.com/watch?v=QNYjd8H7PMY",
        },
        {
          "id": "3",
          "url": "https://www.youtube.com/watch?v=2Is1MXI-HOk",
        },
        {
          "id": "4",
          "url": "https://www.youtube.com/watch?v=BA3fdfr6BjY",
        },
        {
          "id": "5",
          "url": "https://www.youtube.com/watch?v=AEuptkeS3mI",
        },
        {
          "id": "6",
          "url": "https://www.youtube.com/watch?v=QOKMsVGY5JI",
        },
        {
          "id": "7",
          "url": "https://www.youtube.com/watch?v=jO9Mg1f3QEw",
        },
        {
          "id": "8",
          "url": "https://www.youtube.com/watch?v=3-7WXgsIrgE",
        },
        {
          "id": "9",
          "url": "https://www.youtube.com/watch?v=2Ufs09S1JMc",
        },
        {
          "id": "10",
          "url": "https://www.youtube.com/watch?v=UIFJglsNIr8",
        },
        {
          "id": "11",
          "url": "https://www.youtube.com/watch?v=rTN6yCsVqCY",
        }
      ]
    }

    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Raag Darshan" image="banners/swarasankula05.jpg" />

        <div className="container ttm-service-description">
          <br /><br />
          <div className="row">
            <div className="col-md-5">
              <div className="ttm_single_image-wrapper">
                <img className="swaraimage img-fluid" src={process.env.PUBLIC_URL + "/assets/images/raaga/main1.jpg"} title="single-img-twenty" alt="single-img-twenty" />
              </div>
            </div>
            <div className="mob-only"><br /></div>
            <div className="col-md-7 justify" style={{ margin: "auto" }}>
              <div className="section-title with-desc text-left clearfix">
                <div className="title-header" style={{ borderTopColor: "red" }}>
                  <h4 className="title">Raag Darshan</h4>
                </div>
              </div>
              <p className="swaratext1">All the connoisseurs of Music would remember that we had released the Pt. Bhatkhande Project, consisting of all the 1896 compositions collected and published by Pt. Vishnu Narayan Bhatkhande in his “Kramik Pustak Malika”(6 volumes), in audio form (2 DVDs), in March 2015. Later in the year 2016, we had uploaded the entire project on the internet on our website – swarsankula.org – to be down loaded free of cost.</p>
              <p className="swaratext1 paddingtop-1">We are pleased to inform you all that we have embarked on another project titled – Raag Darshan – a series of lecture demonstrations on various Raagas of Hindustani Classical Music To start with, these will be on the 10 Ashraya Raagas viz. Kalyan, Bilawal, Khamaj, Bhairav, Poorvi, Marwa, Kafi,</p>
            </div>
            <div className="col-md-12 justify paddingtop-2" style={{ margin: "auto" }}>
              <p className="swaratext1">Asawari, Todi and Bhairavi. The Lec.Demo is by Pt. Indudhar Nirody, accompanied on Tabla by Pt. Ramesh Dhannur. Harmonioum accompaniment has been provided by Pt. Veerabhadraiah Hiremath in some cases and Shri Sriram Bhat in some other cases.</p>
            </div>
            <div className="col-lg-12 justify paddingtop-1">
              <p className="swaratext1">The Raagdarshan project was inagurated by Sri P.Nithyanand and Sri Rakesh .Swara sankula sangeetha sabha is grateful to Kemps Center For Learning ,Mysuru for providing space for recording the project in their premises.</p>
              <p className="swaratext1 paddingtop-1">As part of our policy that  all the lovers of Music get benefit out of our projects, we have uploaded this project as well on our website and can be downloaded free of cost. Those who, however, desire to have the video DVDs of this project (a set of 3 DVDs) may contact us for the same at our address.</p>
            </div>
            <div className="col-lg-12 paddingtop-2">
              <h6 className="swaratext1">Dr.MS Bhaskar</h6>
              <p className="swaratext1">President</p>
            </div>
            <div className="col-lg-12 paddingtop-1">
              <hr />
              <div className="section-title with-desc text-left clearfix">
                <div className="title-header" style={{ borderTopColor: "red" }}>
                  <h4 className="title">Raag Darshan Videos (Kannada)</h4>
                </div>
              </div>
            </div>

            {
              Data_1.dataList.map((data, i) => (
                <div className="col-lg-3 paddingtop-2">
                  <div class="video-container">
                    <iframe title="video-file" class="embedded-video-16-9" src={"https://www.youtube.com/embed/" + data.url.split("=")[1]} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                  </div>
                </div>
              ))
            }

            <div className="col-lg-12 paddingtop-1">
              <hr />
              <div className="section-title with-desc text-left clearfix">
                <div className="title-header" style={{ borderTopColor: "red" }}>
                  <h4 className="title">Raag Darshan Videos (English)</h4>
                </div>
              </div>
            </div>

            {
              Data_2.dataList.map((data, i) => (
                <div className="col-lg-3 paddingtop-2">
                  <div class="video-container">
                    <iframe title="video-file" class="embedded-video-16-9" src={"https://www.youtube.com/embed/" + data.url.split("=")[1]} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                  </div>
                </div>
              ))
            }
          </div>
          <br /><br />
        </div>

        {/* Footer */}
        <Footer />
      </div>
    )
  }
}
