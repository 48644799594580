import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import BackToTop from './BackToTop';
import { Styles } from "./styles/footer.js";

function FooterTwo() {
    return (
        <Styles>
            {/* Footer Two */}
            <footer className="footer2" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/footer-bg.jpg)` }}>
                <Container>
                    <Row>
                        <Col md="3">
                            <div className="f-newsletter">
                                <div className="box-content">
                                    <h5>Get in Touch</h5>
                                    <p>
                                        #1226, Gange Road, 3rd cross,<br/>
                                        G & H Block, Kuvempunagara, Mysuru,<br/>
                                        Karnataka India-570023.<br/>
                                        bhaskarmuthathi@gmail.com<br/>
                                        +91 99019 02969</p>
                                </div>
                            </div>
                        </Col>
                        <Col md="9">
                            <div className="f-links">
                                <h5>Quick Links</h5>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <ul className="list-unstyled">
                                            <li><Link to={process.env.PUBLIC_URL + "/V-S"}><i className="las la-angle-right"></i>Vision and Mission</Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + "/Members"}><i className="las la-angle-right"></i>Members</Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + "/History"}><i className="las la-angle-right"></i>History</Link></li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-4">
                                        <ul className="list-unstyled">
                                            <li><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-right"></i>Home</Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + "/Video-Coverage"}><i className="las la-angle-right"></i>Video Coverage</Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + "/Press-Articles"}><i className="las la-angle-right"></i>Press Articles</Link></li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-4">
                                        <ul className="list-unstyled">
                                            <li><Link to={process.env.PUBLIC_URL + "/Programmes"}><i className="las la-angle-right"></i>Programmes</Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + "/Bhatkhande-Project"}><i className="las la-angle-right"></i>Bhatkhande Project</Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + "/Bhatkhande-Audio"}><i className="las la-angle-right"></i>Bhatkhande Audio</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md="12">
                            <div className="copytext-area text-center">
                                <p>Copyright &copy; 2022 | Designed &#38; Developed by <a href={process.env.PUBLIC_URL + "/"} target="_blank" rel="noopener noreferrer">Shoonyeka Solutions Mysuru</a></p>
                            </div>
                        </Col>
                    </Row>
                </Container>

                {/* Back To Top  */}
                <BackToTop />
            </footer>
        </Styles>
    );
}

export default FooterTwo
