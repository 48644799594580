import React, { Component } from 'react';
import Banner from '../components/common/Banner';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';

export default class BhatkhandeAudio extends Component {

  state = {
    Raag: [],
    StartingWord: [],
    SerialNo: [],
    data: [
      {
        "SL": "A0001",
        "RAAG": "Yaman",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ni dha pa ma pa ga ma pa",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0001 Yaman Teental(m.laya) Ni dha pa ma pa.mp3"
      },
      {
        "SL": "A0002",
        "RAAG": "Yaman",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ni dha pa ma ga re ",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0002 Yaman Teental(m.laya) Ni dha pa ma ga re.mp3"
      },
      {
        "SL": "A0003",
        "RAAG": "Yaman kalyan",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Yeman Kalyan",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0003 Yaman kalyan Chowtal(Vilambit) Yeman Kalyan.mp3"
      },
      {
        "SL": "A0004",
        "RAAG": "Yaman kalyan",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Saba Gunijan",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0004 Yaman kalyan Ektal(m.laya) Saba Gunijan.mp3"
      },
      {
        "SL": "A0005",
        "RAAG": "Yaman",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Sadashiv BhajMana",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0005 Yaman Teental(m.laya) Sadashiv BhajMana.mp3"
      },
      {
        "SL": "A0006",
        "RAAG": "Yaman",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Gurubin Kaise",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0006 Yaman Teental(m.laya) Gurubin Kaise.mp3"
      },
      {
        "SL": "A0007",
        "RAAG": "Yaman",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Bhaj man karuna",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0007 Yaman Ektal(m.laya) Bhaj man karuna.mp3"
      },
      {
        "SL": "A0008",
        "RAAG": "Yaman kalyan",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Avagun na ki jiye",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0008 Yaman kalyan Teental(m.laya) Avagun na ki jiye.mp3"
      },
      {
        "SL": "A0009",
        "RAAG": "Yaman kalyan",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Piyarava Tehari",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0009 Yaman kalyan Teental(m.laya) Piyarava Tehari.mp3"
      },
      {
        "SL": "A0010",
        "RAAG": "Yaman",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Mandar Mana Laye",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0010 Yaman Teental(m.laya) Mandar Mana Laye.mp3"
      },
      {
        "SL": "A0011",
        "RAAG": "Yaman kalyan",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ale Nabi Aoulade",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0011 Yaman kalyan Teental(m.laya) Ale Nabi Aoulade.mp3"
      },
      {
        "SL": "A0012",
        "RAAG": "Yaman kalyan",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Langar Turak jina",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0012 Yaman kalyan Teental(m.laya) Langar Turak jina.mp3"
      },
      {
        "SL": "A0013",
        "RAAG": "Yaman",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Hey Ganaraj",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0013 Yaman Teental(m.laya) Hey Ganaraj.mp3"
      },
      {
        "SL": "A0014",
        "RAAG": "Yaman",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Aahat anahat Bhedh",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0014 Yaman Teental(m.laya) Aahat anahat Bhedh.mp3"
      },
      {
        "SL": "A0015",
        "RAAG": "Yaman",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Mana tu kaahen dheer",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0015 Yaman Teental(m.laya) Mana tu kaahen dheer.mp3"
      },
      {
        "SL": "A0016",
        "RAAG": "Yaman",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Piyaki Nazariya",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0016 Yaman Teental(m.laya) Piyaki Nazariya.mp3"
      },
      {
        "SL": "A0017",
        "RAAG": "Yaman",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kaho sakhi Piya",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0017 Yaman Teental(m.laya) Kaho sakhi Piya.mp3"
      },
      {
        "SL": "A0018",
        "RAAG": "Yaman",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Anime ki taharni maayi",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0018 Yaman Teental(m.laya) Anime ki taharni maayi.mp3"
      },
      {
        "SL": "A0019",
        "RAAG": "Yaman",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ari Yeri Ali Piyabin",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0019 Yaman Teental(m.laya) Ari Yeri Ali Piyabin.mp3"
      },
      {
        "SL": "A0020",
        "RAAG": "Yaman",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Saware di surat",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0020 Yaman Teental(m.laya) Saware di surat.mp3"
      },
      {
        "SL": "A0021",
        "RAAG": "Yaman",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Bhaj Hari Naam",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0021 Yaman Teental(m.laya) Bhaj Hari Naam.mp3"
      },
      {
        "SL": "A0022",
        "RAAG": "Yaman",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Jiyo Karo Koti",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0022 Yaman Ektal(Vilambit) Jiyo Karo Koti.mp3"
      },
      {
        "SL": "A0023",
        "RAAG": "Yaman",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Sumaran karoo mai",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0023 Yaman Tilwada(Vilambit) Sumaran karoo mai.mp3"
      },
      {
        "SL": "A0024",
        "RAAG": "Yaman",
        "TAAL": "Jhumra(Vilambit)",
        "STARTING_WORDS": "Banare balaiya",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0024 Yaman Jhumra(Vilambit) Banare balaiya.mp3"
      },
      {
        "SL": "A0025",
        "RAAG": "Yaman",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Yaa Nabi yaa rasulilla",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0025 Yaman Tilwada(Vilambit) Yaa Nabi yaa rasulilla.mp3"
      },
      {
        "SL": "A0026",
        "RAAG": "Yaman",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Pat tore kavana de",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0026 Yaman Tilwada(Vilambit) Pat tore kavana de.mp3"
      },
      {
        "SL": "A0027",
        "RAAG": "Yaman",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Yeri lal mile",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0027 Yaman Ektal(Vilambit) Yeri lal mile.mp3"
      },
      {
        "SL": "A0028",
        "RAAG": "Yaman",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Mahumad arabi",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0028 Yaman Ektal(Vilambit) Mahumad arabi.mp3"
      },
      {
        "SL": "A0029",
        "RAAG": "Yaman kalyan",
        "TAAL": "Tilawada(Vilambit)",
        "STARTING_WORDS": "Palkan se mag jhaaru",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0029 Yaman kalyan Tilawada(Vilambit) Palkan se mag jhaaru.mp3"
      },
      {
        "SL": "A0030",
        "RAAG": "Yaman",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Kaho sakhi kaise",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0030 Yaman Ektal(Vilambit) Kaho sakhi kaise.mp3"
      },
      {
        "SL": "A0031",
        "RAAG": "Yaman",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Mera mana baand",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0031 Yaman Ektal(Vilambit) Mera mana baand.mp3"
      },
      {
        "SL": "A0032",
        "RAAG": "Yaman kalyan",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Sohela nandake",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0032 Yaman kalyan Ektal(Vilambit) Sohela nandake.mp3"
      },
      {
        "SL": "A0033",
        "RAAG": "Yaman",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Diyara mai vaarungi",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0033 Yaman Ektal(Vilambit) Diyara mai vaarungi.mp3"
      },
      {
        "SL": "A0034",
        "RAAG": "Yaman",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Yaarda mainu",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0034 Yaman Ektal(Vilambit) Yaarda mainu.mp3"
      },
      {
        "SL": "A0035",
        "RAAG": "Yaman",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Salonare baalam mora",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0035 Yaman Teental(m.laya) Salonare baalam mora.mp3"
      },
      {
        "SL": "A0036",
        "RAAG": "Yaman",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Odelete dele tadre",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0036 Yaman Teental(m.laya) Odelete dele tadre.mp3"
      },
      {
        "SL": "A0037",
        "RAAG": "Yaman kalyan",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tan naadredre tom",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0037 Yaman kalyan Teental(m.laya) Tan naadredre tom.mp3"
      },
      {
        "SL": "A0038",
        "RAAG": "Yaman kalyan",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Yare man yalali yalala",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0038 Yaman kalyan Ektal(Vilambit) Yare man yalali yalala.mp3"
      },
      {
        "SL": "A0039",
        "RAAG": "Yaman kalyan",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Tele naadir deem",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0039 Yaman kalyan Tilwada(Vilambit) Tele naadir deem.mp3"
      },
      {
        "SL": "A0040",
        "RAAG": "Yaman",
        "TAAL": "Jhumara(Vilambit)",
        "STARTING_WORDS": "Dara dani daraa daraa",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0040 Yaman Jhumara(Vilambit) Dara dani daraa daraa.mp3"
      },
      {
        "SL": "A0041",
        "RAAG": "Yaman kalyan",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Toohi bhaj bhajre",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0041 Yaman kalyan Chowtal(Vilambit) Toohi bhaj bhajre.mp3"
      },
      {
        "SL": "A0042",
        "RAAG": "Yaman kalyan",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Peer dastageer",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0042 Yaman kalyan Chowtal(Vilambit) Peer dastageer.mp3"
      },
      {
        "SL": "A0043",
        "RAAG": "Yaman kalyan",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Taan taal sohi gaave",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0043 Yaman kalyan Chowtal(Vilambit) Taan taal sohi gaave.mp3"
      },
      {
        "SL": "A0044",
        "RAAG": "Yaman kalyan",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Charanana sukha",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0044 Yaman kalyan Chowtal(Vilambit) Charanana sukha.mp3"
      },
      {
        "SL": "A0045",
        "RAAG": "Yaman kalyan",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Ye takat hu tehari",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0045 Yaman kalyan Chowtal(Vilambit) Ye takat hu tehari.mp3"
      },
      {
        "SL": "A0046",
        "RAAG": "Yaman kalyan",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Pratham shareer",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0046 Yaman kalyan Chowtal(Vilambit) Pratham shareer.mp3"
      },
      {
        "SL": "A0047",
        "RAAG": "Yaman kalyan",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Kidho tove chetak",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0047 Yaman kalyan Dhamar(Vilambit) Kidho tove chetak.mp3"
      },
      {
        "SL": "A0048",
        "RAAG": "Yaman kalyan",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Kesar ghor ke",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0048 Yaman kalyan Dhamar(Vilambit) Kesar ghor ke.mp3"
      },
      {
        "SL": "A0049",
        "RAAG": "Bhoopali",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "sa sa dha pa ga re sa",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0049 Bhoopali Teental(m.laya) sa sa dha pa ga re sa.mp3"
      },
      {
        "SL": "A0050",
        "RAAG": "Bhoopali",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ma ni baraja gaay",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0050 Bhoopali Teental(m.laya) Ma ni baraja gaay.mp3"
      },
      {
        "SL": "A0051",
        "RAAG": "Bhoopali",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Jaavu tore charan",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0051 Bhoopali Teental(m.laya) Jaavu tore charan.mp3"
      },
      {
        "SL": "A0052",
        "RAAG": "Bhoopali",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Naman kar chatur",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0052 Bhoopali Teental(m.laya) Naman kar chatur.mp3"
      },
      {
        "SL": "A0053",
        "RAAG": "Bhoopali",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Haat na karo",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0053 Bhoopali Teental(m.laya) Haat na karo.mp3"
      },
      {
        "SL": "A0054",
        "RAAG": "Bhoopali",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ariye mori maa",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0054 Bhoopali Teental(m.laya) Ariye mori maa.mp3"
      },
      {
        "SL": "A0055",
        "RAAG": "Bhoopali",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Darashan deeje",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0055 Bhoopali Teental(m.laya) Darashan deeje.mp3"
      },
      {
        "SL": "A0056",
        "RAAG": "Bhoopali",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Jhanjh mandarava",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0056 Bhoopali Teental(m.laya) Jhanjh mandarava.mp3"
      },
      {
        "SL": "A0057",
        "RAAG": "Bhoopali",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Itano joban par maan",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0057 Bhoopali Teental(m.laya) Itano joban par maan.mp3"
      },
      {
        "SL": "A0058",
        "RAAG": "Bhoopali",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Jabase tumasan",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0058 Bhoopali Teental(m.laya) Jabase tumasan.mp3"
      },
      {
        "SL": "A0059",
        "RAAG": "Bhoopali",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tu m hamsan jina bolo",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0059 Bhoopali Teental(m.laya) Tum hamsan jina bolo.mp3"
      },
      {
        "SL": "A0060",
        "RAAG": "Bhoopali",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Noo man joban",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0060 Bhoopali Teental(m.laya) Noo man joban.mp3"
      },
      {
        "SL": "A0061",
        "RAAG": "Bhoopali",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tomtan derena tanom",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0061 Bhoopali Teental(m.laya) Tomtan derena tanom.mp3"
      },
      {
        "SL": "A0062",
        "RAAG": "Bhoopali",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Taredaani deem",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0062 Bhoopali Teental(m.laya) Taredaani deem.mp3"
      },
      {
        "SL": "A0063",
        "RAAG": "Bhoopali",
        "TAAL": "Adachowtal(m.laya)",
        "STARTING_WORDS": "Udatan dheteleta nom",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0063 Bhoopali Adachowtal(m.laya) Udatan dheteleta nom.mp3"
      },
      {
        "SL": "A0064",
        "RAAG": "Bhoopali",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Soodhe bolat naahi",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0064 Bhoopali Tilwada(Vilambit) Soodhe bolat naahi.mp3"
      },
      {
        "SL": "A0065",
        "RAAG": "Bhoopali",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Baran bhayi raath",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0065 Bhoopali Tilwada(Vilambit) Baran bhayi raath.mp3"
      },
      {
        "SL": "A0066",
        "RAAG": "Bhoopali",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Yeri aaj bhayilava",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0066 Bhoopali Tilwada(Vilambit) Yeri aaj bhayilava.mp3"
      },
      {
        "SL": "A0067",
        "RAAG": "Bhoopali",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Jabahi sab nee rapat",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0067 Bhoopali Tilwada(Vilambit) Jabahi sab neerapat.mp3"
      },
      {
        "SL": "A0068",
        "RAAG": "Bhoopali",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Jabaho jaani",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0068 Bhoopali Tilwada(Vilambit) Jabaho jaani.mp3"
      },
      {
        "SL": "A0069",
        "RAAG": "Bhoopali",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Jabaho Jaanam jaan",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0069 Bhoopali Tilwada(Vilambit) Jabaho Jaanam jaan.mp3"
      },
      {
        "SL": "A0070",
        "RAAG": "Bhoopali",
        "TAAL": "Adachowtal-Vilambit",
        "STARTING_WORDS": "Tat bitat ghan shikhar",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0070 Bhoopali Adachowtal-Vilambit Tat bitat ghan shikhar.mp3"
      },
      {
        "SL": "A0071",
        "RAAG": "Bhoopali",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Dil dara mustaphake",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0071 Bhoopali Ektal(Vilambit) Dil dara mustaphake.mp3"
      },
      {
        "SL": "A0072",
        "RAAG": "Bhoopali",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Salone lal kaise",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0072 Bhoopali Ektal(Vilambit) Salone lal kaise.mp3"
      },
      {
        "SL": "A0073",
        "RAAG": "Bhoopali",
        "TAAL": "Jhumra(Vilambit)",
        "STARTING_WORDS": "Ab maanaleri pyaari",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0073 Bhoopali Jhumra(Vilambit) Ab maanaleri pyaari.mp3"
      },
      {
        "SL": "A0074",
        "RAAG": "Bhoopali",
        "TAAL": "Jhumra(Vilambit)",
        "STARTING_WORDS": "Ab maanale-2nd version",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0074 Bhoopali Jhumra(Vilambit) Ab maanale-2nd Version.mp3"
      },
      {
        "SL": "A0075",
        "RAAG": "Bhoopali",
        "TAAL": "Teevra(m.laya)",
        "STARTING_WORDS": "Pratham surko saadhe",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0075 Bhoopali Teevra(m.laya) Pratham surko saadhe.mp3"
      },
      {
        "SL": "A0076",
        "RAAG": "Bhoopali",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Saadhe sur saadhe",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0076 Bhoopali Sultal(m.laya) Saadhe sur saadhe.mp3"
      },
      {
        "SL": "A0077",
        "RAAG": "Bhoopali",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Naad ko saadhe",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0077 Bhoopali Sultal(m.laya) Naad ko saadhe.mp3"
      },
      {
        "SL": "A0078",
        "RAAG": "Bhoopali",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Naache sangeet",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0078 Bhoopali Sultal(m.laya) Naache sangeet.mp3"
      },
      {
        "SL": "A0079",
        "RAAG": "Bhoopali",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Kari karoo kith jaavoo",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0079 Bhoopali Sultal(m.laya) Kari karoo kith jaavoo.mp3"
      },
      {
        "SL": "A0080",
        "RAAG": "Bhoopali",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Karat mohan alaap",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0080 Bhoopali Jhaptal(m.laya) Karat mohan alaap.mp3"
      },
      {
        "SL": "A0081",
        "RAAG": "Bhoopali",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Toohi soorya",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0081 Bhoopali Chowtal(Vilambit) Toohi soorya.mp3"
      },
      {
        "SL": "A0082",
        "RAAG": "Bhoopali",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Paravardigaar kartaar",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0082 Bhoopali Chowtal(Vilambit) Paravardigaar kartaar.mp3"
      },
      {
        "SL": "A0083",
        "RAAG": "Bhoopali",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Basakar lino apano",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0083 Bhoopali Dhamar(Vilambit) Basakar lino apano.mp3"
      },
      {
        "SL": "A0084",
        "RAAG": "Bhoopali",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Laal pichkaari",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0084 Bhoopali Dhamar(Vilambit) Laal pichkaari.mp3"
      },
      {
        "SL": "A0085",
        "RAAG": "Hameer",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "sa ni dha pa ma dha",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0085 Hameer Teental(m.laya) sa ni dha pa ma dha.mp3"
      },
      {
        "SL": "A0086",
        "RAAG": "Hameer",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Gaavat raag hameer",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0086 Hameer Teental(m.laya) Gaavat raag hameer.mp3"
      },
      {
        "SL": "A0087",
        "RAAG": "Hameer",
        "TAAL": "Roopak(m.laya)",
        "STARTING_WORDS": "Kahat raag hameer",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0087 Hameer Roopak(m.laya) Kahat raag hameer.mp3"
      },
      {
        "SL": "A0088",
        "RAAG": "Hameer",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Naman karoo mai",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0088 Hameer Teental(m.laya) Naman karoo mai.mp3"
      },
      {
        "SL": "A0089",
        "RAAG": "Hameer",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ganaadhipa ganraaj",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0089 Hameer Teental(m.laya) Ganaadhipa ganraaj.mp3"
      },
      {
        "SL": "A0090",
        "RAAG": "Hameer",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kaise ghar jaavu",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0090 Hameer Teental(m.laya) Kaise ghar jaavu.mp3"
      },
      {
        "SL": "A0091",
        "RAAG": "Hameer",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Mathan dharan bhaye",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0091 Hameer Teental(m.laya) Mathan dharan bhaye.mp3"
      },
      {
        "SL": "A0092",
        "RAAG": "Hameer",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Mendere yaar aavi",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0092 Hameer Ektal(m.laya) Mendere yaar aavi.mp3"
      },
      {
        "SL": "A0093",
        "RAAG": "Hameer",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Surajhaaya raheere",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0093 Hameer Ektal(Vilambit) Surajhaaya raheere.mp3"
      },
      {
        "SL": "A0094",
        "RAAG": "Hameer",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Kareem karmaakare",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0094 Hameer Ektal(Vilambit) Kareem karmaakare.mp3"
      },
      {
        "SL": "A0095",
        "RAAG": "Hameer",
        "TAAL": "Ektal)Vilambit)",
        "STARTING_WORDS": "Moraare Alabela",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0095 Hameer Ektal)Vilambit) Moraare Alabela.mp3"
      },
      {
        "SL": "A0096",
        "RAAG": "Hameer",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Ahe baajmaara dil",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0096 Hameer Ektal(Vilambit) Ahe baajmaara dil.mp3"
      },
      {
        "SL": "A0097",
        "RAAG": "Hameer",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Chel chabilaa Meraa",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0097 Hameer Ektal(Vilambit) Chel chabilaa Meraa.mp3"
      },
      {
        "SL": "A0098",
        "RAAG": "Hameer",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Dendee allahi jane",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0098 Hameer Tilwada(Vilambit) Dendee allahi jane.mp3"
      },
      {
        "SL": "A0099",
        "RAAG": "Hameer",
        "TAAL": "Jhumra(Vilambit)",
        "STARTING_WORDS": "Chameli Phooli chowpa",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0099 Hameer Jhumra(Vilambit) Chameli Phooli chowpa.mp3"
      },
      {
        "SL": "A0100",
        "RAAG": "Hameer",
        "TAAL": "Jhumra(Vilambit)",
        "STARTING_WORDS": "Apane lalanke aage",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0100 Hameer Jhumra(Vilambit) Apane lalanke aage.mp3"
      },
      {
        "SL": "A0101",
        "RAAG": "Hameer",
        "TAAL": "Roopak(m.laya)",
        "STARTING_WORDS": "Hey jagadeesh chatur",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0101 Hameer Roopak(m.laya) Hey jagadeesh chatur.mp3"
      },
      {
        "SL": "A0102",
        "RAAG": "Hameer",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Prabal jas Keerat",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0102 Hameer Jhaptal(m.laya) Prabal jas Keerat.mp3"
      },
      {
        "SL": "A0103",
        "RAAG": "Hameer",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Karam kartaar karo",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0103 Hameer Jhaptal(m.laya) Karam kartaar karo.mp3"
      },
      {
        "SL": "A0104",
        "RAAG": "Hameer",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Naad dariya bataaye",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0104 Hameer Jhaptal(m.laya) Naad dariya bataaye.mp3"
      },
      {
        "SL": "A0105",
        "RAAG": "Hameer",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Dekheeri Aajnab",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0105 Hameer Jhaptal(m.laya) Dekheeri Aajnab.mp3"
      },
      {
        "SL": "A0106",
        "RAAG": "Hameer",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Kaarman paramaan",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0106 Hameer Chowtal(Vilambit) Kaarman paramaan.mp3"
      },
      {
        "SL": "A0107",
        "RAAG": "Hameer",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Saghan ban",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0107 Hameer Chowtal(Vilambit) Saghan ban.mp3"
      },
      {
        "SL": "A0108",
        "RAAG": "Hameer",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Hey sakhi shyaam",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0108 Hameer Chowtal(Vilambit) Hey sakhi shyaam.mp3"
      },
      {
        "SL": "A0109",
        "RAAG": "Hameer",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Saaras badani",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0109 Hameer Chowtal(Vilambit) Saaras badani.mp3"
      },
      {
        "SL": "A0110",
        "RAAG": "Hameer",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Abir gulal lal kesar",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0110 Hameer Dhamar(Vilambit) Abir gulal lal kesar.mp3"
      },
      {
        "SL": "A0111",
        "RAAG": "Hameer",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Rang jo gulaal",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0111 Hameer Dhamar(Vilambit) Rang jo gulaal.mp3"
      },
      {
        "SL": "A0112",
        "RAAG": "Hameer",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Derderder deemdeem",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0112 Hameer Teental(m.laya) Derderder deemdeem.mp3"
      },
      {
        "SL": "A0113",
        "RAAG": "Hameer",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tananatan deem",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0113 Hameer Teental(m.laya) Tananatan deem.mp3"
      },
      {
        "SL": "A0114",
        "RAAG": "Hameer",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Taanomtanaa derena",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0114 Hameer Teental(m.laya) Taanomtanaa derena.mp3"
      },
      {
        "SL": "A0115",
        "RAAG": "Hameer",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Deemdara derder",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0115 Hameer Ektal(m.laya) Deemdara derder.mp3"
      },
      {
        "SL": "A0116",
        "RAAG": "Hameer",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Dirdirdirdirdirdir tom",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0116 Hameer Ektal(m.laya) Dirdirdirdirdirdir tom.mp3"
      },
      {
        "SL": "A0117",
        "RAAG": "Kedar",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "sa sa ma ma ma",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0117 Kedar Jhaptal(m.laya) sa sa ma ma ma.mp3"
      },
      {
        "SL": "A0118",
        "RAAG": "Kedar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Sharan prabhu",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0118 Kedar Teental(m.laya) Sharan prabhu.mp3"
      },
      {
        "SL": "A0119",
        "RAAG": "Kedar",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Sab kahat kedar",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0119 Kedar Ektal(m.laya) Sab kahat kedar.mp3"
      },
      {
        "SL": "A0120",
        "RAAG": "Kedar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Sharan aayo naath",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0120 Kedar Teental(m.laya) Sharan aayo naath.mp3"
      },
      {
        "SL": "A0121",
        "RAAG": "Kedar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Soch samazh mana",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0121 Kedar Teental(m.laya) Soch samazh mana.mp3"
      },
      {
        "SL": "A0122",
        "RAAG": "Kedar",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Bhaj bhaj manujaare",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0122 Kedar Ektal(m.laya) Bhaj bhaj manujaare.mp3"
      },
      {
        "SL": "A0123",
        "RAAG": "Kedar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Bhadi hai kamala",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0123 Kedar Teental(m.laya) Bhadi hai kamala.mp3"
      },
      {
        "SL": "A0124",
        "RAAG": "Kedar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Akhiya palan laagi",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0124 Kedar Teental(m.laya) Akhiya palan laagi.mp3"
      },
      {
        "SL": "A0125",
        "RAAG": "Kedar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kanganava mora",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0125 Kedar Teental(m.laya) Kanganava mora.mp3"
      },
      {
        "SL": "A0126",
        "RAAG": "Kedar",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Sughar chatur baiyaa",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0126 Kedar Ektal(m.laya) Sughar chatur baiyaa.mp3"
      },
      {
        "SL": "A0127",
        "RAAG": "Kedar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Paayal baaji",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0127 Kedar Teental(m.laya) Paayal baaji.mp3"
      },
      {
        "SL": "A0128",
        "RAAG": "Kedar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kaanhare nandnandan",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0128 Kedar Teental(m.laya) Kaanhare nandnandan.mp3"
      },
      {
        "SL": "A0129",
        "RAAG": "Kedar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Gori hatjin kariye",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0129 Kedar Teental(m.laya) Gori hatjin kariye.mp3"
      },
      {
        "SL": "A0130",
        "RAAG": "Kedar",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Aarangeele aa ghar",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0130 Kedar Ektal(m.laya) Aarangeele aa ghar.mp3"
      },
      {
        "SL": "A0131",
        "RAAG": "Kedar",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Rang ki saari",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0131 Kedar Ektal(m.laya) Rang ki saari.mp3"
      },
      {
        "SL": "A0132",
        "RAAG": "Kedar",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Barakha rutkee",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0132 Kedar Tilwada(Vilambit) Barakha rutkee.mp3"
      },
      {
        "SL": "A0133",
        "RAAG": "Kedar",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Bahuta gayee",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0133 Kedar Ektal(Vilambit) Bahuta gayee.mp3"
      },
      {
        "SL": "A0134",
        "RAAG": "Kedar",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Mor bolere",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0134 Kedar Ektal(Vilambit) Mor bolere.mp3"
      },
      {
        "SL": "A0135",
        "RAAG": "Kedar",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Banthan kahaa",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0135 Kedar Tilwada(Vilambit) Banthan kahaa.mp3"
      },
      {
        "SL": "A0136",
        "RAAG": "Kedar",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Basanthi paag",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0136 Kedar Tilwada(Vilambit) Basanthi paag.mp3"
      },
      {
        "SL": "A0137",
        "RAAG": "Kedar",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Seja nis neend",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0137 Kedar Tilwada(Vilambit) Seja nis neend.mp3"
      },
      {
        "SL": "A0138",
        "RAAG": "Kedar",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Juganvaa chamake",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0138 Kedar Ektal(Vilambit) Juganvaa chamake.mp3"
      },
      {
        "SL": "A0139",
        "RAAG": "Kedar",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Jogi raavala rom",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0139 Kedar Tilwada(Vilambit) Jogi raavala rom.mp3"
      },
      {
        "SL": "A0140",
        "RAAG": "Kedar",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Ab kalanaa pare",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0140 Kedar Tilwada(Vilambit) Ab kalanaa pare.mp3"
      },
      {
        "SL": "A0141",
        "RAAG": "Kedar",
        "TAAL": "Roopak(m.laya)",
        "STARTING_WORDS": "Sharan kar tu gobind",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0141 Kedar Roopak(m.laya) Sharan kar tu gobind.mp3"
      },
      {
        "SL": "A0142",
        "RAAG": "Kedar",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Araz daasth meri",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0142 Kedar Jhaptal(m.laya) Araz daasth meri.mp3"
      },
      {
        "SL": "A0143",
        "RAAG": "Kedar",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Sarasati maata",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0143 Kedar Jhaptal(m.laya) Sarasati maata.mp3"
      },
      {
        "SL": "A0144",
        "RAAG": "Kedar",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Boond pavan",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0144 Kedar Chowtal(Vilambit) Boond pavan.mp3"
      },
      {
        "SL": "A0145",
        "RAAG": "Kedar",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Maayiree Jasoda",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0145 Kedar Chowtal(Vilambit) Maayiree Jasoda.mp3"
      },
      {
        "SL": "A0146",
        "RAAG": "Kedar",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Chatoorang gaavo",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0146 Kedar Chowtal(Vilambit) Chatoorang gaavo.mp3"
      },
      {
        "SL": "A0147",
        "RAAG": "Kedar",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Terori roop anoop",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0147 Kedar Chowtal(Vilambit) Terori roop anoop.mp3"
      },
      {
        "SL": "A0148",
        "RAAG": "Kedar",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Ye jogi jogee",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0148 Kedar Chowtal(Vilambit) Ye jogi jogee.mp3"
      },
      {
        "SL": "A0149",
        "RAAG": "Kedar",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Aaj birj ghar gharee",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0149 Kedar Dhamar(Vilambit) Aaj birj ghar gharee.mp3"
      },
      {
        "SL": "A0150",
        "RAAG": "Kedar",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Maan a taz deree",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0150 Kedar Dhamar(Vilambit) Maana taz deree.mp3"
      },
      {
        "SL": "A0151",
        "RAAG": "Kedar",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Chori chori maarat ho",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0151 Kedar Dhamar(Vilambit) Chori chori maarat ho.mp3"
      },
      {
        "SL": "A0152",
        "RAAG": "Kedar",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Aaj mose hori khelan",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0152 Kedar Dhamar(Vilambit) Aaj mose hori khelan.mp3"
      },
      {
        "SL": "A0153",
        "RAAG": "Kedar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Naadirdir daani tadani",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0153 Kedar Teental(m.laya) Naadirdir daani tadani.mp3"
      },
      {
        "SL": "A0154",
        "RAAG": "Kedar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Naadirdir tudir dirdirdir",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0154 Kedar Teental(m.laya) Naadirdir tudir dirdirdir.mp3"
      },
      {
        "SL": "A0155",
        "RAAG": "Kedar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Naadirdaani taardaani",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0155 Kedar Teental(m.laya) Naadirdaani taardaani.mp3"
      },
      {
        "SL": "A0156",
        "RAAG": "Kedar",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Daani nith drenna",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0156 Kedar Ektal(m.laya) Daani nith drenna.mp3"
      },
      {
        "SL": "A0157",
        "RAAG": "Kedar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tom tom tanana tan",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0157 Kedar Teental(m.laya) Tom tom tanana tan.mp3"
      },
      {
        "SL": "A0158",
        "RAAG": "Kedar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Udena tom tanana",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0158 Kedar Teental(m.laya) Udena tom tanana.mp3"
      },
      {
        "SL": "A0159",
        "RAAG": "Kedar",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Tom tan drenna",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0159 Kedar Tilwada(Vilambit) Tom tan drenna.mp3"
      },
      {
        "SL": "A0160",
        "RAAG": "Shuddh-kalyan",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "ga re sa ni dha",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0160 Shuddh-kalyan Teental(m.laya) ga re sa ni dha.mp3"
      },
      {
        "SL": "A0161",
        "RAAG": "Shuddh-kalyan",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Gaavo kalyan",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0161 Shuddh-kalyan Teental(m.laya) Gaavo kalyan.mp3"
      },
      {
        "SL": "A0162",
        "RAAG": "Shuddh-kalyan",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Gaavo sab sujaan",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0162 Shuddh-kalyan Jhaptal(m.laya) Gaavo sab sujaan.mp3"
      },
      {
        "SL": "A0163",
        "RAAG": "Shuddh-kalyan",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Karale guni aba tu",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0163 Shuddh-kalyan Chowtal(Vilambit) Karale guni aba tu.mp3"
      },
      {
        "SL": "A0164",
        "RAAG": "Shuddh-kalyan",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Baajore baajo",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0164 Shuddh-kalyan Teental(m.laya) Baajore baajo.mp3"
      },
      {
        "SL": "A0165",
        "RAAG": "Shuddh-kalyan",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Maanat naahi mo raa",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0165 Shuddh-kalyan Teental(m.laya) Maanat naahi moraa.mp3"
      },
      {
        "SL": "A0166",
        "RAAG": "Shuddh-kalyan",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Mandar baajo baajore",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0166 Shuddh-kalyan Teental(m.laya) Mandar baajo baajore.mp3"
      },
      {
        "SL": "A0167",
        "RAAG": "Shuddh-kalyan",
        "TAAL": "Sulfaq(m.laya)",
        "STARTING_WORDS": "Jaane guniyan ko",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0167 Shuddh-kalyan Sulfaq(m.laya) Jaane guniyan ko.mp3"
      },
      {
        "SL": "A0168",
        "RAAG": "Shuddh-kalyan",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Aa sarb manataakar",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0168 Shuddh-kalyan Jhaptal(m.laya) Aa sarb manataakar.mp3"
      },
      {
        "SL": "A0169",
        "RAAG": "Shuddh-kalyan",
        "TAAL": "Sulfaq(m.laya)",
        "STARTING_WORDS": "Allah naam sumran",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0169 Shuddh-kalyan Sulfaq(m.laya) Allah naam sumran.mp3"
      },
      {
        "SL": "A0170",
        "RAAG": "Shuddh-kalyan",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Jaako daata",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0170 Shuddh-kalyan Teental(m.laya) Jaako daata.mp3"
      },
      {
        "SL": "A0171",
        "RAAG": "Shuddh-kalyan",
        "TAAL": "Jhumra(Vilambit)",
        "STARTING_WORDS": "Kaayee tak sirave",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0171 Shuddh-kalyan Jhumra(Vilambit) Kaayee tak sirave.mp3"
      },
      {
        "SL": "A0172",
        "RAAG": "Shuddh-kalyan",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Allahhi badaa saayee",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0172 Shuddh-kalyan Ektal(Vilambit) Allahhi badaa saayee.mp3"
      },
      {
        "SL": "A0173",
        "RAAG": "Shuddh-kalyan",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Bolan laagi papihaari",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0173 Shuddh-kalyan Ektal(Vilambit) Bolan laagi papihaari.mp3"
      },
      {
        "SL": "A0174",
        "RAAG": "Shuddh-kalyan",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Baran nanandiyaa",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0174 Shuddh-kalyan Tilwada(Vilambit) Baran nanandiyaa.mp3"
      },
      {
        "SL": "A0175",
        "RAAG": "Shuddh-kalyan",
        "TAAL": "Chowtal(m.laya)",
        "STARTING_WORDS": "Dhar re mana dhar tu",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0175 Shuddh-kalyan Chowtal(m.laya) Dhar re mana dhar tu.mp3"
      },
      {
        "SL": "A0176",
        "RAAG": "Shuddh-kalyan",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Yohee roung mahal",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0176 Shuddh-kalyan Chowtal(Vilambit) Yohee roung mahal.mp3"
      },
      {
        "SL": "A0177",
        "RAAG": "Shuddh-kalyan",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Haadee allah saaheb",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0177 Shuddh-kalyan Chowtal(Vilambit) Haadee allah saaheb.mp3"
      },
      {
        "SL": "A0178",
        "RAAG": "Shuddh-kalyan",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Tuva sur bistaar",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0178 Shuddh-kalyan Chowtal(Vilambit) Tuva sur bistaar.mp3"
      },
      {
        "SL": "A0179",
        "RAAG": "Shuddh-kalyan",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Jyohee sattaar",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0179 Shuddh-kalyan Chowtal(Vilambit) Jyohee sattaar.mp3"
      },
      {
        "SL": "A0180",
        "RAAG": "Shuddh-kalyan",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Hori khelan aaye kaan",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0180 Shuddh-kalyan Dhamar(Vilambit) Hori khelan aaye kaan.mp3"
      },
      {
        "SL": "A0181",
        "RAAG": "Shuddh-kalyan",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Khelat Dhamar",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0181 Shuddh-kalyan Dhamar(Vilambit) Khelat Dhamar.mp3"
      },
      {
        "SL": "A0182",
        "RAAG": "Shuddh-kalyan",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Maan jinkaro",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0182 Shuddh-kalyan Dhamar(Vilambit) Maan jinkaro.mp3"
      },
      {
        "SL": "A0183",
        "RAAG": "Shuddh-kalyan",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Taannu derenaa",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0183 Shuddh-kalyan Tilwada(Vilambit) Taannu derenaa.mp3"
      },
      {
        "SL": "A0184",
        "RAAG": "Kamod",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "re re pa pa dha dha pa",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0184 Kamod Teental(m.laya) re re pa pa dha dha pa.mp3"
      },
      {
        "SL": "A0185",
        "RAAG": "Kamod",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kahat manarang",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0185 Kamod Teental(m.laya) Kahat manarang.mp3"
      },
      {
        "SL": "A0186",
        "RAAG": "Kamod",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ruth basount sakhi",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0186 Kamod Teental(m.laya) Ruth basount sakhi.mp3"
      },
      {
        "SL": "A0187",
        "RAAG": "Kamod",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Barjori gaagar phori",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0187 Kamod Teental(m.laya) Barjori gaagar phori.mp3"
      },
      {
        "SL": "A0188",
        "RAAG": "Kamod",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Kouna tumhe batlaavat",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0188 Kamod Ektal(m.laya) Kouna tumhe batlaavat.mp3"
      },
      {
        "SL": "A0189",
        "RAAG": "Kamod",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Kaahe sochat tu",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0189 Kamod Ektal(m.laya) Kaahe sochat tu.mp3"
      },
      {
        "SL": "A0190",
        "RAAG": "Kamod",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tanman vaaroo",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0190 Kamod Teental(m.laya) Tanman vaaroo.mp3"
      },
      {
        "SL": "A0191",
        "RAAG": "Kamod",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Mori nayi lagan laagire",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0191 Kamod Ektal(m.laya) Mori nayi lagan laagire.mp3"
      },
      {
        "SL": "A0192",
        "RAAG": "Kamod",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Bangari mori",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0192 Kamod Ektal(m.laya) Bangari mori.mp3"
      },
      {
        "SL": "A0193",
        "RAAG": "Kamod",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kare jaanen doongi",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0193 Kamod Teental(m.laya) Kare jaanen doongi.mp3"
      },
      {
        "SL": "A0194",
        "RAAG": "Kamod",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Hunto janam",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0194 Kamod Tilwada(Vilambit) Hunto janam.mp3"
      },
      {
        "SL": "A0195",
        "RAAG": "Kamod",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Ye maati maalaniyaa",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0195 Kamod Tilwada(Vilambit) Ye maati maalaniyaa.mp3"
      },
      {
        "SL": "A0196",
        "RAAG": "Kamod",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Sohelaraa gaavore",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0196 Kamod Ektal(Vilambit) Sohelaraa gaavore.mp3"
      },
      {
        "SL": "A0197",
        "RAAG": "Kamod",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Jayathi nava",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0197 Kamod Jhaptal(m.laya) Jayathi nava.mp3"
      },
      {
        "SL": "A0198",
        "RAAG": "Kamod",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Gore badan par",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0198 Kamod Jhaptal(m.laya) Gore badan par.mp3"
      },
      {
        "SL": "A0199",
        "RAAG": "Kamod",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Deh tho maleen",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0199 Kamod Chowtal(Vilambit) Deh tho maleen.mp3"
      },
      {
        "SL": "A0200",
        "RAAG": "Kamod",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Gaiyaa saanjh ko",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0200 Kamod Chowtal(Vilambit) Gaiyaa saanjh ko.mp3"
      },
      {
        "SL": "A0201",
        "RAAG": "Kamod",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Matwaaro thado",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0201 Kamod Dhamar(Vilambit) Matwaaro thado.mp3"
      },
      {
        "SL": "A0202",
        "RAAG": "Kamod",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Laal mori choonar",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0202 Kamod Dhamar(Vilambit) Laal mori choonar.mp3"
      },
      {
        "SL": "A0203",
        "RAAG": "Kamod",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Raaja maan hori",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0203 Kamod Dhamar(Vilambit) Raaja maan hori.mp3"
      },
      {
        "SL": "A0204",
        "RAAG": "Chaayanat",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "dha dha pa pa",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0204 Chaayanat Teental(m.laya) dha dha pa pa.mp3"
      },
      {
        "SL": "A0205",
        "RAAG": "Chaayanat",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Sab koyi reejhat",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0205 Chaayanat Teental(m.laya) Sab koyi reejhat.mp3"
      },
      {
        "SL": "A0206",
        "RAAG": "Chaayanat",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Bhari gagari mori",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0206 Chaayanat Teental(m.laya) Bhari gagari mori.mp3"
      },
      {
        "SL": "A0207",
        "RAAG": "Chaayanat",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Sab taz bhajho",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0207 Chaayanat Teental(m.laya) Sab taz bhajho.mp3"
      },
      {
        "SL": "A0208",
        "RAAG": "Chaayanat",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Abke raakho",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0208 Chaayanat Teental(m.laya) Abke raakho.mp3"
      },
      {
        "SL": "A0209",
        "RAAG": "Chaayanat",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Pyari navali ladalee",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0209 Chaayanat Teental(m.laya) Pyari navali ladalee.mp3"
      },
      {
        "SL": "A0210",
        "RAAG": "Chaayanat",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Baar baar kahe",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0210 Chaayanat Teental(m.laya) Baar baar kahe.mp3"
      },
      {
        "SL": "A0211",
        "RAAG": "Chaayanat",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Pal pal socha bichaar",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0211 Chaayanat Teental(m.laya) Pal pal socha bichaar.mp3"
      },
      {
        "SL": "A0212",
        "RAAG": "Chaayanat",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Maadhuri murat",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0212 Chaayanat Teental(m.laya) Maadhuri murat.mp3"
      },
      {
        "SL": "A0213",
        "RAAG": "Chaayanat",
        "TAAL": "Roopak(Vilambit)",
        "STARTING_WORDS": "Ye joban moraa",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0213 Chaayanat Roopak(Vilambit) Ye joban moraa.mp3"
      },
      {
        "SL": "A0214",
        "RAAG": "Chaayanat",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Ye karath ho mose",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0214 Chaayanat Ektal(Vilambit) Ye karath ho mose.mp3"
      },
      {
        "SL": "A0215",
        "RAAG": "Chaayanat",
        "TAAL": "Jhumra(Vilambit)",
        "STARTING_WORDS": "Yeri ab gunde laavori",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0215 Chaayanat Jhumra(Vilambit) Yeri ab gunde laavori.mp3"
      },
      {
        "SL": "A0216",
        "RAAG": "Chaayanat",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Tanman dhan sab",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0216 Chaayanat Tilwada(Vilambit) Tanman dhan sab.mp3"
      },
      {
        "SL": "A0217",
        "RAAG": "Chaayanat",
        "TAAL": "Jhumra(Vilambit)",
        "STARTING_WORDS": "Nevarki jhankar",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0217 Chaayanat Jhumra(Vilambit) Nevarki jhankar.mp3"
      },
      {
        "SL": "A0218",
        "RAAG": "Chaayanat",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Pan nabeeri banaaye",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0218 Chaayanat Ektal(Vilambit) Pan nabeeri banaaye.mp3"
      },
      {
        "SL": "A0219",
        "RAAG": "Chaayanat",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Maanani maan",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0219 Chaayanat Chowtal(Vilambit) Maanani maan.mp3"
      },
      {
        "SL": "A0220",
        "RAAG": "Chaayanat",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Chaayaa pari jamuna",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0220 Chaayanat Chowtal(Vilambit) Chaayaa pari jamuna.mp3"
      },
      {
        "SL": "A0221",
        "RAAG": "Chaayanat",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Ab mose khelan laage",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0221 Chaayanat Dhamar(Vilambit) Ab mose khelan laage.mp3"
      },
      {
        "SL": "A0222",
        "RAAG": "Chaayanat",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Lachkat aaweho gori",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0222 Chaayanat Dhamar(Vilambit) Lachkat aaweho gori.mp3"
      },
      {
        "SL": "A0223",
        "RAAG": "Chaayanat",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Dredredredre tananit",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0223 Chaayanat Teental(m.laya) Dredredredre tananit.mp3"
      },
      {
        "SL": "A0224",
        "RAAG": "Chaayanat",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Darana dar tom",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0224 Chaayanat Teental(m.laya) Darana dar tom.mp3"
      },
      {
        "SL": "A0225",
        "RAAG": "Chaayanat",
        "TAAL": "Jhumra(Vilambit)",
        "STARTING_WORDS": "Tom tom tana",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0225 Chaayanat Jhumra(Vilambit) Tom tom tana.mp3"
      },
      {
        "SL": "A0226",
        "RAAG": "Goudsarang",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "pa dha ma pa",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0226 Goudsarang Teental(m.laya) pa dha ma pa.mp3"
      },
      {
        "SL": "A0227",
        "RAAG": "Goudsarang",
        "TAAL": "Sulfaq(m.laya)",
        "STARTING_WORDS": "Sarang goud kahat",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0227 Goudsarang Sulfaq(m.laya) Sarang goud kahat.mp3"
      },
      {
        "SL": "A0228",
        "RAAG": "Goudsarang",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Chedona Neend Mori",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0228 Goudsarang Teental(m.laya) Chedona Neend Mori.mp3"
      },
      {
        "SL": "A0229",
        "RAAG": "Goudsarang",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Bhajman raam naam",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0229 Goudsarang Teental(m.laya) Bhajman raam naam.mp3"
      },
      {
        "SL": "A0230",
        "RAAG": "Goudsarang",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Morey mana nandlaal",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0230 Goudsarang Teental(m.laya) Morey mana nandlaal.mp3"
      },
      {
        "SL": "A0231",
        "RAAG": "Goudsarang",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Lagan laagi mohanso",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0231 Goudsarang Teental(m.laya) Lagan laagi mohanso.mp3"
      },
      {
        "SL": "A0232",
        "RAAG": "Goudsarang",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Aaliri ko ka jane",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0232 Goudsarang Teental(m.laya) Aaliri ko ka jane.mp3"
      },
      {
        "SL": "A0233",
        "RAAG": "Goudsarang",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Hove yaar nazar",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0233 Goudsarang Teental(m.laya) Hove yaar nazar.mp3"
      },
      {
        "SL": "A0234",
        "RAAG": "Goudsarang",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Palan laagi",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0234 Goudsarang Teental(m.laya) Palan laagi.mp3"
      },
      {
        "SL": "A0235",
        "RAAG": "Goudsarang",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Saiyo Maitho ratandee",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0235 Goudsarang Teental(m.laya) Saiyo Maitho ratandee.mp3"
      },
      {
        "SL": "A0236",
        "RAAG": "Goudsarang",
        "TAAL": "Adachowtal-Vilambit",
        "STARTING_WORDS": "Tero sumaran karoo",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0236 Goudsarang Adachowtal-Vilambit Tero sumaran karoo.mp3"
      },
      {
        "SL": "A0237",
        "RAAG": "Goudsarang",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Kajaraare pyari tere",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0237 Goudsarang Tilwada(Vilambit) Kajaraare pyari tere.mp3"
      },
      {
        "SL": "A0238",
        "RAAG": "Goudsarang",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Kajarare(2nd version)",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0238 Goudsarang Tilwada(Vilambit) Kajarare(2nd version).mp3"
      },
      {
        "SL": "A0239",
        "RAAG": "Goudsarang",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Ye laajan aave",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0239 Goudsarang Ektal(Vilambit) Ye laajan aave.mp3"
      },
      {
        "SL": "A0240",
        "RAAG": "Goudsarang",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Paati jhar gaiyaa",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0240 Goudsarang Ektal(Vilambit) Paati jhar gaiyaa.mp3"
      },
      {
        "SL": "A0241",
        "RAAG": "Goudsarang",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Miyaa tande baaravi",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0241 Goudsarang Ektal(Vilambit) Miyaa tande baaravi.mp3"
      },
      {
        "SL": "A0242",
        "RAAG": "Goudsarang",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Kaahe taande baanu",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0242 Goudsarang Ektal(Vilambit) Kaahe taande baanu.mp3"
      },
      {
        "SL": "A0243",
        "RAAG": "Goudsarang",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Prachound dound",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0243 Goudsarang Chowtal(Vilambit) Prachound dound.mp3"
      },
      {
        "SL": "A0244",
        "RAAG": "Goudsarang",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Maadho mukund",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0244 Goudsarang Chowtal(Vilambit) Maadho mukund.mp3"
      },
      {
        "SL": "A0245",
        "RAAG": "Goudsarang",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Sudaamaake daalidra",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0245 Goudsarang Chowtal(Vilambit) Sudaamaake daalidra.mp3"
      },
      {
        "SL": "A0246",
        "RAAG": "Goudsarang",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Aise nainaa arun",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0246 Goudsarang Chowtal(Vilambit) Aise nainaa arun.mp3"
      },
      {
        "SL": "A0247",
        "RAAG": "Goudsarang",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Bahor daph baajan",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0247 Goudsarang Dhamar(Vilambit) Bahor daph baajan.mp3"
      },
      {
        "SL": "A0248",
        "RAAG": "Goudsarang",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Saras ras rahas",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0248 Goudsarang Dhamar(Vilambit) Saras ras rahas.mp3"
      },
      {
        "SL": "A0249",
        "RAAG": "Goudsarang",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Daraa deemtanom",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0249 Goudsarang Ektal(m.laya) Daraa deemtanom.mp3"
      },
      {
        "SL": "A0250",
        "RAAG": "Goudsarang",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tom taannaa derenna",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0250 Goudsarang Teental(m.laya) Tom taannaa derenna.mp3"
      },
      {
        "SL": "A0251",
        "RAAG": "Goudsarang",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tadare daani tadeem",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0251 Goudsarang Teental(m.laya) Tadare daani tadeem.mp3"
      },
      {
        "SL": "A0252",
        "RAAG": "Goudsarang",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ata dere tadare dere",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0252 Goudsarang Teental(m.laya) Ata dere tadare dere.mp3"
      },
      {
        "SL": "A0253",
        "RAAG": "Hindol",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "ga ga sa sa",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0253 Hindol Jhaptal(m.laya) ga ga sa sa.mp3"
      },
      {
        "SL": "A0254",
        "RAAG": "Hindol",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "ma ga sa ni dha",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0254 Hindol Teental(m.laya) ma ga sa ni dha.mp3"
      },
      {
        "SL": "A0255",
        "RAAG": "Hindol",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Hindol ke bol",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0255 Hindol Jhaptal(m.laya) Hindol ke bol.mp3"
      },
      {
        "SL": "A0256",
        "RAAG": "Hindol",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Aankh kholare",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0256 Hindol Jhaptal(m.laya) Aankh kholare.mp3"
      },
      {
        "SL": "A0257",
        "RAAG": "Hindol",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Chanak boond parilore",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0257 Hindol Teental(m.laya) Chanak boond parilore.mp3"
      },
      {
        "SL": "A0258",
        "RAAG": "Hindol",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Sajanavaa",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0258 Hindol Teental(m.laya) Sajanavaa.mp3"
      },
      {
        "SL": "A0259",
        "RAAG": "Hindol",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Baran baran ke phool",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0259 Hindol Teental(m.laya) Baran baran ke phool.mp3"
      },
      {
        "SL": "A0260",
        "RAAG": "Hindol",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Samajh samajh guna",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0260 Hindol Teental(m.laya) Samajh samajh guna.mp3"
      },
      {
        "SL": "A0261",
        "RAAG": "Hindol",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Sajanavaa(2nd version)",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0261 Hindol Teental(m.laya) Sajanavaa(2nd Version).mp3"
      },
      {
        "SL": "A0262",
        "RAAG": "Hindol",
        "TAAL": "Teebtal(m.laya)",
        "STARTING_WORDS": "Koyaliya bole",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0262 Hindol Teebtal(m.laya) Koyaliya bole.mp3"
      },
      {
        "SL": "A0263",
        "RAAG": "Hindol",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Sukhase jhoolat",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0263 Hindol Teental(m.laya) Sukhase jhoolat.mp3"
      },
      {
        "SL": "A0264",
        "RAAG": "Hindol",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Taanan gaaye bajaaye",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0264 Hindol Teental(m.laya) Taanan gaaye bajaaye.mp3"
      },
      {
        "SL": "A0265",
        "RAAG": "Hindol",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Raat cholara",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0265 Hindol Teental(m.laya) Raat cholara.mp3"
      },
      {
        "SL": "A0266",
        "RAAG": "Hindol",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Karale pichakaari",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0266 Hindol Teental(m.laya) Karale pichakaari.mp3"
      },
      {
        "SL": "A0267",
        "RAAG": "Hindol",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Yeri maan thoo",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0267 Hindol Ektal(Vilambit) Yeri maan thoo.mp3"
      },
      {
        "SL": "A0268",
        "RAAG": "Hindol",
        "TAAL": "Ektal(Villambit)",
        "STARTING_WORDS": "Maayi peeyu",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0268 Hindol Ektal(Villambit) Maayi peeyu.mp3"
      },
      {
        "SL": "A0269",
        "RAAG": "Hindol",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Pee sounga khelo",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0269 Hindol Ektal(Vilambit) Pee sounga khelo.mp3"
      },
      {
        "SL": "A0270",
        "RAAG": "Hindol",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Maanat sab jan Hindol",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0270 Hindol howtal(Vilambit) Maanat sab jan Hindol.mp3"
      },
      {
        "SL": "A0271",
        "RAAG": "Hindol",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Kount baran kar",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0271 Hindol Sultal(m.laya) Kount baran kar.mp3"
      },
      {
        "SL": "A0272",
        "RAAG": "Hindol",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Naad bhedh",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0272 Hindol Chowtal(Vilambit) Naad bhedh.mp3"
      },
      {
        "SL": "A0273",
        "RAAG": "Hindol",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Laakh barasaloji",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0273 Hindol Chowtal(Vilambit) Laakh barasaloji.mp3"
      },
      {
        "SL": "A0274",
        "RAAG": "Hindol",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Ruth basant",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0274 Hindol Chowtal(Vilambit) Ruth basant.mp3"
      },
      {
        "SL": "A0275",
        "RAAG": "Hindol",
        "TAAL": "Chowtal)Vilambit)",
        "STARTING_WORDS": "Goungadhar",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0275 Hindol Chowtal)Vilambit) Goungadhar.mp3"
      },
      {
        "SL": "A0276",
        "RAAG": "Hindol",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Laaljin karori",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0276 Hindol Dhamar(Vilambit) Laaljin karori.mp3"
      },
      {
        "SL": "A0277",
        "RAAG": "Hindol",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Abarak lagaave",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0277 Hindol Dhamar(Vilambit) Abarak lagaave.mp3"
      },
      {
        "SL": "A0278",
        "RAAG": "Hindol",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tadare tare daani",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0278 Hindol Teental(m.laya) Tadare tare daani.mp3"
      },
      {
        "SL": "A0279",
        "RAAG": "Chandrakant",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Chandrakant sakhi",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0279 Chandrakant Teental(m.laya) Chandrakant sakhi.mp3"
      },
      {
        "SL": "A0280",
        "RAAG": "Chandrakant",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Pyare tori chabi",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0280 Chandrakant Teental(m.laya) Pyare tori chabi.mp3"
      },
      {
        "SL": "A0281",
        "RAAG": "Savani kalyan",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Sab sakhiyaa mil",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0281 Savani kalyan Teental(m.laya) Sab sakhiyaa mil.mp3"
      },
      {
        "SL": "A0282",
        "RAAG": "Savani kalyan",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Jaahoo tan laage",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0282 Savani kalyan Tilwada(Vilambit) Jaahoo tan laage.mp3"
      },
      {
        "SL": "A0283",
        "RAAG": "Jait kalyan",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Jaya jaya bhavaani",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0283 Jait kalyan Jhaptal(m.laya) Jaya jaya bhavaani.mp3"
      },
      {
        "SL": "A0284",
        "RAAG": "Jait kalyan",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Jaiso jaako bhaav",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0284 Jait kalyan Teental(m.laya) Jaiso jaako bhaav.mp3"
      },
      {
        "SL": "A0285",
        "RAAG": "Jait kalyan",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Ati his ras ras maata",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0285 Jait kalyan Tilwada(Vilambit) Ati his ras ras maata.mp3"
      },
      {
        "SL": "A0286",
        "RAAG": "Jait kalyan",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Meri surang",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0286 Jait kalyan Teental(m.laya) Meri surang.mp3"
      },
      {
        "SL": "A0287",
        "RAAG": "Jait kalyan",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Gaagariya choovan",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0287 Jait kalyan Chowtal(Vilambit) Gaagariya choovan.mp3"
      },
      {
        "SL": "A0288",
        "RAAG": "Jait kalyan",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Phaagun aayo",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0288 Jait kalyan Dhamar(Vilambit) Phaagun aayo.mp3"
      },
      {
        "SL": "A0289",
        "RAAG": "Jait kalyan",
        "TAAL": "Adachowtal(m.laya)",
        "STARTING_WORDS": "Utatana derenaa",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0289 Jait kalyan Adachowtal(m.laya) Utatana derenaa.mp3"
      },
      {
        "SL": "A0290",
        "RAAG": "Shyam kalyan",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Shyam kalyan gaavat",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0290 Shyam kalyan Teental(m.laya) Shyam kalyan gaavat.mp3"
      },
      {
        "SL": "A0291",
        "RAAG": "Shyam kalyan",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Sunoo aho shyam",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0291 Shyam kalyan Jhaptal(m.laya) Sunoo aho shyam.mp3"
      },
      {
        "SL": "A0292",
        "RAAG": "Shyam kalyan",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Jhoolan aa hindore",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0292 Shyam kalyan Jhaptal(m.laya) Jhoolan aa hindore.mp3"
      },
      {
        "SL": "A0293",
        "RAAG": "Shyam kalyan",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Saavan ki saazh",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0293 Shyam kalyan Teental(m.laya) Saavan ki saazh.mp3"
      },
      {
        "SL": "A0294",
        "RAAG": "Shyam kalyan",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Neend na aavat",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0294 Shyam kalyan Teental(m.laya) Neend na aavat.mp3"
      },
      {
        "SL": "A0295",
        "RAAG": "Shyam kalyan",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Ghataa kaari hoosne",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0295 Shyam kalyan Ektal(Vilambit) Ghataa kaari hoosne.mp3"
      },
      {
        "SL": "A0296",
        "RAAG": "Shyam kalyan",
        "TAAL": "Roopak(Vilambit)",
        "STARTING_WORDS": "Mhaaraa rasiyaa",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0296 Shyam kalyan Roopak(Vilambit) Mhaaraa rasiyaa.mp3"
      },
      {
        "SL": "A0297",
        "RAAG": "Shyam kalyan",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Jiyo mero laal",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0297 Shyam kalyan Tilwada(Vilambit) Jiyo mero laal.mp3"
      },
      {
        "SL": "A0298",
        "RAAG": "Shyam kalyan",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Paarvati naath",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0298 Shyam kalyan Chowtal(Vilambit) Paarvati naath.mp3"
      },
      {
        "SL": "A0299",
        "RAAG": "Shyam kalyan",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Aaliri paavas ritu",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0299 Shyam kalyan Chowtal(Vilambit) Aaliri paavas ritu.mp3"
      },
      {
        "SL": "A0300",
        "RAAG": "Maalshri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Sab sakhiyaa mil",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0300 Maalshri Teental(m.laya) Sab sakhiyaa mil.mp3"
      },
      {
        "SL": "A0301",
        "RAAG": "Maalshri",
        "TAAL": "Roopak(m.laya)",
        "STARTING_WORDS": "Mela kalyan",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0301 Maalshri Roopak(m.laya) Mela kalyan.mp3"
      },
      {
        "SL": "A0302",
        "RAAG": "Maalshri",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Kahe kalpadhruma",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0302 Maalshri Jhaptal(m.laya) Kahe kalpadhruma.mp3"
      },
      {
        "SL": "A0303",
        "RAAG": "Maalshri",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Oudava maalasiri",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0303 Maalshri Sultal(m.laya) Oudava maalasiri.mp3"
      },
      {
        "SL": "A0304",
        "RAAG": "Maalshri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Karat ho sakal singaar",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0304 Maalshri Teental(m.laya) Karat ho sakal singaar.mp3"
      },
      {
        "SL": "A0305",
        "RAAG": "Maalshri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Baajere tumak tumak",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0305 Maalshri Teental(m.laya) Baajere tumak tumak.mp3"
      },
      {
        "SL": "A0306",
        "RAAG": "Maalshri",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Mendi jind too",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0306 Maalshri Ektal(m.laya) Mendi jind too.mp3"
      },
      {
        "SL": "A0307",
        "RAAG": "Maalshri",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Aave aa raanj",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0307 Maalshri Tilwada(Vilambit) Aave aa raanj.mp3"
      },
      {
        "SL": "A0308",
        "RAAG": "Maalshri",
        "TAAL": "Roopak(m.laya)",
        "STARTING_WORDS": "Makhadooma saa",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0308 Maalshri Roopak(m.laya) Makhadooma saa.mp3"
      },
      {
        "SL": "A0309",
        "RAAG": "Maalshri",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Abagun bakas merey",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0309 Maalshri Sultal(m.laya) Abagun bakas merey.mp3"
      },
      {
        "SL": "A0310",
        "RAAG": "Maalshri",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Uthare musaaphir",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0310 Maalshri Jhaptal(m.laya) Uthare musaaphir.mp3"
      },
      {
        "SL": "A0311",
        "RAAG": "Maalshri",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Durage durit door",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0311 Maalshri Jhaptal(m.laya) Durage durit door.mp3"
      },
      {
        "SL": "A0312",
        "RAAG": "Maalshri",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Daan karat samaan",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0312 Maalshri Sultal(m.laya) Daan karat samaan.mp3"
      },
      {
        "SL": "A0313",
        "RAAG": "Maalshri",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Nirmal moukha",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0313 Maalshri Sultal(m.laya) Nirmal moukha.mp3"
      },
      {
        "SL": "A0314",
        "RAAG": "Maalshri",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Joban madamaati naar",
        "THAAT": "Kalyan",
        "FILE_NAME": "A0314 Maalshri Dhamar(Vilambit) Joban madamaati naar.mp3"
      },
      {
        "SL": "B0315",
        "RAAG": "Bilawal",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "sa ni dha ni sa",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0315 Bilawal Ektal(m.laya) sa ni dha ni sa.mp3"
      },
      {
        "SL": "B0316",
        "RAAG": "Aliaha Bilawal",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "ga pa ni dha ni sa",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0316 Aliaha Bilawal Jhaptal(m.laya) ga pa ni dha ni sa.mp3"
      },
      {
        "SL": "B0317",
        "RAAG": "Aliaha Bilawal",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tab kahat bilaval",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0317 Aliaha Bilawal Teental(m.laya) Tab kahat bilaval.mp3"
      },
      {
        "SL": "B0318",
        "RAAG": "Aliaha Bilawal",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Aliaha Bilawal",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0318 Aliaha Bilawal Chowtal(Vilambit) Aliaha Bilawal.mp3"
      },
      {
        "SL": "B0319",
        "RAAG": "Aliaha Bilawal",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tab kahat aliaha bilawal",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0319 Aliaha Bilawal Teental(m.laya) Tab kahat aliaha bilawa.mp3"
      },
      {
        "SL": "B0320",
        "RAAG": "Bilawal",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Toohi aadhar sakal",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0320 Bilawal Teental(m.laya) Toohi aadhar sakal.mp3"
      },
      {
        "SL": "B0321",
        "RAAG": "Bilawal",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Te hari naaman sumiran",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0321 Bilawal Teental(m.laya) Te hari naaman sumiran.mp3"
      },
      {
        "SL": "B0322",
        "RAAG": "Bilawal",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Rabaso neha lagaa",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0322 Bilawal Teental(m.laya) Rabaso neha lagaa.mp3"
      },
      {
        "SL": "B0323",
        "RAAG": "Bilawal",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Jaag uthe sab jan",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0323 Bilawal Teental(m.laya) Jaag uthe sab jan.mp3"
      },
      {
        "SL": "B0324",
        "RAAG": "Aliaha Bilawal",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Man haravare",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0324 Aliaha Bilawal Teental(m.laya) Man haravare.mp3"
      },
      {
        "SL": "B0325",
        "RAAG": "Aliaha Bilawal",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Hariso chakra dharaavu",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0325 Aliaha Bilawal Teental(m.laya) Hariso chakra dharaavu.mp3"
      },
      {
        "SL": "B0326",
        "RAAG": "Aliaha Bilawal",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Laadalee laal phule avat",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0326 Aliaha Bilawal Teental(m.laya) Laadalee laal phule ava.mp3"
      },
      {
        "SL": "B0327",
        "RAAG": "Aliaha Bilawal",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Bali bali jaavoo",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0327 Aliaha Bilawal Teental(m.laya) Bali bali jaavoo.mp3"
      },
      {
        "SL": "B0328",
        "RAAG": "Aliaha Bilawal",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kavan batariyaa",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0328 Aliaha Bilawal Teental(m.laya) Kavan batariyaa.mp3"
      },
      {
        "SL": "B0329",
        "RAAG": "Aliaha Bilawal",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ati arun baran piya",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0329 Aliaha bilawal Teental(m.laya) Ata arun baran piya.mp3"
      },
      {
        "SL": "B0330",
        "RAAG": "Aliaha Bilawal",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Daiyaa kahaa gaye",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0330 Aliaha Bilawal Tilwada(Vilambit) Daiyaa kahaa gaye.mp3"
      },
      {
        "SL": "B0331",
        "RAAG": "Aliaha Bilawal",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Peevan laago maadh",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0331 Aliaha Bilawal Teental(m.laya) Peevan laago maadh.mp3"
      },
      {
        "SL": "B0332",
        "RAAG": "Aliaha Bilawal",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Dolan maande jaane",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0332 Aliaha Bilawal Tilwada(Vilambit) Dolan maande jaane.mp3"
      },
      {
        "SL": "B0333",
        "RAAG": "Aliaha Bilawal",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Maayee Ekato kathaa",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0333 Aliaha Bilawal Ektal(Vilambit) Maayee Ekato kathaa.mp3"
      },
      {
        "SL": "B0334",
        "RAAG": "Aliaha Bilawal",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Sari Raina banaaban",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0334 Aliaha Bilawal Ektal(Vilambit) Sari Raina banaaban.mp3"
      },
      {
        "SL": "B0335",
        "RAAG": "Aliaha Bilawal",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Kaahoo ko naa inako",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0335 Aliaha Bilawal Ektal(Vilambit) Kaahoo ko naa inako.mp3"
      },
      {
        "SL": "B0336",
        "RAAG": "Aliaha Bilawal",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Kounthaa morey",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0336 Aliaha Bilawal Ektal(Vilambit) Kounthaa morey.mp3"
      },
      {
        "SL": "B0337",
        "RAAG": "Aliaha Bilawal",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Le teri lakari",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0337 Aliaha Bilawal Jhaptal(m.laya) Le teri lakari.mp3"
      },
      {
        "SL": "B0338",
        "RAAG": "Aliaha Bilawal",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Sarasa pratap Tej bal",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0338 Aliaha Bilawal Chowtal(Vilambit) Sarasa pratap Tej bal.mp3"
      },
      {
        "SL": "B0339",
        "RAAG": "Bilawal",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Paalana palap lagaavu",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0339 Bilawal Chowtal(Vilambit) Paalana palap lagaavu.mp3"
      },
      {
        "SL": "B0340",
        "RAAG": "Bilawal",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Aaj aour kaal aour",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0340 Bilawal Chowtal(Vilambit) Aaj aour kaal aour.mp3"
      },
      {
        "SL": "B0341",
        "RAAG": "Aliaha Bilawal",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Motiyan kee zhaalralagi",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0341 Aliaha Bilawal Chowtal(Vilambit) Motiyan kee zhaalrala.mp3"
      },
      {
        "SL": "B0342",
        "RAAG": "Aliaha Bilawal",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Surat bisaari",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0342 Aliaha Bilawal Chowtal(Vilambit) Surat bisaari.mp3"
      },
      {
        "SL": "B0343",
        "RAAG": "Aliaha Bilawal",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Aaj ko singaar subhag",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0343 Aliaha Bilawal Chowtal(Vilambit) Aaj ko singaar subhag.mp3"
      },
      {
        "SL": "B0344",
        "RAAG": "Aliaha Bilawal",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Baar baar aavan",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0344 Aliaha Bilawal Chowtal(Vilambit) Baar baar aavan.mp3"
      },
      {
        "SL": "B0345",
        "RAAG": "Aliaha Bilawal",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Ye joo kaal kee gwaalin",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0345 Aliaha Bilawal Dhamar(Vilambit) Ye joo kaal kee gwaali.mp3"
      },
      {
        "SL": "B0346",
        "RAAG": "Aliaha Bilawal",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Ye taari dhaph baajat",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0346 Aliaha Bilawal Dhamar(Vilambit) Ye taari dhaph baajat.mp3"
      },
      {
        "SL": "B0347",
        "RAAG": "Aliaha Bilawal",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Dim tadim dim tan drena",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0347 Aliaha Bilawal Teental(m.laya) Dim tadim dim tan drena.mp3"
      },
      {
        "SL": "B0348",
        "RAAG": "Aliaha Bilawal",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Odetan deem deem",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0348 Aliaha Bilawal Teental(m.laya) Odetan deem deem.mp3"
      },
      {
        "SL": "B0349",
        "RAAG": "Aliaha Bilawal",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Dre dre dre tana derena",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0349 Aliaha Bilawal Tilwada(Vilambit) Dre dre dre tana dere.mp3"
      },
      {
        "SL": "B0350",
        "RAAG": "Aliaha Bilawal",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Tanana derenaa dre na",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0350 Aliaha Bilawal Tilwada(Vilambit) Tanana derenaa dre na.mp3"
      },
      {
        "SL": "B0351",
        "RAAG": "Bihag",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "ga sa ga ma pa ni pa",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0351 Bihag Teental(m.laya) ga sa ga ma pa ni pa.mp3"
      },
      {
        "SL": "B0352",
        "RAAG": "Bihag",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Shudha suranako mel",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0352 Bihag Teental(m.laya) Shudha suranako mel.mp3"
      },
      {
        "SL": "B0353",
        "RAAG": "Bihag",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ata sugam roop raagani",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0353 Bihag Teental(m.laya) Ata sugam roop raagani.mp3"
      },
      {
        "SL": "B0354",
        "RAAG": "Bihag",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Sab sakhiyaa chaalo",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0354 Bihag Teental(m.laya) Sab sakhiyaa chaalo.mp3"
      },
      {
        "SL": "B0355",
        "RAAG": "Bihag",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Jaay itanee binat mori",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0355 Bihag Teental(m.laya) Jaay itanee binat mori.mp3"
      },
      {
        "SL": "B0356",
        "RAAG": "Bihag",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Durage mahaarani",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0356 Bihag Teental(m.laya) Durage mahaarani.mp3"
      },
      {
        "SL": "B0357",
        "RAAG": "Bihag",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Balmure morey manake",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0357 Bihag Teental(m.laya) Balmure morey manake.mp3"
      },
      {
        "SL": "B0358",
        "RAAG": "Bihag",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Abahoo lalan maikaa",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0358 Bihag Teental(m.laya) Abahoo lalan maikaa.mp3"
      },
      {
        "SL": "B0359",
        "RAAG": "Bihag",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Langar dheet mag",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0359 Bihag Teental(m.laya) Langar dheet mag.mp3"
      },
      {
        "SL": "B0360",
        "RAAG": "Bihag",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Meri man atakyo",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0360 Bihag Teental(m.laya) Meri man atakyo.mp3"
      },
      {
        "SL": "B0361",
        "RAAG": "Bihag",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Apano kabhoo kar",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0361 Bihag Teental(m.laya) Apano kabhoo kar.mp3"
      },
      {
        "SL": "B0362",
        "RAAG": "Bihag",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Jabate bichure laalan",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0362 Bihag Teental(m.laya) Jabate bichure laalan.mp3"
      },
      {
        "SL": "B0363",
        "RAAG": "Bihag",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Hamari madhaiyaa",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0363 Bihag Teental(m.laya) Hamari madhaiyaa.mp3"
      },
      {
        "SL": "B0364",
        "RAAG": "Bihag",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Jisko dilpar gujari",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0364 Bihag Teental(m.laya) Jisko dilpar gujari.mp3"
      },
      {
        "SL": "B0365",
        "RAAG": "Bihag",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Baajeri mori paayal",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0365 Bihag Teental(m.laya) Baajeri mori paayal.mp3"
      },
      {
        "SL": "B0366",
        "RAAG": "Bihag",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Raina andheri dar pave",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0366 Bihag Teental(m.laya) Raina andheri dar pave.mp3"
      },
      {
        "SL": "B0367",
        "RAAG": "Bihag",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ab ghar aavan",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0367 Bihag Teental(m.laya) Ab ghar aavan.mp3"
      },
      {
        "SL": "B0368",
        "RAAG": "Bihag",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Nir dayee langar maika",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0368 Bihag Teental(m.laya) Nir dayee langar maika.mp3"
      },
      {
        "SL": "B0369",
        "RAAG": "Bihag",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tere sir kusam bithur",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0369 Bihag Teental(m.laya) Tere sira kusam bithur.mp3"
      },
      {
        "SL": "B0370",
        "RAAG": "Bihag",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Koun kahe unase",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0370 Bihag Teental(m.laya) Koun kahe unase.mp3"
      },
      {
        "SL": "B0371",
        "RAAG": "Bihag",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Sakhiree mora ji",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0371 Bihag Teental(m.laya) Sakhiree mora ji.mp3"
      },
      {
        "SL": "B0372",
        "RAAG": "Bihag",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Kavan dhang tora",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0372 Bihag Ektal(Vilambit) Kavan dhang tora.mp3"
      },
      {
        "SL": "B0373",
        "RAAG": "Bihag",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Pari ho paay pyaare",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0373 Bihag Tilwada(Vilambit) Pari ho paay pyaare.mp3"
      },
      {
        "SL": "B0374",
        "RAAG": "Bihag",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Jag jeevan thoraa",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0374 Bihag Tilwada(Vilambit) Jag jeevan thoraa.mp3"
      },
      {
        "SL": "B0375",
        "RAAG": "Bihag",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Kaise sukha sove",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0375 Bihag Ektal(Vilambit) Kaise sukha sove.mp3"
      },
      {
        "SL": "B0376",
        "RAAG": "Bihag",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Ho maayee aaj",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0376 Bihag Ektal(Vilambit) Ho maayee aaj.mp3"
      },
      {
        "SL": "B0377",
        "RAAG": "Bihag",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Homa dhan dhan re",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0377 Bihag Tilwada(Vilambit) Homa dhan dhan re.mp3"
      },
      {
        "SL": "B0378",
        "RAAG": "Bihag",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Moraa mana hey laagi",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0378 Bihag Tilwada(Vilambit) Moraa mana hey laagi.mp3"
      },
      {
        "SL": "B0379",
        "RAAG": "Bihag",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Haave choungi bolale",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0379 Bihag Tilwada(Vilambit) Haave choungi bolale.mp3"
      },
      {
        "SL": "B0380",
        "RAAG": "Bihag",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Dil dive kaliyaa",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0380 Bihag Tilwada(Vilambit) Dil dive kaliyaa.mp3"
      },
      {
        "SL": "B0381",
        "RAAG": "Bihag",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Goree tero raaj sohaag",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0381 Bihag Tilwada(Vilambit) Goree tero raaj sohaag.mp3"
      },
      {
        "SL": "B0382",
        "RAAG": "Bihag",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Mendidaa rang laal",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0382 Bihag Tilwada(Vilambit) Mendidaa rang laal.mp3"
      },
      {
        "SL": "B0383",
        "RAAG": "Bihag",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Sat bachan kaho sajan",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0383 Bihag Jhaptal(m.laya) Sat bachan kaho sajan.mp3"
      },
      {
        "SL": "B0384",
        "RAAG": "Bihag",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Aad shiv shounkar",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0384 Bihag Jhaptal(m.laya) Aad shiv shounkar.mp3"
      },
      {
        "SL": "B0385",
        "RAAG": "Bihag",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Kaayam rahey raaj",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0385 Bihag Jhaptal(m.laya) Kaayam rahey raaj.mp3"
      },
      {
        "SL": "B0386",
        "RAAG": "Bihag",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Utam gay vo gaay",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0386 Bihag Chowtal(Vilambit) Utam gay vo gaay.mp3"
      },
      {
        "SL": "B0387",
        "RAAG": "Bihag",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Room jhoom bhar aaye",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0387 Bihag Chowtal(Vilambit) Room jhoom bhar aaye.mp3"
      },
      {
        "SL": "B0388",
        "RAAG": "Bihag",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Jo lo hi maartound",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0388 Bihag Chowtal(Vilambit) Jo lo hi maartound.mp3"
      },
      {
        "SL": "B0389",
        "RAAG": "Bihag",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Aat honge aali pyare",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0389 Bihag Chowtal(Vilambit) Aat honge aali pyare.mp3"
      },
      {
        "SL": "B0390",
        "RAAG": "Bihag",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Langaravaa kood paraa",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0390 Bihag Dhamar(Vilambit) Langaravaa kood paraa.mp3"
      },
      {
        "SL": "B0391",
        "RAAG": "Bihag",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Jamuna jal sajaneeree",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0391 Bihag Dhamar(Vilambit) Jamuna jal sajaneeree.mp3"
      },
      {
        "SL": "B0392",
        "RAAG": "Bihag",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Joban madmaati gujari",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0392 Bihag Dhamar(Vilambit) Joban madmaati gujari.mp3"
      },
      {
        "SL": "B0393",
        "RAAG": "Bihag",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tananaa di r dir taanno",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0393 Bihag Teental(m.laya) Tananaa dir dir taanno.mp3"
      },
      {
        "SL": "B0394",
        "RAAG": "Bihag",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Tadiyare tadre naare",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0394 Bihag Tilwada(Vilambit) Tadiyare tadre naare.mp3"
      },
      {
        "SL": "B0395",
        "RAAG": "Bihag",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Nadre tumdre taare",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0395 Bihag Tilwada(Vilambit) Nadre tumdre taare.mp3"
      },
      {
        "SL": "B0396",
        "RAAG": "Bihag",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Yalalamu yalalamu",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0396 Bihag Teental(m.laya) Yalalamu yalalamu.mp3"
      },
      {
        "SL": "B0397",
        "RAAG": "Bihag",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tom tanana tadeem",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0397 Bihag Teental(m.laya) Tom tanana tadeem.mp3"
      },
      {
        "SL": "B0398",
        "RAAG": "Shankara",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "sa sa ni ni pa",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0398 Shankara Jhaptal(m.laya) sa sa ni ni pa.mp3"
      },
      {
        "SL": "B0399",
        "RAAG": "Shankara",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Niki niki taan",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0399 Shankara Teental(m.laya) Niki niki taan.mp3"
      },
      {
        "SL": "B0400",
        "RAAG": "Shankara",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Velaaval melamo janya",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0400 Shankara Chowtal(Vilambit) Velaaval melamo janya.mp3"
      },
      {
        "SL": "B0401",
        "RAAG": "Shankara",
        "TAAL": "Matttal(m.laya)",
        "STARTING_WORDS": "Shankar panch vadan",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0401 Shankara Matttal(m.laya) Shankar panch vadan.mp3"
      },
      {
        "SL": "B0402",
        "RAAG": "Shankara",
        "TAAL": "Vasanttal(m.laya)",
        "STARTING_WORDS": "Naachat sab taal",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0402 Shankara Vasanttal(m.laya) Naachat sab taal.mp3"
      },
      {
        "SL": "B0403",
        "RAAG": "Shankara",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Sukhkar dukhkar tum",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0403 Shankara Teental(m.laya) Sukhkar dukhkar tum.mp3"
      },
      {
        "SL": "B0404",
        "RAAG": "Shankara",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Karanaa ho so karale",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0404 Shankara Teental(m.laya) Karanaa ho so karale.mp3"
      },
      {
        "SL": "B0405",
        "RAAG": "Shankara",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Savalado mhane bhayo",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0405 Shankara Teental(m.laya) Savalado mhane bhayo.mp3"
      },
      {
        "SL": "B0406",
        "RAAG": "Shankara",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ghowvaa charaave",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0406 Shankara Teental(m.laya) Ghowvaa charaave.mp3"
      },
      {
        "SL": "B0407",
        "RAAG": "Shankara",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Bolare rangeele",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0407 Shankara Teental(m.laya) Bolare rangeele.mp3"
      },
      {
        "SL": "B0408",
        "RAAG": "Shankara",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Maathe tere dhaar",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0408 Shankara Tilwada(Vilambit) Maathe tere dhaar.mp3"
      },
      {
        "SL": "B0409",
        "RAAG": "Shankara",
        "TAAL": "Jhumra(Vilambit)",
        "STARTING_WORDS": "Aad mahadev",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0409 Shankara Jhumra(Vilambit) Aad mahadev.mp3"
      },
      {
        "SL": "B0410",
        "RAAG": "Shankara",
        "TAAL": "Jhumra(Vilambit)",
        "STARTING_WORDS": "Aad mahadev",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0410 Shankara Jhumra(Vilambit) Aad mahadev.mp3"
      },
      {
        "SL": "B0411",
        "RAAG": "Shankara",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Kinore k artaar",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0411 Shankara Ektal(Vilambit) Kinore kartaar.mp3"
      },
      {
        "SL": "B0412",
        "RAAG": "Shankara",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Dhoomlandiya",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0412 Shankara Tilwada(Vilambit) Dhoomlandiya.mp3"
      },
      {
        "SL": "B0413",
        "RAAG": "Shankara",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "So jaanure jaanu",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0413 Shankara Tilwada(Vilambit) So jaanure jaanu.mp3"
      },
      {
        "SL": "B0414",
        "RAAG": "Shankara",
        "TAAL": "Ektaal(Vilambit)",
        "STARTING_WORDS": "Turakva san mana",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0414 Shankara Ektaal(Vilambit) Turakva san mana.mp3"
      },
      {
        "SL": "B0415",
        "RAAG": "Shankara",
        "TAAL": "Adachowtal(m.laya)",
        "STARTING_WORDS": "Chowndi chownd",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0415 Shankara Adachowtal(m.laya) Chowndi chownd.mp3"
      },
      {
        "SL": "B0416",
        "RAAG": "Shankara",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Jaya gounge taarani",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0416 Shankara Jhaptal(m.laya) Jaya gounge taarani.mp3"
      },
      {
        "SL": "B0417",
        "RAAG": "Shankara",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Ganapat gaouri suth",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0417 Shankara Sultal(m.laya) Ganapat gaouri suth.mp3"
      },
      {
        "SL": "B0418",
        "RAAG": "Shankara",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Hari hari chaandake",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0418 Shankara Chowtal(Vilambit) Hari hari chaandake.mp3"
      },
      {
        "SL": "B0419",
        "RAAG": "Shankara",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Aadnaad bhrahmanaad",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0419 Shankara Chowtal(Vilambit) Aadnaad bhrahmanaad.mp3"
      },
      {
        "SL": "B0420",
        "RAAG": "Shankara",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Sakhi tab so chain",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0420 Shankara Chowtal(Vilambit) Sakhi tab so chain.mp3"
      },
      {
        "SL": "B0421",
        "RAAG": "Shankara",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Tori hori tuje shubha",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0421 Shankara Dhamar(Vilambit) Tori hori tuje shubha.mp3"
      },
      {
        "SL": "B0422",
        "RAAG": "Shankara",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Saavaro hori khele",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0422 Shankara Dhamar(Vilambit) Saavaro hori khele.mp3"
      },
      {
        "SL": "B0423",
        "RAAG": "Shankara",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tom tananananana",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0423 Shankara Teental(m.laya) Tom tananananana.mp3"
      },
      {
        "SL": "B0424",
        "RAAG": "Shankara",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tananana tana derenna",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0424 Shankara Teental(m.laya) Tananana tana derenna.mp3"
      },
      {
        "SL": "B0425",
        "RAAG": "Shankara",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Atatana derenaa",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0425 Shankara Teental(m.laya) Atatana derenaa.mp3"
      },
      {
        "SL": "B0426",
        "RAAG": "Shankara",
        "TAAL": "Adachowtal(Vilambit)",
        "STARTING_WORDS": "Tana derenaa tomtana",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0426 Shankara Adachowtal(Vilambit) Tana derenaa tomtana.mp3"
      },
      {
        "SL": "B0427",
        "RAAG": "Deshkar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "ga re sa sa pa dha pa",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0427 Deshkar Teental(m.laya) ga re sa sa pa dha pa.mp3"
      },
      {
        "SL": "B0428",
        "RAAG": "Deshkar",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Praath samaya deshkar",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0428 Deshkar Chowtal(Vilambit) Praath samaya deshkar.mp3"
      },
      {
        "SL": "B0429",
        "RAAG": "Deshkar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Har ko naam uchh aar",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0429 Deshkar Teental(m.laya) Har ko naam uchhaar.mp3"
      },
      {
        "SL": "B0430",
        "RAAG": "Deshkar",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Gaay manujaa",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0430 Deshkar Jhaptal(m.laya) Gaay manujaa.mp3"
      },
      {
        "SL": "B0431",
        "RAAG": "Deshkar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Harihari nam le",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0431 Deshkar Teental(m.laya) Harihari nam le.mp3"
      },
      {
        "SL": "B0432",
        "RAAG": "Deshkar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tere pad pounkaj",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0432 Deshkar Teental(m.laya) Tere pad pounkaj.mp3"
      },
      {
        "SL": "B0433",
        "RAAG": "Deshkar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tum parvaari",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0433 Deshkar Teental(m.laya) Tum parvaari.mp3"
      },
      {
        "SL": "B0434",
        "RAAG": "Deshkar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Sunalijo binathi hamaari",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0434 Deshkar Teental(m.laya) Sunalijo binathi hamaari.mp3"
      },
      {
        "SL": "B0435",
        "RAAG": "Deshkar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Jag jag jag kinore",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0435 Deshkar Teental(m.laya) Jag jag jag kinore.mp3"
      },
      {
        "SL": "B0436",
        "RAAG": "Deshkar",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Bhajale mahesh",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0436 Deshkar Tilwada(Vilambit) Bhajale mahesh.mp3"
      },
      {
        "SL": "B0437",
        "RAAG": "Deshkar",
        "TAAL": "Adachowtal(Vilambit)",
        "STARTING_WORDS": "Jab pardes gavan kino",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0437 Deshkar Adachowtal(Vilambit) Jab pardes gavan kino.mp3"
      },
      {
        "SL": "B0438",
        "RAAG": "Deshkar",
        "TAAL": "Teental(vilambit)",
        "STARTING_WORDS": "Mori angiyaa ke band",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0438 Deshkar Teental(vilambit) Mori angiyaa ke band.mp3"
      },
      {
        "SL": "B0439",
        "RAAG": "Deshkar",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Katvaa gad gayilavaa",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0439 Deshkar Tilwada(Vilambit) Katvaa gad gayilavaa.mp3"
      },
      {
        "SL": "B0440",
        "RAAG": "Deshkar",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Khumar bhare pi",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0440 Deshkar Tilwada(Vilambit) Khumar bhare pi.mp3"
      },
      {
        "SL": "B0441",
        "RAAG": "Deshkar",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Kase kahu gunaki",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0441 Deshkar Tilwada(Vilambit) Kase kahu gunaki.mp3"
      },
      {
        "SL": "B0442",
        "RAAG": "Deshkar",
        "TAAL": "Matttal(m.laya)",
        "STARTING_WORDS": "Paak naam guruko",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0442 Deshkar Matttal(m.laya) Paak naam guruko.mp3"
      },
      {
        "SL": "B0443",
        "RAAG": "Deshkar",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Devi parasaad deeje",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0443 Deshkar Jhaptal(m.laya) Devi parasaad deeje.mp3"
      },
      {
        "SL": "B0444",
        "RAAG": "Deshkar",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Shoubho mahaadeva",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0444 Deshkar Chowtal(Vilambit) Shoubho mahaadeva.mp3"
      },
      {
        "SL": "B0445",
        "RAAG": "Deshkar",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Jaagiye gopaal la al",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0445 Deshkar Chowtal(Vilambit) Jaagiye gopaal laal.mp3"
      },
      {
        "SL": "B0446",
        "RAAG": "Deshkar",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Natwar parbhekh dhar",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0446 Deshkar Chowtal(Vilambit) Natwar parbhekh dhar.mp3"
      },
      {
        "SL": "B0447",
        "RAAG": "Deshkar",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Janedo ho banvaari",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0447 Deshkar Dhamar(Vilambit) Janedo ho banvaari.mp3"
      },
      {
        "SL": "B0448",
        "RAAG": "Deshkar",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Madhmaate laalan",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0448 Deshkar Dhamar(Vilambit) Madhmaate laalan.mp3"
      },
      {
        "SL": "B0449",
        "RAAG": "Deshkar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Nitaann nitaann tom",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0449 Deshkar Teental(m.laya) Nitaann nitaann tom.mp3"
      },
      {
        "SL": "B0450",
        "RAAG": "Hem Kalyan",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ab mai kaase jaya kahu",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0450 Hem Kalyan Teental(m.laya) Ab mai kaase jaya kahu.mp3"
      },
      {
        "SL": "B0451",
        "RAAG": "Hem Kalyan",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Saavan Aayori",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0451 Hem Kalyan Ektal(Vilambit) Saavan Aayori.mp3"
      },
      {
        "SL": "B0452",
        "RAAG": "Hem Kalyan",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Sundar Gol",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0452 Hem Kalyan Jhaptal(m.laya) Sundar Gol.mp3"
      },
      {
        "SL": "B0453",
        "RAAG": "Yamani Bilawal",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Yamani Bilawali",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0453 Yamani Bilawal Jhaptal(m.laya) Yamani Bilawali.mp3"
      },
      {
        "SL": "B0454",
        "RAAG": "Yamani Bilawal",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Bhor bhayo hai",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0454 Yamani Bilawal Teental(m.laya) Bhor bhayo hai.mp3"
      },
      {
        "SL": "B0455",
        "RAAG": "Yamani Bilawal",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Piyabin kaise ke bhariye",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0455 Yamani Bilawal Teental(m.laya) Piyabin kaise ke bhariy.mp3"
      },
      {
        "SL": "B0456",
        "RAAG": "Yamani Bilawal",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Jab sudhi aave mitraki",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0456 Yamani Bilawal Ektal(Vilambit) Jab sudhi aave mitraki.mp3"
      },
      {
        "SL": "B0457",
        "RAAG": "Yamani Bilawal",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Aan parori",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0457 Yamani Bilawal Tilwada(Vilambit) Aan parori.mp3"
      },
      {
        "SL": "B0458",
        "RAAG": "Yamani Bilawal",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Jug jug jivore",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0458 Yamani Bilawal Tilwada(Vilambit) Jug jug jivore.mp3"
      },
      {
        "SL": "B0459",
        "RAAG": "Yamani Bilawal",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Gherori jaladhar",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0459 Yamani Bilawal Chowtal(Vilambit) Gherori jaladhar.mp3"
      },
      {
        "SL": "B0460",
        "RAAG": "Yamani Bilawal",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Thoo kit karat",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0460 Yamani Bilawal Chowtal(Vilambit) Thoo kit karat.mp3"
      },
      {
        "SL": "B0461",
        "RAAG": "Devgiri Bilawal",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Aaj bilaaval",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0461 Devgiri Bilawal Jhaptal(m.laya) Aaj bilaaval.mp3"
      },
      {
        "SL": "B0462",
        "RAAG": "Devgiri Bilawal",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Aaj badhaayee maayee",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0462 Devgiri Bilawal Tilwada(Vilambit) Aaj badhaayee maayee.mp3"
      },
      {
        "SL": "B0463",
        "RAAG": "Devgiri Bilawal",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Dingin dere bamanaare",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0463 Devgiri Bilawal Tilwada(Vilambit) Dingin dere bamanaar.mp3"
      },
      {
        "SL": "B0464",
        "RAAG": "Devgiri Bilawal",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Kab ghar aave piya",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0464 Devgiri Bilawal Ektal(Vilambit) Kab ghar aave piya.mp3"
      },
      {
        "SL": "B0465",
        "RAAG": "Devgiri Bilawal",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Ye banaa byahan aayo",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0465 Devgiri Bilawal Tilwada(Vilambit) Ye banaa byahan aayo.mp3"
      },
      {
        "SL": "B0466",
        "RAAG": "Devgiri Bilawal",
        "TAAL": "Roopak(Vilambit)",
        "STARTING_WORDS": "Ye dina hamare",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0466 Devgiri Bilawal Roopak(Vilambit) Ye dina hamare.mp3"
      },
      {
        "SL": "B0467",
        "RAAG": "Devgiri Bilawal",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Ruse ho piya aaj",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0467 Devgiri Bilawal Ektal(Vilambit) Ruse ho piya aaj.mp3"
      },
      {
        "SL": "B0468",
        "RAAG": "Devgiri Bilawal",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Milana dohilaa",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0468 Devgiri Bilawal Ektal(Vilambit) Milana dohilaa.mp3"
      },
      {
        "SL": "B0469",
        "RAAG": "Audav Devgiri",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Kasap nandan",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0469 Audav Devgiri Sultal(m.laya) Kasap nandan.mp3"
      },
      {
        "SL": "B0470",
        "RAAG": "Audav Devgiri",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Anu drut laghu guru",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0470 Audav Devgiri Sultal(m.laya) Anu drut laghu guru.mp3"
      },
      {
        "SL": "B0471",
        "RAAG": "Sarparda Bilawal",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Bid hu badan yuvati gan",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0471 Sarparda Bilawal Jhaptal(m.laya) Bidhu badan yuvati ga.mp3"
      },
      {
        "SL": "B0472",
        "RAAG": "Sarparda Bilawal",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Lachchan guni sarparda",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0472 Sarparda Bilawal Jhaptal(m.laya) Lachchan guni sarpard.mp3"
      },
      {
        "SL": "B0473",
        "RAAG": "Sarparda Bilawal",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Yeto manmanaa rahe",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0473 Sarparda Bilawal Teental(m.laya) Yeto manmanaa rahe.mp3"
      },
      {
        "SL": "B0474",
        "RAAG": "Sarparda Bilawal",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Rain maito jaagi piyaake",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0474 Sarparda Bilawal Ektal(m.laya) Rain maito jaagi piyaak.mp3"
      },
      {
        "SL": "B0475",
        "RAAG": "Sarparda Bilawal",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Nayire lagan",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0475 Sarparda Bilawal Teental(m.laya) Nayire lagan.mp3"
      },
      {
        "SL": "B0476",
        "RAAG": "Sarparda Bilawal",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Rangila nera moraa",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0476 Sarparda Bilawal Ektal(Vilambit) Rangila nera moraa.mp3"
      },
      {
        "SL": "B0477",
        "RAAG": "Sarparda Bilawal",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Nazara rome lo deejo",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0477 Sarparda Bilawal Ektal(Vilambit) Nazara rome lo deejo.mp3"
      },
      {
        "SL": "B0478",
        "RAAG": "Laachsaakh",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Laachsaakh sundar",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0478 Laachsaakh Teental(m.laya) Laachsaakh sundar.mp3"
      },
      {
        "SL": "B0479",
        "RAAG": "Laachsaakh",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Saheliyaa gaavo",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0479 Laachsaakh Jhaptal(m.laya) Saheliyaa gaavo.mp3"
      },
      {
        "SL": "B0480",
        "RAAG": "Laachsaakh",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Sohilara gaavo",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0480 Laachsaakh Jhaptal(m.laya) Sohilara gaavo.mp3"
      },
      {
        "SL": "B0481",
        "RAAG": "Laachsaakh",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Sambhu shyaam sundar",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0481 Laachsaakh Teental(m.laya) Sambhu shyaam sundar.mp3"
      },
      {
        "SL": "B0482",
        "RAAG": "Laachsaakh",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Ajahoo samazhre mana",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0482 Laachsaakh Chowtal(Vilambit) Ajahoo samazhre mana.mp3"
      },
      {
        "SL": "B0483",
        "RAAG": "Laachsaakh",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Pratham taar sur sadhe",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0483 Laachsaakh Jhaptal(m.laya) Pratham taar sur sadhe.mp3"
      },
      {
        "SL": "B0484",
        "RAAG": "Shukla Bilawal",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Shukala bilaaval",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0484 Shukla Bilawal Jhaptal(m.laya) Shukala bilaaval.mp3"
      },
      {
        "SL": "B0485",
        "RAAG": "Shukla Bilawal",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Kalana parat mohe",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0485 Shukla Bilawal Jhaptal(m.laya) Kalana parat mohe.mp3"
      },
      {
        "SL": "B0486",
        "RAAG": "Shukla Bilawal",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Toohito paalan haaraa",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0486 Shukla Bilawal Tilwada(Vilambit) Toohito paalan haaraa.mp3"
      },
      {
        "SL": "B0487",
        "RAAG": "Shukla Bilawal",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Mai nihaare",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0487 Shukla Bilawal Tilwada(Vilambit) Mai nihaare.mp3"
      },
      {
        "SL": "B0488",
        "RAAG": "Shukla Bilawal",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Dharmeenamey",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0488 Shukla Bilawal Jhaptal(m.laya) Dharmeenamey.mp3"
      },
      {
        "SL": "B0489",
        "RAAG": "Shukla Bilawal",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Shubh ghari shubh din",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0489 Shukla Bilawal Jhaptal(m.laya) Shubh ghari shubh din.mp3"
      },
      {
        "SL": "B0490",
        "RAAG": "Shukla Bilawal",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Raaja raam niranjan",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0490 Shukla Bilawal Chowtal(Vilambit) Raaja raam niranjan.mp3"
      },
      {
        "SL": "B0491",
        "RAAG": "Shukla Bilawal",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Bhar an jogayi jal",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0491 Shukla Bilawal Chowtal(Vilambit) Bharan jogayi jal.mp3"
      },
      {
        "SL": "B0492",
        "RAAG": "Kukub Bilawal",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Gaavo gunijan sab",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0492 Kukub Bilawal Sultal(m.laya) Gaavo gunijan sab.mp3"
      },
      {
        "SL": "B0493",
        "RAAG": "Kukub Bilawal",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Gaavo saheliyaa",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0493 Kukub Bilawal Jhaptal(m.laya) Gaavo saheliyaa.mp3"
      },
      {
        "SL": "B0494",
        "RAAG": "Kukub Bilawal",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Tere milanadaa",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0494 Kukub Bilawal Jhaptal(m.laya) Tere milanadaa.mp3"
      },
      {
        "SL": "B0495",
        "RAAG": "Kukub Bilawal",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Ka ko bhajan bee n",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0495 Kukub Bilawal Jhaptal(m.laya) Ka ko bhajan been.mp3"
      },
      {
        "SL": "B0496",
        "RAAG": "Kukub Bilawal",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Govind giridhar",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0496 Kukub Bilawal Jhaptal(m.laya) Govind giridhar.mp3"
      },
      {
        "SL": "B0497",
        "RAAG": "Kukub Bilawal",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Shiri shambhodhar",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0497 Kukub Bilawal Sultal(m.laya) Shiri shambhodhar.mp3"
      },
      {
        "SL": "B0498",
        "RAAG": "Kukub Bilawal",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Mahaadev",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0498 Kukub Bilawal Chowtal(Vilambit) Mahaadev.mp3"
      },
      {
        "SL": "B0499",
        "RAAG": "Kukub Bilawal",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Hazarat khwaaja",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0499 Kukub Bilawal Jhaptal(m.laya) Hazarat khwaaja.mp3"
      },
      {
        "SL": "B0500",
        "RAAG": "Kukub Bilawal",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Aba kovu kaise hori",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0500 Kukub Bilawal Dhamar(Vilambit) Aba kovu kaise hori.mp3"
      },
      {
        "SL": "B0501",
        "RAAG": "Kukub Bilawal",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Mana haran chaal",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0501 Kukub Bilawal Dhamar(Vilambit) Mana haran chaal.mp3"
      },
      {
        "SL": "B0502",
        "RAAG": "Nat",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Shudha swar rach mela",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0502 Nat Jhaptal(m.laya) Shudha swar rach mela.mp3"
      },
      {
        "SL": "B0503",
        "RAAG": "Nat",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Juvati jutha san phaag",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0503 Nat Jhaptal(m.laya) Juvati jutha san phaag.mp3"
      },
      {
        "SL": "B0504",
        "RAAG": "Nat Narayan",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Haath damaru liye",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0504 Nat Narayan Jhaptal(m.laya) Haath damaru liye.mp3"
      },
      {
        "SL": "B0505",
        "RAAG": "Nat Bilawal",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Aaj nav naagari",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0505 Nat Bilawal Jhaptal(m.laya) Aaj nav naagari.mp3"
      },
      {
        "SL": "B0506",
        "RAAG": "Nat Bilawal",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Mukut ke ranganpai",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0506 Nat Bilawal Jhaptal(m.laya) Mukut ke ranganpai.mp3"
      },
      {
        "SL": "B0507",
        "RAAG": "Nat Bilawal",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Puran puran parmanand",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0507 Nat Bilawal Chowtal(Vilambit) Puran puran parmanand.mp3"
      },
      {
        "SL": "B0508",
        "RAAG": "Pat Bihag",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Saajainna ye naayeri",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0508 Pat Bihag Teental(m.laya) Saajainna ye naayeri.mp3"
      },
      {
        "SL": "B0509",
        "RAAG": "Kamod Naat",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ho gaaye kamod n aat",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0509 Kamod Naat Teental(m.laya) Ho gaaye kamod naat.mp3"
      },
      {
        "SL": "B0510",
        "RAAG": "Kamod Naat",
        "TAAL": "Chowtal(m.laya)",
        "STARTING_WORDS": "Saavari surat",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0510 Kamod Naat Chowtal(m.laya) Saavari surat.mp3"
      },
      {
        "SL": "B0511",
        "RAAG": "Kedar Naat",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Dai maarore dhitan tora",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0511 Kedar Naat Teental(m.laya) Dai maarore dhitan tora.mp3"
      },
      {
        "SL": "B0512",
        "RAAG": "Bihagada",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Gaavat raag Bihagada",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0512 Bihagada Teental(m.laya) Gaavat raag Bihagada.mp3"
      },
      {
        "SL": "B0513",
        "RAAG": "Bihagada",
        "TAAL": "Rupak(m.laya)",
        "STARTING_WORDS": "Mag jaiyeree",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0513 Bihagada Rupak(m.laya) Mag jaiyeree.mp3"
      },
      {
        "SL": "B0514",
        "RAAG": "Nat Bihag",
        "TAAL": "Adachowtal(m.laya)",
        "STARTING_WORDS": "Kaise kaise bolat moso",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0514 Nat Bihag Adachowtal(m.laya) Kaise kaise bolat moso.mp3"
      },
      {
        "SL": "B0515",
        "RAAG": "Savani(Bihag ang)",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Jane akal sab",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0515 Savani(Bihag ang) Jhaptal(m.laya) Jane akal sab.mp3"
      },
      {
        "SL": "B0516",
        "RAAG": "Maluha Kedar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Maluhakedar chatur",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0516 Maluha Kedar Teental(m.laya) Maluhakedar chatur.mp3"
      },
      {
        "SL": "B0517",
        "RAAG": "Maluha Kedar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Krishna muraari shyam",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0517 Maluha Kedar Teental(m.laya) Krishna muraari shyam.mp3"
      },
      {
        "SL": "B0518",
        "RAAG": "Maluha Kedar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kaise jiyaa dharare",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0518 Maluha Kedar Teental(m.laya) Kaise jiyaa dharare.mp3"
      },
      {
        "SL": "B0519",
        "RAAG": "Maluha Kedar",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Mandar baajere",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0519 Maluha Kedar Tilwada(Vilambit) Mandar baajere.mp3"
      },
      {
        "SL": "B0520",
        "RAAG": "Maluha Kedar",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Mandar ma diyani",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0520 Maluha Kedar Tilwada(Vilambit) Mandar ma diyani.mp3"
      },
      {
        "SL": "B0521",
        "RAAG": "Maluha Kedar",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Mope rang daar gayo",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0521 Maluha Kedar Dhamar(Vilambit) Mope rang daar gayo.mp3"
      },
      {
        "SL": "B0522",
        "RAAG": "Maluha Kedar",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Lajohee aankhe",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0522 Maluha Kedar Dhamar(Vilambit) Lajohee aankhe.mp3"
      },
      {
        "SL": "B0523",
        "RAAG": "Jaldhar Kedar",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Ati manohar roop ragani",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0523 Jaldhar Kedar Jhaptal(m.laya) Ati manohar roop ragani.mp3"
      },
      {
        "SL": "B0524",
        "RAAG": "Jaldhar Kedar",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Jaldhar Kedar guni kahat",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0524 Jaldhar Kedar Jhaptal(m.laya) Jaldhar Kedar guni kahat.mp3"
      },
      {
        "SL": "B0525",
        "RAAG": "Durga",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Raag guni durga",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0525 Durga Teental(m.laya) Raag guni durga.mp3"
      },
      {
        "SL": "B0526",
        "RAAG": "Durga",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Chitak rahi chandani",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0526 Durga Teental(m.laya) Chitak rahi chandani.mp3"
      },
      {
        "SL": "B0527",
        "RAAG": "Durga",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Devi bhajo durga",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0527 Durga Teental(m.laya) Devi bhajo durga.mp3"
      },
      {
        "SL": "B0528",
        "RAAG": "Durga",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Aho jin bolo piya",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0528 Durga Teental(m.laya) Aho jin bolo piya.mp3"
      },
      {
        "SL": "B0529",
        "RAAG": "Durga",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Too jin bolare pyare",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0529 Durga Teental(m.laya) Too jin bolare pyare.mp3"
      },
      {
        "SL": "B0530",
        "RAAG": "Chaya",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Sakhiya racho raas",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0530 Chaya Jhaptal(m.laya) Sakhiya racho raas.mp3"
      },
      {
        "SL": "B0531",
        "RAAG": "Chaya Tilak",
        "TAAL": "Jhumra(m.laya))",
        "STARTING_WORDS": "Jaay sunaavo hariso",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0531 Chaya Tilak Jhumra(m.laya)) Jaay sunaavo hariso.mp3"
      },
      {
        "SL": "B0532",
        "RAAG": "Gunkali",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Vidya kaha pa ayee",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0532 Gunkali Sultal(m.laya) Vidya kaha paayee.mp3"
      },
      {
        "SL": "B0533",
        "RAAG": "Gunkali",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Chatur naam japale",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0533 Gunkali Sultal(m.laya) Chatur naam japale.mp3"
      },
      {
        "SL": "B0534",
        "RAAG": "Pahadi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Murali madhur chatur",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0534 Pahadi Teental(m.laya) Murali madhur chatur.mp3"
      },
      {
        "SL": "B0535",
        "RAAG": "Pahadi",
        "TAAL": "Deepchandi(m.laya)",
        "STARTING_WORDS": "Saadhujire naahi dolan",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0535 Pahadi Deepchandi(m.laya) Saadhujire naahi dolan.mp3"
      },
      {
        "SL": "B0536",
        "RAAG": "Maand",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Maand surat batlaaye",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0536 Maand Teental(m.laya) Maand surat batlaaye.mp3"
      },
      {
        "SL": "B0537",
        "RAAG": "Mewada",
        "TAAL": "Dadra(m.laya)",
        "STARTING_WORDS": "Kunjarali de sandeso",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0537 Mewada Dadra(m.laya) Kunjarali de sandeso.mp3"
      },
      {
        "SL": "B0538",
        "RAAG": "Patmanjari",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Chaahat hai man hori",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0538 Patmanjari Dhamar(Vilambit) Chaahat hai man hori.mp3"
      },
      {
        "SL": "B0539",
        "RAAG": "Patmanjari",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Roop joban guna khelat",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0539 Patmanjari Dhamar(Vilambit) Roop joban guna khelat.mp3"
      },
      {
        "SL": "B0540",
        "RAAG": "Patmanjari",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Trishoolakhappar",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0540 Patmanjari Sultal(m.laya) Trishoolakhappar.mp3"
      },
      {
        "SL": "B0541",
        "RAAG": "Patmanjari",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Anaahat naad samudra",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0541 Patmanjari Chowtal(Vilambit) Anaahat naad samudra.mp3"
      },
      {
        "SL": "B0542",
        "RAAG": "Patmanjari",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Sakal guni jaane",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0542 Patmanjari Jhaptal(m.laya) Sakal guni jaane.mp3"
      },
      {
        "SL": "B0543",
        "RAAG": "Hamsdhwani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Gunijan Haunsdhwani",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0543 Hamsdhwani Teental(m.laya) Gunijan Haunsdhwani.mp3"
      },
      {
        "SL": "B0544",
        "RAAG": "Deepak",
        "TAAL": "Jhumra(Vilambit)",
        "STARTING_WORDS": "Laalake brijabaalake",
        "THAAT": "Bilawal",
        "FILE_NAME": "B0544 Deepak Jhumra(Vilambit) Laalake brijabaalake.mp3"
      },
      {
        "SL": "C0545",
        "RAAG": "Khamaj",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "ga ga sa ga ma pa",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0545 Khamaj Teental(m.laya) ga ga sa ga ma pa.mp3"
      },
      {
        "SL": "C0546",
        "RAAG": "Khamaj",
        "TAAL": "Ada chowtal(m.laya)",
        "STARTING_WORDS": "sa ga ma pa ga ma",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0546 Khamaj Ada chowtal(m.laya) sa ga ma pa ga ma.mp3"
      },
      {
        "SL": "C0547",
        "RAAG": "Khamaj",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tab kahat chatur",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0547 Khamaj Teental(m.laya) Tab kahat chatur.mp3"
      },
      {
        "SL": "C0548",
        "RAAG": "Khamaj",
        "TAAL": "Ada chowtal(m.laya)",
        "STARTING_WORDS": "Sab sakhiyaa mil",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0548 Khamaj Ada chowtal(m.laya) Sab sakhiyaa mil.mp3"
      },
      {
        "SL": "C0549",
        "RAAG": "Khamaj",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Panghat muraliya baaje",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0549 Khamaj Teental(m.laya) Panghat muraliya baaje.mp3"
      },
      {
        "SL": "C0550",
        "RAAG": "Khamaj",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Lagarahyo mana radha",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0550 Khamaj Teental(m.laya) Lagarahyo mana radha.mp3"
      },
      {
        "SL": "C0551",
        "RAAG": "Khamaj",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Arey mana harso",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0551 Khamaj Teental(m.laya) Arey mana harso.mp3"
      },
      {
        "SL": "C0552",
        "RAAG": "Khamaj",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Saaval mohanaa mai",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0552 Khamaj Teental(m.laya) Saaval mohanaa mai.mp3"
      },
      {
        "SL": "C0553",
        "RAAG": "Khamaj",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Naman karoo mai",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0553 Khamaj Teental(m.laya) Naman karoo mai.mp3"
      },
      {
        "SL": "C0554",
        "RAAG": "Khamaj",
        "TAAL": "Dadra(m.laya)",
        "STARTING_WORDS": "Sudhan leeni jabse gaye",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0554 Khamaj Dadra(m.laya) Sudhan leeni jabse gaye.mp3"
      },
      {
        "SL": "C0555",
        "RAAG": "Khamaj",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Na maanugi na maanugi",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0555 Khamaj Teental(m.laya) Na maanugi na maanugi.mp3"
      },
      {
        "SL": "C0556",
        "RAAG": "Khamaj",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Sakhi morey man-leeno",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0556 Khamaj Teental(m.laya) Sakhi morey man-leeno.mp3"
      },
      {
        "SL": "C0557",
        "RAAG": "Khamaj",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Morey saiyaa nahi aaye",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0557 Khamaj Teental(m.laya) Morey saiyaa nahi aaye.mp3"
      },
      {
        "SL": "C0558",
        "RAAG": "Khamaj",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ab kab tak tarsaaye",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0558 Khamaj Teental(m.laya) Ab kab tak tarsaaye.mp3"
      },
      {
        "SL": "C0559",
        "RAAG": "Khamaj",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Dhun so baajeso sakhi",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0559 Khamaj Teental(m.laya) Dhun so baajeso sakhi.mp3"
      },
      {
        "SL": "C0560",
        "RAAG": "Khamaj",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Samajhat naahi panghat",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0560 Khamaj Teental(m.laya) Samajhat naahi panghat.mp3"
      },
      {
        "SL": "C0561",
        "RAAG": "Khamaj",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Arey morey saiyaa",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0561 Khamaj Teental(m.laya) Arey morey saiyaa.mp3"
      },
      {
        "SL": "C0562",
        "RAAG": "Khamaj",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Shyam sundar banmali",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0562 Khamaj Teental(m.laya) Shyam sundar banmali.mp3"
      },
      {
        "SL": "C0563",
        "RAAG": "Khamaj",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Pardesava jin jaayee",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0563 Khamaj Teental(m.laya) Pardesava jin jaayee.mp3"
      },
      {
        "SL": "C0564",
        "RAAG": "Khamaj",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Solai kala jame samani",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0564 Khamaj Jhaptal(m.laya) Solai kala jame samani.mp3"
      },
      {
        "SL": "C0565",
        "RAAG": "Khamaj",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Sudh bisar gayi aaj",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0565 Khamaj Jhaptal(m.laya) Sudh bisar gayi aaj.mp3"
      },
      {
        "SL": "C0566",
        "RAAG": "Khamaj",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Sang at annaghaat",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0566 Khamaj Jhaptal(m.laya) Sangat annaghaat.mp3"
      },
      {
        "SL": "C0567",
        "RAAG": "Khamaj",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Rang laal roop laal",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0567 Khamaj Chowtal(Vilambit) Rang laal roop laal.mp3"
      },
      {
        "SL": "C0568",
        "RAAG": "Khamaj",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Terohi chandra badan",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0568 Khamaj Chowtal(Vilambit) Terohi chandra badan.mp3"
      },
      {
        "SL": "C0569",
        "RAAG": "Khamaj",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Maayiree barjo",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0569 Khamaj Chowtal(Vilambit) Maayiree barjo.mp3"
      },
      {
        "SL": "C0570",
        "RAAG": "Khamaj",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Aaj to sakhiree",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0570 Khamaj Chowtal(Vilambit) Aaj to sakhiree.mp3"
      },
      {
        "SL": "C0571",
        "RAAG": "Khamaj",
        "TAAL": "Deepchandi(m.laya)",
        "STARTING_WORDS": "Hori aaj jare",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0571 Khamaj Deepchandi(m.laya) Hori aaj jare.mp3"
      },
      {
        "SL": "C0572",
        "RAAG": "Khamaj",
        "TAAL": "Deepchandi(m.laya)",
        "STARTING_WORDS": "Achara choro ab janede",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0572 Khamaj Deepchandi(m.laya) Achara choro ab janede.mp3"
      },
      {
        "SL": "C0573",
        "RAAG": "Khamaj",
        "TAAL": "Deepchandi(m.laya)",
        "STARTING_WORDS": "Jaavo kadar naahi bolo",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0573 Khamaj Deepchandi(m.laya) Jaavo kadar naahi bolo.mp3"
      },
      {
        "SL": "C0574",
        "RAAG": "Khamaj",
        "TAAL": "Deepchandi(m.laya)",
        "STARTING_WORDS": "Hori khelat mose nayire",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0574 Khamaj Deepchandi(m.laya) Hori khelat mose nayire.mp3"
      },
      {
        "SL": "C0575",
        "RAAG": "Khamaj",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Abake samai phaagun",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0575 Khamaj Dhamar(Vilambit) Abake samai phaagun.mp3"
      },
      {
        "SL": "C0576",
        "RAAG": "Khamaj",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Aaye rangso bhije",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0576 Khamaj Dhamar(Vilambit) Aaye rangso bhije.mp3"
      },
      {
        "SL": "C0577",
        "RAAG": "Khamaj",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Odena tomtanan derena",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0577 Khamaj Teental(m.laya) Odena tomtanan derena.mp3"
      },
      {
        "SL": "C0578",
        "RAAG": "Khamaj",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Odetana derenaa deem",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0578 Khamaj Teental(m.laya) Odetana derenaa deem.mp3"
      },
      {
        "SL": "C0579",
        "RAAG": "Des",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "ni dha pa ma ga re",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0579 Des Teental(m.laya) ni dha pa ma ga re.mp3"
      },
      {
        "SL": "C0580",
        "RAAG": "Des",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Kahe chatur ab murat",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0580 Des Jhaptal(m.laya) Kahe chatur ab murat.mp3"
      },
      {
        "SL": "C0581",
        "RAAG": "Des",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Morey mana hari charan",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0581 Des Teental(m.laya) Morey mana hari charan.mp3"
      },
      {
        "SL": "C0582",
        "RAAG": "Des",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Rama pati raam",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0582 Des Teental(m.laya) Rama pati raam.mp3"
      },
      {
        "SL": "C0583",
        "RAAG": "Des",
        "TAAL": "Dadra(m.laya)",
        "STARTING_WORDS": "Mukat chatak giridhar",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0583 Des Dadra(m.laya) Mukat chatak giridhar.mp3"
      },
      {
        "SL": "C0584",
        "RAAG": "Des",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Rab guna gaayare",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0584 Des Teental(m.laya) Rab guna gaayare.mp3"
      },
      {
        "SL": "C0585",
        "RAAG": "Des",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Dragan laagi Morey",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0585 Des Ektal(m.laya) Dragan laagi Morey.mp3"
      },
      {
        "SL": "C0586",
        "RAAG": "Des",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Piya kar dhar dekho",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0586 Des Ektal(m.laya) Piya kar dhar dekho.mp3"
      },
      {
        "SL": "C0587",
        "RAAG": "Des",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Paiya paroo sees",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0587 Des Ektal(m.laya) Paiya paroo sees.mp3"
      },
      {
        "SL": "C0588",
        "RAAG": "Des",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Mehare banban daar",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0588 Des Teental(m.laya) Mehare banban daar.mp3"
      },
      {
        "SL": "C0589",
        "RAAG": "Des",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Sudh leho piyake aavan",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0589 Des Teental(m.laya) Sudh leho piyake aavan.mp3"
      },
      {
        "SL": "C0590",
        "RAAG": "Des",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Mohan tore paiyaa",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0590 Des Teental(m.laya) Mohan tore paiyaa.mp3"
      },
      {
        "SL": "C0591",
        "RAAG": "Des",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ghan gagan ghan",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0591 Des Teental(m.laya) Ghan gagan ghan.mp3"
      },
      {
        "SL": "C0592",
        "RAAG": "Des",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Chatrang gaave",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0592 Des Teental(m.laya) Chatrang gaave.mp3"
      },
      {
        "SL": "C0593",
        "RAAG": "Des",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Aavori saheli zhoka",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0593 Des Teental(m.laya) Aavori saheli zhoka.mp3"
      },
      {
        "SL": "C0594",
        "RAAG": "Des",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Jo mana ulazhe julphan",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0594 Des Teental(m.laya) Jo mana ulazhe julphan.mp3"
      },
      {
        "SL": "C0595",
        "RAAG": "Des",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ho nainoraa savaadi",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0595 Des Teental(m.laya) Ho nainoraa savaadi.mp3"
      },
      {
        "SL": "C0596",
        "RAAG": "Des",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Laagrahyo mana",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0596 Des Teental(m.laya) Laagrahyo mana.mp3"
      },
      {
        "SL": "C0597",
        "RAAG": "Des",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Hamaraa unabin",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0597 Des Teental(m.laya) Hamaraa unabin.mp3"
      },
      {
        "SL": "C0598",
        "RAAG": "Des",
        "TAAL": "Adachowtal(Vilambit)",
        "STARTING_WORDS": "Maru ho rame che sikaar",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0598 Des Adachowtal(Vilambit) Maru ho rameche sikaar.mp3"
      },
      {
        "SL": "C0599",
        "RAAG": "Des",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Hoji mhaaree bega suna",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0599 Des Tilwada(Vilambit) Hoji mhaaree bega suna.mp3"
      },
      {
        "SL": "C0600",
        "RAAG": "Des",
        "TAAL": "Jhaptal(m.laya).",
        "STARTING_WORDS": "Phiratan phere naina",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0600 Des Jhaptal(m.laya). Phiratan phere naina.mp3"
      },
      {
        "SL": "C0601",
        "RAAG": "Des",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Achal raho raj",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0601 Des Jhaptal(m.laya) Achal raho raj.mp3"
      },
      {
        "SL": "C0602",
        "RAAG": "Des",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Shobha sadan pyaari",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0602 Des Jhaptal(m.laya) Shobha sadan pyaari.mp3"
      },
      {
        "SL": "C0603",
        "RAAG": "Des",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Dekhori na maane",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0603 Des Jhaptal(m.laya) Dekhori na maane.mp3"
      },
      {
        "SL": "C0604",
        "RAAG": "Des",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Lochan ruth laal",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0604 Des Chowtal(Vilambit) Lochan ruth laal.mp3"
      },
      {
        "SL": "C0605",
        "RAAG": "Des",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Tho so kon bole",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0605 Des Chowtal(Vilambit) Tho so kon bole.mp3"
      },
      {
        "SL": "C0606",
        "RAAG": "Des",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Yeri sakhi saavan aayo",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0606 Des Chowtal(Vilambit) Yeri sakhi saavan aayo.mp3"
      },
      {
        "SL": "C0607",
        "RAAG": "Des",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Ye ata dhoom machayi",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0607 Des Dhamar(Vilambit) Ye ata dhoom machayi.mp3"
      },
      {
        "SL": "C0608",
        "RAAG": "Des",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Nand ke chail chandade",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0608 Des Dhamar(Vilambit) Nand ke chail chandade.mp3"
      },
      {
        "SL": "C0609",
        "RAAG": "Des",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tadiyare tadeemtanan",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0609 Des Teental(m.laya) Tadiyare tadeemtanan.mp3"
      },
      {
        "SL": "C0610",
        "RAAG": "Des",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tadare daani deem",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0610 Des Teental(m.laya) Tadare daani deem.mp3"
      },
      {
        "SL": "C0611",
        "RAAG": "Tilak Kamod",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "ni pa ni sa re ga ni sa",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0611 Tilak Kamod Teental(m.laya) ni pa ni sa re ga ni sa.mp3"
      },
      {
        "SL": "C0612",
        "RAAG": "Tilak Kamod",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Tilak Kamod ko",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0612 Tilak Kamod Jhaptal(m.laya) Tilak Kamod ko.mp3"
      },
      {
        "SL": "C0613",
        "RAAG": "Tilak Kamod",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "pa ni sa re ga sa",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0613 Tilak Kamod Teental(m.laya) pa ni sa re ga sa.mp3"
      },
      {
        "SL": "C0614",
        "RAAG": "Tilak Kamod",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kar hari ko bhajan",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0614 Tilak Kamod Teental(m.laya) Kar hari ko bhajan.mp3"
      },
      {
        "SL": "C0615",
        "RAAG": "Tilak Kamod",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Magame jhagaro karat",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0615 Tilak Kamod Teental(m.laya) Magame jhagaro karat.mp3"
      },
      {
        "SL": "C0616",
        "RAAG": "Tilak Kamod",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Neer bharan kaise javu",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0616 Tilak Kamod Teental(m.laya) Neer bharan kaise javu.mp3"
      },
      {
        "SL": "C0617",
        "RAAG": "Tilak Kamod",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kadar aage naa pag",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0617 Tilak Kamod Teental(m.laya) Kadar aage naa pag.mp3"
      },
      {
        "SL": "C0618",
        "RAAG": "Tilak Kamod",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Unabin bicharath raina",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0618 Tilak Kamod Ektal(m.laya) Unabin bicharath raina.mp3"
      },
      {
        "SL": "C0619",
        "RAAG": "Tilak Kamod",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Gunaki characha karale",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0619 Tilak Kamod Teental(m.laya) Gunaki characha karale.mp3"
      },
      {
        "SL": "C0620",
        "RAAG": "Tilak Kamod",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Dhukhva mai kase kahu",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0620 Tilak Kamod Teental(m.laya) Dhukhva mai kase kahu.mp3"
      },
      {
        "SL": "C0621",
        "RAAG": "Tilak Kamod",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Mai kadar piyase kaise",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0621 Tilak Kamod Teental(m.laya) Mai kadar piyase kaise.mp3"
      },
      {
        "SL": "C0622",
        "RAAG": "Tilak Kamod",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Naina morey nipat bikat",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0622 Tilak Kamod Teental(m.laya) Naina morey nipat bikat.mp3"
      },
      {
        "SL": "C0623",
        "RAAG": "Tilak Kamod",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Atha laathi ati lachak",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0623 Tilak Kamod Teental(m.laya) Atha laathi ati lachak.mp3"
      },
      {
        "SL": "C0624",
        "RAAG": "Tilak Kamod",
        "TAAL": "Ektal(m.laya))",
        "STARTING_WORDS": "Khevatiya paar lagaavo",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0624 Tilak Kamod Ektal(m.laya)) Khevatiya paar lagaavo.mp3"
      },
      {
        "SL": "C0625",
        "RAAG": "Tilak Kamod",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Chandra badani",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0625 Tilak Kamod Sultal(m.laya) Chandra badani.mp3"
      },
      {
        "SL": "C0626",
        "RAAG": "Tilak Kamod",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Bikat narasing so",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0626 Tilak Kamod Jhaptal(m.laya) Bikat narasing so.mp3"
      },
      {
        "SL": "C0627",
        "RAAG": "Tilak Kamod",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Hara binaayak lambodar",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0627 Tilak Kamod Jhaptal(m.laya) Hara binaayak lambodar.mp3"
      },
      {
        "SL": "C0628",
        "RAAG": "Tilak Kamod",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Harbinayak-2nd version",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0628 Tilak Kamod Jhaptal(m.laya) Harbinayak-2nd version.mp3"
      },
      {
        "SL": "C0629",
        "RAAG": "Tilak Kamod",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Naiyaa san itani jaay",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0629 Tilak Kamod Jhaptal(m.laya) Naiyaa san itani jaay.mp3"
      },
      {
        "SL": "C0630",
        "RAAG": "Tilak Kamod",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Chanchal chitchor chatur",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0630 Tilak Kamod Chowtal(Vilambit) Chanchal chitchor chatur.mp3"
      },
      {
        "SL": "C0631",
        "RAAG": "Tilak Kamod",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Thoo dayaal deen",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0631 Tilak Kamod Chowtal(Vilambit) Thoo dayaal deen.mp3"
      },
      {
        "SL": "C0632",
        "RAAG": "Tilak Kamod",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Ramnaam sumaran karo",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0632 Tilak Kamod Chowtal(Vilambit) Ramnaam sumaran karo.mp3"
      },
      {
        "SL": "C0633",
        "RAAG": "Tilak Kamod",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Har har har karat phir at",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0633 Tilak Kamod Chowtal(Vilambit) Har har har karat phirat.mp3"
      },
      {
        "SL": "C0634",
        "RAAG": "Tilak Kamod",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Mori suranga chunariya",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0634 Tilak Kamod Dhamar(Vilambit) Mori suranga chunariya.mp3"
      },
      {
        "SL": "C0635",
        "RAAG": "Tilak Kamod",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Hori mai kaise ab khelo",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0635 Tilak Kamod Dhamar(Vilambit) Hori mai kaise ab khelo.mp3"
      },
      {
        "SL": "C0636",
        "RAAG": "Tilak Kamod",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Chalori hori khelan",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0636 Tilak Kamod Dhamar(Vilambit) Chalori hori khelan.mp3"
      },
      {
        "SL": "C0637",
        "RAAG": "Tilak Kamod",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Tanomtanana tanom",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0637 Tilak Kamod Ektal(m.laya) Tanomtanana tanom.mp3"
      },
      {
        "SL": "C0638",
        "RAAG": "Jaijaiwanthi",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "pa pa ri ri ri ri ri ri ga sa",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0638 Jaijaiwanthi Jhaptal(m.laya) pa pa ri ri ri ri ri ri g.mp3"
      },
      {
        "SL": "C0639",
        "RAAG": "Jaijaiwanthi",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Sorat ko aoung saadh",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0639 Jaijaiwanthi Chowtal(Vilambit) Sorat ko aoung saadh.mp3"
      },
      {
        "SL": "C0640",
        "RAAG": "Jaijaiwanthi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Gaavo Sakhiya",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0640 Jaijaiwanthi Teental(m.laya) Gaavo Sakhiya.mp3"
      },
      {
        "SL": "C0641",
        "RAAG": "Jaijaiwanthi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Daamani damake",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0641 Jaijaiwanthi Teental(m.laya) Daamani damake.mp3"
      },
      {
        "SL": "C0642",
        "RAAG": "Jaijaiwanthi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kithe ram rahe mana",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0642 Jaijaiwanthi Teental(m.laya) Kithe ram rahe mana.mp3"
      },
      {
        "SL": "C0643",
        "RAAG": "Jaijaiwanthi",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Kouchan singhasan",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0643 Jaijaiwanthi Jhaptal(m.laya) Kouchan singhasan.mp3"
      },
      {
        "SL": "C0644",
        "RAAG": "Jaijaiwanthi",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Chit chadi sakhi mori",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0644 Jaijaiwanthi Jhaptal(m.laya) Chit chadi sakhi mori.mp3"
      },
      {
        "SL": "C0645",
        "RAAG": "Jaijaiwanthi",
        "TAAL": "Jhumra(Vilambit)",
        "STARTING_WORDS": "Lara maayi sajani",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0645 Jaijaiwanthi Jhumra(Vilambit) Lara maayi sajani.mp3"
      },
      {
        "SL": "C0646",
        "RAAG": "Jaijaiwanthi",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Jabate Laagali Ankhe",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0646 Jaijaiwanthi Tilwada(Vilambit) Jabate Laagali Ankhe.mp3"
      },
      {
        "SL": "C0647",
        "RAAG": "Jaijaiwanthi",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Paalana ghad laare",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0647 Jaijaiwanthi Ektal(Vilambit) Paalana ghad laare.mp3"
      },
      {
        "SL": "C0648",
        "RAAG": "Jaijaiwanthi",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Maayi savan maas",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0648 Jaijaiwanthi Ektal(Vilambit) Maayi savan maas.mp3"
      },
      {
        "SL": "C0649",
        "RAAG": "Jaijaiwanthi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Bairan ho gayee",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0649 Jaijaiwanthi Teental(m.laya) Bairan ho gayee.mp3"
      },
      {
        "SL": "C0650",
        "RAAG": "Jaijaiwanthi",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Piya ham jaan leeni",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0650 Jaijaiwanthi Tilwada(Vilambit) Piya ham jaan leeni.mp3"
      },
      {
        "SL": "C0651",
        "RAAG": "Jaijaiwanthi",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Chouchal chapal nain",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0651 Jaijaiwanthi Sultal(m.laya) Chouchal chapal nain.mp3"
      },
      {
        "SL": "C0652",
        "RAAG": "Jaijaiwanthi",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Naayan ke Bhekha",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0652 Jaijaiwanthi Chowtal(Vilambit) Naayan ke Bhekha.mp3"
      },
      {
        "SL": "C0653",
        "RAAG": "Jaijaiwanthi",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Madhe raat aayi",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0653 Jaijaiwanthi Chowtal(Vilambit) Madhe raat aayi.mp3"
      },
      {
        "SL": "C0654",
        "RAAG": "Jaijaiwanthi",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Aali aaj praan pyare",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0654 Jaijaiwanthi Chowtal(Vilambit) Aali aaj praan pyare.mp3"
      },
      {
        "SL": "C0655",
        "RAAG": "Jaijaiwanthi",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Jayamaal raani",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0655 Jaijaiwanthi Chowtal(Vilambit) Jayamaal raani.mp3"
      },
      {
        "SL": "C0656",
        "RAAG": "Jaijaiwanthi",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Bhali bhayee brij hori",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0656 Jaijaiwanthi Dhamar(Vilambit) Bhali bhayee brij hori.mp3"
      },
      {
        "SL": "C0657",
        "RAAG": "Jaijaiwanthi",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Yeri aaj piya",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0657 Jaijaiwanthi Dhamar(Vilambit) Yeri aaj piya.mp3"
      },
      {
        "SL": "C0658",
        "RAAG": "Jaijaiwanthi",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Aaj chabilo mohan",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0658 Jaijaiwanthi Dhamar(Vilambit) Aaj chabilo mohan.mp3"
      },
      {
        "SL": "C0659",
        "RAAG": "Goud Malhar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "re ma re ma ga re sa sa",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0659 Goud Malhar Teental(m.laya) re ma re ma ga re sa sa.mp3"
      },
      {
        "SL": "C0660",
        "RAAG": "Goud Malhar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Goud malhar",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0660 Goud Malhar Teental(m.laya) Goud malhar.mp3"
      },
      {
        "SL": "C0661",
        "RAAG": "Goud Malhar",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Gaaje Raaje",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0661 Goud Malhar Ektal(m.laya) Gaaje Raaje.mp3"
      },
      {
        "SL": "C0662",
        "RAAG": "Goud Malhar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Piyaravaa ajhu na aaye",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0662 Goud Malhar Teental(m.laya) Piyaravaa ajhu na aaye.mp3"
      },
      {
        "SL": "C0663",
        "RAAG": "Goud Malhar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Jani jani tumare mana",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0663 Goud Malhar Teental(m.laya) Jani jani tumare mana.mp3"
      },
      {
        "SL": "C0664",
        "RAAG": "Goud Malhar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Balamaa bahar aayi",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0664 Goud Malhar Teental(m.laya) Balamaa bahar aayi.mp3"
      },
      {
        "SL": "C0665",
        "RAAG": "Goud Malhar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Garajath barasath",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0665 Goud Malhar Teental(m.laya) Garajath barasath.mp3"
      },
      {
        "SL": "C0666",
        "RAAG": "Goud Malhar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Banari byaahan aayare",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0666 Goud Malhar Teental(m.laya) Banari byaahan aayare.mp3"
      },
      {
        "SL": "C0667",
        "RAAG": "Goud Malhar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Meharava barasath",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0667 Goud Malhar Teental(m.laya) Meharava barasath.mp3"
      },
      {
        "SL": "C0668",
        "RAAG": "Goud Malhar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Aaye bada raa kaare",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0668 Goud malhar Teental(m.laya) Aaye badara kaare.mp3"
      },
      {
        "SL": "C0669",
        "RAAG": "Goud Malhar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Migava jharjhar laage",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0669 Goud Malhar Teental(m.laya) Migava jharjhar laage.mp3"
      },
      {
        "SL": "C0670",
        "RAAG": "Goud Malhar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Room-jhoom badarava",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0670 Goud Malhar Teental(m.laya) Room-jhoom badarava.mp3"
      },
      {
        "SL": "C0671",
        "RAAG": "Goud Malhar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Daadurva bulaayire",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0671 Goud Malhar Teental(m.laya) Daadurva bulaayire.mp3"
      },
      {
        "SL": "C0672",
        "RAAG": "Goud Malhar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Jhuk aayi badariya",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0672 Goud Malhar Teental(m.laya) Jhuk aayi badariya.mp3"
      },
      {
        "SL": "C0673",
        "RAAG": "Goud Malhar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kaari badariyaa ghere",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0673 Goud Malhar Teental(m.laya) Kaari badariyaa ghere.mp3"
      },
      {
        "SL": "C0674",
        "RAAG": "Goud Malhar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Maanan kareri gori",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0674 Goud Malhar Teental(m.laya) Maanan kareri gori.mp3"
      },
      {
        "SL": "C0675",
        "RAAG": "Goud Malhar",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Kahe ho",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0675 Goud Malhar Tilwada(Vilambit) Kahe ho.mp3"
      },
      {
        "SL": "C0676",
        "RAAG": "Goud Malhar",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Piyaare aa aajoji",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0676 Goud Malhar Tilwada(Vilambit) Piyaare aa aajoji.mp3"
      },
      {
        "SL": "C0677",
        "RAAG": "Goud Malhar",
        "TAAL": "Adachoutal(Vilambit)",
        "STARTING_WORDS": "Basee meharava",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0677 Goud Malhar Adachoutal(Vilambit) Basee meharava.mp3"
      },
      {
        "SL": "C0678",
        "RAAG": "Goud Malhar",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Aayiye ghataa ghumand",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0678 Goud Malhar Chowtal(Vilambit) Aayiye ghataa ghumand.mp3"
      },
      {
        "SL": "C0679",
        "RAAG": "Goud Malhar",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Umand ghumand aaye",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0679 Goud Malhar Chowtal(Vilambit) Umand ghumand aaye.mp3"
      },
      {
        "SL": "C0680",
        "RAAG": "Goud Malhar",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Aayiri barakha ruth",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0680 Goud Malhar Chowtal(Vilambit) Aayiri barakha ruth.mp3"
      },
      {
        "SL": "C0681",
        "RAAG": "Goud Malhar",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Umand ghumand ghan",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0681 Goud Malhar Dhamar(Vilambit) Umand ghumand ghan.mp3"
      },
      {
        "SL": "C0682",
        "RAAG": "Goud Malhar",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Aho ghan ghumandyo",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0682 Goud Malhar Dhamar(Vilambit) Aho ghan ghumandyo.mp3"
      },
      {
        "SL": "C0683",
        "RAAG": "Goud Malhar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tanana dir dir dani",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0683 Goud Malhar Teental(m.laya) Tanana dir dir dani.mp3"
      },
      {
        "SL": "C0684",
        "RAAG": "Jhinjhoti",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Aashraya raag",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0684 Jhinjhoti Teental(m.laya) Aashraya raag.mp3"
      },
      {
        "SL": "C0685",
        "RAAG": "Jhinjhoti",
        "TAAL": "Dadra(m.laya)",
        "STARTING_WORDS": "Madhur madhur panghat",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0685 Jhinjhoti Dadra(m.laya) Madhur madhur panghat.mp3"
      },
      {
        "SL": "C0686",
        "RAAG": "Jhinjhoti",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Akhiya johathi ab nain",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0686 Jhinjhoti Teental(m.laya) Akhiya johathi ab nain.mp3"
      },
      {
        "SL": "C0687",
        "RAAG": "Jhinjhoti",
        "TAAL": "Ektaal(Vilambit)",
        "STARTING_WORDS": "Merey mana laal gopaal",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0687 Jhinjhoti Ektaal(Vilambit) Merey mana laal gopaal.mp3"
      },
      {
        "SL": "C0688",
        "RAAG": "Jhinjhoti",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Akhiya johati ab nain",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0688 Jhinjhoti Chowtal(Vilambit) Akhiya johati ab nain.mp3"
      },
      {
        "SL": "C0689",
        "RAAG": "Jhinjhoti",
        "TAAL": "Ektaal(m.laya)",
        "STARTING_WORDS": "Jahaa kachu taajimey",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0689 Jhinjhoti Ektaal(m.laya) Jahaa kachu taajimey.mp3"
      },
      {
        "SL": "C0690",
        "RAAG": "Jhinjhoti",
        "TAAL": "Chowtal(m.laya)",
        "STARTING_WORDS": "Itana kovu kaho",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0690 Jhinjhoti Chowtal(m.laya) Itana kovu kaho.mp3"
      },
      {
        "SL": "C0691",
        "RAAG": "Jhinjhoti",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Aayo phaagun maas",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0691 Jhinjhoti Dhamar(Vilambit) Aayo phaagun maas.mp3"
      },
      {
        "SL": "C0692",
        "RAAG": "Jhinjhoti",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Chalori sakhi brijamey",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0692 Jhinjhoti Dhamar(Vilambit) Chalori sakhi brijamey.mp3"
      },
      {
        "SL": "C0693",
        "RAAG": "Khambavati",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Chatara khambaavati",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0693 Khambavati Jhaptal(m.laya) Chatara khambaavati.mp3"
      },
      {
        "SL": "C0694",
        "RAAG": "Khambavati",
        "TAAL": "Sultaal(m.laya)",
        "STARTING_WORDS": "Khambavati gaavath",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0694 Khambavati Sultaal(m.laya) Khambavati gaavath.mp3"
      },
      {
        "SL": "C0695",
        "RAAG": "Khambavati",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Piya bin naina neend",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0695 Khambavati Teental(m.laya) Piya bin naina neend.mp3"
      },
      {
        "SL": "C0696",
        "RAAG": "Khambavati",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Ginath rahi taare",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0696 Khambavati Jhaptal(m.laya) Ginath rahi taare.mp3"
      },
      {
        "SL": "C0697",
        "RAAG": "Khambavati",
        "TAAL": "Adachowtal(Vilambit)",
        "STARTING_WORDS": "Mai tho jaagi",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0697 Khambavati Adachowtal(Vilambit) Mai tho jaagi.mp3"
      },
      {
        "SL": "C0698",
        "RAAG": "Tilang",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ri dha barajath roop",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0698 Tilang Teental(m.laya) Ri dha barajath roop.mp3"
      },
      {
        "SL": "C0699",
        "RAAG": "Tilang",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Gaay sakhi raadhika",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0699 Tilang Jhaptal(m.laya) Gaay sakhi raadhika.mp3"
      },
      {
        "SL": "C0700",
        "RAAG": "Tilang",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Sajan thum kaahe na",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0700 Tilang Teental(m.laya) Sajan thum kaahe na.mp3"
      },
      {
        "SL": "C0701",
        "RAAG": "Tilang",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ho mereto mana shyam",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0701 Tilang Teental(m.laya) Ho mereto mana shyam.mp3"
      },
      {
        "SL": "C0702",
        "RAAG": "Tilang",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Baskino baat chalat jiya",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0702 Tilang Teental(m.laya) Baskino baat chalat jiya.mp3"
      },
      {
        "SL": "C0703",
        "RAAG": "Tilang",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Samajh samajh aali",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0703 Tilang Chowtal(Vilambit) Samajh samajh aali.mp3"
      },
      {
        "SL": "C0704",
        "RAAG": "Durga",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Devi duraga",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0704 Durga Jhaptal(m.laya) Devi duraga.mp3"
      },
      {
        "SL": "C0705",
        "RAAG": "Durga",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Jo banaake jor tor",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0705 Durga Chowtal(Vilambit) Jo banaake jor tor.mp3"
      },
      {
        "SL": "C0706",
        "RAAG": "Raageshwari",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Pratham mela saadhe",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0706 Raageshwari Jhaptal(m.laya) Pratham mela saadhe.mp3"
      },
      {
        "SL": "C0707",
        "RAAG": "Raageshwari",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Pratham sur saadhe",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0707 Raageshwari Jhaptal(m.laya) Pratham sur saadhe.mp3"
      },
      {
        "SL": "C0708",
        "RAAG": "Gara",
        "TAAL": "Ektaal(m.laya)",
        "STARTING_WORDS": "Guni baranath gaare",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0708 Gara Ektaal(m.laya) Guni baranath gaare.mp3"
      },
      {
        "SL": "C0709",
        "RAAG": "Gara",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kaan pari jab bhanak",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0709 Gara Teental(m.laya) Kaan pari jab bhanak.mp3"
      },
      {
        "SL": "C0710",
        "RAAG": "Gara",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Mero rangilaa",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0710 Gara Teental(m.laya) Mero rangilaa.mp3"
      },
      {
        "SL": "C0711",
        "RAAG": "Gara",
        "TAAL": "Ektaal(Vilambit)",
        "STARTING_WORDS": "Jaani aa gare lagajaa",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0711 Gara Ektaal(Vilambit) Jani aa gare lagajaa.mp3"
      },
      {
        "SL": "C0712",
        "RAAG": "Gara",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Ye janaa daa janaa di",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0712 Gara Tilwada(Vilambit) Ye janaa daa janaa di.mp3"
      },
      {
        "SL": "C0713",
        "RAAG": "Gara",
        "TAAL": "Rupak(m.laya)",
        "STARTING_WORDS": "Takat baitho dulahaa",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0713 Gara Rupak(m.laya) Takat baitho dulahaa.mp3"
      },
      {
        "SL": "C0714",
        "RAAG": "Gara",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Pyare ki moorat",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0714 Gara Chowtal(Vilambit) Pyare ki moorat.mp3"
      },
      {
        "SL": "C0715",
        "RAAG": "Gara",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Kar singaar khelan ko",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0715 Gara Dhamar(Vilambit) Kar singaar khelan ko.mp3"
      },
      {
        "SL": "C0716",
        "RAAG": "Gara",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Rang bhari pichakari",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0716 Gara Dhamar(Vilambit) Rang bhari pichakari.mp3"
      },
      {
        "SL": "C0717",
        "RAAG": "Sorat",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kahoo ab sorat des ko",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0717 Sorat Teental(m.laya) Kahoo ab sorat des ko.mp3"
      },
      {
        "SL": "C0718",
        "RAAG": "Sorat",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Sorat raagani",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0718 Sorat Chowtal(Vilambit) Sorat raagani.mp3"
      },
      {
        "SL": "C0719",
        "RAAG": "Sorat",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Joban jhaal larhyo",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0719 Sorat Teental(m.laya) Joban jhaal larhyo.mp3"
      },
      {
        "SL": "C0720",
        "RAAG": "Sorat",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Laaraa laagohi aave",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0720 Sorat Teental(m.laya) Laaraa laagohi aave.mp3"
      },
      {
        "SL": "C0721",
        "RAAG": "Sorat",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Hoji mhari bega",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0721 Sorat Tilwada(Vilambit) Hoji mhari bega.mp3"
      },
      {
        "SL": "C0722",
        "RAAG": "Sorat",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Maaruji chaandani",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0722 Sorat Teental(m.laya) Maaruji chaandani.mp3"
      },
      {
        "SL": "C0723",
        "RAAG": "Sorat",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Terohi dhyaan dharat",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0723 Sorat Jhaptal(m.laya) Terohi dhyaan dharat.mp3"
      },
      {
        "SL": "C0724",
        "RAAG": "Sorat",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Paayo ho aavalono",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0724 Sorat Chowtal(Vilambit) Paayo ho aavalono.mp3"
      },
      {
        "SL": "C0725",
        "RAAG": "Sorat",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Poojan jaat shiv murat",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0725 Sorat Chowtal(Vilambit) Poojan jaat shiv murat.mp3"
      },
      {
        "SL": "C0726",
        "RAAG": "Sorat",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Ulahan laageri",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0726 Sorat Chowtal(Vilambit) Ulahan laageri.mp3"
      },
      {
        "SL": "C0727",
        "RAAG": "Naarayani",
        "TAAL": "Sultaal(m.laya)",
        "STARTING_WORDS": "Naarayan ko naam",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0727 Naarayani Sultaal(m.laya) Naarayan ko naam.mp3"
      },
      {
        "SL": "C0728",
        "RAAG": "Saavan",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Yeri kaari baadari",
        "THAAT": "Khamaj",
        "FILE_NAME": "C0728 Saavan Teental(m.laya) Yeri kaari baadari.mp3"
      },
      {
        "SL": "D0729",
        "RAAG": "Bhairav",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "sa dha pa pa dha",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0729 Bhairav Jhaptal(m.laya) sa dha pa pa dha.mp3"
      },
      {
        "SL": "D0730",
        "RAAG": "Bhairav",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "re ga re ga ma pa ga",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0730 Bhairav Teental(m.laya) re ga re ga ma pa ga.mp3"
      },
      {
        "SL": "D0731",
        "RAAG": "Bhairav",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Sa re re ga ma pa",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0731 Bhairav Chowtal(Vilambit) Sa re re ga ma pa.mp3"
      },
      {
        "SL": "D0732",
        "RAAG": "Bhairav",
        "TAAL": "Sultaal(m.laya)",
        "STARTING_WORDS": "Dhaivat komal kar",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0732 Bhairav Sultaal(m.laya) Dhaivat komal kar.mp3"
      },
      {
        "SL": "D0733",
        "RAAG": "Bhairav",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Bhairav lachhan gaye",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0733 Bhairav Teental(m.laya) Bhairav lachhan gaye.mp3"
      },
      {
        "SL": "D0734",
        "RAAG": "Bhairav",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Gurunaatha saban k e",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0734 Bhairav Teental(m.laya) Gurunaatha saban ke.mp3"
      },
      {
        "SL": "D0735",
        "RAAG": "Bhairav",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Jago mohan pyare",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0735 Bhairav Teental(m.laya) Jago mohan pyare.mp3"
      },
      {
        "SL": "D0736",
        "RAAG": "Bhairav",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Saamb sadashiv bhola",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0736 Bhairav Teental(m.laya) Saamb sadashiv bhola.mp3"
      },
      {
        "SL": "D0737",
        "RAAG": "Bhairav",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Dhan dhan moorat",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0737 Bhairav Teental(m.laya) Dhan dhan moorat.mp3"
      },
      {
        "SL": "D0738",
        "RAAG": "Bhairav",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Aban jagavo pyare",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0738 Bhairav Teental(m.laya) Aban jagavo pyare.mp3"
      },
      {
        "SL": "D0739",
        "RAAG": "Bhairav",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Anat kahaa jin jaavo",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0739 Bhairav Teental(m.laya) Anat kahaa jin jaavo.mp3"
      },
      {
        "SL": "D0740",
        "RAAG": "Bhairav",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Pyala mujhe bhar dere",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0740 Bhairav Teental(m.laya) Pyala mujhe bhar dere.mp3"
      },
      {
        "SL": "D0741",
        "RAAG": "Bhairav",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Pavan chalat purbaiyaa",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0741 Bhairav Teental(m.laya) Pavan chalat purbaiyaa.mp3"
      },
      {
        "SL": "D0742",
        "RAAG": "Bhairav",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Bhor bh ayeelo pyare",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0742 Bhairav Teental(m.laya) Bhor bhayeelo pyare.mp3"
      },
      {
        "SL": "D0743",
        "RAAG": "Bhairav",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Mehar ki nazar keeje",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0743 Bhairav Teental(m.laya) Mehar ki nazar keeje.mp3"
      },
      {
        "SL": "D0744",
        "RAAG": "Bhairav",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Prabhu daathaa",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0744 Bhairav Teental(m.laya) Prabhu daatha.mp3"
      },
      {
        "SL": "D0745",
        "RAAG": "Bhairav",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ye hoo tho bar bar",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0745 Bhairav Teental(m.laya) Ye hoo tho bar bar.mp3"
      },
      {
        "SL": "D0746",
        "RAAG": "Bhairav",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Piya milanki baari",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0746 Bhairav Teental(m.laya) Piya milanki baari.mp3"
      },
      {
        "SL": "D0747",
        "RAAG": "Bhairav",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Baalamuvaa",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0747 Bhairav Tilwada(Vilambit) Baalamuvaa.mp3"
      },
      {
        "SL": "D0748",
        "RAAG": "Bhairav",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Jiyara hulase",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0748 Bhairav Tilwada(Vilambit) Jiyara hulase.mp3"
      },
      {
        "SL": "D0749",
        "RAAG": "Bhairav",
        "TAAL": "Ektaal(Vilambit)",
        "STARTING_WORDS": "Yeka pal-chhin",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0749 Bhairav Ektaal(Vilambit) Yeka pal-chhin.mp3"
      },
      {
        "SL": "D0750",
        "RAAG": "Bhairav",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Awsar laag rahi",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0750 Bhairav Tilwada(Vilambit) Awsar laag rahi.mp3"
      },
      {
        "SL": "D0751",
        "RAAG": "Bhairav",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Palak dariyaab mera",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0751 Bhairav Tilwada(Vilambit) Palak dariyaab mera.mp3"
      },
      {
        "SL": "D0752",
        "RAAG": "Bhairav",
        "TAAL": "Ektaal(Vilambit)",
        "STARTING_WORDS": "Kana baansuri bajaave",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0752 Bhairav Ektaal(Vilambit) Kana baansuri bajaave.mp3"
      },
      {
        "SL": "D0753",
        "RAAG": "Bhairav",
        "TAAL": "Ektaal(Vilambit)",
        "STARTING_WORDS": "Koyal bole",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0753 Bhairav Ektaal(Vilambit) Koyal bole.mp3"
      },
      {
        "SL": "D0754",
        "RAAG": "Bhairav",
        "TAAL": "Ektaal(Vilambit)",
        "STARTING_WORDS": "Bina har koun khabar",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0754 Bhairav Ektaal(Vilambit) Bina har koun khabar.mp3"
      },
      {
        "SL": "D0755",
        "RAAG": "Bhairav",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Karim rahim bande",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0755 Bhairav Tilwada(Vilambit) Karim rahim bande.mp3"
      },
      {
        "SL": "D0756",
        "RAAG": "Bhairav",
        "TAAL": "Sultaal(m.laya)",
        "STARTING_WORDS": "Aaaye bhairav",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0756 Bhairav Sultaal(m.laya) Aaaye bhairav.mp3"
      },
      {
        "SL": "D0757",
        "RAAG": "Bhairav",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Too ab yaad karare",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0757 Bhairav Jhaptal(m.laya) Too ab yaad karare.mp3"
      },
      {
        "SL": "D0758",
        "RAAG": "Bhairav",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Jnan madmaathe",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0758 Bhairav Jhaptal(m.laya) Jnan madmaathe.mp3"
      },
      {
        "SL": "D0759",
        "RAAG": "Bhairav",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Moumadi moumadi",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0759 Bhairav Jhaptal(m.laya) Moumadi moumadi.mp3"
      },
      {
        "SL": "D0760",
        "RAAG": "Bhairav",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Aaj nandlal sakhi",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0760 Bhairav Jhaptal(m.laya) Aaj nandlal sakhi.mp3"
      },
      {
        "SL": "D0761",
        "RAAG": "Bhairav",
        "TAAL": "Sultaal(m.laya)",
        "STARTING_WORDS": "Saadho bidyadha r",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0761 Bhairav Sultaal(m.laya) Saadho bidyadhar.mp3"
      },
      {
        "SL": "D0762",
        "RAAG": "Bhairav",
        "TAAL": "Brahmataal(m.laya)",
        "STARTING_WORDS": "Khelat laage hari joo",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0762 Bhairav Brahmataal(m.laya) Khelat laage hari joo.mp3"
      },
      {
        "SL": "D0763",
        "RAAG": "Bhairav",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Beeshnu charan jal",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0763 Bhairav Chowtal(Vilambit) Beeshnu charan jal.mp3"
      },
      {
        "SL": "D0764",
        "RAAG": "Bhairav",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Sharada saraswathi",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0764 Bhairav Chowtal(Vilambit) Sharada saraswathi.mp3"
      },
      {
        "SL": "D0765",
        "RAAG": "Bhairav",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Saghan ban chaayo",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0765 Bhairav Chowtal(Vilambit) Saghan ban chaayo.mp3"
      },
      {
        "SL": "D0766",
        "RAAG": "Bhairav",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Baadar zhoom zhoom",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0766 Bhairav Chowtal(Vilambit) Baadar zhoom zhoom.mp3"
      },
      {
        "SL": "D0767",
        "RAAG": "Bhairav",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Ye gananaayak",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0767 Bhairav Chowtal(Vilambit) Ye gananaayak.mp3"
      },
      {
        "SL": "D0768",
        "RAAG": "Bhairav",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Gangadhar trinayan",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0768 Bhairav Chowtal(Vilambit) Gangadhar trinayan.mp3"
      },
      {
        "SL": "D0769",
        "RAAG": "Bhairav",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Sab mil gaavo",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0769 Bhairav Chowtal(Vilambit) Sab mil gaavo.mp3"
      },
      {
        "SL": "D0770",
        "RAAG": "Bhairav",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Lal alasane b horehi aye",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0770 Bhairav Dhamar(Vilambit) Lal alasane bhorehi aye.mp3"
      },
      {
        "SL": "D0771",
        "RAAG": "Bhairav",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Aaj rasamaathey",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0771 Bhairav Dhamar(Vilambit) Aaj rasamaathey.mp3"
      },
      {
        "SL": "D0772",
        "RAAG": "Bhairav",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tanana tanana tana",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0772 Bhairav Teental(m.laya) Tanana tanana tana.mp3"
      },
      {
        "SL": "D0773",
        "RAAG": "Bhairav",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Odana tom tanana",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0773 Bhairav Teental(m.laya) Odana tom tanana.mp3"
      },
      {
        "SL": "D0774",
        "RAAG": "Kalingada",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "ni ni sa re sa ni dha pa",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0774 Kalingada Teental(m.laya) ni ni sa re sa ni dha pa.mp3"
      },
      {
        "SL": "D0775",
        "RAAG": "Kalingada",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Sakal guni gaavat",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0775 Kalingada Teental(m.laya) Sakal guni gaavat.mp3"
      },
      {
        "SL": "D0776",
        "RAAG": "Kalingada",
        "TAAL": "Dadra(m.laya)",
        "STARTING_WORDS": "Ram naam bhajan karo",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0776 Kalingada Dadra(m.laya) Ram naam bhajan karo.mp3"
      },
      {
        "SL": "D0777",
        "RAAG": "Kalingada",
        "TAAL": "Dadra(m.laya)",
        "STARTING_WORDS": "Piya milanko jaane de",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0777 Kalingada Dadra(m.laya) Piya milanko jaane de.mp3"
      },
      {
        "SL": "D0778",
        "RAAG": "Kalingada",
        "TAAL": "Dadra(m.laya)",
        "STARTING_WORDS": "Si paiya tori baarire",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0778 Kalingada Dadra(m.laya) Si paiya tori baarire.mp3"
      },
      {
        "SL": "D0779",
        "RAAG": "Kalingada",
        "TAAL": "Ektaal(m.laya)",
        "STARTING_WORDS": "Dhandhan sakhi sakal",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0779 Kalingada Ektaal(m.laya) Dhandhan sakhi sakal.mp3"
      },
      {
        "SL": "D0780",
        "RAAG": "Kalingada",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Aba hone lago parbhat",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0780 Kalingada Teental(m.laya) Aba hone lago parbhat.mp3"
      },
      {
        "SL": "D0781",
        "RAAG": "Kalingada",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Bhajan bina dhik",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0781 Kalingada Teental(m.laya) Bhajan bina dhik.mp3"
      },
      {
        "SL": "D0782",
        "RAAG": "Kalingada",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Gagariyaa mai kaise le",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0782 Kalingada Teental(m.laya) Gagariyaa mai kaise le.mp3"
      },
      {
        "SL": "D0783",
        "RAAG": "Kalingada",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Banaji tharo sehar",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0783 Kalingada Teental(m.laya) Banaji tharo sehar.mp3"
      },
      {
        "SL": "D0784",
        "RAAG": "Kalingada",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Piya abatak mori si jiya",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0784 Kalingada Teental(m.laya) Piya abatak mori si jiya.mp3"
      },
      {
        "SL": "D0785",
        "RAAG": "Kalingada",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Maika bharade pyaala",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0785 Kalingada Teental(m.laya) Maika bharade pyaala.mp3"
      },
      {
        "SL": "D0786",
        "RAAG": "Kalingada",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ujiyaari bhayee li raath",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0786 Kalingada Teental(m.laya) Ujiyaari bhayeeli raath.mp3"
      },
      {
        "SL": "D0787",
        "RAAG": "Kalingada",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Banvaari ban mu raari",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0787 Kalingada Teental(m.laya) Banvaari ban muraari.mp3"
      },
      {
        "SL": "D0788",
        "RAAG": "Kalingada",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Neka najarse dekho",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0788 Kalingada Teental(m.laya) Neka najarse dekho.mp3"
      },
      {
        "SL": "D0789",
        "RAAG": "Kalingada",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Baadal garajey",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0789 Kalingada Teental(m.laya) Baadal garajey.mp3"
      },
      {
        "SL": "D0790",
        "RAAG": "Kalingada",
        "TAAL": "Deepchandi(m.laya)",
        "STARTING_WORDS": "Kaun khele tumse hori",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0790 Kalingada Deepchandi(m.laya) Kaun khele tumse hori.mp3"
      },
      {
        "SL": "D0791",
        "RAAG": "Kalingada",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tanana derenaa tadare",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0791 Kalingada Teental(m.laya) Tanana derenaa tadare.mp3"
      },
      {
        "SL": "D0792",
        "RAAG": "Ramkali",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "ga ma ni dha pa",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0792 Ramkali Teental(m.laya) ga ma ni dha pa.mp3"
      },
      {
        "SL": "D0793",
        "RAAG": "Ramkali",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Nit utha sumaran kariye",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0793 Ramkali Teental(m.laya) Nit utha sumaran kariye.mp3"
      },
      {
        "SL": "D0794",
        "RAAG": "Ramkali",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Rab gareebanvaa",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0794 Ramkali Teental(m.laya) Rab gareebanvaa.mp3"
      },
      {
        "SL": "D0795",
        "RAAG": "Ramkali",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Harihari harihari japana",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0795 Ramkali Teental(m.laya) Harihari harihari japana.mp3"
      },
      {
        "SL": "D0796",
        "RAAG": "Ramkali",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Sagari rainake jaage",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0796 Ramkali Teental(m.laya) Sagari rainake jaage.mp3"
      },
      {
        "SL": "D0797",
        "RAAG": "Ramkali",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Bhoraki chiraiya bulaayi",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0797 Ramkali Teental(m.laya) Bhoraki chiraiya bulaayi.mp3"
      },
      {
        "SL": "D0798",
        "RAAG": "Ramkali",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Bhoraki chiraiya",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0798 Ramkali Teental(m.laya) Bhoraki chiraiya.mp3"
      },
      {
        "SL": "D0799",
        "RAAG": "Ramkali",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Bhor maayi dheet",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0799 Ramkali Teental(m.laya) Bhor maayi dheet.mp3"
      },
      {
        "SL": "D0800",
        "RAAG": "Ramkali",
        "TAAL": "Ektaal(Vilambit)",
        "STARTING_WORDS": "Le saaheb ko naam",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0800 Ramkali Ektaal(Vilambit) Le saaheb ko naam.mp3"
      },
      {
        "SL": "D0801",
        "RAAG": "Ramkali",
        "TAAL": "Ektaal(Vilambit)",
        "STARTING_WORDS": "Menda dil lagaa",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0801 Ramkali Ektaal(Vilambit) Menda dil lagaa.mp3"
      },
      {
        "SL": "D0802",
        "RAAG": "Ramkali",
        "TAAL": "Ektaal(Vilambit)",
        "STARTING_WORDS": "Aaj ra dhe tore",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0802 Ramkali Ektaal(Vilambit) Aaj radhe tore.mp3"
      },
      {
        "SL": "D0803",
        "RAAG": "Ramkali",
        "TAAL": "Ektaal(Vilambit)",
        "STARTING_WORDS": "Aaj roung hai jogi",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0803 Ramkali Ektaal(Vilambit) Aaj roung hai jogi.mp3"
      },
      {
        "SL": "D0804",
        "RAAG": "Ramkali",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Darbaar dhaavoo",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0804 Ramkali Tilwada(Vilambit) Darbaar dhaavoo.mp3"
      },
      {
        "SL": "D0805",
        "RAAG": "Ramkali",
        "TAAL": "Jhumra(Vilambit)",
        "STARTING_WORDS": "Aache rangeelare",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0805 Ramkali Jhumra(Vilambit) Aache rangeelare.mp3"
      },
      {
        "SL": "D0806",
        "RAAG": "Ramkali",
        "TAAL": "Jhumra(Vilambit)",
        "STARTING_WORDS": "Maachariya mendi laagi",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0806 Ramkali Jhumra(Vilambit) Maachariya mendi laagi.mp3"
      },
      {
        "SL": "D0807",
        "RAAG": "Ramkali",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Pyari aayee navasat",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0807 Ramkali Jhaptal(m.laya) Pyari aayee navasat.mp3"
      },
      {
        "SL": "D0808",
        "RAAG": "Ramkali",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Ab balee tab balee",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0808 Ramkali Jhaptal(m.laya) Ab balee tab balee.mp3"
      },
      {
        "SL": "D0809",
        "RAAG": "Ramkali",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Kanhaiya aaj ban-basuri",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0809 Ramkali Chowtal(Vilambit) Kanhaiya aaj ban-basuri.mp3"
      },
      {
        "SL": "D0810",
        "RAAG": "Ramkali",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Peet basan aung",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0810 Ramkali Chowtal(Vilambit) Peet basan aung.mp3"
      },
      {
        "SL": "D0811",
        "RAAG": "Ramkali",
        "TAAL": "Dhamar(Vila mbit)",
        "STARTING_WORDS": "Yeri gavaar",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0811 Ramkali Dhamar(Vilambit) Yeri gavaar.mp3"
      },
      {
        "SL": "D0812",
        "RAAG": "Ramkali",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Dhoom machaayi",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0812 Ramkali Dhamar(Vilambit) Dhoom machaayi.mp3"
      },
      {
        "SL": "D0813",
        "RAAG": "Bangal Bhairav",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Ye banata ban aaya",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0813 Bangal Bhairav Tilwada(Vilambit) Ye banata ban aaya.mp3"
      },
      {
        "SL": "D0814",
        "RAAG": "Anand Bhairav",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Aaje anand bhayo",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0814 Anand Bhairav Jhaptal(m.laya) Aaje anand bhayo.mp3"
      },
      {
        "SL": "D0815",
        "RAAG": "Anand Bhairav",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Mere mana sumarankar",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0815 Anand Bhairav Jhaptal(m.laya) Mere mana sumarankar.mp3"
      },
      {
        "SL": "D0816",
        "RAAG": "Saurashtra Tank",
        "TAAL": "Rupak(m.laya)",
        "STARTING_WORDS": "Prabhu kirtaar",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0816 Saurashtra Tank Rupak(m.laya) Prabhu kirtaar.mp3"
      },
      {
        "SL": "D0817",
        "RAAG": "Saurashtra Tank",
        "TAAL": "Rupak(m.laya)",
        "STARTING_WORDS": "Katath bikaar naam",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0817 Saurashtra Tank Rupak(m.laya) Katath bikaar naam.mp3"
      },
      {
        "SL": "D0818",
        "RAAG": "Ahir Bhairav",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Raadhika raman",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0818 Ahir Bhairav Jhaptal(m.laya) Raadhika raman.mp3"
      },
      {
        "SL": "D0819",
        "RAAG": "Ahir Bhairav",
        "TAAL": "Adachowtal(Vilambit)",
        "STARTING_WORDS": "Banara mora rasamaata",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0819 Ahir Bhairav Adachowtal(Vilambit) Banara mora rasamaat.mp3"
      },
      {
        "SL": "D0820",
        "RAAG": "Ahir Bhairav",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Rasiya mhaara",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0820 Ahir Bhairav Tilwada(Vilambit) Rasiya mhaara.mp3"
      },
      {
        "SL": "D0821",
        "RAAG": "Ahir Bhairav",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Ye tonava moraa",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0821 Ahir Bhairav Tilwada(Vilambit) Ye tonava moraa.mp3"
      },
      {
        "SL": "D0822",
        "RAAG": "Shivmat Bhairav",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Gaavo milake aaj",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0822 Shivmat Bhairav Tilwada(Vilambit) Gaavo milake aaj.mp3"
      },
      {
        "SL": "D0823",
        "RAAG": "Shivmat Bhairav",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Chaal chalat alasani",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0823 Shivmat Bhairav Chowtal(Vilambit) Chaal chalat alasani.mp3"
      },
      {
        "SL": "D0824",
        "RAAG": "Shivmat Bhairav",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Aho so bhali",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0824 Shivmat Bhairav Dhamar(Vilambit) Aho so bhali.mp3"
      },
      {
        "SL": "D0825",
        "RAAG": "Prabhat Bhairav",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kaahen mana thu",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0825 Prabhat Bhairav Teental(m.laya) Kaahen mana thu.mp3"
      },
      {
        "SL": "D0826",
        "RAAG": "Lalit Pancham",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kaho tum saachi kaha",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0826 Lalit Pancham Teental(m.laya) Kaho tum saachi kaha.mp3"
      },
      {
        "SL": "D0827",
        "RAAG": "Lalit Pancham",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kar mana kayee bichaar",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0827 Lalit Pancham Teental(m.laya) Kar mana kayee bichaar.mp3"
      },
      {
        "SL": "D0828",
        "RAAG": "Lalit Pancham",
        "TAAL": "Ektaal(m.laya)",
        "STARTING_WORDS": "Jab aave morey saiyaa",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0828 Lalit Pancham Ektaal(m.laya) Jab aave morey saiyaa.mp3"
      },
      {
        "SL": "D0829",
        "RAAG": "Lalit Pancham",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Baamdeva mahadev",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0829 Lalit Pancham Teental(m.laya) Baamdeva mahadev.mp3"
      },
      {
        "SL": "D0830",
        "RAAG": "Lalit Pancham",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ye allah tero saacho",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0830 Lalit Pancham Teental(m.laya) Ye allah tero saacho.mp3"
      },
      {
        "SL": "D0831",
        "RAAG": "Lalit Pancham",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Alas uneende nain laal",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0831 Lalit Pancham Tilwada(Vilambit) Alas uneende nain laal.mp3"
      },
      {
        "SL": "D0832",
        "RAAG": "Megharanjani",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Lalaatan aheeran",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0832 Megharanjani Jhaptal(m.laya) Lalaatan aheeran.mp3"
      },
      {
        "SL": "D0833",
        "RAAG": "Gunakri",
        "TAAL": "Teevratal(m.laya)",
        "STARTING_WORDS": "Roop anupam aaj gaayo",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0833 Gunakri Teevratal(m.laya) Roop anupam aaj gaayo.mp3"
      },
      {
        "SL": "D0834",
        "RAAG": "Gunakri",
        "TAAL": "Teevratal(m.laya)",
        "STARTING_WORDS": "Damaru harakar baaje",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0834 Gunakri Teevratal(m.laya) Damaru harakar baaje.mp3"
      },
      {
        "SL": "D0835",
        "RAAG": "Jogiya",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Gunijan raag likhit jogiko",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0835 Jogiya Teental(m.laya) Gunijan raag likhit jogiko.mp3"
      },
      {
        "SL": "D0836",
        "RAAG": "Jogiya",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ani ani charkadaa",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0836 Jogiya Teental(m.laya) Ani ani charkadaa.mp3"
      },
      {
        "SL": "D0837",
        "RAAG": "Jogiya",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Hu tho thane aavan",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0837 Jogiya Tilwada(Vilambit) Hu tho thane aavan.mp3"
      },
      {
        "SL": "D0838",
        "RAAG": "Jogiya",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Akhil gunan bhaandaar",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0838 Jogiya Chowtal(Vilambit) Akhil gunan bhaandaar.mp3"
      },
      {
        "SL": "D0839",
        "RAAG": "Jogiya",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Hori ko chel",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0839 Jogiya Dhamar(Vilambit) Hori ko chel.mp3"
      },
      {
        "SL": "D0840",
        "RAAG": "Jogiya",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Rang abir kahase paavu",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0840 Jogiya Dhamar(Vilambit) Rang abir kahase paavu.mp3"
      },
      {
        "SL": "D0841",
        "RAAG": "Devranjani",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Tribhidh gaamani",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0841 Devranjani Jhaptal(m.laya) Tribhidh gaamani.mp3"
      },
      {
        "SL": "D0842",
        "RAAG": "Bibhas",
        "TAAL": "Sultaal(m.laya)",
        "STARTING_WORDS": "Raag Bibhas",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0842 Bibhas Sultaal(m.laya) Raag Bibhas.mp3"
      },
      {
        "SL": "D0843",
        "RAAG": "Bibhas",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Pyari pyari bathiya",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0843 Bibhas Teental(m.laya) Pyari pyari bathiya.mp3"
      },
      {
        "SL": "D0844",
        "RAAG": "Bibhas",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Bairan nandiya",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0844 Bibhas Teental(m.laya) Bairan nandiya.mp3"
      },
      {
        "SL": "D0845",
        "RAAG": "Bibhas",
        "TAAL": "Roopak(m.laya)",
        "STARTING_WORDS": "Aaj tum bhor",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0845 Bibhas Roopak(m.laya) Aaj tum bhor.mp3"
      },
      {
        "SL": "D0846",
        "RAAG": "Bibhas",
        "TAAL": "Ektaal(Vilambit)",
        "STARTING_WORDS": "Piya tum vahee jaavo",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0846 Bibhas Ektaal(Vilambit) Piya tum vahee jaavo.mp3"
      },
      {
        "SL": "D0847",
        "RAAG": "Bibhas",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Aaj badhaavo raajendra",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0847 Bibhas Tilwada(Vilambit) Aaj badhaavo raajendra.mp3"
      },
      {
        "SL": "D0848",
        "RAAG": "Bibhas",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Chi riya chuha chuhaayi",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0848 Bibhas Jhaptal(m.laya) Chiriya chuha chuhaayi.mp3"
      },
      {
        "SL": "D0849",
        "RAAG": "Bibhas",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Ye narhar naarayan",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0849 Bibhas Chowtal(Vilambit) Ye narhar naarayan.mp3"
      },
      {
        "SL": "D0850",
        "RAAG": "Bibhas",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Badan panch bhaal",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0850 Bibhas Chowtal(Vilambit) Badan panch bhaal.mp3"
      },
      {
        "SL": "D0851",
        "RAAG": "Bibhas",
        "TAAL": "Brahmataal(m.laya)",
        "STARTING_WORDS": "Shyam ati sundar",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0851 Bibhas Brahmataal(m.laya) Shyam ati sundar.mp3"
      },
      {
        "SL": "D0852",
        "RAAG": "Jheelaph",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Meri madad karo",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0852 Jheelaph Jhaptal(m.laya) Meri madad karo.mp3"
      },
      {
        "SL": "D0853",
        "RAAG": "Jheelaph",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Naamhee keval",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0853 Jheelaph Jhaptal(m.laya) Naamhee keval.mp3"
      },
      {
        "SL": "D0854",
        "RAAG": "Gowri",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Phooli saanjh",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0854 Gowri Chowtal(Vilambit) Phooli saanjh.mp3"
      },
      {
        "SL": "D0855",
        "RAAG": "Gowri",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Murali bajaavori",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0855 Gowri Chowtal(Vilambit) Murali bajaavori.mp3"
      },
      {
        "SL": "D0856",
        "RAAG": "Gowri",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Moorath mana mey lagi",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0856 Gowri Chowtal(Vilambit) Moorath mana mey lagi.mp3"
      },
      {
        "SL": "D0857",
        "RAAG": "Jangula",
        "TAAL": "Ektaal(Vilambit)",
        "STARTING_WORDS": "Manshumaadil gudaanj",
        "THAAT": "Bhairav",
        "FILE_NAME": "D0857 Jangula Ektaal(Vilambit) Manshumaadil gudaanj.mp3"
      },
      {
        "SL": "E0858",
        "RAAG": "Poorvi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "sa dha ma pa ga ma ga",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0858 Poorvi Teental(m.laya) sa dha ma pa ga ma ga.mp3"
      },
      {
        "SL": "E0859",
        "RAAG": "Poorvi",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "ni re ga ga ma ga re ga",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0859 Poorvi Jhaptal(m.laya) ni re ga ga ma ga re ga.mp3"
      },
      {
        "SL": "E0860",
        "RAAG": "Poorvi",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Yeri sakhi tohe poorabi",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0860 Poorvi Ektal(m.laya) Yeri sakhi tohe poorabi.mp3"
      },
      {
        "SL": "E0861",
        "RAAG": "Poorvi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Poorabike sur gayaguni",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0861 Poorvi Teental(m.laya) Poorabike sur gayaguni.mp3"
      },
      {
        "SL": "E0862",
        "RAAG": "Poorvi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Yeri maika sab sukha",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0862 Poorvi Teental(m.laya) Yeri maika sab sukha.mp3"
      },
      {
        "SL": "E0863",
        "RAAG": "Poorvi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Naa th naath kar bol",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0863 Poorvi Teental(m.laya) Naath naath kar bol.mp3"
      },
      {
        "SL": "E0864",
        "RAAG": "Poorvi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Banath banaavoo",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0864 Poorvi Teental(m.laya) Banath banaavoo.mp3"
      },
      {
        "SL": "E0865",
        "RAAG": "Poorvi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Aavan kahi gaye",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0865 Poorvi Teental(m.laya) Aavan kahi gaye.mp3"
      },
      {
        "SL": "E0866",
        "RAAG": "Poorvi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kagava boley",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0866 Poorvi Teental(m.laya) Kagava boley.mp3"
      },
      {
        "SL": "E0867",
        "RAAG": "Poorvi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Rangili raam ki akhiya",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0867 Poorvi Teental(m.laya) Rangili raam ki akhiya.mp3"
      },
      {
        "SL": "E0868",
        "RAAG": "Poorvi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Chalori mayi aouliya",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0868 Poorvi Teental(m.laya) Chalori mayi aouliya.mp3"
      },
      {
        "SL": "E0869",
        "RAAG": "Poorvi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Mathura na jaavo",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0869 Poorvi Teental(m.laya) Mathura na jaavo.mp3"
      },
      {
        "SL": "E0870",
        "RAAG": "Poorvi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kaajar kare",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0870 Poorvi Teental(m.laya) Kaajar kare.mp3"
      },
      {
        "SL": "E0871",
        "RAAG": "Poorvi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Maayi mere nainan",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0871 Poorvi Teental(m.laya) Maayi mere nainan.mp3"
      },
      {
        "SL": "E0872",
        "RAAG": "Poorvi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Wahi kyu na jaavo",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0872 Poorvi Teental(m.laya) Wahi kyu na jaavo.mp3"
      },
      {
        "SL": "E0873",
        "RAAG": "Poorvi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Mouja maani ho",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0873 Poorvi Teental(m.laya) Mouja maani ho.mp3"
      },
      {
        "SL": "E0874",
        "RAAG": "Poorvi",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Nayire lagan mori lagi",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0874 Poorvi Ektal(m.laya) Nayire lagan mori lagi.mp3"
      },
      {
        "SL": "E0875",
        "RAAG": "Poorvi",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Peer nizamoddin auliya",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0875 Poorvi Ektal(Vilambit) Peer nizamoddin auliya.mp3"
      },
      {
        "SL": "E0876",
        "RAAG": "Poorvi",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Tunava hey mayee",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0876 Poorvi Tilwada(Vilambit) Tunava hey mayee.mp3"
      },
      {
        "SL": "E0877",
        "RAAG": "Poorvi",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Naiya mori paar karore",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0877 Poorvi Tilwada(Vilambit) Naiya mori paar karore.mp3"
      },
      {
        "SL": "E0878",
        "RAAG": "Poorvi",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Piyarava ki baase",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0878 Poorvi Ektal(Vilambit) Piyarava ki baase.mp3"
      },
      {
        "SL": "E0879",
        "RAAG": "Poorvi",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Mana mastha jaame",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0879 Poorvi Tilwada(Vilambit) Mana mastha jaame.mp3"
      },
      {
        "SL": "E0880",
        "RAAG": "Poorvi",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Charan parsat",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0880 Poorvi Tilwada(Vilambit) Charan parsat.mp3"
      },
      {
        "SL": "E0881",
        "RAAG": "Poorvi",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Ye mehaboob peere",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0881 Poorvi Ektal(Vilambit) Ye mehaboob peere.mp3"
      },
      {
        "SL": "E0882",
        "RAAG": "Poorvi",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Ye makadoom saheb",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0882 Poorvi Tilwada(Vilambit) Ye makadoom saheb.mp3"
      },
      {
        "SL": "E0883",
        "RAAG": "Poorvi",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Dard ki safaayi allah",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0883 Poorvi Tilwada(Vilambit) Dard ki safaayi allah.mp3"
      },
      {
        "SL": "E0884",
        "RAAG": "Poorvi",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Salahey kaar kuja",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0884 Poorvi Ektal(Vilambit) Salahey kaar kuja.mp3"
      },
      {
        "SL": "E0885",
        "RAAG": "Poorvi",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Piya sanavaa mori",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0885 Poorvi Ektal(Vilambit) Piya sanavaa mori.mp3"
      },
      {
        "SL": "E0886",
        "RAAG": "Poorvi",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Kar kapaal lochan",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0886 Poorvi Sultal(m.laya) Kar kapaal lochan.mp3"
      },
      {
        "SL": "E0887",
        "RAAG": "Poorvi",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Ab chatur dandimath",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0887 Poorvi Jhaptal(m.laya) Ab chatur dandimath.mp3"
      },
      {
        "SL": "E0888",
        "RAAG": "Poorvi",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Paayo hai peere",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0888 Poorvi Jhaptal(m.laya) Paayo hai peere.mp3"
      },
      {
        "SL": "E0889",
        "RAAG": "Poorvi",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Meri path raakhali",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0889 Poorvi Chowtal(Vilambit) Meri path raakhali.mp3"
      },
      {
        "SL": "E0890",
        "RAAG": "Poorvi",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Lochan lal lal",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0890 Poorvi Chowtal(Vilambit) Lochan lal lal.mp3"
      },
      {
        "SL": "E0891",
        "RAAG": "Poorvi",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Gyananmo gyani",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0891 Poorvi Chowtal(Vilambit) Gyananmo gyani.mp3"
      },
      {
        "SL": "E0892",
        "RAAG": "Poorvi",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Aho kaise jaan paavoge",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0892 Poorvi Dhamar(Vilambit) Aho kaise jaan paavoge.mp3"
      },
      {
        "SL": "E0893",
        "RAAG": "Poorvi",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Bhalori tero jubanaa",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0893 Poorvi Dhamar(Vilambit) Bhalori tero jubanaa.mp3"
      },
      {
        "SL": "E0894",
        "RAAG": "Poorvi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tanana tanana tom",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0894 Poorvi Teental(m.laya) Tanana tanana tom.mp3"
      },
      {
        "SL": "E0895",
        "RAAG": "Poorvi",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Tanderena tadrenaa",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0895 Poorvi Tilwada(Vilambit) Tanderena tadrenaa.mp3"
      },
      {
        "SL": "E0896",
        "RAAG": "Shree",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "re re sa sa ga re ga re",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0896 Shree Jhaptal(m.laya) re re sa sa ga re ga re.mp3"
      },
      {
        "SL": "E0897",
        "RAAG": "Shree",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Hararang ab kahat raag",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0897 Shree Chowtal(Vilambit) Hararang ab kahat raag.mp3"
      },
      {
        "SL": "E0898",
        "RAAG": "Shree",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Siri raag dha ga tyaag",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0898 Shree Jhaptal(m.laya) Siri raag dha ga tyaag.mp3"
      },
      {
        "SL": "E0899",
        "RAAG": "Shree",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Dariye ramsan aayirey",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0899 Shree Teental(m.laya) Dariye ramsan aayirey.mp3"
      },
      {
        "SL": "E0900",
        "RAAG": "Shree",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Yere hunto aasana gaili",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0900 Shree Teental(m.laya) Yeri hunto aasana gaili.mp3"
      },
      {
        "SL": "E0901",
        "RAAG": "Shree",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Yeri aali aachhenu",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0901 Shree Teental(m.laya) Yeri aali aachhenu.mp3"
      },
      {
        "SL": "E0902",
        "RAAG": "Shree",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Gajarava baaje",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0902 Shree Tilwada(Vilambit) Gajarava baaje.mp3"
      },
      {
        "SL": "E0903",
        "RAAG": "Shree",
        "TAAL": "Ektaal(Vilambit)",
        "STARTING_WORDS": "Aarake ke Phoolan",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0903 Shree Ektaal(Vilambit) Aarake ke Phoolan.mp3"
      },
      {
        "SL": "E0904",
        "RAAG": "Shree",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Saanz bhayee aavore",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0904 Shree Tilwada(Vilambit) Saanz bhayee aavore.mp3"
      },
      {
        "SL": "E0905",
        "RAAG": "Shree",
        "TAAL": "Ektaal(Vilambit)",
        "STARTING_WORDS": "Saanz bhayee",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0905 Shree Ektaal(Vilambit) Saanz bhayee.mp3"
      },
      {
        "SL": "E0906",
        "RAAG": "Shree",
        "TAAL": "Jhumra(Vilambit)",
        "STARTING_WORDS": "Laagohi aavere",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0906 Shree Jhumra(Vilambit) Laagohi aavere.mp3"
      },
      {
        "SL": "E0907",
        "RAAG": "Shree",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Atul parakram",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0907 Shree Jhaptal(m.laya) Atul parakram.mp3"
      },
      {
        "SL": "E0908",
        "RAAG": "Shree",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Prabhu ke charan kamal",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0908 Shree Jhaptal(m.laya) Prabhu ke charan kamal.mp3"
      },
      {
        "SL": "E0909",
        "RAAG": "Shree",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Aayo aayori",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0909 Shree Sultal(m.laya) Aayo aayori.mp3"
      },
      {
        "SL": "E0910",
        "RAAG": "Shree",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Gowri aradhaang",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0910 Shree Sultal(m.laya) Gowri aradhaang.mp3"
      },
      {
        "SL": "E0911",
        "RAAG": "Shree",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Shankar bikhdhar",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0911 Shree Sultal(m.laya) Shankar bikhdhar.mp3"
      },
      {
        "SL": "E0912",
        "RAAG": "Shree",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Bounsidhar pinaakdhar",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0912 Shree Chowtal(Vilambit) Bounsidhar pinaakdhar.mp3"
      },
      {
        "SL": "E0913",
        "RAAG": "Shree",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Sohat mukut seesa",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0913 Shree Chowtal(Vilambit) Sohat mukut seesa.mp3"
      },
      {
        "SL": "E0914",
        "RAAG": "Shree",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Pooran bhrahma",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0914 Shree Chowtal(Vilambit) Pooran bhrahma.mp3"
      },
      {
        "SL": "E0915",
        "RAAG": "Shree",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Siri raag guni bakhane",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0915 Shree Chowtal(Vilambit) Siri raag guni bakhane.mp3"
      },
      {
        "SL": "E0916",
        "RAAG": "Shree",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Narahar narottam",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0916 Shree Chowtal(Vilambit) Narahar narottam.mp3"
      },
      {
        "SL": "E0917",
        "RAAG": "Shree",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Kundal gal kounth maal",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0917 Shree Chowtal(Vilambit) Kundal gal kounth maal.mp3"
      },
      {
        "SL": "E0918",
        "RAAG": "Shree",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Sankar bhasm chadaya",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0918 Shree Chowtal(Vilambit) Sankar bhasm chadaya.mp3"
      },
      {
        "SL": "E0919",
        "RAAG": "Shree",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Chootath pichakaari",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0919 Shree Dhamar(Vilambit) Chootath pichakaari.mp3"
      },
      {
        "SL": "E0920",
        "RAAG": "Shree",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Saavaro khel rahyo hai",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0920 Shree Dhamar(Vilambit) Saavaro khel rahyo hai.mp3"
      },
      {
        "SL": "E0921",
        "RAAG": "Shree",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Amodaa kounkar maari",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0921 Shree Dhamar(Vilambit) Amodaa kounkar maari.mp3"
      },
      {
        "SL": "E0922",
        "RAAG": "Shree",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Ari mori soorat bisaari",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0922 Shree Dhamar(Vilambit) Ari mori soorat bisaari.mp3"
      },
      {
        "SL": "E0923",
        "RAAG": "Shree",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Aaj rang bheejan laage",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0923 Shree Dhamar(Vilambit) Aaj rang bheejan laage.mp3"
      },
      {
        "SL": "E0924",
        "RAAG": "Shree",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Derena tadare daani",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0924 Shree Teental(m.laya) Derena tadare daani.mp3"
      },
      {
        "SL": "E0925",
        "RAAG": "Puriya Dhanashri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "ni re ga ma pa",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0925 Puriya Dhanashri Teental(m.laya) ni re ga ma pa.mp3"
      },
      {
        "SL": "E0926",
        "RAAG": "Puriya Dhanashri",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Gaavath sakal guni",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0926 Puriya Dhanashri Jhaptal(m.laya) Gaavath sakal guni.mp3"
      },
      {
        "SL": "E0927",
        "RAAG": "Puriya Dhanashri",
        "TAAL": "Ektaal(Vilambit)",
        "STARTING_WORDS": "Murali bazaaye",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0927 Puriya Dhanashri Ektaal(Vilambit) Murali bazaaye.mp3"
      },
      {
        "SL": "E0928",
        "RAAG": "Puriya Dhanashri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Nipat nithor tihaari baan",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0928 Puriya Dhanashri Teental(m.laya) Nipat nithor tihaari .mp3"
      },
      {
        "SL": "E0929",
        "RAAG": "Puriya Dhanashri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Paayaliya jhankaar mori",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0929 Puriya Dhanashri Teental(m.laya) Paayaliya jhankaar mo.mp3"
      },
      {
        "SL": "E0930",
        "RAAG": "Puriya Dhanashri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Hari sumaran ki maa l",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0930 Puriya Dhanashri Teental(m.laya) Hari sumaran ki maal.mp3"
      },
      {
        "SL": "E0931",
        "RAAG": "Puriya Dhanashri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Panghatva janena pavu",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0931 Puriya Dhanashri Teental(m.laya) Panghatva janena pavu.mp3"
      },
      {
        "SL": "E0932",
        "RAAG": "Puriya Dhanashri",
        "TAAL": "Roopak(m.laya)",
        "STARTING_WORDS": "Dota teree baasuri",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0932 Puriya Dhanashri Roopak(m.laya) Dota teree baasuri.mp3"
      },
      {
        "SL": "E0933",
        "RAAG": "Puriya Dhanashri",
        "TAAL": "Roopak(m.laya)",
        "STARTING_WORDS": "Kubaja tu kaahe",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0933 Puriya Dhanashri Roopak(m.laya) Kubaja tu kaahe.mp3"
      },
      {
        "SL": "E0934",
        "RAAG": "Puriya Dhanashri",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Tere darasan kee",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0934 Puriya Dhanashri Jhaptal(m.laya) Tere darasan kee.mp3"
      },
      {
        "SL": "E0935",
        "RAAG": "Puriya Dhanashri",
        "TAAL": "Ektaal(Vilambit)",
        "STARTING_WORDS": "Maayi ko hook Koyaliya",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0935 Puriya Dhanashri Ektaal(Vilambit) Maayi ko hook Koyali.mp3"
      },
      {
        "SL": "E0936",
        "RAAG": "Puriya Dhanashri",
        "TAAL": "Ektaal(Vilambit)",
        "STARTING_WORDS": "Sada rang nith",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0936 Puriya Dhanashri Ektaal(Vilambit) Sadarang nith.mp3"
      },
      {
        "SL": "E0937",
        "RAAG": "Puriya Dhanashri",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Chehindara jagadaa",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0937 Puriya Dhanashri Tilwada(Vilambit) Chehindara jagadaa.mp3"
      },
      {
        "SL": "E0938",
        "RAAG": "Puriya Dhanashri",
        "TAAL": "Ektaal(Vilambit)",
        "STARTING_WORDS": "Mehaboob nizaamuddin",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0938 Puriya Dhanashri Ektaal(Vilambit) Mehaboob nizaamuddin.mp3"
      },
      {
        "SL": "E0939",
        "RAAG": "Puriya Dhanashri",
        "TAAL": "Ektaal(Vilambit)",
        "STARTING_WORDS": "Dabari lagaave",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0939 Puriya Dhanashri Ektaal(Vilambit) Dabari lagaave.mp3"
      },
      {
        "SL": "E0940",
        "RAAG": "Puriya Dhanashri",
        "TAAL": "Adachowtal(Vilambit)",
        "STARTING_WORDS": "Aashak mara padaave",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0940 Puriya Dhanashri Adachowtal(Vilambit) Aashak mara pada.mp3"
      },
      {
        "SL": "E0941",
        "RAAG": "Puriya Dhanashri",
        "TAAL": "Ektaal(Vilambit)",
        "STARTING_WORDS": "Abato rutmaan aaye",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0941 Puriya Dhanashri Ektaal(Vilambit) Abato rutmaan aaye.mp3"
      },
      {
        "SL": "E0942",
        "RAAG": "Puriya Dhanashri",
        "TAAL": "Ektaal(Vilambit)",
        "STARTING_WORDS": "Balbal jaavoo",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0942 Puriya Dhanashri Ektaal(Vilambit) Balbal jaavoo.mp3"
      },
      {
        "SL": "E0943",
        "RAAG": "Puriya Dhanashri",
        "TAAL": "Adachowtal(Vilambit)",
        "STARTING_WORDS": "Chalo jaaye poochiye",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0943 Puriya Dhanashri Adachowtal(Vilambit) Chalo jaaye pooc.mp3"
      },
      {
        "SL": "E0944",
        "RAAG": "Puriya Dhanashri",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Kaise din katin",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0944 Puriya Dhanashri Chowtal(Vilambit) Kaise din katin.mp3"
      },
      {
        "SL": "E0945",
        "RAAG": "Puriya Dhanashri",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Sumaran hara ko karore",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0945 Puriya Dhanashri Chowtal(Vilambit) Sumaran hara ko kar.mp3"
      },
      {
        "SL": "E0946",
        "RAAG": "Puriya Dhanashri",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Mohan khelaar",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0946 Puriya Dhanashri Dhamar(Vilambit) Mohan khelaar.mp3"
      },
      {
        "SL": "E0947",
        "RAAG": "Puriya Dhanashri",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Yeri tore angiyaa",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0947 Puriya Dhanashri Dhamar(Vilambit) Yeri tore angiyaa.mp3"
      },
      {
        "SL": "E0948",
        "RAAG": "Puriya Dhanashri",
        "TAAL": "Ektaal(m.laya)",
        "STARTING_WORDS": "Haiya dostanoo",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0948 Puriya Dhanashri Ektaal(m.laya) Haiya dostanoo.mp3"
      },
      {
        "SL": "E0949",
        "RAAG": "Basant",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "sa ni dha ni dha pa",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0949 Basant Teental(m.laya) sa ni dha ni dha pa.mp3"
      },
      {
        "SL": "E0950",
        "RAAG": "Basant",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "ni sa ga ma ni dha re ni",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0950 Basant Teental(m.laya) ni sa ga ma ni dha re ni.mp3"
      },
      {
        "SL": "E0951",
        "RAAG": "Basant",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kaisi sarasa basountee",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0951 Basant Teental(m.laya) Kaisi sarasa basountee.mp3"
      },
      {
        "SL": "E0952",
        "RAAG": "Basant",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Saras sughoundh",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0952 Basant Teental(m.laya) Saras sughoundh.mp3"
      },
      {
        "SL": "E0953",
        "RAAG": "Basant",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Rut basount",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0953 Basant Teental(m.laya) Rut basount.mp3"
      },
      {
        "SL": "E0954",
        "RAAG": "Basant",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kaahe na leth prabhu",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0954 Basant Teental(m.laya) Kaahe na leth prabhu.mp3"
      },
      {
        "SL": "E0955",
        "RAAG": "Basant",
        "TAAL": "Ektaal(m.laya)",
        "STARTING_WORDS": "Pashupat girijapat har",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0955 Basant Ektaal(m.laya) Pashupat girijapat har.mp3"
      },
      {
        "SL": "E0956",
        "RAAG": "Basant",
        "TAAL": "Ektaal(m.laya)",
        "STARTING_WORDS": "Phulavaa binath daar",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0956 Basant Ektaal(m.laya) Phulavaa binath daar.mp3"
      },
      {
        "SL": "E0957",
        "RAAG": "Basant",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ath sab des patchaande",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0957 Basant Teental(m.laya) Ath sab des patchaande.mp3"
      },
      {
        "SL": "E0958",
        "RAAG": "Basant",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Phagava brij dekhan",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0958 Basant Teental(m.laya) Phagava brij dekhan.mp3"
      },
      {
        "SL": "E0959",
        "RAAG": "Basant",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Panghatva thado mayiri",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0959 Basant Teental(m.laya) Panghatva thado mayiri.mp3"
      },
      {
        "SL": "E0960",
        "RAAG": "Basant",
        "TAAL": "Ektaal(m.laya)",
        "STARTING_WORDS": "Yendi yendi gendi gendi",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0960 Basant Ektaal(m.laya) Yendi yendi gendi gendi.mp3"
      },
      {
        "SL": "E0961",
        "RAAG": "Basant",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Piya saunga kheloori",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0961 Basant Teental(m.laya) Piya saunga kheloori.mp3"
      },
      {
        "SL": "E0962",
        "RAAG": "Basant",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Nayi basounth nayee",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0962 Basant Teental(m.laya) Nayi basounth nayee.mp3"
      },
      {
        "SL": "E0963",
        "RAAG": "Basant",
        "TAAL": "Ektaal(Vilambit)",
        "STARTING_WORDS": "Arey mana gobind ko",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0963 Basant Ektaal(Vilambit) Arey mana gobind ko.mp3"
      },
      {
        "SL": "E0964",
        "RAAG": "Basant",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Nabi ke darbaar",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0964 Basant Tilwada(Vilambit) Nabi ke darbaar.mp3"
      },
      {
        "SL": "E0965",
        "RAAG": "Basant",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Pat bhijanaa milee",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0965 Basant Tilwada(Vilambit) Pat bhijanaa milee.mp3"
      },
      {
        "SL": "E0966",
        "RAAG": "Basant",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Ambuva mori",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0966 Basant Tilwada(Vilambit) Ambuva mori.mp3"
      },
      {
        "SL": "E0967",
        "RAAG": "Basant",
        "TAAL": "Ektaal(Vilambit)",
        "STARTING_WORDS": "Aayi ruth",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0967 Basant Ektaal(Vilambit) Aayi ruth.mp3"
      },
      {
        "SL": "E0968",
        "RAAG": "Basant",
        "TAAL": "Ektaal(Vilambit)",
        "STARTING_WORDS": "Yeri mero laadala",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0968 Basant Ektaal(Vilambit) Yeri mero laadala.mp3"
      },
      {
        "SL": "E0969",
        "RAAG": "Basant",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Taal surake jaane",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0969 Basant Sultal(m.laya) Taal surake jaane.mp3"
      },
      {
        "SL": "E0970",
        "RAAG": "Basant",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Maalathi ketaki",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0970 Basant Chowtal(Vilambit) Maalathi ketaki.mp3"
      },
      {
        "SL": "E0971",
        "RAAG": "Basant",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Gaavo basount ruth",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0971 Basant Dhamar(Vilambit) Gaavo basount ruth.mp3"
      },
      {
        "SL": "E0972",
        "RAAG": "Basant",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Dhim tom tana taardani",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0972 Basant Teental(m.laya) Dhim tom tana taardani.mp3"
      },
      {
        "SL": "E0973",
        "RAAG": "Basant",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Deem tan derenaa",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0973 Basant Teental(m.laya) Deem tan derenaa.mp3"
      },
      {
        "SL": "E0974",
        "RAAG": "Basant",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tan naadir dir deem",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0974 Basant Teental(m.laya) Tan naadir dir deem.mp3"
      },
      {
        "SL": "E0975",
        "RAAG": "Basant",
        "TAAL": "Adachowtal(Vilambit)",
        "STARTING_WORDS": "Tom tan drerenna",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0975 Basant Adachowtal(Vilambit) Tom tan drerenna.mp3"
      },
      {
        "SL": "E0976",
        "RAAG": "Basant",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Phooli Basounth",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0976 Basant Tilwada(Vilambit) Phooli Basounth.mp3"
      },
      {
        "SL": "E0977",
        "RAAG": "Basant",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Bhavara phooli",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0977 Basant Dhamar(Vilambit) Bhavara phooli.mp3"
      },
      {
        "SL": "E0978",
        "RAAG": "Paraj",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "sa ni dha sa ni dha",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0978 Paraj Teental(m.laya) sa ni dha sa ni dha.mp3"
      },
      {
        "SL": "E0979",
        "RAAG": "Paraj",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Mohe paraj piyane",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0979 Paraj Teental(m.laya) Mohe paraj piyane.mp3"
      },
      {
        "SL": "E0980",
        "RAAG": "Paraj",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Paraj kaha t purabi",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0980 Paraj Teental(m.laya) Paraj kahat purabi.mp3"
      },
      {
        "SL": "E0981",
        "RAAG": "Paraj",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Jhulat hindole tripurari",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0981 Paraj Teental(m.laya) Jhulat hindole tripurari.mp3"
      },
      {
        "SL": "E0982",
        "RAAG": "Paraj",
        "TAAL": "Dadra(m.laya)",
        "STARTING_WORDS": "Brijaki naar paraj gaay",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0982 Paraj Dadra(m.laya) Brijaki naar paraj gaay.mp3"
      },
      {
        "SL": "E0983",
        "RAAG": "Paraj",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Bolan laagi papiyaraa",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0983 Paraj Teental(m.laya) Bolan lagi papiyaraa.mp3"
      },
      {
        "SL": "E0984",
        "RAAG": "Paraj",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Mag chalat kaanh",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0984 Paraj Teental(m.laya) Mag chalat kaanh.mp3"
      },
      {
        "SL": "E0985",
        "RAAG": "Paraj",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kya karu na maaneri",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0985 Paraj Teental(m.laya) Kya karu na maaneri.mp3"
      },
      {
        "SL": "E0986",
        "RAAG": "Paraj",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kaari kaari kaam liya",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0986 Paraj Teental(m.laya) Kaari kaari kaam liya.mp3"
      },
      {
        "SL": "E0987",
        "RAAG": "Paraj",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kahe bajayi been",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0987 Paraj Teental(m.laya) Kahe bajayi been.mp3"
      },
      {
        "SL": "E0988",
        "RAAG": "Paraj",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "My kyo gayi jamunaa",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0988 Paraj Teental(m.laya) My kyo gayi jamunaa.mp3"
      },
      {
        "SL": "E0989",
        "RAAG": "Paraj",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Pavan chalat ali",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0989 Paraj Teental(m.laya) Pavan chalat ali.mp3"
      },
      {
        "SL": "E0990",
        "RAAG": "Paraj",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Dolan mende deghar",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0990 Paraj Teental(m.laya) Dolan mende deghar.mp3"
      },
      {
        "SL": "E0991",
        "RAAG": "Paraj",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Maiko roko nahi",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0991 Paraj Teental(m.laya) Maiko roko nahi.mp3"
      },
      {
        "SL": "E0992",
        "RAAG": "Paraj",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Adhak chakaayo",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0992 Paraj Tilwada(Vilambit) Adhak chakaayo.mp3"
      },
      {
        "SL": "E0993",
        "RAAG": "Paraj",
        "TAAL": "Ektaal(Vilambit)",
        "STARTING_WORDS": "Ghungatdo mahne kholo",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0993 Paraj Ektaal(Vilambit) Ghungatdo mahne kholo.mp3"
      },
      {
        "SL": "E0994",
        "RAAG": "Paraj",
        "TAAL": "Ektaal(Vilambit)",
        "STARTING_WORDS": "Baakadalo mahrooji",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0994 Paraj Ektaal(Vilambit) Baakadalo mahrooji.mp3"
      },
      {
        "SL": "E0995",
        "RAAG": "Paraj",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Aad madh aout jo",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0995 Paraj Jhaptal(m.laya) Aad madh aout jo.mp3"
      },
      {
        "SL": "E0996",
        "RAAG": "Paraj",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Laal aayeho",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0996 Paraj Jhaptal(m.laya) Laal aayeho.mp3"
      },
      {
        "SL": "E0997",
        "RAAG": "Paraj",
        "TAAL": "Shikhartal(m.laya)",
        "STARTING_WORDS": "Kaahna kuvar",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0997 Paraj Shikhartal(m.laya) Kaahna kuvar.mp3"
      },
      {
        "SL": "E0998",
        "RAAG": "Paraj",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Baanke banvaari",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0998 Paraj Sultal(m.laya) Baanke banvaari.mp3"
      },
      {
        "SL": "E0999",
        "RAAG": "Paraj",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Sohe seesa mukut",
        "THAAT": "Poorvi",
        "FILE_NAME": "E0999 Paraj Chowtal(Vilambit) Sohe seesa mukut.mp3"
      },
      {
        "SL": "E1000",
        "RAAG": "Paraj",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Aoung aoung roung",
        "THAAT": "Poorvi",
        "FILE_NAME": "E1000 Paraj Chowtal(Vilambit) Aoung aoung roung.mp3"
      },
      {
        "SL": "E1001",
        "RAAG": "Paraj",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Chinariyaa dhoom",
        "THAAT": "Poorvi",
        "FILE_NAME": "E1001 Paraj Dhamar(Vilambit) Chinariyaa dhoom.mp3"
      },
      {
        "SL": "E1002",
        "RAAG": "Paraj",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Mero baaro jobanvaa",
        "THAAT": "Poorvi",
        "FILE_NAME": "E1002 Paraj Dhamar(Vilambit) Mero baaro jobanvaa.mp3"
      },
      {
        "SL": "E1003",
        "RAAG": "Paraj",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Barasaane te aaye",
        "THAAT": "Poorvi",
        "FILE_NAME": "E1003 Paraj Dhamar(Vilambit) Barasaane te aaye.mp3"
      },
      {
        "SL": "E1004",
        "RAAG": "Paraj",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Daani deemtanana",
        "THAAT": "Poorvi",
        "FILE_NAME": "E1004 Paraj Teental(m.laya) Daani deemtanana.mp3"
      },
      {
        "SL": "E1005",
        "RAAG": "Gowri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kaha karoo",
        "THAAT": "Poorvi",
        "FILE_NAME": "E1005 Gowri Teental(m.laya) Kaha karoo.mp3"
      },
      {
        "SL": "E1006",
        "RAAG": "Gowri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Mohe baat chalat",
        "THAAT": "Poorvi",
        "FILE_NAME": "E1006 Gowri Teental(m.laya) Mohe baat chalat.mp3"
      },
      {
        "SL": "E1007",
        "RAAG": "Gowri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Bhatkat kaahe phire",
        "THAAT": "Poorvi",
        "FILE_NAME": "E1007 Gowri Teental(m.laya) Bhatkat kaahe phire.mp3"
      },
      {
        "SL": "E1008",
        "RAAG": "Gowri",
        "TAAL": "Roopak(Vilambit)",
        "STARTING_WORDS": "Lankaa layee raamji",
        "THAAT": "Poorvi",
        "FILE_NAME": "E1008 Gowri Roopak(Vilambit) Lankaa layee raamji.mp3"
      },
      {
        "SL": "E1009",
        "RAAG": "Gowri",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Yeri daiyaa",
        "THAAT": "Poorvi",
        "FILE_NAME": "E1009 Gowri Tilwada(Vilambit) Yeri daiyaa.mp3"
      },
      {
        "SL": "E1010",
        "RAAG": "Gowri",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Akabar dour",
        "THAAT": "Poorvi",
        "FILE_NAME": "E1010 Gowri Tilwada(Vilambit) Akabar dour.mp3"
      },
      {
        "SL": "E1011",
        "RAAG": "Gowri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Laaj rakho meri",
        "THAAT": "Poorvi",
        "FILE_NAME": "E1011 Gowri Teental(m.laya) Laaj rakho meri.mp3"
      },
      {
        "SL": "E1012",
        "RAAG": "Gowri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tare dani tadnom",
        "THAAT": "Poorvi",
        "FILE_NAME": "E1012 Gowri Teental(m.laya) Tare dani tadnom.mp3"
      },
      {
        "SL": "E1013",
        "RAAG": "Triveni",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Ahobal kahat raag tirban",
        "THAAT": "Poorvi",
        "FILE_NAME": "E1013 Triveni Jhaptal(m.laya) Ahobal kahat raag tirban.mp3"
      },
      {
        "SL": "E1014",
        "RAAG": "Triveni",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Kaalindi sarsuti",
        "THAAT": "Poorvi",
        "FILE_NAME": "E1014 Triveni Jhaptal(m.laya) Kaalindi sarsuti.mp3"
      },
      {
        "SL": "E1015",
        "RAAG": "Triveni",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Sansaar kaaran thu",
        "THAAT": "Poorvi",
        "FILE_NAME": "E1015 Triveni Jhaptal(m.laya) Sansaar kaaran thu.mp3"
      },
      {
        "SL": "E1016",
        "RAAG": "Shritanki",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Kaambardani sur",
        "THAAT": "Poorvi",
        "FILE_NAME": "E1016 Shritanki Sultal(m.laya) Kaambardani sur.mp3"
      },
      {
        "SL": "E1017",
        "RAAG": "Shritanki",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Hari hari kar man",
        "THAAT": "Poorvi",
        "FILE_NAME": "E1017 Shritanki Teental(m.laya) Hari hari kar man.mp3"
      },
      {
        "SL": "E1018",
        "RAAG": "Shritanki",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Sumiran kar manujaa",
        "THAAT": "Poorvi",
        "FILE_NAME": "E1018 Shritanki Sultal(m.laya) Sumiran kar manujaa.mp3"
      },
      {
        "SL": "E1019",
        "RAAG": "Maalavi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Oothan mana karale",
        "THAAT": "Poorvi",
        "FILE_NAME": "E1019 Maalavi Teental(m.laya) Oothan mana karale.mp3"
      },
      {
        "SL": "E1020",
        "RAAG": "Maalavi",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Aayo phaagun maas",
        "THAAT": "Poorvi",
        "FILE_NAME": "E1020 Maalavi Dhamar(Vilambit) Aayo phaagun maas.mp3"
      },
      {
        "SL": "E1021",
        "RAAG": "Bibhas",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Raag bibhas chatur",
        "THAAT": "Poorvi",
        "FILE_NAME": "E1021 Bibhas Jhaptal(m.laya) Raag bibhas chatur.mp3"
      },
      {
        "SL": "E1022",
        "RAAG": "Reva",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Saanjh samey",
        "THAAT": "Poorvi",
        "FILE_NAME": "E1022 Reva Sultal(m.laya) Saanjh samey.mp3"
      },
      {
        "SL": "E1023",
        "RAAG": "Jaitashri",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Ahobal raag likhath",
        "THAAT": "Poorvi",
        "FILE_NAME": "E1023 Jaitashri Sultal(m.laya) Ahobal raag likhath.mp3"
      },
      {
        "SL": "E1024",
        "RAAG": "Jaitashri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Maanan keeje",
        "THAAT": "Poorvi",
        "FILE_NAME": "E1024 Jaitashri Teental(m.laya) Maanan keeje.mp3"
      },
      {
        "SL": "E1025",
        "RAAG": "Jaitashri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kar chatur sughar",
        "THAAT": "Poorvi",
        "FILE_NAME": "E1025 Jaitashri Teental(m.laya) Kar chatur sughar.mp3"
      },
      {
        "SL": "E1026",
        "RAAG": "Jaitashri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Bahuth din beetey",
        "THAAT": "Poorvi",
        "FILE_NAME": "E1026 Jaitashri Teental(m.laya) Bahuth din beetey.mp3"
      },
      {
        "SL": "E1027",
        "RAAG": "Jaitashri",
        "TAAL": "Jhumra(Vilambit)",
        "STARTING_WORDS": "Haalariyaa maayee",
        "THAAT": "Poorvi",
        "FILE_NAME": "E1027 Jaitashri Jhumra(Vilambit) Haalariyaa maayee.mp3"
      },
      {
        "SL": "E1028",
        "RAAG": "Jaitashri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Mhane akeli daar gayo",
        "THAAT": "Poorvi",
        "FILE_NAME": "E1028 Jaitashri Teental(m.laya) Mhane akeli daar gayo.mp3"
      },
      {
        "SL": "E1029",
        "RAAG": "Jaitashri",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Tehare darasakee aas",
        "THAAT": "Poorvi",
        "FILE_NAME": "E1029 Jaitashri Tilwada(Vilambit) Tehare darasakee aas.mp3"
      },
      {
        "SL": "E1030",
        "RAAG": "Jaitashri",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Mana tumisan laagrahyo",
        "THAAT": "Poorvi",
        "FILE_NAME": "E1030 Jaitashri Tilwada(Vilambit) Mana tumisan laagrahyo.mp3"
      },
      {
        "SL": "E1031",
        "RAAG": "Jaitashri",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Kaanhar janam bhayo",
        "THAAT": "Poorvi",
        "FILE_NAME": "E1031 Jaitashri Chowtal(Vilambit) Kaanhar janam bhayo.mp3"
      },
      {
        "SL": "E1032",
        "RAAG": "Deepak",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Deepak kathan karath",
        "THAAT": "Poorvi",
        "FILE_NAME": "E1032 Deepak Jhaptal(m.laya) Deepak kathan karath.mp3"
      },
      {
        "SL": "E1033",
        "RAAG": "Hans Naraayani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Bhajman naaraayan",
        "THAAT": "Poorvi",
        "FILE_NAME": "E1033 Hans Naraayani Teental(m.laya) Bhajman naaraayan.mp3"
      },
      {
        "SL": "E1034",
        "RAAG": "Manohar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Atahi manohar",
        "THAAT": "Poorvi",
        "FILE_NAME": "E1034 Manohar Teental(m.laya) Atahi manohar.mp3"
      },
      {
        "SL": "F1035",
        "RAAG": "Marwa",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "dha ma dha ma ga re",
        "THAAT": "Marwa",
        "FILE_NAME": "F1035 Marwa Ektal(m.laya) dha ma dha ma ga re.mp3"
      },
      {
        "SL": "F1036",
        "RAAG": "Marwa",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "ni ri ga ma ni dha ma",
        "THAAT": "Marwa",
        "FILE_NAME": "F1036 Marwa Teental(m.laya) ni ri ga ma ni dha ma.mp3"
      },
      {
        "SL": "F1037",
        "RAAG": "Marwa",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "dha ma dha ga ma dha",
        "THAAT": "Marwa",
        "FILE_NAME": "F1037 Marwa Teental(m.laya) dha ma dha ga ma dha.mp3"
      },
      {
        "SL": "F1038",
        "RAAG": "Marwa",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Teevar ga ma dha ni sur",
        "THAAT": "Marwa",
        "FILE_NAME": "F1038 Marwa Jhaptal(m.laya) Teevar ga ma dha ni sur.mp3"
      },
      {
        "SL": "F1039",
        "RAAG": "Marwa",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tat bitath sushir",
        "THAAT": "Marwa",
        "FILE_NAME": "F1039 Marwa Teental(m.laya) Tat bitath sushir.mp3"
      },
      {
        "SL": "F1040",
        "RAAG": "Marwa",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Layeere shyam hamari",
        "THAAT": "Marwa",
        "FILE_NAME": "F1040 Marwa Teental(m.laya) Layeere shyam hamari.mp3"
      },
      {
        "SL": "F1041",
        "RAAG": "Marwa",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Javo mohan morey",
        "THAAT": "Marwa",
        "FILE_NAME": "F1041 Marwa Teental(m.laya) Javo mohan morey.mp3"
      },
      {
        "SL": "F1042",
        "RAAG": "Marwa",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Jagat janani jagdhambh",
        "THAAT": "Marwa",
        "FILE_NAME": "F1042 Marwa Teental(m.laya) Jagat janani jagdhambh.mp3"
      },
      {
        "SL": "F1043",
        "RAAG": "Marwa",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kavuki reeth kovu kare",
        "THAAT": "Marwa",
        "FILE_NAME": "F1043 Marwa Teental(m.laya) Kavuki reeth kovu kare.mp3"
      },
      {
        "SL": "F1044",
        "RAAG": "Marwa",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Aba guna bakas karam",
        "THAAT": "Marwa",
        "FILE_NAME": "F1044 Marwa Teental(m.laya) Aba guna bakas karam.mp3"
      },
      {
        "SL": "F1045",
        "RAAG": "Marwa",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Sughar sughar baithe",
        "THAAT": "Marwa",
        "FILE_NAME": "F1045 Marwa Teental(m.laya) Sughar sughar baithe.mp3"
      },
      {
        "SL": "F1046",
        "RAAG": "Marwa",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Aanave mil jaanave",
        "THAAT": "Marwa",
        "FILE_NAME": "F1046 Marwa Teental(m.laya) Aanave mil jaanave.mp3"
      },
      {
        "SL": "F1047",
        "RAAG": "Marwa",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Bolan bin kabahoo",
        "THAAT": "Marwa",
        "FILE_NAME": "F1047 Marwa Ektal(m.laya) Bolan bin kabahoo.mp3"
      },
      {
        "SL": "F1048",
        "RAAG": "Marwa",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Chalo hato giradhaari",
        "THAAT": "Marwa",
        "FILE_NAME": "F1048 Marwa Ektal(m.laya) Chalo hato giradhaari.mp3"
      },
      {
        "SL": "F1049",
        "RAAG": "Marwa",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Suna suna bathiyaa",
        "THAAT": "Marwa",
        "FILE_NAME": "F1049 Marwa Teental(m.laya) Suna suna bathiyaa.mp3"
      },
      {
        "SL": "F1050",
        "RAAG": "Marwa",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Jhanjhan mora jh ankayi",
        "THAAT": "Marwa",
        "FILE_NAME": "F1050 Marwa Ektal(Vilambit) Jhanjhan mora jhankayi.mp3"
      },
      {
        "SL": "F1051",
        "RAAG": "Marwa",
        "TAAL": "Jhumra(Vilambit)",
        "STARTING_WORDS": "Nanandiyaa chavaab",
        "THAAT": "Marwa",
        "FILE_NAME": "F1051 Marwa Jhumra(Vilambit) Nanandiyaa chavaab.mp3"
      },
      {
        "SL": "F1052",
        "RAAG": "Marwa",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Jiiyaa nikasoyi aave",
        "THAAT": "Marwa",
        "FILE_NAME": "F1052 Marwa Ektal(Vilambit) Jiiyaa nikasoyi aave.mp3"
      },
      {
        "SL": "F1053",
        "RAAG": "Marwa",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Maayi mohe kaahoo",
        "THAAT": "Marwa",
        "FILE_NAME": "F1053 Marwa Ektal(Vilambit) Maayi mohe kaahoo.mp3"
      },
      {
        "SL": "F1054",
        "RAAG": "Marwa",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Piyaa morey anath des",
        "THAAT": "Marwa",
        "FILE_NAME": "F1054 Marwa Tilwada(Vilambit) Piyaa morey anath des.mp3"
      },
      {
        "SL": "F1055",
        "RAAG": "Marwa",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Maha dal bikat",
        "THAAT": "Marwa",
        "FILE_NAME": "F1055 Marwa Jhaptal(m.laya) Maha dal bikat.mp3"
      },
      {
        "SL": "F1056",
        "RAAG": "Marwa",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Pratham manjan kar",
        "THAAT": "Marwa",
        "FILE_NAME": "F1056 Marwa Chowtal(Vilambit) Pratham manjan kar.mp3"
      },
      {
        "SL": "F1057",
        "RAAG": "Marwa",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Anaahat naad",
        "THAAT": "Marwa",
        "FILE_NAME": "F1057 Marwa Chowtal(Vilambit) Anaahat naad.mp3"
      },
      {
        "SL": "F1058",
        "RAAG": "Marwa",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Gaav gaav ke lugaiyyaa",
        "THAAT": "Marwa",
        "FILE_NAME": "F1058 Marwa Chowtal(Vilambit) Gaav gaav ke lugaiyyaa.mp3"
      },
      {
        "SL": "F1059",
        "RAAG": "Marwa",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Gaayak sab mil bichaar",
        "THAAT": "Marwa",
        "FILE_NAME": "F1059 Marwa Chowtal(Vilambit) Gaayak sab mil bichaar.mp3"
      },
      {
        "SL": "F1060",
        "RAAG": "Marwa",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Kaise hori khelat hai",
        "THAAT": "Marwa",
        "FILE_NAME": "F1060 Marwa Dhamar(Vilambit) Kaise hori khelat hai.mp3"
      },
      {
        "SL": "F1061",
        "RAAG": "Marwa",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Ham aaye phaag khelan",
        "THAAT": "Marwa",
        "FILE_NAME": "F1061 Marwa Dhamar(Vilambit) Ham aaye phaag khelan.mp3"
      },
      {
        "SL": "F1062",
        "RAAG": "Marwa",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ude tana derenaa deem",
        "THAAT": "Marwa",
        "FILE_NAME": "F1062 Marwa Teental(m.laya) Ude tana derenaa deem.mp3"
      },
      {
        "SL": "F1063",
        "RAAG": "Marwa",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Tom tanana tanana tom",
        "THAAT": "Marwa",
        "FILE_NAME": "F1063 Marwa Ektal(m.laya) Tom tanana tanana tom.mp3"
      },
      {
        "SL": "F1064",
        "RAAG": "Marwa",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Tananana tanana",
        "THAAT": "Marwa",
        "FILE_NAME": "F1064 Marwa Tilwada(Vilambit) Tananana tanana.mp3"
      },
      {
        "SL": "F1065",
        "RAAG": "Sohani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "ma dha ni sa re re sa",
        "THAAT": "Marwa",
        "FILE_NAME": "F1065 Sohani Teental(m.laya) ma dha ni sa re re sa.mp3"
      },
      {
        "SL": "F1066",
        "RAAG": "Sohani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Sohaniko chatur sunaye",
        "THAAT": "Marwa",
        "FILE_NAME": "F1066 Sohani Teental(m.laya) Sohaniko chatur sunaye.mp3"
      },
      {
        "SL": "F1067",
        "RAAG": "Sohani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Sohani surana hoth",
        "THAAT": "Marwa",
        "FILE_NAME": "F1067 Sohani Teental(m.laya) Sohani surana hoth.mp3"
      },
      {
        "SL": "F1068",
        "RAAG": "Sohani",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Maaravako thaat kare",
        "THAAT": "Marwa",
        "FILE_NAME": "F1068 Sohani Ektal(m.laya) Maaravako thaat kare.mp3"
      },
      {
        "SL": "F1069",
        "RAAG": "Sohani",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Sohani surath mohani",
        "THAAT": "Marwa",
        "FILE_NAME": "F1069 Sohani Ektal(m.laya) Sohani surath mohani.mp3"
      },
      {
        "SL": "F1070",
        "RAAG": "Sohani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Guru charan sharan",
        "THAAT": "Marwa",
        "FILE_NAME": "F1070 Sohani Teental(m.laya) Guru charan sharan.mp3"
      },
      {
        "SL": "F1071",
        "RAAG": "Sohani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kaahe aba tum aayeho",
        "THAAT": "Marwa",
        "FILE_NAME": "F1071 Sohani Teental(m.laya) Kaahe aba tum aayeho.mp3"
      },
      {
        "SL": "F1072",
        "RAAG": "Sohani",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Sunari mori aali bitha a",
        "THAAT": "Marwa",
        "FILE_NAME": "F1072 Sohani Ektaal(m.laya) Sunari mori aali bithaa.mp3"
      },
      {
        "SL": "F1073",
        "RAAG": "Sohani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Mela beko jiyaa mora",
        "THAAT": "Marwa",
        "FILE_NAME": "F1073 Sohani Teental(m.laya) Mela beko jiyaa mora.mp3"
      },
      {
        "SL": "F1074",
        "RAAG": "Sohani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Yeri ye jasoda",
        "THAAT": "Marwa",
        "FILE_NAME": "F1074 Sohani Teental(m.laya) Yeri ye jasoda.mp3"
      },
      {
        "SL": "F1075",
        "RAAG": "Sohani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Rumjhum rumjhum",
        "THAAT": "Marwa",
        "FILE_NAME": "F1075 Sohani Teental(m.laya) Rumjhum rumjhum.mp3"
      },
      {
        "SL": "F1076",
        "RAAG": "Sohani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Soni surat yaar",
        "THAAT": "Marwa",
        "FILE_NAME": "F1076 Sohani Teental(m.laya) Soni surat yaar.mp3"
      },
      {
        "SL": "F1077",
        "RAAG": "Sohani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Murali kaanha",
        "THAAT": "Marwa",
        "FILE_NAME": "F1077 Sohani Teental(m.laya) Murali kaanha.mp3"
      },
      {
        "SL": "F1078",
        "RAAG": "Sohani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Saavaleso laago mharo",
        "THAAT": "Marwa",
        "FILE_NAME": "F1078 Sohani Teental(m.laya) Saavaleso laago mharo.mp3"
      },
      {
        "SL": "F1079",
        "RAAG": "Sohani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Dekhbeko jiya lalcha aya",
        "THAAT": "Marwa",
        "FILE_NAME": "F1079 Sohani Teental(m.laya) Dekhbeko jiya lalchaaya.mp3"
      },
      {
        "SL": "F1080",
        "RAAG": "Sohani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kahe hamako dikhaavo",
        "THAAT": "Marwa",
        "FILE_NAME": "F1080 Sohani Teental(m.laya) Kahe hamako dikhaavo.mp3"
      },
      {
        "SL": "F1081",
        "RAAG": "Sohani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ye phariyaad allaha",
        "THAAT": "Marwa",
        "FILE_NAME": "F1081 Sohani Teental(m.laya) Ye phariyaad allaha.mp3"
      },
      {
        "SL": "F1082",
        "RAAG": "Sohani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Laadal bane banee so",
        "THAAT": "Marwa",
        "FILE_NAME": "F1082 Sohani Teental(m.laya) Laadal bane banee so.mp3"
      },
      {
        "SL": "F1083",
        "RAAG": "Sohani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Pyara yendee nazar",
        "THAAT": "Marwa",
        "FILE_NAME": "F1083 Sohani Teental(m.laya) Pyara yendee nazar.mp3"
      },
      {
        "SL": "F1084",
        "RAAG": "Sohani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Baanke bihaari",
        "THAAT": "Marwa",
        "FILE_NAME": "F1084 Sohani Teental(m.laya) Baanke bihaari.mp3"
      },
      {
        "SL": "F1085",
        "RAAG": "Sohani",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Mhanehee bulaave",
        "THAAT": "Marwa",
        "FILE_NAME": "F1085 Sohani Tilwada(Vilambit) Mhanehee bulaave.mp3"
      },
      {
        "SL": "F1086",
        "RAAG": "Sohani",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Mhanenaa pilaave",
        "THAAT": "Marwa",
        "FILE_NAME": "F1086 Sohani Tilwada(Vilambit) Mhanenaa pilaave.mp3"
      },
      {
        "SL": "F1087",
        "RAAG": "Sohani",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Kyu sunaave bandiyaa",
        "THAAT": "Marwa",
        "FILE_NAME": "F1087 Sohani Tilwada(Vilambit) Kyu sunaave bandiyaa.mp3"
      },
      {
        "SL": "F1088",
        "RAAG": "Sohani",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Toohee gaduvaalaa",
        "THAAT": "Marwa",
        "FILE_NAME": "F1088 Sohani Tilwada(Vilambit) Toohee gaduvaalaa.mp3"
      },
      {
        "SL": "F1089",
        "RAAG": "Sohani",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Pratham aada shiva",
        "THAAT": "Marwa",
        "FILE_NAME": "F1089 Sohani Sultal(m.laya) Pratham aada shiva.mp3"
      },
      {
        "SL": "F1090",
        "RAAG": "Sohani",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Tose mai durayi naahi",
        "THAAT": "Marwa",
        "FILE_NAME": "F1090 Sohani Chowtal(Vilambit) Tose mai durayi naahi.mp3"
      },
      {
        "SL": "F1091",
        "RAAG": "Sohani",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Chatrapathi akabar",
        "THAAT": "Marwa",
        "FILE_NAME": "F1091 Sohani Chowtal(Vilambit) Chatrapathi akabar.mp3"
      },
      {
        "SL": "F1092",
        "RAAG": "Sohani",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Langar lang raayee",
        "THAAT": "Marwa",
        "FILE_NAME": "F1092 Sohani Dhamar(Vilambit) Langar lang raayee.mp3"
      },
      {
        "SL": "F1093",
        "RAAG": "Sohani",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Aaye mose hori khelan",
        "THAAT": "Marwa",
        "FILE_NAME": "F1093 Sohani Dhamar(Vilambit) Aaye mose hori khelan.mp3"
      },
      {
        "SL": "F1094",
        "RAAG": "Sohani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Dimtanana dirdir tarnom",
        "THAAT": "Marwa",
        "FILE_NAME": "F1094 Sohani Teental(m.laya) Dimtanana dirdir tarnom.mp3"
      },
      {
        "SL": "F1095",
        "RAAG": "Sohani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tanana tana naadir",
        "THAAT": "Marwa",
        "FILE_NAME": "F1095 Sohani Teental(m.laya) Tanana tana naadir.mp3"
      },
      {
        "SL": "F1096",
        "RAAG": "Puriya",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "ma ma ga re sa",
        "THAAT": "Marwa",
        "FILE_NAME": "F1096 Puriya Teental(m.laya) ma ma ga re sa.mp3"
      },
      {
        "SL": "F1097",
        "RAAG": "Puriya",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Poori aas sakhi",
        "THAAT": "Marwa",
        "FILE_NAME": "F1097 Puriya Teental(m.laya) Poori aas sakhi.mp3"
      },
      {
        "SL": "F1098",
        "RAAG": "Puriya",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Shyam sunderki sundar",
        "THAAT": "Marwa",
        "FILE_NAME": "F1098 Puriya Teental(m.laya) Shyam sunderki sundar.mp3"
      },
      {
        "SL": "F1099",
        "RAAG": "Puriya",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Chinachina baat takat",
        "THAAT": "Marwa",
        "FILE_NAME": "F1099 Puriya Teental(m.laya) Chinachina baat takat.mp3"
      },
      {
        "SL": "F1100",
        "RAAG": "Puriya",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Thaasu kaayee raar",
        "THAAT": "Marwa",
        "FILE_NAME": "F1100 Puriya Teental(m.laya) Thaasu kaayee raar.mp3"
      },
      {
        "SL": "F1101",
        "RAAG": "Puriya",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Sapane mey aayeho",
        "THAAT": "Marwa",
        "FILE_NAME": "F1101 Puriya Teental(m.laya) Sapane mey aayeho.mp3"
      },
      {
        "SL": "F1102",
        "RAAG": "Puriya",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ayi choundaal rajani",
        "THAAT": "Marwa",
        "FILE_NAME": "F1102 Puriya Teental(m.laya) Ayi choundaal rajani.mp3"
      },
      {
        "SL": "F1103",
        "RAAG": "Puriya",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Unbin kalana parath",
        "THAAT": "Marwa",
        "FILE_NAME": "F1103 Puriya Teental(m.laya) Unbin kalana parath.mp3"
      },
      {
        "SL": "F1104",
        "RAAG": "Puriya",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Logava sune piyarava",
        "THAAT": "Marwa",
        "FILE_NAME": "F1104 Puriya Teental(m.laya) Logava sune piyarava.mp3"
      },
      {
        "SL": "F1105",
        "RAAG": "Puriya",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Raat basey aayeho",
        "THAAT": "Marwa",
        "FILE_NAME": "F1105 Puriya Ektal(Vilambit) Raat basey aayeho.mp3"
      },
      {
        "SL": "F1106",
        "RAAG": "Puriya",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Ghadiyaa ginat jaat",
        "THAAT": "Marwa",
        "FILE_NAME": "F1106 Puriya Ektal(Vilambit) Ghadiyaa ginat jaat.mp3"
      },
      {
        "SL": "F1107",
        "RAAG": "Puriya",
        "TAAL": "Ektal(Vilanbit)",
        "STARTING_WORDS": "Piyaa gunavountaa",
        "THAAT": "Marwa",
        "FILE_NAME": "F1107 Puriya Ektal(Vilanbit) Piyaa gunavountaa.mp3"
      },
      {
        "SL": "F1108",
        "RAAG": "Puriya",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Phoolan ke haravaa",
        "THAAT": "Marwa",
        "FILE_NAME": "F1108 Puriya Ektal(Vilambit) Phoolan ke haravaa.mp3"
      },
      {
        "SL": "F1109",
        "RAAG": "Puriya",
        "TAAL": "Jhumra(Vilambit)",
        "STARTING_WORDS": "Pyare de gar laagi",
        "THAAT": "Marwa",
        "FILE_NAME": "F1109 Puriya Jhumra(Vilambit) Pyare de gar laagi.mp3"
      },
      {
        "SL": "F1110",
        "RAAG": "Puriya",
        "TAAL": "Adachowtal(Vilambit)",
        "STARTING_WORDS": "Kote gayaa",
        "THAAT": "Marwa",
        "FILE_NAME": "F1110 Puriya Adachowtal(Vilambit) Kote gayaa.mp3"
      },
      {
        "SL": "F1111",
        "RAAG": "Puriya",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Dil laagaare dayaarve",
        "THAAT": "Marwa",
        "FILE_NAME": "F1111 Puriya Tilwada(Vilambit) Dil laagaare dayaarve.mp3"
      },
      {
        "SL": "F1112",
        "RAAG": "Puriya",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Ye jiyaraa na rahe",
        "THAAT": "Marwa",
        "FILE_NAME": "F1112 Puriya Tilwada(Vilambit) Ye jiyaraa na rahe.mp3"
      },
      {
        "SL": "F1113",
        "RAAG": "Puriya",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Sughar banaa gaavo",
        "THAAT": "Marwa",
        "FILE_NAME": "F1113 Puriya Tilwada(Vilambit) Sughar banaa gaavo.mp3"
      },
      {
        "SL": "F1114",
        "RAAG": "Puriya",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Tuhee kartaar",
        "THAAT": "Marwa",
        "FILE_NAME": "F1114 Puriya Ektal(Vilambit) Tuhee kartaar.mp3"
      },
      {
        "SL": "F1115",
        "RAAG": "Puriya",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Aaj bhale bane laal",
        "THAAT": "Marwa",
        "FILE_NAME": "F1115 Puriya Chowtal(Vilambit) Aaj bhale bane laal.mp3"
      },
      {
        "SL": "F1116",
        "RAAG": "Puriya",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Ye achha bana byahan",
        "THAAT": "Marwa",
        "FILE_NAME": "F1116 Puriya Chowtal(Vilambit) Ye achha bana byahan.mp3"
      },
      {
        "SL": "F1117",
        "RAAG": "Puriya",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Seesa chatra sohe",
        "THAAT": "Marwa",
        "FILE_NAME": "F1117 Puriya Chowtal(Vilambit) Seesa chatra sohe.mp3"
      },
      {
        "SL": "F1118",
        "RAAG": "Puriya",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Kaan mori angiyaa",
        "THAAT": "Marwa",
        "FILE_NAME": "F1118 Puriya Dhamar(Vilambit) Kaan mori angiyaa.mp3"
      },
      {
        "SL": "F1119",
        "RAAG": "Puriya",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Puriya turi tera suhaag",
        "THAAT": "Marwa",
        "FILE_NAME": "F1119 Puriya Dhamar(Vilambit) Puriya turi tera suhaag.mp3"
      },
      {
        "SL": "F1120",
        "RAAG": "Puriya",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tanana dirdir deem",
        "THAAT": "Marwa",
        "FILE_NAME": "F1120 Puriya Teental(m.laya) Tanana dirdir deem.mp3"
      },
      {
        "SL": "F1121",
        "RAAG": "Puriya",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Nadirdir dimtanana",
        "THAAT": "Marwa",
        "FILE_NAME": "F1121 Puriya Teental(m.laya) Nadirdir dimtanana.mp3"
      },
      {
        "SL": "F1122",
        "RAAG": "Puriya",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tom tanananananana",
        "THAAT": "Marwa",
        "FILE_NAME": "F1122 Puriya Teental(m.laya) Tom tanananananana.mp3"
      },
      {
        "SL": "F1123",
        "RAAG": "Puriya",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tom Tanana t anana",
        "THAAT": "Marwa",
        "FILE_NAME": "F1123 Puriya Teental(m.laya) Tom Tanana tanana.mp3"
      },
      {
        "SL": "F1124",
        "RAAG": "Puriya",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Yaar mouyala yala",
        "THAAT": "Marwa",
        "FILE_NAME": "F1124 Puriya Tilwada(Vilambit) Yaar mouyala yala.mp3"
      },
      {
        "SL": "F1125",
        "RAAG": "Lalit",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "ni re ga re sa",
        "THAAT": "Marwa",
        "FILE_NAME": "F1125 Lalit Teental(m.laya) ni re ga re sa.mp3"
      },
      {
        "SL": "F1126",
        "RAAG": "Lalit",
        "TAAL": "Roopak(m.laya)",
        "STARTING_WORDS": "Maarvaako thaat jab kar",
        "THAAT": "Marwa",
        "FILE_NAME": "F1126 Lalit Roopak(m.laya) Maarvaako thaat jab kar.mp3"
      },
      {
        "SL": "F1127",
        "RAAG": "Lalit",
        "TAAL": "Gajajhampa(m.laya)",
        "STARTING_WORDS": "Keeje hari darasan",
        "THAAT": "Marwa",
        "FILE_NAME": "F1127 Lalit Gajajhampa(m.laya) Keeje hari darasan.mp3"
      },
      {
        "SL": "F1128",
        "RAAG": "Lalit",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Bighan haran",
        "THAAT": "Marwa",
        "FILE_NAME": "F1128 Lalit Teental(m.laya) Bighan haran.mp3"
      },
      {
        "SL": "F1129",
        "RAAG": "Lalit",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Balbal javu mukharbind",
        "THAAT": "Marwa",
        "FILE_NAME": "F1129 Lalit Teental(m.laya) Balbal javu mukharbind.mp3"
      },
      {
        "SL": "F1130",
        "RAAG": "Lalit",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Manukha janam",
        "THAAT": "Marwa",
        "FILE_NAME": "F1130 Lalit Ektal(m.laya) Manukha janam.mp3"
      },
      {
        "SL": "F1131",
        "RAAG": "Lalit",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Sabmil moungal gaayiye",
        "THAAT": "Marwa",
        "FILE_NAME": "F1131 Lalit Teental(m.laya) Sabmil moungal gaayiye.mp3"
      },
      {
        "SL": "F1132",
        "RAAG": "Lalit",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Mohe kankariyaa naa",
        "THAAT": "Marwa",
        "FILE_NAME": "F1132 Lalit Teental(m.laya) Mohe kankariyaa naa.mp3"
      },
      {
        "SL": "F1133",
        "RAAG": "Lalit",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Mhare ghungharvaa",
        "THAAT": "Marwa",
        "FILE_NAME": "F1133 Lalit Teental(m.laya) Mhare ghungharvaa.mp3"
      },
      {
        "SL": "F1134",
        "RAAG": "Lalit",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Piyu piyu ratat papiyara",
        "THAAT": "Marwa",
        "FILE_NAME": "F1134 Lalit Teental(m.laya) Piyu piyu ratat papiyara.mp3"
      },
      {
        "SL": "F1135",
        "RAAG": "Lalit",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Bhavanda yarda jobana",
        "THAAT": "Marwa",
        "FILE_NAME": "F1135 Lalit Teental(m.laya) Bhavanda yarda jobana.mp3"
      },
      {
        "SL": "F1136",
        "RAAG": "Lalit",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Marur bulave kaise javu",
        "THAAT": "Marwa",
        "FILE_NAME": "F1136 Lalit Tilwada(Vilambit) Marur bulave kaise javu.mp3"
      },
      {
        "SL": "F1137",
        "RAAG": "Lalit",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Pyaari tere naina",
        "THAAT": "Marwa",
        "FILE_NAME": "F1137 Lalit Tilwada(Vilambit) Pyaari tere naina.mp3"
      },
      {
        "SL": "F1138",
        "RAAG": "Lalit",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Bhorahi aaye",
        "THAAT": "Marwa",
        "FILE_NAME": "F1138 Lalit Tilwada(Vilambit) Bhorahi aaye.mp3"
      },
      {
        "SL": "F1139",
        "RAAG": "Lalit",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Uninde kaanha aaye",
        "THAAT": "Marwa",
        "FILE_NAME": "F1139 Lalit Tilwada(Vilambit) Uninde kaanha aaye.mp3"
      },
      {
        "SL": "F1140",
        "RAAG": "Lalit",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Hey raadhe tere nainan",
        "THAAT": "Marwa",
        "FILE_NAME": "F1140 Lalit Tilwada(Vilambit) Hey raadhe tere nainan.mp3"
      },
      {
        "SL": "F1141",
        "RAAG": "Lalit",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Morey ghar aave",
        "THAAT": "Marwa",
        "FILE_NAME": "F1141 Lalit Tilwada(Vilambit) Morey ghar aave.mp3"
      },
      {
        "SL": "F1142",
        "RAAG": "Lalit",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Baas basaayee ho",
        "THAAT": "Marwa",
        "FILE_NAME": "F1142 Lalit Ektal(Vilambit) Baas basaayee ho.mp3"
      },
      {
        "SL": "F1143",
        "RAAG": "Lalit",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Jaagi tore sounge",
        "THAAT": "Marwa",
        "FILE_NAME": "F1143 Lalit Tilwada(Vilambit) Jaagi tore sounge.mp3"
      },
      {
        "SL": "F1144",
        "RAAG": "Lalit",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Rainakaa sapanaa",
        "THAAT": "Marwa",
        "FILE_NAME": "F1144 Lalit Tilwada(Vilambit) Rainakaa sapanaa.mp3"
      },
      {
        "SL": "F1145",
        "RAAG": "Lalit",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Noore mana sumaran",
        "THAAT": "Marwa",
        "FILE_NAME": "F1145 Lalit Ektal(Vilambit) Noore mana sumaran.mp3"
      },
      {
        "SL": "F1146",
        "RAAG": "Lalit",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Chaarohi ved puraan",
        "THAAT": "Marwa",
        "FILE_NAME": "F1146 Lalit Sultal(m.laya) Chaarohi ved puraan.mp3"
      },
      {
        "SL": "F1147",
        "RAAG": "Lalit",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Arey mana tho bhajare",
        "THAAT": "Marwa",
        "FILE_NAME": "F1147 Lalit Chowtal(Vilambit) Arey mana tho bhajare.mp3"
      },
      {
        "SL": "F1148",
        "RAAG": "Lalit",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Narayan teri gat agaadh",
        "THAAT": "Marwa",
        "FILE_NAME": "F1148 Lalit Chowtal(Vilambit) Narayan teri gat agaadh.mp3"
      },
      {
        "SL": "F1149",
        "RAAG": "Lalit",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Adham udhaaran",
        "THAAT": "Marwa",
        "FILE_NAME": "F1149 Lalit Chowtal(Vilambit) Adham udhaaran.mp3"
      },
      {
        "SL": "F1150",
        "RAAG": "Lalit",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Aajrang bheene",
        "THAAT": "Marwa",
        "FILE_NAME": "F1150 Lalit Dhamar(Vilambit) Aajrang bheene.mp3"
      },
      {
        "SL": "F1151",
        "RAAG": "Lalit",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Pag phaar bheejogi",
        "THAAT": "Marwa",
        "FILE_NAME": "F1151 Lalit Dhamar(Vilambit) Pag phaar bheejogi.mp3"
      },
      {
        "SL": "F1152",
        "RAAG": "Lalit",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Ho toko kon seekh deho",
        "THAAT": "Marwa",
        "FILE_NAME": "F1152 Lalit Dhamar(Vilambit) Ho toko kon seekh deho.mp3"
      },
      {
        "SL": "F1153",
        "RAAG": "Lalit",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tana naadir daani",
        "THAAT": "Marwa",
        "FILE_NAME": "F1153 Lalit Teental(m.laya) Tana naadir daani.mp3"
      },
      {
        "SL": "F1154",
        "RAAG": "Lalit",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Dirdir tanana tan dere",
        "THAAT": "Marwa",
        "FILE_NAME": "F1154 Lalit Teental(m.laya) Dirdir tanana tan dere.mp3"
      },
      {
        "SL": "F1155",
        "RAAG": "Lalit",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tanadere tadiyare",
        "THAAT": "Marwa",
        "FILE_NAME": "F1155 Lalit Teental(m.laya) Tanadere tadiyare.mp3"
      },
      {
        "SL": "F1156",
        "RAAG": "Puraba",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Ekpal hoo na jaane deta",
        "THAAT": "Marwa",
        "FILE_NAME": "F1156 Puraba Tilwada(Vilambit) Ekpal hoo na jaane deta.mp3"
      },
      {
        "SL": "F1157",
        "RAAG": "Puraba",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Moundar baaje",
        "THAAT": "Marwa",
        "FILE_NAME": "F1157 Puraba Tilwada(Vilambit) Moundar baaje.mp3"
      },
      {
        "SL": "F1158",
        "RAAG": "Purva-kalyan",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Hovan laagi saanjh",
        "THAAT": "Marwa",
        "FILE_NAME": "F1158 Purva-kalyan Ektal(Vilambit) Hovan laagi saanjh.mp3"
      },
      {
        "SL": "F1159",
        "RAAG": "Maligoura",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kar yaad prabhuko aaj",
        "THAAT": "Marwa",
        "FILE_NAME": "F1159 Maligoura Teental(m.laya) Kar yaad prabhuko aaj.mp3"
      },
      {
        "SL": "F1160",
        "RAAG": "Maligoura",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Mere mana laag rahi",
        "THAAT": "Marwa",
        "FILE_NAME": "F1160 Maligoura Teental(m.laya) Mere mana laag rahi.mp3"
      },
      {
        "SL": "F1161",
        "RAAG": "Maligoura",
        "TAAL": "Roopak(m.laya)",
        "STARTING_WORDS": "Ye ras raasar",
        "THAAT": "Marwa",
        "FILE_NAME": "F1161 Maligoura Roopak(m.laya) Ye ras raasar.mp3"
      },
      {
        "SL": "F1162",
        "RAAG": "Maligoura",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Tera cholara dulara",
        "THAAT": "Marwa",
        "FILE_NAME": "F1162 Maligoura Ektal(Vilambit) Tera cholara dulara.mp3"
      },
      {
        "SL": "F1163",
        "RAAG": "Maligoura",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Ahiyo sune peer",
        "THAAT": "Marwa",
        "FILE_NAME": "F1163 Maligoura Ektal(Vilambit) Ahiyo sune peer.mp3"
      },
      {
        "SL": "F1164",
        "RAAG": "Maligoura",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Raaja siriraam",
        "THAAT": "Marwa",
        "FILE_NAME": "F1164 Maligoura Chowtal(Vilambit) Raaja siriraam.mp3"
      },
      {
        "SL": "F1165",
        "RAAG": "Maligoura",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Ree daph baajan laage",
        "THAAT": "Marwa",
        "FILE_NAME": "F1165 Maligoura Dhamar(Vilambit) Ree daph baajan laage.mp3"
      },
      {
        "SL": "F1166",
        "RAAG": "Jait",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Morey raaja",
        "THAAT": "Marwa",
        "FILE_NAME": "F1166 Jait Tilwada(Vilambit) Morey raaja.mp3"
      },
      {
        "SL": "F1167",
        "RAAG": "Jait",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Morey raja-2nd version",
        "THAAT": "Marwa",
        "FILE_NAME": "F1167 Jait Tilwada(Vilambit) Morey raja-2nd version.mp3"
      },
      {
        "SL": "F1168",
        "RAAG": "Jait",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Kalas jyot laagi",
        "THAAT": "Marwa",
        "FILE_NAME": "F1168 Jait Jhaptal(m.laya) Kalas jyot laagi.mp3"
      },
      {
        "SL": "F1169",
        "RAAG": "Barari",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Birahoon baavaree",
        "THAAT": "Marwa",
        "FILE_NAME": "F1169 Barari Chowtal(Vilambit) Birahoon baavaree.mp3"
      },
      {
        "SL": "F1170",
        "RAAG": "Barari",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Sumaran karale jiyaa",
        "THAAT": "Marwa",
        "FILE_NAME": "F1170 Barari Chowtal(Vilambit) Sumaran karale jiyaa.mp3"
      },
      {
        "SL": "F1171",
        "RAAG": "Barari",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Tu jhak jhor",
        "THAAT": "Marwa",
        "FILE_NAME": "F1171 Barari Chowtal(Vilambit) Tu jhak jhor.mp3"
      },
      {
        "SL": "F1172",
        "RAAG": "Bibhas",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Hari bhajare tu manuja",
        "THAAT": "Marwa",
        "FILE_NAME": "F1172 Bibhas Teental(m.laya) Hari bhajare tu manuja.mp3"
      },
      {
        "SL": "F1173",
        "RAAG": "Bibhas",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Rabaako naam sumar",
        "THAAT": "Marwa",
        "FILE_NAME": "F1173 Bibhas Teental(m.laya) Rabaako naam sumar.mp3"
      },
      {
        "SL": "F1174",
        "RAAG": "Bibhas",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kaskuvaruvaa jaayila",
        "THAAT": "Marwa",
        "FILE_NAME": "F1174 Bibhas Teental(m.laya) Kaskuvaruvaa jaayila.mp3"
      },
      {
        "SL": "F1175",
        "RAAG": "Bibhas",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "So ab more surajanava",
        "THAAT": "Marwa",
        "FILE_NAME": "F1175 Bibhas Teental(m.laya) So ab more surajanava.mp3"
      },
      {
        "SL": "F1176",
        "RAAG": "Bibhas",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Morare peet piyaravaa",
        "THAAT": "Marwa",
        "FILE_NAME": "F1176 Bibhas Teental(m.laya) Morare peet piyaravaa.mp3"
      },
      {
        "SL": "F1177",
        "RAAG": "Bibhas",
        "TAAL": "Adachowtal(Vilambit)",
        "STARTING_WORDS": "Mai tohe dhaavoo",
        "THAAT": "Marwa",
        "FILE_NAME": "F1177 Bibhas Adachowtal(Vilambit) Mai tohe dhaavoo.mp3"
      },
      {
        "SL": "F1178",
        "RAAG": "Pancham",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Maaruv samaakhyaat",
        "THAAT": "Marwa",
        "FILE_NAME": "F1178 Pancham Jhaptal(m.laya) Maaruv samaakhyaat.mp3"
      },
      {
        "SL": "F1179",
        "RAAG": "Pancham",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Pratham guni bichaar",
        "THAAT": "Marwa",
        "FILE_NAME": "F1179 Pancham Sultal(m.laya) Pratham guni bichaar.mp3"
      },
      {
        "SL": "F1180",
        "RAAG": "Pancham",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Raam naam lele",
        "THAAT": "Marwa",
        "FILE_NAME": "F1180 Pancham Sultal(m.laya) Raam naam lele.mp3"
      },
      {
        "SL": "F1181",
        "RAAG": "Pancham",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Maadhava maadhava",
        "THAAT": "Marwa",
        "FILE_NAME": "F1181 Pancham Chowtal(Vilambit) Maadhava maadhava.mp3"
      },
      {
        "SL": "F1182",
        "RAAG": "Pancham",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Gokul gaav ke basaiyya",
        "THAAT": "Marwa",
        "FILE_NAME": "F1182 Pancham Dhamar(Vilambit) Gokul gaav ke basaiyya.mp3"
      },
      {
        "SL": "F1183",
        "RAAG": "Bhatiyar",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Nis dinana bisumaran",
        "THAAT": "Marwa",
        "FILE_NAME": "F1183 Bhatiyar Jhaptal(m.laya) Nis dinana bisumaran.mp3"
      },
      {
        "SL": "F1184",
        "RAAG": "Bhatiyar",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Baraneen jaaye",
        "THAAT": "Marwa",
        "FILE_NAME": "F1184 Bhatiyar Tilwada(Vilambit) Baraneen jaaye.mp3"
      },
      {
        "SL": "F1185",
        "RAAG": "Bhatiyar",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Uchat gayee mori neend",
        "THAAT": "Marwa",
        "FILE_NAME": "F1185 Bhatiyar Tilwada(Vilambit) Uchat gayee mori neend.mp3"
      },
      {
        "SL": "F1186",
        "RAAG": "Bhatiyar",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Mero mana haraleeno",
        "THAAT": "Marwa",
        "FILE_NAME": "F1186 Bhatiyar Ektal(Vilambit) Mero mana haraleeno.mp3"
      },
      {
        "SL": "F1187",
        "RAAG": "Bhankar",
        "TAAL": "Roopak(m.laya)",
        "STARTING_WORDS": "Rab guna gaaya re thoo",
        "THAAT": "Marwa",
        "FILE_NAME": "F1187 Bhankar Roopak(m.laya) Rab guna gaayare thoo.mp3"
      },
      {
        "SL": "F1188",
        "RAAG": "Bhankar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Saajan aaye",
        "THAAT": "Marwa",
        "FILE_NAME": "F1188 Bhankar Teental(m.laya) Saajan aaye.mp3"
      },
      {
        "SL": "F1189",
        "RAAG": "Bhankar",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Ye rootmaa hey karee",
        "THAAT": "Marwa",
        "FILE_NAME": "F1189 Bhankar Tilwada(Vilambit) Ye rootmaa hey karee.mp3"
      },
      {
        "SL": "F1190",
        "RAAG": "Saanjgiri",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Yeri aaje aanound",
        "THAAT": "Marwa",
        "FILE_NAME": "F1190 Saanjgiri Ektal(Vilambit) Yeri aaje aanound.mp3"
      },
      {
        "SL": "F1191",
        "RAAG": "Saanjgiri",
        "TAAL": "Adachowtal(Vilambit)",
        "STARTING_WORDS": "Change kaam hoye",
        "THAAT": "Marwa",
        "FILE_NAME": "F1191 Saanjgiri Adachowtal(Vilambit) Change kaam hoye.mp3"
      },
      {
        "SL": "F1192",
        "RAAG": "Lalita-gowri",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Muralivaale saavare",
        "THAAT": "Marwa",
        "FILE_NAME": "F1192 Lalita-gowri Dhamar(Vilambit) Muralivaale saavare.mp3"
      },
      {
        "SL": "G1193",
        "RAAG": "Kaafi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "sa sa re re ga ga ma ma",
        "THAAT": "Kafi",
        "FILE_NAME": "G1193 Kaafi Teental(m.laya) sa sa re re ga ga ma ma.mp3"
      },
      {
        "SL": "G1194",
        "RAAG": "Kaafi",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "ga ga re sa re pa ma pa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1194 Kaafi Jhaptal(m.laya) ga ga re sa re pa ma pa.mp3"
      },
      {
        "SL": "G1195",
        "RAAG": "Kaafi",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Guni maanat Kaafi raag",
        "THAAT": "Kafi",
        "FILE_NAME": "G1195 Kaafi Ektal(m.laya) Guni maanat Kaafi raag.mp3"
      },
      {
        "SL": "G1196",
        "RAAG": "Kaafi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Prabhu teri dayaa hai",
        "THAAT": "Kafi",
        "FILE_NAME": "G1196 Kaafi Teental(m.laya) Prabhu teri dayaa hai.mp3"
      },
      {
        "SL": "G1197",
        "RAAG": "Kaafi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Manare suna puran",
        "THAAT": "Kafi",
        "FILE_NAME": "G1197 Kaafi Teental(m.laya) Manare suna puran.mp3"
      },
      {
        "SL": "G1198",
        "RAAG": "Kaafi",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Kaahe chedo mohe",
        "THAAT": "Kafi",
        "FILE_NAME": "G1198 Kaafi Ektal(m.laya) Kaahe chedo mohe.mp3"
      },
      {
        "SL": "G1199",
        "RAAG": "Kaafi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Jin daro rang man o",
        "THAAT": "Kafi",
        "FILE_NAME": "G1199 Kaafi Teental(m.laya) Jin daro rang mano.mp3"
      },
      {
        "SL": "G1200",
        "RAAG": "Kaafi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kadar piya naiyaa mori",
        "THAAT": "Kafi",
        "FILE_NAME": "G1200 Kaafi Teental(m.laya) Kadar piya naiyaa mori.mp3"
      },
      {
        "SL": "G1201",
        "RAAG": "Kaafi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Chando chando chailaa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1201 Kaafi Teental(m.laya) Chando chando chailaa.mp3"
      },
      {
        "SL": "G1202",
        "RAAG": "Kaafi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Aaj mana basgayee",
        "THAAT": "Kafi",
        "FILE_NAME": "G1202 Kaafi Teental(m.laya) Aaj mana basgayee.mp3"
      },
      {
        "SL": "G1203",
        "RAAG": "Kaafi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Sakhi ab kaiso karat",
        "THAAT": "Kafi",
        "FILE_NAME": "G1203 Kaafi Teental(m.laya) Sakhi ab kaiso karat.mp3"
      },
      {
        "SL": "G1204",
        "RAAG": "Kaafi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Jorey jorey chovo nahi",
        "THAAT": "Kafi",
        "FILE_NAME": "G1204 Kaafi Teental(m.laya) Jorey jorey chovo nahi.mp3"
      },
      {
        "SL": "G1205",
        "RAAG": "Kaafi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Murkaa ke kalaayee",
        "THAAT": "Kafi",
        "FILE_NAME": "G1205 Kaafi Teental(m.laya) Murkaa ke kalaayee.mp3"
      },
      {
        "SL": "G1206",
        "RAAG": "Kaafi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Rahi hai kaise bijuri",
        "THAAT": "Kafi",
        "FILE_NAME": "G1206 Kaafi Teental(m.laya) Rahi hai kaise bijuri.mp3"
      },
      {
        "SL": "G1207",
        "RAAG": "Kaafi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Janedo mohe choro",
        "THAAT": "Kafi",
        "FILE_NAME": "G1207 Kaafi Teental(m.laya) Janedo mohe choro.mp3"
      },
      {
        "SL": "G1208",
        "RAAG": "Kaafi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Mai vaari saiyaa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1208 Kaafi Teental(m.laya) Mai vaari saiyaa.mp3"
      },
      {
        "SL": "G1209",
        "RAAG": "Kaafi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Piya jhumjhum mohe",
        "THAAT": "Kafi",
        "FILE_NAME": "G1209 Kaafi Teental(m.laya) Piya jhumjhum mohe.mp3"
      },
      {
        "SL": "G1210",
        "RAAG": "Kaafi",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Soutan ke sanga lagare",
        "THAAT": "Kafi",
        "FILE_NAME": "G1210 Kaafi Jhaptal(m.laya) Soutan ke sanga lagare.mp3"
      },
      {
        "SL": "G1211",
        "RAAG": "Kaafi",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Aayeree mere dhaam",
        "THAAT": "Kafi",
        "FILE_NAME": "G1211 Kaafi Chowtal(Vilambit) Aayeree mere dhaam.mp3"
      },
      {
        "SL": "G1212",
        "RAAG": "Kaafi",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Jaage mere bhaag",
        "THAAT": "Kafi",
        "FILE_NAME": "G1212 Kaafi Chowtal(Vilambit) Jaage mere bhaag.mp3"
      },
      {
        "SL": "G1213",
        "RAAG": "Kaafi",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Dhay dhay mere garaj",
        "THAAT": "Kafi",
        "FILE_NAME": "G1213 Kaafi Chowtal(Vilambit) Dhay dhay mere garaj.mp3"
      },
      {
        "SL": "G1214",
        "RAAG": "Kaafi",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Aaj morey aaye",
        "THAAT": "Kafi",
        "FILE_NAME": "G1214 Kaafi Chowtal(Vilambit) Aaj morey aaye.mp3"
      },
      {
        "SL": "G1215",
        "RAAG": "Kaafi",
        "TAAL": "Deepchandi(m.laya)",
        "STARTING_WORDS": "Brajame hari hori",
        "THAAT": "Kafi",
        "FILE_NAME": "G1215 Kaafi Deepchandi(m.laya) Brajame hari hori.mp3"
      },
      {
        "SL": "G1216",
        "RAAG": "Kaafi",
        "TAAL": "Deepchandi(m.laya)",
        "STARTING_WORDS": "Abake tek hamaari",
        "THAAT": "Kafi",
        "FILE_NAME": "G1216 Kaafi Deepchandi(m.laya) Abake tek hamaari.mp3"
      },
      {
        "SL": "G1217",
        "RAAG": "Kaafi",
        "TAAL": "Deepchandi(m.laya)",
        "STARTING_WORDS": "Nikaalaa niraalaa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1217 Kaafi Deepchandi(m.laya) Nikaalaa niraalaa.mp3"
      },
      {
        "SL": "G1218",
        "RAAG": "Kaafi",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Yeriye mai koune",
        "THAAT": "Kafi",
        "FILE_NAME": "G1218 Kaafi Dhamar(Vilambit) Yeriye mai koune.mp3"
      },
      {
        "SL": "G1219",
        "RAAG": "Kaafi",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Sundar tere mukhase",
        "THAAT": "Kafi",
        "FILE_NAME": "G1219 Kaafi Dhamar(Vilambit) Sundar tere mukhase.mp3"
      },
      {
        "SL": "G1220",
        "RAAG": "Kaafi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tan derenaa daanni",
        "THAAT": "Kafi",
        "FILE_NAME": "G1220 Kaafi Teental(m.laya) Tan derenaa daanni.mp3"
      },
      {
        "SL": "G1221",
        "RAAG": "Baageshri",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "re sa ni dha ni sa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1221 Baageshri Jhaptal(m.laya) re sa ni dha ni sa.mp3"
      },
      {
        "SL": "G1222",
        "RAAG": "Baageshri",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "ma ga re sa ni sa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1222 Baageshri Ektal(m.laya) ma ga re sa ni sa.mp3"
      },
      {
        "SL": "G1223",
        "RAAG": "Baageshri",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Gaavo baagesari",
        "THAAT": "Kafi",
        "FILE_NAME": "G1223 Baageshri Jhaptal(m.laya) Gaavo baagesari.mp3"
      },
      {
        "SL": "G1224",
        "RAAG": "Baageshri",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Chatur sujan raag kahat",
        "THAAT": "Kafi",
        "FILE_NAME": "G1224 Baageshri Ektal(m.laya) Chatur sujan raag kahat.mp3"
      },
      {
        "SL": "G1225",
        "RAAG": "Baageshri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Nisa baasar hari naam",
        "THAAT": "Kafi",
        "FILE_NAME": "G1225 Baageshri Teental(m.laya) Nisa baasar hari naam.mp3"
      },
      {
        "SL": "G1226",
        "RAAG": "Baageshri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Koun gat bhayeelee",
        "THAAT": "Kafi",
        "FILE_NAME": "G1226 Baageshri Teental(m.laya) Koun gat bhayeelee.mp3"
      },
      {
        "SL": "G1227",
        "RAAG": "Baageshri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Yeho dhaaya dhaaya",
        "THAAT": "Kafi",
        "FILE_NAME": "G1227 Baageshri Teental(m.laya) Yeho dhaaya dhaaya.mp3"
      },
      {
        "SL": "G1228",
        "RAAG": "Baageshri",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Neeke shakhi neeke",
        "THAAT": "Kafi",
        "FILE_NAME": "G1228 Baageshri Ektal(m.laya) Neeke shakhi neeke.mp3"
      },
      {
        "SL": "G1229",
        "RAAG": "Baageshri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Koun karat tor i binati",
        "THAAT": "Kafi",
        "FILE_NAME": "G1229 Baageshri Teental(m.laya) Koun karat tori binati.mp3"
      },
      {
        "SL": "G1230",
        "RAAG": "Baageshri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Moha layire",
        "THAAT": "Kafi",
        "FILE_NAME": "G1230 Baageshri Teental(m.laya) Moha layire.mp3"
      },
      {
        "SL": "G1231",
        "RAAG": "Baageshri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Balma mori tore sanga",
        "THAAT": "Kafi",
        "FILE_NAME": "G1231 Baageshri Teental(m.laya) Balma mori tore sanga.mp3"
      },
      {
        "SL": "G1232",
        "RAAG": "Baageshri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Gunde laavore",
        "THAAT": "Kafi",
        "FILE_NAME": "G1232 Baageshri Teental(m.laya) Gunde laavore.mp3"
      },
      {
        "SL": "G1233",
        "RAAG": "Baageshri",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Kaise kate rajanee",
        "THAAT": "Kafi",
        "FILE_NAME": "G1233 Baageshri Tilwada(Vilambit) Kaise kate rajanee.mp3"
      },
      {
        "SL": "G1234",
        "RAAG": "Baageshri",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Mohe manavan aayeho",
        "THAAT": "Kafi",
        "FILE_NAME": "G1234 Baageshri Ektal(Vilambit) Mohe manavan aayeho.mp3"
      },
      {
        "SL": "G1235",
        "RAAG": "Baageshri",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Maan manaave mere",
        "THAAT": "Kafi",
        "FILE_NAME": "G1235 Baageshri Ektal(Vilambit) Maan manaave mere.mp3"
      },
      {
        "SL": "G1236",
        "RAAG": "Baageshri",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Bahoo guna kaam",
        "THAAT": "Kafi",
        "FILE_NAME": "G1236 Baageshri Ektal(Vilambit) Bahoo guna kaam.mp3"
      },
      {
        "SL": "G1237",
        "RAAG": "Baageshri",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Sakhi mana laage",
        "THAAT": "Kafi",
        "FILE_NAME": "G1237 Baageshri Ektal(Vilambit) Sakhi mana laage.mp3"
      },
      {
        "SL": "G1238",
        "RAAG": "Baageshri",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Mori aali aba rangavaa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1238 Baageshri Tilwada(Vilambit) Mori aali aba rangavaa.mp3"
      },
      {
        "SL": "G1239",
        "RAAG": "Baageshri",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Gorey gorey mukha par",
        "THAAT": "Kafi",
        "FILE_NAME": "G1239 Baageshri Ektal(Vilambit) Gorey gorey mukha par.mp3"
      },
      {
        "SL": "G1240",
        "RAAG": "Baageshri",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Beliyarave miyaa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1240 Baageshri Tilwada(Vilambit) Beliyarave miyaa.mp3"
      },
      {
        "SL": "G1241",
        "RAAG": "Baageshri",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Peet lagali morey",
        "THAAT": "Kafi",
        "FILE_NAME": "G1241 Baageshri Tilwada(Vilambit) Peet lagali morey.mp3"
      },
      {
        "SL": "G1242",
        "RAAG": "Baageshri",
        "TAAL": "Adachowtal(Vilambit)",
        "STARTING_WORDS": "Baraj raheere",
        "THAAT": "Kafi",
        "FILE_NAME": "G1242 Baageshri Adachowtal(Vilambit) Baraj raheere.mp3"
      },
      {
        "SL": "G1243",
        "RAAG": "Baageshri",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Binatee suno mori",
        "THAAT": "Kafi",
        "FILE_NAME": "G1243 Baageshri Jhaptal(m.laya) Binatee suno mori.mp3"
      },
      {
        "SL": "G1244",
        "RAAG": "Baageshri",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Dhan aaj doolo",
        "THAAT": "Kafi",
        "FILE_NAME": "G1244 Baageshri Jhaptal(m.laya) Dhan aaj doolo.mp3"
      },
      {
        "SL": "G1245",
        "RAAG": "Baageshri",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Laal manaaye",
        "THAAT": "Kafi",
        "FILE_NAME": "G1245 Baageshri Jhaptal(m.laya) Laal manaaye.mp3"
      },
      {
        "SL": "G1246",
        "RAAG": "Baageshri",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Paarvati naath shiv",
        "THAAT": "Kafi",
        "FILE_NAME": "G1246 Baageshri Chowtal(Vilambit) Paarvati naath shiv.mp3"
      },
      {
        "SL": "G1247",
        "RAAG": "Baageshri",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Kaal so na balbounth",
        "THAAT": "Kafi",
        "FILE_NAME": "G1247 Baageshri Chowtal(Vilambit) Kaal so na balbounth.mp3"
      },
      {
        "SL": "G1248",
        "RAAG": "Baageshri",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Pooja re ganeesako",
        "THAAT": "Kafi",
        "FILE_NAME": "G1248 Baageshri Chowtal(Vilambit) Poojare ganeesako.mp3"
      },
      {
        "SL": "G1249",
        "RAAG": "Baageshri",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Aaye raghubeer dheer",
        "THAAT": "Kafi",
        "FILE_NAME": "G1249 Baageshri Chowtal(Vilambit) Aaye raghubeer dheer.mp3"
      },
      {
        "SL": "G1250",
        "RAAG": "Baageshri",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Naar chali aavat jhukat",
        "THAAT": "Kafi",
        "FILE_NAME": "G1250 Baageshri Dhamar(Vilambit) Naar chali aavat jhukat.mp3"
      },
      {
        "SL": "G1251",
        "RAAG": "Baageshri",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Ina giridhar mero mana",
        "THAAT": "Kafi",
        "FILE_NAME": "G1251 Baageshri Dhamar(Vilambit) Ina giridhar mero mana.mp3"
      },
      {
        "SL": "G1252",
        "RAAG": "Baageshri",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Aayo phaagun maas",
        "THAAT": "Kafi",
        "FILE_NAME": "G1252 Baageshri Dhamar(Vilambit) Aayo phaagun maas.mp3"
      },
      {
        "SL": "G1253",
        "RAAG": "Baageshri",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Tanana tan derenaa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1253 Baageshri Ektal(m.laya) Tanana tan derenaa.mp3"
      },
      {
        "SL": "G1254",
        "RAAG": "Baageshri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Udatan derenaa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1254 Baageshri Teental(m.laya) Udatan derenaa.mp3"
      },
      {
        "SL": "G1255",
        "RAAG": "Baageshri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Udanita daani tom",
        "THAAT": "Kafi",
        "FILE_NAME": "G1255 Baageshri Teental(m.laya) Udanita daani tom.mp3"
      },
      {
        "SL": "G1256",
        "RAAG": "Baageshri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Telelana tananat diya",
        "THAAT": "Kafi",
        "FILE_NAME": "G1256 Baageshri Teental(m.laya) Telelana tananat diya.mp3"
      },
      {
        "SL": "G1257",
        "RAAG": "Baageshri",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Tana dere naaa tadaare",
        "THAAT": "Kafi",
        "FILE_NAME": "G1257 Baageshri Tilwada(Vilambit) Tana dere naaa tadaare.mp3"
      },
      {
        "SL": "G1258",
        "RAAG": "Brindabani Sarang",
        "TAAL": "Roopak(m.laya)",
        "STARTING_WORDS": "ni ni pa ma re re sa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1258 Brindabani Sarang Roopak(m.laya) ni ni pa ma re re sa.mp3"
      },
      {
        "SL": "G1259",
        "RAAG": "Brindabani Sarang",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Ka rat harpriya mela",
        "THAAT": "Kafi",
        "FILE_NAME": "G1259 Brindabani Sarang Jhaptal(m.laya) Karat harpriya mela.mp3"
      },
      {
        "SL": "G1260",
        "RAAG": "Brindabani Sarang",
        "TAAL": "Sulfaq(m.laya)",
        "STARTING_WORDS": "Sarang raagena",
        "THAAT": "Kafi",
        "FILE_NAME": "G1260 Brindabani Sarang Sulfaq(m.laya) Sarang raagena.mp3"
      },
      {
        "SL": "G1261",
        "RAAG": "Brindabani Sarang",
        "TAAL": "Brahmatal(m.laya)",
        "STARTING_WORDS": "Gaavat brahmataal",
        "THAAT": "Kafi",
        "FILE_NAME": "G1261 Brindabani Sarang Brahmatal(m.laya) Gaavat brahmataal.mp3"
      },
      {
        "SL": "G1262",
        "RAAG": "Brindabani Sarang",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ratakar rasana",
        "THAAT": "Kafi",
        "FILE_NAME": "G1262 Brindabani Sarang Teental(m.laya) Ratakar rasana.mp3"
      },
      {
        "SL": "G1263",
        "RAAG": "Brindabani Sarang",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Mil baito morey saiyaa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1263 Brindabani Sarang Teental(m.laya) Mil baito morey saiy.mp3"
      },
      {
        "SL": "G1264",
        "RAAG": "Brindabani Sarang",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Mori guyyaa nazariyaa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1264 Brindabani Sarang Teental(m.laya) Mori guyyaa nazariya.mp3"
      },
      {
        "SL": "G1265",
        "RAAG": "Brindabani Sarang",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Mai hoo balbal",
        "THAAT": "Kafi",
        "FILE_NAME": "G1265 Brindabani Sarang Teental(m.laya) Mai hoo balbal.mp3"
      },
      {
        "SL": "G1266",
        "RAAG": "Brindabani Sarang",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Naa bolo shyaam",
        "THAAT": "Kafi",
        "FILE_NAME": "G1266 Brindabani Sarang Teental(m.laya) Naa bolo shyaam.mp3"
      },
      {
        "SL": "G1267",
        "RAAG": "Brindabani Sarang",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Yerima jaan mai kayi",
        "THAAT": "Kafi",
        "FILE_NAME": "G1267 Brindabani Sarang Teental(m.laya) Yerima jaan mai kayi.mp3"
      },
      {
        "SL": "G1268",
        "RAAG": "Brindabani Sarang",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Aaj mori baiyyaa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1268 Brindabani Sarang Teental(m.laya) Aaj mori baiyyaa.mp3"
      },
      {
        "SL": "G1269",
        "RAAG": "Brindabani Sarang",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ghungarava mora baje",
        "THAAT": "Kafi",
        "FILE_NAME": "G1269 Brindabani Sarang Teental(m.laya) Ghungarava mora baje.mp3"
      },
      {
        "SL": "G1270",
        "RAAG": "Brindabani Sarang",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Banban dhundan javu",
        "THAAT": "Kafi",
        "FILE_NAME": "G1270 Brindabani Sarang Teental(m.laya) Banban dhundan javu.mp3"
      },
      {
        "SL": "G1271",
        "RAAG": "Brindabani Sarang",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Piyaa bides gavanavaa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1271 Brindabani Sarang Teental(m.laya) Piyaa bides gavanava.mp3"
      },
      {
        "SL": "G1272",
        "RAAG": "Brindabani Sarang",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Aache peer morey",
        "THAAT": "Kafi",
        "FILE_NAME": "G1272 Brindabani Sarang Teental(m.laya) Aache peer morey.mp3"
      },
      {
        "SL": "G1273",
        "RAAG": "Brindabani Sarang",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Magavaro",
        "THAAT": "Kafi",
        "FILE_NAME": "G1273 Brindabani Sarang Teental(m.laya) Magavaro.mp3"
      },
      {
        "SL": "G1274",
        "RAAG": "Brindabani Sarang",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Chailva kaahoose",
        "THAAT": "Kafi",
        "FILE_NAME": "G1274 Brindabani Sarang Teental(m.laya) Chailva kaahoose.mp3"
      },
      {
        "SL": "G1275",
        "RAAG": "Brindabani Sarang",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Aaj badhavara",
        "THAAT": "Kafi",
        "FILE_NAME": "G1275 Brindabani Sarang Tilwada(Vilambit) Aaj badhavara.mp3"
      },
      {
        "SL": "G1276",
        "RAAG": "Brindabani Sarang",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Borey jin allaha",
        "THAAT": "Kafi",
        "FILE_NAME": "G1276 Brindabani Sarang Tilwada(Vilambit) Borey jin allaha.mp3"
      },
      {
        "SL": "G1277",
        "RAAG": "Brindabani Sarang",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Naad muralidhar",
        "THAAT": "Kafi",
        "FILE_NAME": "G1277 Brindabani Sarang Sultal(m.laya) Naad muralidhar.mp3"
      },
      {
        "SL": "G1278",
        "RAAG": "Brindabani Sarang",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Tum rab tum saheb",
        "THAAT": "Kafi",
        "FILE_NAME": "G1278 Brindabani Sarang Sultal(m.laya) Tum rab tum saheb.mp3"
      },
      {
        "SL": "G1279",
        "RAAG": "Brindabani Sarang",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Deen aoura duniya",
        "THAAT": "Kafi",
        "FILE_NAME": "G1279 Brindabani Sarang Jhaptal(m.laya) Deen aoura duniya.mp3"
      },
      {
        "SL": "G1280",
        "RAAG": "Brindabani Sarang",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Bhookhan bhujang ghor",
        "THAAT": "Kafi",
        "FILE_NAME": "G1280 Brindabani Sarang Jhaptal(m.laya) Bhookhan bhujang gho.mp3"
      },
      {
        "SL": "G1281",
        "RAAG": "Brindabani Sarang",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Haa peer mori",
        "THAAT": "Kafi",
        "FILE_NAME": "G1281 Brindabani Sarang Jhaptal(m.laya) Haa peer mori.mp3"
      },
      {
        "SL": "G1282",
        "RAAG": "Brindabani Sarang",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Dahan laagyo chandra",
        "THAAT": "Kafi",
        "FILE_NAME": "G1282 Brindabani Sarang Chowtal(Vilambit) Dahan laagyo chand.mp3"
      },
      {
        "SL": "G1283",
        "RAAG": "Brindabani Sarang",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Too tho kachu bhoomi",
        "THAAT": "Kafi",
        "FILE_NAME": "G1283 Brindabani Sarang Chowtal(Vilambit) Too tho kachu bhoo.mp3"
      },
      {
        "SL": "G1284",
        "RAAG": "Brindabani Sarang",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Soor aayo sees par",
        "THAAT": "Kafi",
        "FILE_NAME": "G1284 Brindabani Sarang Chowtal(Vilambit) Soor aayo sees par.mp3"
      },
      {
        "SL": "G1285",
        "RAAG": "Brindabani Sarang",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Saras sughoundh",
        "THAAT": "Kafi",
        "FILE_NAME": "G1285 Brindabani Sarang Chowtal(Vilambit) Saras sughoundh.mp3"
      },
      {
        "SL": "G1286",
        "RAAG": "Brindabani Sarang",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Yaa ruth greekhmath",
        "THAAT": "Kafi",
        "FILE_NAME": "G1286 Brindabani Sarang Chowtal(Vilambit) Yaa ruth greekhmat.mp3"
      },
      {
        "SL": "G1287",
        "RAAG": "Brindabani Sarang",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Dhan dhan briindaban",
        "THAAT": "Kafi",
        "FILE_NAME": "G1287 Brindabani Sarang Chowtal(Vilambit) Dhan dhan briindab.mp3"
      },
      {
        "SL": "G1288",
        "RAAG": "Brindabani Sarang",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Gounga ka ali deesar",
        "THAAT": "Kafi",
        "FILE_NAME": "G1288 Brindabani Sarang Chowtal(Vilambit) Gounga kaali deesa.mp3"
      },
      {
        "SL": "G1289",
        "RAAG": "Brindabani Sarang",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Raat samey ras khel",
        "THAAT": "Kafi",
        "FILE_NAME": "G1289 Brindabani Sarang Chowtal(Vilambit) Raat samey ras khe.mp3"
      },
      {
        "SL": "G1290",
        "RAAG": "Brindabani Sarang",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Brijame dhoom machi",
        "THAAT": "Kafi",
        "FILE_NAME": "G1290 Brindabani Sarang Dhamar(Vilambit) Brijame dhoom machi.mp3"
      },
      {
        "SL": "G1291",
        "RAAG": "Brindabani Sarang",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Lal mori gail chaand",
        "THAAT": "Kafi",
        "FILE_NAME": "G1291 Brindabani Sarang Dhamar(Vilambit) Lal mori gail chaan.mp3"
      },
      {
        "SL": "G1292",
        "RAAG": "Brindabani Sarang",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Aba tum lal",
        "THAAT": "Kafi",
        "FILE_NAME": "G1292 Brindabani Sarang Dhamar(Vilambit) Aba tum lal.mp3"
      },
      {
        "SL": "G1293",
        "RAAG": "Brindabani Sarang",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Daani odatananana",
        "THAAT": "Kafi",
        "FILE_NAME": "G1293 Brindabani Sarang Teental(m.laya) Daani odatananana.mp3"
      },
      {
        "SL": "G1294",
        "RAAG": "Brindabani Sarang",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Deem daraa dhetel e",
        "THAAT": "Kafi",
        "FILE_NAME": "G1294 Brindabani Sarang Teental(m.laya) Deem daraa dhetele.mp3"
      },
      {
        "SL": "G1295",
        "RAAG": "Brindabani Sarang",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tadaani dirdir tom",
        "THAAT": "Kafi",
        "FILE_NAME": "G1295 Brindabani Sarang Teental(m.laya) Tadaani dirdir tom.mp3"
      },
      {
        "SL": "G1296",
        "RAAG": "Brindabani Sarang",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Daran tadarnath deem",
        "THAAT": "Kafi",
        "FILE_NAME": "G1296 Brindabani Sarang Teental(m.laya) Daran tadarnath deem.mp3"
      },
      {
        "SL": "G1297",
        "RAAG": "Brindabani Sarang",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Nadir daani tom tom",
        "THAAT": "Kafi",
        "FILE_NAME": "G1297 Brindabani Sarang Tilwada(Vilambit) Nadir daani tom to.mp3"
      },
      {
        "SL": "G1298",
        "RAAG": "Bheempalasi",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "ni sa ma ga re sa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1298 Bheempalasi Jhaptal(m.laya) ni sa ma ga re sa.mp3"
      },
      {
        "SL": "G1299",
        "RAAG": "Bheempalasi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Melan harapriya",
        "THAAT": "Kafi",
        "FILE_NAME": "G1299 Bheempalasi Teental(m.laya) Melan harapriya.mp3"
      },
      {
        "SL": "G1300",
        "RAAG": "Bheempalasi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Maanat sab bhimpalasi",
        "THAAT": "Kafi",
        "FILE_NAME": "G1300 Bheempalasi Teental(m.laya) Maanat sab bhimpalasi.mp3"
      },
      {
        "SL": "G1301",
        "RAAG": "Bheempalasi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Dhand han bheempalasi",
        "THAAT": "Kafi",
        "FILE_NAME": "G1301 Bheempalasi Teental(m.laya) Dhandhan bheempalasi.mp3"
      },
      {
        "SL": "G1302",
        "RAAG": "Bheempalasi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Mo man bhaayeri maa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1302 Bheempalasi Teental(m.laya) Mo man bhaayeri maa.mp3"
      },
      {
        "SL": "G1303",
        "RAAG": "Bheempalasi",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Kanahaiyaa tore paraho",
        "THAAT": "Kafi",
        "FILE_NAME": "G1303 Bheempalasi Ektal(m.laya) Kanahaiyaa tore paraho.mp3"
      },
      {
        "SL": "G1304",
        "RAAG": "Bheempalasi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Mai vaariyavo mainu",
        "THAAT": "Kafi",
        "FILE_NAME": "G1304 Bheempalasi Teental(m.laya) Mai vaariyavo mainu.mp3"
      },
      {
        "SL": "G1305",
        "RAAG": "Bheempalasi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Jaa jaare apaney",
        "THAAT": "Kafi",
        "FILE_NAME": "G1305 Bheempalasi Teental(m.laya) Jaa jaare apaney.mp3"
      },
      {
        "SL": "G1306",
        "RAAG": "Bheempalasi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Garava harava darugi",
        "THAAT": "Kafi",
        "FILE_NAME": "G1306 Bheempalasi Teental(m.laya) Garava harava darugi.mp3"
      },
      {
        "SL": "G1307",
        "RAAG": "Bheempalasi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Balama taragayiyya",
        "THAAT": "Kafi",
        "FILE_NAME": "G1307 Bheempalasi Teental(m.laya) Balama taragayiyya.mp3"
      },
      {
        "SL": "G1308",
        "RAAG": "Bheempalasi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kahe sataavo",
        "THAAT": "Kafi",
        "FILE_NAME": "G1308 Bheempalasi Teental(m.laya) Kahe sataavo.mp3"
      },
      {
        "SL": "G1309",
        "RAAG": "Bheempalasi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ye kahaa jaane",
        "THAAT": "Kafi",
        "FILE_NAME": "G1309 Bheempalasi Teental(m.laya) Ye kahaa jaane.mp3"
      },
      {
        "SL": "G1310",
        "RAAG": "Bheempalasi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Gorey mukhaso morey",
        "THAAT": "Kafi",
        "FILE_NAME": "G1310 Bheempalasi Teental(m.laya) Gorey mukhaso morey.mp3"
      },
      {
        "SL": "G1311",
        "RAAG": "Bheempalasi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tendere naal",
        "THAAT": "Kafi",
        "FILE_NAME": "G1311 Bheempalasi Teental(m.laya) Tendere naal.mp3"
      },
      {
        "SL": "G1312",
        "RAAG": "Bheempalasi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Dolan mende ghar",
        "THAAT": "Kafi",
        "FILE_NAME": "G1312 Bheempalasi Teental(m.laya) Dolan mende ghar.mp3"
      },
      {
        "SL": "G1313",
        "RAAG": "Bheempalasi",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Kaise dheer dharoo",
        "THAAT": "Kafi",
        "FILE_NAME": "G1313 Bheempalasi Tilwada(Vilambit) Kaise dheer dharoo.mp3"
      },
      {
        "SL": "G1314",
        "RAAG": "Bheempalasi",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Piya mosey kahen boley",
        "THAAT": "Kafi",
        "FILE_NAME": "G1314 Bheempalasi Tilwada(Vilambit) Piya mosey kahen boley.mp3"
      },
      {
        "SL": "G1315",
        "RAAG": "Bheempalasi",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Lok chavaava karo",
        "THAAT": "Kafi",
        "FILE_NAME": "G1315 Bheempalasi Tilwada(Vilambit) Lok chavaava karo.mp3"
      },
      {
        "SL": "G1316",
        "RAAG": "Bheempalasi",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Varive gumaan kariye",
        "THAAT": "Kafi",
        "FILE_NAME": "G1316 Bheempalasi Ektal(Vilambit) Varive gumaan kariye.mp3"
      },
      {
        "SL": "G1317",
        "RAAG": "Bheempalasi",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Abatho badiber bhayi",
        "THAAT": "Kafi",
        "FILE_NAME": "G1317 Bheempalasi Tilwada(Vilambit) Abatho badiber bhayi.mp3"
      },
      {
        "SL": "G1318",
        "RAAG": "Bheempalasi",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Abtho sunale",
        "THAAT": "Kafi",
        "FILE_NAME": "G1318 Bheempalasi Tilwada(Vilambit) Abtho sunale.mp3"
      },
      {
        "SL": "G1319",
        "RAAG": "Bheempalasi",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Abtho sunale-2nd version",
        "THAAT": "Kafi",
        "FILE_NAME": "G1319 Bheempalasi Ektal(Vilambit) Abtho sunale-2nd version.mp3"
      },
      {
        "SL": "G1320",
        "RAAG": "Bheempalasi",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Ye jai jai ka r poojo",
        "THAAT": "Kafi",
        "FILE_NAME": "G1320 Bheempalasi Ektal(Vilambit) Ye jai jai kar poojo.mp3"
      },
      {
        "SL": "G1321",
        "RAAG": "Bheempalasi",
        "TAAL": "Roopak(m.laya)",
        "STARTING_WORDS": "Piyaa prem ras chake",
        "THAAT": "Kafi",
        "FILE_NAME": "G1321 Bheempalasi Roopak(m.laya) Piyaa prem ras chake.mp3"
      },
      {
        "SL": "G1322",
        "RAAG": "Bheempalasi",
        "TAAL": "Roopak(m.laya)",
        "STARTING_WORDS": "Sajana mendaa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1322 Bheempalasi Roopak(m.laya) Sajana mendaa.mp3"
      },
      {
        "SL": "G1323",
        "RAAG": "Bheempalasi",
        "TAAL": "Roopak(m.laya)",
        "STARTING_WORDS": "Paavat kovu naahi",
        "THAAT": "Kafi",
        "FILE_NAME": "G1323 Bheempalasi Roopak(m.laya) Paavat kovu naahi.mp3"
      },
      {
        "SL": "G1324",
        "RAAG": "Bheempalasi",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Atahi sughar laal",
        "THAAT": "Kafi",
        "FILE_NAME": "G1324 Bheempalasi Sultal(m.laya) Atahi sughar laal.mp3"
      },
      {
        "SL": "G1325",
        "RAAG": "Bheempalasi",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Naad sagar aprampaar",
        "THAAT": "Kafi",
        "FILE_NAME": "G1325 Bheempalasi Chowtal(Vilambit) Naad sagar aprampaar.mp3"
      },
      {
        "SL": "G1326",
        "RAAG": "Bheempalasi",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Raag saagar apaar",
        "THAAT": "Kafi",
        "FILE_NAME": "G1326 Bheempalasi Chowtal(Vilambit) Raag saagar apaar.mp3"
      },
      {
        "SL": "G1327",
        "RAAG": "Bheempalasi",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Gorey gorey angako",
        "THAAT": "Kafi",
        "FILE_NAME": "G1327 Bheempalasi Chowtal(Vilambit) Gorey gorey angako.mp3"
      },
      {
        "SL": "G1328",
        "RAAG": "Bheempalasi",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Dharan muran taan taal",
        "THAAT": "Kafi",
        "FILE_NAME": "G1328 Bheempalasi Chowtal(Vilambit) Dharan muran taan taal.mp3"
      },
      {
        "SL": "G1329",
        "RAAG": "Bheempalasi",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Tan nagar hira deth",
        "THAAT": "Kafi",
        "FILE_NAME": "G1329 Bheempalasi Chowtal(Vilambit) Tan nagar hira deth.mp3"
      },
      {
        "SL": "G1330",
        "RAAG": "Bheempalasi",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Jaake mriga chaalaa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1330 Bheempalasi Chowtal(Vilambit) Jaake mriga chaalaa.mp3"
      },
      {
        "SL": "G1331",
        "RAAG": "Bheempalasi",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Yeri mana lee gayo",
        "THAAT": "Kafi",
        "FILE_NAME": "G1331 Bheempalasi Dhamar(Vilambit) Yeri mana lee gayo.mp3"
      },
      {
        "SL": "G1332",
        "RAAG": "Bheempalasi",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Kavan guna koun",
        "THAAT": "Kafi",
        "FILE_NAME": "G1332 Bheempalasi Dhamar(Vilambit) Kavan guna koun.mp3"
      },
      {
        "SL": "G1333",
        "RAAG": "Bheempalasi",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Aho mukha mounde",
        "THAAT": "Kafi",
        "FILE_NAME": "G1333 Bheempalasi Dhamar(Vilambit) Aho mukha mounde.mp3"
      },
      {
        "SL": "G1334",
        "RAAG": "Bheempalasi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Na dir deem tanana",
        "THAAT": "Kafi",
        "FILE_NAME": "G1334 Bheempalasi Teental(m.laya) Na dir deem tanana.mp3"
      },
      {
        "SL": "G1335",
        "RAAG": "Piloo",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "sa ga re ga re sa re",
        "THAAT": "Kafi",
        "FILE_NAME": "G1335 Piloo Teental(m.laya) sa ga re ga re sa re.mp3"
      },
      {
        "SL": "G1336",
        "RAAG": "Piloo",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ajab thaat sakhi piloo",
        "THAAT": "Kafi",
        "FILE_NAME": "G1336 Piloo Teental(m.laya) Ajab thaat sakhi piloo.mp3"
      },
      {
        "SL": "G1337",
        "RAAG": "Piloo",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Sakhi tore piluki chamak",
        "THAAT": "Kafi",
        "FILE_NAME": "G1337 Piloo Teental(m.laya) Sakhi tore piluki chamak.mp3"
      },
      {
        "SL": "G1338",
        "RAAG": "Piloo",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Hamare tirath koun kare",
        "THAAT": "Kafi",
        "FILE_NAME": "G1338 Piloo Teental(m.laya) Hamare tirath koun kare.mp3"
      },
      {
        "SL": "G1339",
        "RAAG": "Piloo",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Nith utha sumaran",
        "THAAT": "Kafi",
        "FILE_NAME": "G1339 Piloo Teental(m.laya) Nith utha sumaran.mp3"
      },
      {
        "SL": "G1340",
        "RAAG": "Piloo",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Bhajman tribhuvannaath",
        "THAAT": "Kafi",
        "FILE_NAME": "G1340 Piloo Teental(m.laya) Bhajman tribhuvannaath.mp3"
      },
      {
        "SL": "G1341",
        "RAAG": "Piloo",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Shyam tori ajab rangile",
        "THAAT": "Kafi",
        "FILE_NAME": "G1341 Piloo Teental(m.laya) Shyam tori ajab rangile.mp3"
      },
      {
        "SL": "G1342",
        "RAAG": "Piloo",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Pyari lala toreri adheen",
        "THAAT": "Kafi",
        "FILE_NAME": "G1342 Piloo Teental(m.laya) Pyari lala toreri adheen.mp3"
      },
      {
        "SL": "G1343",
        "RAAG": "Piloo",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Mori aali piyake daras",
        "THAAT": "Kafi",
        "FILE_NAME": "G1343 Piloo Teental(m.laya) Mori aali piyake daras.mp3"
      },
      {
        "SL": "G1344",
        "RAAG": "Piloo",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Piyabina jiyaa moraa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1344 Piloo Teental(m.laya) Piyabina jiyaa moraa.mp3"
      },
      {
        "SL": "G1345",
        "RAAG": "Piloo",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Thaari balihaaree",
        "THAAT": "Kafi",
        "FILE_NAME": "G1345 Piloo Ektal(m.laya) Thaari balihaaree.mp3"
      },
      {
        "SL": "G1346",
        "RAAG": "Piloo",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Pa pihare pee ki bolina",
        "THAAT": "Kafi",
        "FILE_NAME": "G1346 Piloo Teental(m.laya) Papihare pee ki bolina.mp3"
      },
      {
        "SL": "G1347",
        "RAAG": "Piloo",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kadar pyare laage",
        "THAAT": "Kafi",
        "FILE_NAME": "G1347 Piloo Teental(m.laya) Kadar pyare laage.mp3"
      },
      {
        "SL": "G1348",
        "RAAG": "Piloo",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Arey mohanare badiko",
        "THAAT": "Kafi",
        "FILE_NAME": "G1348 Piloo Teental(m.laya) Arey mohanare badiko.mp3"
      },
      {
        "SL": "G1349",
        "RAAG": "Piloo",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kaleja vaare baasuri",
        "THAAT": "Kafi",
        "FILE_NAME": "G1349 Piloo Teental(m.laya) Kaleja vaare baasuri.mp3"
      },
      {
        "SL": "G1350",
        "RAAG": "Piloo",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Mori akhiyone bairi kiya",
        "THAAT": "Kafi",
        "FILE_NAME": "G1350 Piloo Teental(m.laya) Mori akhiyone bairi kiya.mp3"
      },
      {
        "SL": "G1351",
        "RAAG": "Piloo",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Mori akhiyaa doobarhee",
        "THAAT": "Kafi",
        "FILE_NAME": "G1351 Piloo Teental(m.laya) Mori akhiyaa doobarhee.mp3"
      },
      {
        "SL": "G1352",
        "RAAG": "Piloo",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Tumahi basath ho",
        "THAAT": "Kafi",
        "FILE_NAME": "G1352 Piloo Jhaptal(m.laya) Tumahi basath ho.mp3"
      },
      {
        "SL": "G1353",
        "RAAG": "Piloo",
        "TAAL": "Roopak(m.laya)",
        "STARTING_WORDS": "Laagarhi yeri ye saavan",
        "THAAT": "Kafi",
        "FILE_NAME": "G1353 Piloo Roopak(m.laya) Laagarhi yeri ye saavan.mp3"
      },
      {
        "SL": "G1354",
        "RAAG": "Piloo",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Aaj shyaam dhun",
        "THAAT": "Kafi",
        "FILE_NAME": "G1354 Piloo Chowtal(Vilambit) Aaj shyaam dhun.mp3"
      },
      {
        "SL": "G1355",
        "RAAG": "Piloo",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Haus swetha",
        "THAAT": "Kafi",
        "FILE_NAME": "G1355 Piloo Chowtal(Vilambit) Haus swetha.mp3"
      },
      {
        "SL": "G1356",
        "RAAG": "Piloo",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Tum tarasavath",
        "THAAT": "Kafi",
        "FILE_NAME": "G1356 Piloo Chowtal(Vilambit) Tum tarasavath.mp3"
      },
      {
        "SL": "G1357",
        "RAAG": "Piloo",
        "TAAL": "Deepchandi(m.laya)",
        "STARTING_WORDS": "Soch samajh nadaan",
        "THAAT": "Kafi",
        "FILE_NAME": "G1357 Piloo Deepchandi(m.laya) Soch samajh nadaan.mp3"
      },
      {
        "SL": "G1358",
        "RAAG": "Piloo",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Aayo phaagun maas",
        "THAAT": "Kafi",
        "FILE_NAME": "G1358 Piloo Dhamar(Vilambit) Aayo phaagun maas.mp3"
      },
      {
        "SL": "G1359",
        "RAAG": "Piloo",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Aiso rang daaro",
        "THAAT": "Kafi",
        "FILE_NAME": "G1359 Piloo Dhamar(Vilambit) Aiso rang daaro.mp3"
      },
      {
        "SL": "G1360",
        "RAAG": "Piloo",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Birjame udath hai gulaal",
        "THAAT": "Kafi",
        "FILE_NAME": "G1360 Piloo Dhamar(Vilambit) Birjame udath hai gulaal.mp3"
      },
      {
        "SL": "G1361",
        "RAAG": "Miya malhar",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "re sa ni dha ma pa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1361 Miya malhar Ektal(m.laya) re sa ni dha ma pa.mp3"
      },
      {
        "SL": "G1362",
        "RAAG": "Miya malhar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Gaavat raag malaar",
        "THAAT": "Kafi",
        "FILE_NAME": "G1362 Miya malhar Teental(m.laya) Gaavat raag malaar.mp3"
      },
      {
        "SL": "G1363",
        "RAAG": "Miya malhar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Bijari chamake barase",
        "THAAT": "Kafi",
        "FILE_NAME": "G1363 Miya malhar Teental(m.laya) Bijari chamake barase.mp3"
      },
      {
        "SL": "G1364",
        "RAAG": "Miya malhar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Bolare papaiyaraa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1364 Miya malhar Teental(m.laya) Bolare papaiyaraa.mp3"
      },
      {
        "SL": "G1365",
        "RAAG": "Miya malhar",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Mori boo ndariyaa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1365 Miya malhar Ektal(m.laya) Mori boondariyaa.mp3"
      },
      {
        "SL": "G1366",
        "RAAG": "Miya malhar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Bouchaaran barase",
        "THAAT": "Kafi",
        "FILE_NAME": "G1366 Miya malhar Teental(m.laya) Bouchaaran barase.mp3"
      },
      {
        "SL": "G1367",
        "RAAG": "Miya malhar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Barsan laagire badari",
        "THAAT": "Kafi",
        "FILE_NAME": "G1367 Miya malhar Teental(m.laya) Barsan laagire badari.mp3"
      },
      {
        "SL": "G1368",
        "RAAG": "Miya malhar",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Mammadsa rangeelare",
        "THAAT": "Kafi",
        "FILE_NAME": "G1368 Miya malhar Ektal(m.laya) Mammadsa rangeelare.mp3"
      },
      {
        "SL": "G1369",
        "RAAG": "Miya malhar",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Aayee samadhan mori",
        "THAAT": "Kafi",
        "FILE_NAME": "G1369 Miya malhar Ektal(m.laya) Aayee samadhan mori.mp3"
      },
      {
        "SL": "G1370",
        "RAAG": "Miya malhar",
        "TAAL": "Adachowtal(m.laya)",
        "STARTING_WORDS": "Kahe laadalee laad",
        "THAAT": "Kafi",
        "FILE_NAME": "G1370 Miya malhar Adachowtal(m.laya) Kahe laadalee laad.mp3"
      },
      {
        "SL": "G1371",
        "RAAG": "Miya malhar",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Kareema naam thero",
        "THAAT": "Kafi",
        "FILE_NAME": "G1371 Miya malhar Ektal(Vilambit) Kareema naam thero.mp3"
      },
      {
        "SL": "G1372",
        "RAAG": "Miya malhar",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Boondan bheeje",
        "THAAT": "Kafi",
        "FILE_NAME": "G1372 Miya malhar Ektal(Vilambit) Boondan bheeje.mp3"
      },
      {
        "SL": "G1373",
        "RAAG": "Miya malhar",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Wey ath dhoom",
        "THAAT": "Kafi",
        "FILE_NAME": "G1373 Miya malhar Ektal(Vilambit) Wey ath dhoom.mp3"
      },
      {
        "SL": "G1374",
        "RAAG": "Miya malhar",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Dhunghaja so aayaa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1374 Miya malhar Tilwada(Vilambit) Dhunghaja so aayaa.mp3"
      },
      {
        "SL": "G1375",
        "RAAG": "Miya malhar",
        "TAAL": "Jhumra(Vilambit)",
        "STARTING_WORDS": "Baajat tata bitata",
        "THAAT": "Kafi",
        "FILE_NAME": "G1375 Miya malhar Jhumra(Vilambit) Baajat tata bitata.mp3"
      },
      {
        "SL": "G1376",
        "RAAG": "Miya malhar",
        "TAAL": "Jhumra(Vilambit)",
        "STARTING_WORDS": "Saavan kahi aagam",
        "THAAT": "Kafi",
        "FILE_NAME": "G1376 Miya malhar Jhumra(Vilambit) Saavan kahi aagam.mp3"
      },
      {
        "SL": "G1377",
        "RAAG": "Miya malhar",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Aayo hai megha nayee",
        "THAAT": "Kafi",
        "FILE_NAME": "G1377 Miya malhar Jhaptal(m.laya) Aayo hai megha nayee.mp3"
      },
      {
        "SL": "G1378",
        "RAAG": "Miya malhar",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Chamakat ghan shyaam",
        "THAAT": "Kafi",
        "FILE_NAME": "G1378 Miya malhar Chowtal(Vilambit) Chamakat ghan shyaam.mp3"
      },
      {
        "SL": "G1379",
        "RAAG": "Miya malhar",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Barasat ghan shyaam",
        "THAAT": "Kafi",
        "FILE_NAME": "G1379 Miya malhar Chowtal(Vilambit) Barasat ghan shyaam.mp3"
      },
      {
        "SL": "G1380",
        "RAAG": "Miya malhar",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Arey mana dheeraj",
        "THAAT": "Kafi",
        "FILE_NAME": "G1380 Miya malhar Chowtal(Vilambit) Arey mana dheeraj.mp3"
      },
      {
        "SL": "G1381",
        "RAAG": "Miya malhar",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Jal dhar aavath",
        "THAAT": "Kafi",
        "FILE_NAME": "G1381 Miya malhar Chowtal(Vilambit) Jal dhar aavath.mp3"
      },
      {
        "SL": "G1382",
        "RAAG": "Miya malhar",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Khelan aaye hori",
        "THAAT": "Kafi",
        "FILE_NAME": "G1382 Miya malhar Dhamar(Vilambit) Khelan aaye hori.mp3"
      },
      {
        "SL": "G1383",
        "RAAG": "Miya malhar",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Aho dhoom machee",
        "THAAT": "Kafi",
        "FILE_NAME": "G1383 Miya malhar Dhamar(Vilambit) Aho dhoom machee.mp3"
      },
      {
        "SL": "G1384",
        "RAAG": "Miya malhar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tom tana derenaa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1384 Miya malhar Teental(m.laya) Tom tana derenaa.mp3"
      },
      {
        "SL": "G1385",
        "RAAG": "Miya malhar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ude tananana tana",
        "THAAT": "Kafi",
        "FILE_NAME": "G1385 Miya malhar Teental(m.laya) Ude tananana tana.mp3"
      },
      {
        "SL": "G1386",
        "RAAG": "Miya malhar",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Tana naadir deem",
        "THAAT": "Kafi",
        "FILE_NAME": "G1386 Miya malhar Ektal(m.laya) Tana naadir deem.mp3"
      },
      {
        "SL": "G1387",
        "RAAG": "Bahar",
        "TAAL": "Roopak(m.laya)",
        "STARTING_WORDS": "n i ni pa ma pa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1387 Bahar Roopak(m.laya) ni ni pa ma pa.mp3"
      },
      {
        "SL": "G1388",
        "RAAG": "Bahar",
        "TAAL": "Roopak(m.laya)",
        "STARTING_WORDS": "Kahat raag bahaar",
        "THAAT": "Kafi",
        "FILE_NAME": "G1388 Bahar Roopak(m.laya) Kahat raag bahaar.mp3"
      },
      {
        "SL": "G1389",
        "RAAG": "Bahar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Bhaj raghubir shyam",
        "THAAT": "Kafi",
        "FILE_NAME": "G1389 Bahar Teental(m.laya) Bhaj raghubir shyam.mp3"
      },
      {
        "SL": "G1390",
        "RAAG": "Bahar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Girijaa ke naath",
        "THAAT": "Kafi",
        "FILE_NAME": "G1390 Bahar Teental(m.laya) Girijaa ke naath.mp3"
      },
      {
        "SL": "G1391",
        "RAAG": "Bahar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Sohani surath mohani",
        "THAAT": "Kafi",
        "FILE_NAME": "G1391 Bahar Teental(m.laya) Sohani surath mohani.mp3"
      },
      {
        "SL": "G1392",
        "RAAG": "Bahar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Phulavaale koundh",
        "THAAT": "Kafi",
        "FILE_NAME": "G1392 Bahar Teental(m.laya) Phulavaale koundh.mp3"
      },
      {
        "SL": "G1393",
        "RAAG": "Bahar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ruth basanth mey",
        "THAAT": "Kafi",
        "FILE_NAME": "G1393 Bahar Teental(m.laya) Ruth basanth mey.mp3"
      },
      {
        "SL": "G1394",
        "RAAG": "Bahar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kaisee nikasi chaandani",
        "THAAT": "Kafi",
        "FILE_NAME": "G1394 Bahar Teental(m.laya) Kaisee nikasi chaandani.mp3"
      },
      {
        "SL": "G1395",
        "RAAG": "Bahar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Baalamuvaa maayi ree",
        "THAAT": "Kafi",
        "FILE_NAME": "G1395 Bahar Teental(m.laya) Baalamuvaa maayiree.mp3"
      },
      {
        "SL": "G1396",
        "RAAG": "Bahar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Saghan ghanee amarayi",
        "THAAT": "Kafi",
        "FILE_NAME": "G1396 Bahar Teental(m.laya) Saghan ghanee amarayi.mp3"
      },
      {
        "SL": "G1397",
        "RAAG": "Bahar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Bahaar aayee belariyaa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1397 Bahar Teental(m.laya) Bahaar aayee belariyaa.mp3"
      },
      {
        "SL": "G1398",
        "RAAG": "Bahar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Aaye damaruva gayila",
        "THAAT": "Kafi",
        "FILE_NAME": "G1398 Bahar Teental(m.laya) Aaye damaruva gayila.mp3"
      },
      {
        "SL": "G1399",
        "RAAG": "Bahar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kaliyan sang karat rang",
        "THAAT": "Kafi",
        "FILE_NAME": "G1399 Bahar Teental(m.laya) Kaliyan sang karat rang.mp3"
      },
      {
        "SL": "G1400",
        "RAAG": "Bahar",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Mahoomad yaarabee",
        "THAAT": "Kafi",
        "FILE_NAME": "G1400 Bahar Tilwada(Vilambit) Mahoomad yaarabee.mp3"
      },
      {
        "SL": "G1401",
        "RAAG": "Bahar",
        "TAAL": "Jhumra(Vilambit)",
        "STARTING_WORDS": "Bahaar aayeere",
        "THAAT": "Kafi",
        "FILE_NAME": "G1401 Bahar Jhumra(Vilambit) Bahaar aayeere.mp3"
      },
      {
        "SL": "G1402",
        "RAAG": "Bahar",
        "TAAL": "Jhumra(Vilambit)",
        "STARTING_WORDS": "Bhavarare laagee",
        "THAAT": "Kafi",
        "FILE_NAME": "G1402 Bahar Jhumra(Vilambit) Bhavarare laagee.mp3"
      },
      {
        "SL": "G1403",
        "RAAG": "Bahar",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Nayee ruth nayee",
        "THAAT": "Kafi",
        "FILE_NAME": "G1403 Bahar Ektal(Vilambit) Nayee ruth nayee.mp3"
      },
      {
        "SL": "G1404",
        "RAAG": "Bahar",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Chotilee choti aambiyaa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1404 Bahar Ektal(Vilambit) Chotilee choti aambiyaa.mp3"
      },
      {
        "SL": "G1405",
        "RAAG": "Bahar",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Jayati shiriraadhike",
        "THAAT": "Kafi",
        "FILE_NAME": "G1405 Bahar Jhaptal(m.laya) Jayati shiriraadhike.mp3"
      },
      {
        "SL": "G1406",
        "RAAG": "Bahar",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Gaavo guni aaje bahaar",
        "THAAT": "Kafi",
        "FILE_NAME": "G1406 Bahar Jhaptal(m.laya) Gaavo guni aaje bahaar.mp3"
      },
      {
        "SL": "G1407",
        "RAAG": "Bahar",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Piyaa piyaake kaaree",
        "THAAT": "Kafi",
        "FILE_NAME": "G1407 Bahar Chowtal(Vilambit) Piyaa piyaake kaaree.mp3"
      },
      {
        "SL": "G1408",
        "RAAG": "Bahar",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Sapta suran baran",
        "THAAT": "Kafi",
        "FILE_NAME": "G1408 Bahar Chowtal(Vilambit) Sapta suran baran.mp3"
      },
      {
        "SL": "G1409",
        "RAAG": "Bahar",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Khelan aayee phaag",
        "THAAT": "Kafi",
        "FILE_NAME": "G1409 Bahar Dhamar(Vilambit) Khelan aayee phaag.mp3"
      },
      {
        "SL": "G1410",
        "RAAG": "Bahar",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Hori khelan siri mohan",
        "THAAT": "Kafi",
        "FILE_NAME": "G1410 Bahar Dhamar(Vilambit) Hori khelan siri mohan.mp3"
      },
      {
        "SL": "G1411",
        "RAAG": "Bahar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Udetana dre tan",
        "THAAT": "Kafi",
        "FILE_NAME": "G1411 Bahar Teental(m.laya) Udetana dre tan.mp3"
      },
      {
        "SL": "G1412",
        "RAAG": "Bahar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tadiyare derenaa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1412 Bahar Teental(m.laya) Tadiyare derenaa.mp3"
      },
      {
        "SL": "G1413",
        "RAAG": "Basant bahar",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Barjo na maane",
        "THAAT": "Kafi",
        "FILE_NAME": "G1413 Basant bahar Tilwada(Vilambit) Barjo na maane.mp3"
      },
      {
        "SL": "G1414",
        "RAAG": "Hindol bahar",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Koyaliyaa bolath lajaath",
        "THAAT": "Kafi",
        "FILE_NAME": "G1414 Hindol bahar Tilwada(Vilambit) Koyaliyaa bolath lajaat.mp3"
      },
      {
        "SL": "G1415",
        "RAAG": "Bhairav bahar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Daalariyaa jhuk jhuk",
        "THAAT": "Kafi",
        "FILE_NAME": "G1415 Bhairav bahar Teental(m.laya) Daalariyaa jhuk jhuk.mp3"
      },
      {
        "SL": "G1416",
        "RAAG": "Bhairav bahar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Daalariyaa(2nd version)",
        "THAAT": "Kafi",
        "FILE_NAME": "G1416 Bhairav bahar Teental(m.laya) Daalariyaa(2nd version).mp3"
      },
      {
        "SL": "G1417",
        "RAAG": "Bhairav bahar",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Aayilaare bahaar",
        "THAAT": "Kafi",
        "FILE_NAME": "G1417 Bhairav bahar Tilwada(Vilambit) Aayilaare bahaar.mp3"
      },
      {
        "SL": "G1418",
        "RAAG": "Bhairav bahar",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Paida kiyeki saram karo",
        "THAAT": "Kafi",
        "FILE_NAME": "G1418 Bhairav bahar Chowtal(Vilambit) Paida kiyeki saram kar.mp3"
      },
      {
        "SL": "G1419",
        "RAAG": "Baageshri bahar",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Maalan tohe basant ruth",
        "THAAT": "Kafi",
        "FILE_NAME": "G1419 Baageshri bahar Ektal(Vilambit) Maalan tohe basant rut.mp3"
      },
      {
        "SL": "G1420",
        "RAAG": "Adana bahar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Piyaso neha nayo tum",
        "THAAT": "Kafi",
        "FILE_NAME": "G1420 Adana bahar Teental(m.laya) Piyaso neha nayo tum.mp3"
      },
      {
        "SL": "G1421",
        "RAAG": "Adana bahar",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Yalla saayee sajan aa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1421 Adana bahar Ektal(Vilambit) Yalla saayee sajan aa.mp3"
      },
      {
        "SL": "G1422",
        "RAAG": "Jounpuri bahar",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Piho paradesvaa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1422 Jounpuri bahar Ektal(Vilambit) Piho paradesvaa.mp3"
      },
      {
        "SL": "G1423",
        "RAAG": "Malkous bahar",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Ata tan drennaa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1423 Malkous bahar Tilwada(Vilambit) Ata tan drennaa.mp3"
      },
      {
        "SL": "G1424",
        "RAAG": "Sindoora",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Bighan binaashana",
        "THAAT": "Kafi",
        "FILE_NAME": "G1424 Sindoora Teental(m.laya) Bighan binaashana.mp3"
      },
      {
        "SL": "G1425",
        "RAAG": "Sindoora",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Koun hare dukh",
        "THAAT": "Kafi",
        "FILE_NAME": "G1425 Sindoora Teental(m.laya) Koun hare dukh.mp3"
      },
      {
        "SL": "G1426",
        "RAAG": "Sindoora",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Unabin kal nahi",
        "THAAT": "Kafi",
        "FILE_NAME": "G1426 Sindoora Tilwada(Vilambit) Unabin kal nahi.mp3"
      },
      {
        "SL": "G1427",
        "RAAG": "Sindoora",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Aaj nandlaal",
        "THAAT": "Kafi",
        "FILE_NAME": "G1427 Sindoora Jhaptal(m.laya) Aaj nandlaal.mp3"
      },
      {
        "SL": "G1428",
        "RAAG": "Sindoora",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Lochan bishal banmaal",
        "THAAT": "Kafi",
        "FILE_NAME": "G1428 Sindoora Jhaptal(m.laya) Lochan bishal banmaal.mp3"
      },
      {
        "SL": "G1429",
        "RAAG": "Sindoora",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Peetaki reet na jaani",
        "THAAT": "Kafi",
        "FILE_NAME": "G1429 Sindoora Jhaptal(m.laya) Peetaki reet na jaani.mp3"
      },
      {
        "SL": "G1430",
        "RAAG": "Sindoora",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Yeri mere mukha par",
        "THAAT": "Kafi",
        "FILE_NAME": "G1430 Sindoora Dhamar(Vilambit) Yeri mere mukha par.mp3"
      },
      {
        "SL": "G1431",
        "RAAG": "Sindoora",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Hori khele basounth",
        "THAAT": "Kafi",
        "FILE_NAME": "G1431 Sindoora Dhamar(Vilambit) Hori khele basounth.mp3"
      },
      {
        "SL": "G1432",
        "RAAG": "Sindoora",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Yeri daiya aba na javu",
        "THAAT": "Kafi",
        "FILE_NAME": "G1432 Sindoora Dhamar(Vilambit) Yeri daiya aba na javu.mp3"
      },
      {
        "SL": "G1433",
        "RAAG": "Sindoora",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Yeri mai tho jab jaanoo",
        "THAAT": "Kafi",
        "FILE_NAME": "G1433 Sindoora Dhamar(Vilambit) Yeri mai tho jab jaanoo.mp3"
      },
      {
        "SL": "G1434",
        "RAAG": "Sindh",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Aaj salone",
        "THAAT": "Kafi",
        "FILE_NAME": "G1434 Sindh Teental(m.laya) Aaj salone.mp3"
      },
      {
        "SL": "G1435",
        "RAAG": "Barwa",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Gaavath raagani",
        "THAAT": "Kafi",
        "FILE_NAME": "G1435 Barwa Teental(m.laya) Gaavath raagani.mp3"
      },
      {
        "SL": "G1436",
        "RAAG": "Barwa",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Soch samajh mana",
        "THAAT": "Kafi",
        "FILE_NAME": "G1436 Barwa Jhaptal(m.laya) Soch samajh mana.mp3"
      },
      {
        "SL": "G1437",
        "RAAG": "Barwa",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Nahi maanath naari",
        "THAAT": "Kafi",
        "FILE_NAME": "G1437 Barwa Teental(m.laya) Nahi maanath naari.mp3"
      },
      {
        "SL": "G1438",
        "RAAG": "Barwa",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Hamaari kovu naa s uney",
        "THAAT": "Kafi",
        "FILE_NAME": "G1438 Barwa Ektal(Vilambit) Hamaari kovu naa suney.mp3"
      },
      {
        "SL": "G1439",
        "RAAG": "Barwa",
        "TAAL": "Adachowtal(Vilambit)",
        "STARTING_WORDS": "Yeri maika naahi parey",
        "THAAT": "Kafi",
        "FILE_NAME": "G1439 Barwa Adachowtal(Vilambit) Yeri maika naahi parey.mp3"
      },
      {
        "SL": "G1440",
        "RAAG": "Barwa",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Aayo saavan sohaavan",
        "THAAT": "Kafi",
        "FILE_NAME": "G1440 Barwa Chowtal(Vilambit) Aayo saavan sohaavan.mp3"
      },
      {
        "SL": "G1441",
        "RAAG": "Barwa",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Mope rang daaro",
        "THAAT": "Kafi",
        "FILE_NAME": "G1441 Barwa Dhamar(Vilambit) Mope rang daaro.mp3"
      },
      {
        "SL": "G1442",
        "RAAG": "Punjabi- Barwa",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Jogee jag tyaag hama",
        "THAAT": "Kafi",
        "FILE_NAME": "G1442 Punjabi- Barwa Chowtal(Vilambit) Jogee jag tyaag hama.mp3"
      },
      {
        "SL": "G1443",
        "RAAG": "Dhanashri",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Shastra su math raag",
        "THAAT": "Kafi",
        "FILE_NAME": "G1443 Dhanashri Chowtal(Vilambit) Shastra sumath raag.mp3"
      },
      {
        "SL": "G1444",
        "RAAG": "Dhanashri",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Aaj krapa kare",
        "THAAT": "Kafi",
        "FILE_NAME": "G1444 Dhanashri Sultal(m.laya) Aaj krapa kare.mp3"
      },
      {
        "SL": "G1445",
        "RAAG": "Dhanashri",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Aaj krapa (2nd version)",
        "THAAT": "Kafi",
        "FILE_NAME": "G1445 Dhanashri Jhaptal(m.laya) Aaj krapa (2nd version).mp3"
      },
      {
        "SL": "G1446",
        "RAAG": "Dhanashri",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Saas sadan madan",
        "THAAT": "Kafi",
        "FILE_NAME": "G1446 Dhanashri Chowtal(Vilambit) Saas sadan madan.mp3"
      },
      {
        "SL": "G1447",
        "RAAG": "Dhaani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ab maan manaa hari",
        "THAAT": "Kafi",
        "FILE_NAME": "G1447 Dhaani Teental(m.laya) Ab maan manaa hari.mp3"
      },
      {
        "SL": "G1448",
        "RAAG": "Pradeepaki",
        "TAAL": "Jhumra(Vilambit)",
        "STARTING_WORDS": "Aree beer aa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1448 Pradeepaki Jhumra(Vilambit) Yeree beer aa.mp3"
      },
      {
        "SL": "G1449",
        "RAAG": "Pradeepaki",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Piyaa ghar naahi",
        "THAAT": "Kafi",
        "FILE_NAME": "G1449 Pradeepaki Tilwada(Vilambit) Piyaa ghar naahi.mp3"
      },
      {
        "SL": "G1450",
        "RAAG": "Haunskinkini",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Dhan haunskankani",
        "THAAT": "Kafi",
        "FILE_NAME": "G1450 Haunskinkini Jhaptal(m.laya) Dhan haunskankani.mp3"
      },
      {
        "SL": "G1451",
        "RAAG": "Haunskinkini",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Karataa so poundit",
        "THAAT": "Kafi",
        "FILE_NAME": "G1451 Haunskinkini Jhaptal(m.laya) Karataa so poundit.mp3"
      },
      {
        "SL": "G1452",
        "RAAG": "Haunskinkini",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tendere ghol ghol",
        "THAAT": "Kafi",
        "FILE_NAME": "G1452 Haunskinkini Teental(m.laya) Tendere ghol ghol.mp3"
      },
      {
        "SL": "G1453",
        "RAAG": "Palasi",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Choundra so aanan",
        "THAAT": "Kafi",
        "FILE_NAME": "G1453 Palasi Jhaptal(m.laya) Choundra so aanan.mp3"
      },
      {
        "SL": "G1454",
        "RAAG": "Madhmad sarang",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Likhat madhmaad budh",
        "THAAT": "Kafi",
        "FILE_NAME": "G1454 Madhmad sarang Jhaptal(m.laya) Likhat madhmaad budh.mp3"
      },
      {
        "SL": "G1455",
        "RAAG": "Madhmad sarang",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Madhmaad sarang",
        "THAAT": "Kafi",
        "FILE_NAME": "G1455 Madhmad sarang Jhaptal(m.laya) Madhmaad sarang.mp3"
      },
      {
        "SL": "G1456",
        "RAAG": "Madhmad sarang",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Aaj anjan diyo",
        "THAAT": "Kafi",
        "FILE_NAME": "G1456 Madhmad sarang Jhaptal(m.laya) Aaj anjan diyo.mp3"
      },
      {
        "SL": "G1457",
        "RAAG": "Madhmad sarang",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Aisee sardaayee",
        "THAAT": "Kafi",
        "FILE_NAME": "G1457 Madhmad sarang Chowtal(Vilambit) Aisee sardaayee.mp3"
      },
      {
        "SL": "G1458",
        "RAAG": "Madhmad sarang",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Apane apane gharkee",
        "THAAT": "Kafi",
        "FILE_NAME": "G1458 Madhmad sarang Chowtal(Vilambit) Apane apane gharkee.mp3"
      },
      {
        "SL": "G1 459",
        "RAAG": "Madhmad sarang",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Sakhi brijake kilaiyaa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1459 Madhmad sarang Dhamar(Vilambit) Sakhi brijake kilaiyaa.mp3"
      },
      {
        "SL": "G1460",
        "RAAG": "Madhmad sarang",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Daph kee dhukaare",
        "THAAT": "Kafi",
        "FILE_NAME": "G1460 Madhmad sarang Dhamar(Vilambit) Daph kee dhukaare.mp3"
      },
      {
        "SL": "G1461",
        "RAAG": "Suddha sarang",
        "TAAL": "Roopak(m.laya)",
        "STARTING_WORDS": "Sab bidh kaaj",
        "THAAT": "Kafi",
        "FILE_NAME": "G1461 Suddha sarang Roopak(m.laya) Sab bidh kaaj.mp3"
      },
      {
        "SL": "G1462",
        "RAAG": "Suddha sarang",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Mayiri mai kaase kahoo",
        "THAAT": "Kafi",
        "FILE_NAME": "G1462 Suddha sarang Ektal(Vilambit) Mayiri mai kaase kahoo.mp3"
      },
      {
        "SL": "G1463",
        "RAAG": "Badhans sarang",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Mero mana sakhi haran",
        "THAAT": "Kafi",
        "FILE_NAME": "G1463 Badhans sarang Ektal(m.laya) Mero mana sakhi haran.mp3"
      },
      {
        "SL": "G1464",
        "RAAG": "Badhans sarang",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Dhoop parath aali jiya",
        "THAAT": "Kafi",
        "FILE_NAME": "G1464 Badhans sarang Teental(m.laya) Dhoop parath aali jiya.mp3"
      },
      {
        "SL": "G1465",
        "RAAG": "Badhans sarang",
        "TAAL": "Jhumra(V ilambit)",
        "STARTING_WORDS": "Aaj baalam supanehee",
        "THAAT": "Kafi",
        "FILE_NAME": "G1465 Badhans sarang Jhumra(Vilambit) Aaj baalam supanehee.mp3"
      },
      {
        "SL": "G1466",
        "RAAG": "Badhans sarang",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Laal hamiso aba kaahe",
        "THAAT": "Kafi",
        "FILE_NAME": "G1466 Badhans sarang Ektal(Vilambit) Laal hamiso aba kaahe.mp3"
      },
      {
        "SL": "G1467",
        "RAAG": "Badhans sarang",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Taal surake bhedha",
        "THAAT": "Kafi",
        "FILE_NAME": "G1467 Badhans sarang Jhaptal(m.laya) Taal surake bhedha.mp3"
      },
      {
        "SL": "G1468",
        "RAAG": "Samant sarang",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Saamant sarang",
        "THAAT": "Kafi",
        "FILE_NAME": "G1468 Samant sarang Jhaptal(m.laya) Saamant sarang.mp3"
      },
      {
        "SL": "G1469",
        "RAAG": "Miyaki sarang",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Pyare vaako kahaa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1469 Miyaki sarang Tilwada(Vilambit) Pyare vaako kahaa.mp3"
      },
      {
        "SL": "G1470",
        "RAAG": "Miyaki sarang",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Maangath pana",
        "THAAT": "Kafi",
        "FILE_NAME": "G1470 Miyaki sarang Ektal(Vilambit) Maangath pana.mp3"
      },
      {
        "SL": "G1471",
        "RAAG": "Miyaki sarang",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Dayanidha daya kar",
        "THAAT": "Kafi",
        "FILE_NAME": "G1471 Miyaki sarang Chowtal(Vilambit) Dayanidha daya kar.mp3"
      },
      {
        "SL": "G1472",
        "RAAG": "Miyaki sarang",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Kaam ko damaame",
        "THAAT": "Kafi",
        "FILE_NAME": "G1472 Miyaki sarang Dhamar(Vilambit) Kaam ko damaame.mp3"
      },
      {
        "SL": "G1473",
        "RAAG": "Lankdahansarang",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Gulaal rang bhar",
        "THAAT": "Kafi",
        "FILE_NAME": "G1473 Lankdahansarang Dhamar(Vilambit) Gulaal rang bhar.mp3"
      },
      {
        "SL": "G1474",
        "RAAG": "Patmanjari",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Apanee bitha ko aali",
        "THAAT": "Kafi",
        "FILE_NAME": "G1474 Patmanjari Jhaptal(m.laya) Apanee bitha ko aali.mp3"
      },
      {
        "SL": "G1475",
        "RAAG": "Patmanjari",
        "TAAL": "Adachowtal(Vilambit) ",
        "STARTING_WORDS": " ",
        "THAAT": "Kafi",
        "FILE_NAME": "G1475 Patmanjari Adachowtal(Vilambit) ) Patiyaare likh bhejo.mp3"
      },
      {
        "SL": "G1476",
        "RAAG": "Suha",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Karhaar priya thaat",
        "THAAT": "Kafi",
        "FILE_NAME": "G1476 Suha Jhaptal(m.laya) Karhaar priya thaat.mp3"
      },
      {
        "SL": "G1477",
        "RAAG": "Suha",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Sohat gal beech",
        "THAAT": "Kafi",
        "FILE_NAME": "G1477 Suha Teental(m.laya) Sohat gal beech.mp3"
      },
      {
        "SL": "G1478",
        "RAAG": "Suha",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ka karoo ho kaha karoo",
        "THAAT": "Kafi",
        "FILE_NAME": "G1478 Suha Teental(m.laya) Ka karoo ho kaha karoo.mp3"
      },
      {
        "SL": "G1479",
        "RAAG": "Suha",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Palana sohaya harrang",
        "THAAT": "Kafi",
        "FILE_NAME": "G1479 Suha Teental(m.laya) Palana sohaya harrang.mp3"
      },
      {
        "SL": "G1480",
        "RAAG": "Suha",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Aa jare badhaava",
        "THAAT": "Kafi",
        "FILE_NAME": "G1480 Suha Tilwada(Vilambit) Aajare badhaava.mp3"
      },
      {
        "SL": "G1481",
        "RAAG": "Suha",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Aka kaka kaka kakka",
        "THAAT": "Kafi",
        "FILE_NAME": "G1481 Suha Jhaptal(m.laya) Aka kaka kaka kakka.mp3"
      },
      {
        "SL": "G1482",
        "RAAG": "Suha",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Rathakee garoor dhoor",
        "THAAT": "Kafi",
        "FILE_NAME": "G1482 Suha Chowtal(Vilambit) Rathakee garoor dhoor.mp3"
      },
      {
        "SL": "G1483",
        "RAAG": "Suha",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Hari hari koonjana",
        "THAAT": "Kafi",
        "FILE_NAME": "G1483 Suha Chowtal(Vilambit) Hari hari koonjana.mp3"
      },
      {
        "SL": "G1484",
        "RAAG": "Suha",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Kanak baindi ulate",
        "THAAT": "Kafi",
        "FILE_NAME": "G1484 Suha Chowtal(Vilambit) Kanak baindi ulate.mp3"
      },
      {
        "SL": "G1485",
        "RAAG": "Suha",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Yeri mai kase kahoo",
        "THAAT": "Kafi",
        "FILE_NAME": "G1485 Suha Dhamar(Vilambit) Yeri mai kase kahoo.mp3"
      },
      {
        "SL": "G1486",
        "RAAG": "Sugharayi",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Daiyaa piyaabin maikaa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1486 Sugharayi Jhaptal(m.laya) Daiyaa piyaabin maikaa.mp3"
      },
      {
        "SL": "G1487",
        "RAAG": "Sugharayi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Adhag bhujatava",
        "THAAT": "Kafi",
        "FILE_NAME": "G1487 Sugharayi Teental(m.laya) Adhag bhujatava.mp3"
      },
      {
        "SL": "G1488",
        "RAAG": "Sugharayi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Balmaare chunariyaa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1488 Sugharayi Teental(m.laya) Balmaare chunariyaa.mp3"
      },
      {
        "SL": "G1489",
        "RAAG": "Sugharayi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tore garazava haare",
        "THAAT": "Kafi",
        "FILE_NAME": "G1489 Sugharayi Teental(m.laya) Tore garazava haare.mp3"
      },
      {
        "SL": "G1490",
        "RAAG": "Sugharayi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Paayal rumjhum baaje",
        "THAAT": "Kafi",
        "FILE_NAME": "G1490 Sugharayi Teental(m.laya) Paayal rumjhum baaje.mp3"
      },
      {
        "SL": "G1491",
        "RAAG": "Sugharayi",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Baar baar baar baar",
        "THAAT": "Kafi",
        "FILE_NAME": "G1491 Sugharayi Ektal(m.laya) Baar baar baar baar.mp3"
      },
      {
        "SL": "G1492",
        "RAAG": "Sugharayi",
        "TAAL": "Adachowtal(m.laya)",
        "STARTING_WORDS": "Hukamakee baaree",
        "THAAT": "Kafi",
        "FILE_NAME": "G1492 Sugharayi Adachowtal(m.laya) Hukamakee baaree.mp3"
      },
      {
        "SL": "G1493",
        "RAAG": "Sugharayi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Jaane na devungi",
        "THAAT": "Kafi",
        "FILE_NAME": "G1493 Sugharayi Teental(m.laya) Jaane na devungi.mp3"
      },
      {
        "SL": "G1494",
        "RAAG": "Sugharayi",
        "TAAL": "Roopak(m.laya)",
        "STARTING_WORDS": "Ho mataki morire",
        "THAAT": "Kafi",
        "FILE_NAME": "G1494 Sugharayi Roopak(m.laya) Ho mataki morire.mp3"
      },
      {
        "SL": "G1495",
        "RAAG": "Sugharayi",
        "TAAL": "Roopak(m.laya)",
        "STARTING_WORDS": "Ho mataki (2nd version)",
        "THAAT": "Kafi",
        "FILE_NAME": "G1495 Sugharayi Roopak(m.laya) Ho mataki (2nd version).mp3"
      },
      {
        "SL": "G1496",
        "RAAG": "Sugharayi",
        "TAAL": "Roopak(m.laya)",
        "STARTING_WORDS": "Karam kar kareem",
        "THAAT": "Kafi",
        "FILE_NAME": "G1496 Sugharayi Roopak(m.laya) Karam kar kareem.mp3"
      },
      {
        "SL": "G1497",
        "RAAG": "Sugharayi",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Sundar navelee",
        "THAAT": "Kafi",
        "FILE_NAME": "G1497 Sugharayi Jhaptal(m.laya) Sundar navelee.mp3"
      },
      {
        "SL": "G1498",
        "RAAG": "Sugharayi",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Ghunghat khol",
        "THAAT": "Kafi",
        "FILE_NAME": "G1498 Sugharayi Chowtal(Vilambit) Ghunghat khol.mp3"
      },
      {
        "SL": "G1499",
        "RAAG": "Suha Sugharayi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Sahelariyaa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1499 Suha Sugharayi Teental(m.laya) Sahelariyaa.mp3"
      },
      {
        "SL": "G1500",
        "RAAG": "Shahana",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Jaaned e maikaa suno",
        "THAAT": "Kafi",
        "FILE_NAME": "G1500 Shahana Teental(m.laya) Jaanede maikaa suno.mp3"
      },
      {
        "SL": "G1501",
        "RAAG": "Shahana",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Avaguna bhayo sakal",
        "THAAT": "Kafi",
        "FILE_NAME": "G1501 Shahana Jhaptal(m.laya) Avaguna bhayo sakal.mp3"
      },
      {
        "SL": "G1502",
        "RAAG": "Shahana",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Gundelaa maalan",
        "THAAT": "Kafi",
        "FILE_NAME": "G1502 Shahana Jhaptal(m.laya) Gundelaa maalan.mp3"
      },
      {
        "SL": "G1503",
        "RAAG": "Shahana",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Kunjan udath hai gulaal",
        "THAAT": "Kafi",
        "FILE_NAME": "G1503 Shahana Dhamar(Vilambit) Kunjan udath hai gulaal.mp3"
      },
      {
        "SL": "G1504",
        "RAAG": "Shahana",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Aachee nibhaatso khele",
        "THAAT": "Kafi",
        "FILE_NAME": "G1504 Shahana Dhamar(Vilambit) Aachee nibhaatso khele.mp3"
      },
      {
        "SL": "G1505",
        "RAAG": "Nayaki kanada",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Sajan bin bhayee",
        "THAAT": "Kafi",
        "FILE_NAME": "G1505 Nayaki kanada Teental(m.laya) Sajan bin bhayee.mp3"
      },
      {
        "SL": "G1506",
        "RAAG": "Nayaki kanada",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Paapi hari mukha bolare",
        "THAAT": "Kafi",
        "FILE_NAME": "G1506 Nayaki kanada Teental(m.laya) Paapi hari mukha bolare.mp3"
      },
      {
        "SL": "G1507",
        "RAAG": "Nayaki kanada",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Ye mag vaaran vaaru",
        "THAAT": "Kafi",
        "FILE_NAME": "G1507 Nayaki kanada Tilwada(Vilambit) Ye mag vaaran vaaru.mp3"
      },
      {
        "SL": "G1508",
        "RAAG": "Nayaki kanada",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Banara mora pyaaraa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1508 Nayaki kanada Tilwada(Vilambit) Banara mora pyaaraa.mp3"
      },
      {
        "SL": "G1509",
        "RAAG": "Nayaki kanada",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Sukh kar aayee",
        "THAAT": "Kafi",
        "FILE_NAME": "G1509 Nayaki kanada Ektal(Vilambit) Sukh kar aayee.mp3"
      },
      {
        "SL": "G1510",
        "RAAG": "Nayaki kanada",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Sundar bin sukhasadan",
        "THAAT": "Kafi",
        "FILE_NAME": "G1510 Nayaki kanada Chowtal(Vilambit) Sundar bin sukhasadan.mp3"
      },
      {
        "SL": "G1511",
        "RAAG": "Nayaki kanada",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Doupathee raaj rahe",
        "THAAT": "Kafi",
        "FILE_NAME": "G1511 Nayaki kanada Jhaptal(m.laya) Doupathee raaj rahe.mp3"
      },
      {
        "SL": "G1512",
        "RAAG": "Nayaki kanada",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Bimbothana kundal",
        "THAAT": "Kafi",
        "FILE_NAME": "G1512 Nayaki kanada Chowtal(Vilambit) Bimbothana kundal.mp3"
      },
      {
        "SL": "G1513",
        "RAAG": "Nayaki kanada",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Ye madha maoundal",
        "THAAT": "Kafi",
        "FILE_NAME": "G1513 Nayaki kanada Dhamar(Vilambit) Ye madha maoundal.mp3"
      },
      {
        "SL": "G1514",
        "RAAG": "Nayaki kanada",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Laal laadalee khelani",
        "THAAT": "Kafi",
        "FILE_NAME": "G1514 Nayaki kanada Dhamar(Vilambit) Laal laadalee khelani.mp3"
      },
      {
        "SL": "G1515",
        "RAAG": "Devshaakh",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ratiyaa beeth gayee",
        "THAAT": "Kafi",
        "FILE_NAME": "G1515 Devshaakh Teental(m.laya) Ratiyaa beeth gayee.mp3"
      },
      {
        "SL": "G1516",
        "RAAG": "Devshaakh",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Akhiyaa laag rahee",
        "THAAT": "Kafi",
        "FILE_NAME": "G1516 Devshaakh Teental(m.laya) Akhiyaa laag rahee.mp3"
      },
      {
        "SL": "G1517",
        "RAAG": "Devshaakh",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Durage harani",
        "THAAT": "Kafi",
        "FILE_NAME": "G1517 Devshaakh Jhaptal(m.laya) Durage harani.mp3"
      },
      {
        "SL": "G1518",
        "RAAG": "Devshaakh",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Daanav dalan kaaj",
        "THAAT": "Kafi",
        "FILE_NAME": "G1518 Devshaakh Jhaptal(m.laya) Daanav dalan kaaj.mp3"
      },
      {
        "SL": "G1519",
        "RAAG": "Kausi Kanada",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Mai jab dekhiri gopaal",
        "THAAT": "Kafi",
        "FILE_NAME": "G1519 Kausi Kanada Chowtal(Vilambit) Mai jab dekhiri gopaal.mp3"
      },
      {
        "SL": "G1520",
        "RAAG": "Kausi Kanada",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Kirpaakare jogidaa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1520 Kausi Kanada Ektal(Vilambit) Kirpaakare jogidaa.mp3"
      },
      {
        "SL": "G1521",
        "RAAG": "Kausi Kanada",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Raajana key sirtaaja",
        "THAAT": "Kafi",
        "FILE_NAME": "G1521 Kausi Kanada Ektal(Vilambit) Raajana key sirtaaja.mp3"
      },
      {
        "SL": "G1522",
        "RAAG": "Megh malhar",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Umand ghumand megh",
        "THAAT": "Kafi",
        "FILE_NAME": "G1522 Megh malhar Jhaptal(m.laya) Umand ghumand megh.mp3"
      },
      {
        "SL": "G1523",
        "RAAG": "Megh malhar",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Tero sumaran karoo",
        "THAAT": "Kafi",
        "FILE_NAME": "G1523 Megh malhar Jhaptal(m.laya) Tero sumaran karoo.mp3"
      },
      {
        "SL": "G1524",
        "RAAG": "Megh malhar",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Garaj ghataaghan",
        "THAAT": "Kafi",
        "FILE_NAME": "G1524 Megh malhar Jhaptal(m.laya) Garaj ghataaghan.mp3"
      },
      {
        "SL": "G1525",
        "RAAG": "Megh malhar",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Dhoom aaye baadariya",
        "THAAT": "Kafi",
        "FILE_NAME": "G1525 Megh malhar Jhaptal(m.laya) Dhoom aaye baadariya.mp3"
      },
      {
        "SL": "G1526",
        "RAAG": "Megh malhar",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Magan rahore daalidra",
        "THAAT": "Kafi",
        "FILE_NAME": "G1526 Megh malhar Jhaptal(m.laya) Magan rahore daalidra.mp3"
      },
      {
        "SL": "G1527",
        "RAAG": "Megh malhar",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Prabal dal saaj jag",
        "THAAT": "Kafi",
        "FILE_NAME": "G1527 Megh malhar Jhaptal(m.laya) Prabal dal saaj jag.mp3"
      },
      {
        "SL": "G1528",
        "RAAG": "Megh malhar",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Raadhe aaye",
        "THAAT": "Kafi",
        "FILE_NAME": "G1528 Megh malhar Sultal(m.laya) Raadhe aaye.mp3"
      },
      {
        "SL": "G1529",
        "RAAG": "Megh malhar",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Aayee gher ghore",
        "THAAT": "Kafi",
        "FILE_NAME": "G1529 Megh malhar Chowtal(Vilambit) Aayee gher ghore.mp3"
      },
      {
        "SL": "G1530",
        "RAAG": "Megh malhar",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Tu laagee mana",
        "THAAT": "Kafi",
        "FILE_NAME": "G1530 Megh malhar Chowtal(Vilambit) Tu laagee mana.mp3"
      },
      {
        "SL": "G1531",
        "RAAG": "Megh malhar",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Aaayo aba barakha ruth",
        "THAAT": "Kafi",
        "FILE_NAME": "G1531 Megh malhar Chowtal(Vilambit) Aaayo aba barakha ruth.mp3"
      },
      {
        "SL": "G1532",
        "RAAG": "Megh malhar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Chamak bijareeyaa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1532 Megh malhar Teental(m.laya) Chamak bijareeyaa.mp3"
      },
      {
        "SL": "G1533",
        "RAAG": "Sur malhar",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Gaavo gunee sakal",
        "THAAT": "Kafi",
        "FILE_NAME": "G1533 Sur malhar Jhaptal(m.laya) Gaavo gunee sakal.mp3"
      },
      {
        "SL": "G1534",
        "RAAG": "Sur malhar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Barakha ruth bairi",
        "THAAT": "Kafi",
        "FILE_NAME": "G1534 Sur malhar Teental(m.laya) Barakha ruth bairi.mp3"
      },
      {
        "SL": "G1535",
        "RAAG": "Sur malhar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Barsan ke baadar kaare",
        "THAAT": "Kafi",
        "FILE_NAME": "G1535 Sur malhar Teental(m.laya) Barsan ke baadar kaare.mp3"
      },
      {
        "SL": "G1536",
        "RAAG": "Sur malhar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ab ghat-hee dekh daree",
        "THAAT": "Kafi",
        "FILE_NAME": "G1536 Sur malhar Teental(m.laya) Ab ghat-hee dekh daree.mp3"
      },
      {
        "SL": "G1537",
        "RAAG": "Sur malhar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Baadarva barsanko aye",
        "THAAT": "Kafi",
        "FILE_NAME": "G1537 Sur malhar Teental(m.laya) Baadarva barsanko aye.mp3"
      },
      {
        "SL": "G1538",
        "RAAG": "Sur malhar",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ghor ghor ghor",
        "THAAT": "Kafi",
        "FILE_NAME": "G1538 Sur malhar Teental(m.laya) Ghor ghor ghor.mp3"
      },
      {
        "SL": "G1539",
        "RAAG": "Sur malhar",
        "TAAL": "Adachowtal(Vilambit)",
        "STARTING_WORDS": "Garajath aaye",
        "THAAT": "Kafi",
        "FILE_NAME": "G1539 Sur malhar Adachowtal(Vilambit) Garajath aaye.mp3"
      },
      {
        "SL": "G1540",
        "RAAG": "Sur malhar",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Haraso itanaa kahyo",
        "THAAT": "Kafi",
        "FILE_NAME": "G1540 Sur malhar Dhamar(Vilambit) Haraso itanaa kahyo.mp3"
      },
      {
        "SL": "G1541",
        "RAAG": "Sur malhar",
        "TAAL": "Roopak(m.laya)",
        "STARTING_WORDS": "Dani tomtha tomtana",
        "THAAT": "Kafi",
        "FILE_NAME": "G1541 Sur malhar Roopak(m.laya) Dani tomtha tomtana.mp3"
      },
      {
        "SL": "G1542",
        "RAAG": "Ramdasi malhar",
        "TAAL": "Adachowtal(Vilambit)",
        "STARTING_WORDS": "Terohi chel moso karat",
        "THAAT": "Kafi",
        "FILE_NAME": "G1542 Ramdasi malhar Adachowtal(Vilambit) Terohi chel moso k.mp3"
      },
      {
        "SL": "G1543",
        "RAAG": "Ramdasi malhar",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Sakal ban hulaaye",
        "THAAT": "Kafi",
        "FILE_NAME": "G1543 Ramdasi malhar Ektal(Vilambit) Sakal ban hulaaye.mp3"
      },
      {
        "SL": "G1544",
        "RAAG": "Ramdasi malhar",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Aiiso khelan badoungi",
        "THAAT": "Kafi",
        "FILE_NAME": "G1544 Ramdasi malhar Dhamar(Vilambit) Aiiso khelan badoungi.mp3"
      },
      {
        "SL": "G1545",
        "RAAG": "Nat malhar",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Piyoo likhanaa",
        "THAAT": "Kafi",
        "FILE_NAME": "G1545 Nat malhar Tilwada(Vilambit) Piyoo likhanaa.mp3"
      },
      {
        "SL": "G1546",
        "RAAG": "Nat malhar",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Nazare nahi aanda",
        "THAAT": "Kafi",
        "FILE_NAME": "G1546 Nat malhar Tilwada(Vilambit) Nazare nahi aanda.mp3"
      },
      {
        "SL": "G1547",
        "RAAG": "Nat malhar",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Banvaari bin moko",
        "THAAT": "Kafi",
        "FILE_NAME": "G1547 Nat malhar Chowtal(Vilambit) Banvaari bin moko.mp3"
      },
      {
        "SL": "G1548",
        "RAAG": "Mirabayiki malhar",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Tum ghanse ghan",
        "THAAT": "Kafi",
        "FILE_NAME": "G1548 Mirabayiki malhar Chowtal(Vilambit) Tum ghanse ghan.mp3"
      },
      {
        "SL": "G1549",
        "RAAG": "Charjooki malhar",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Hamey boli bolale",
        "THAAT": "Kafi",
        "FILE_NAME": "G1549 Charjooki malhar Chowtal(Vilambit) Hamey boli bolale.mp3"
      },
      {
        "SL": "G1550",
        "RAAG": "Chanchalsas Malhar ",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Yeri mayee piyaake",
        "THAAT": "Kafi",
        "FILE_NAME": "G1550 Chanchalsas Malhar Chowtal(Vilambit) Yeri mayee piyaak.mp3"
      },
      {
        "SL": "G1551",
        "RAAG": "Dhuliya malhar",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Aaj dharatee le",
        "THAAT": "Kafi",
        "FILE_NAME": "G1551 Dhuliya malhar Chowtal(Vilambit) Aaj dharatee le.mp3"
      },
      {
        "SL": "G1552",
        "RAAG": "Rupmanjari malhar",
        "TAAL": "Roopak(m.laya)",
        "STARTING_WORDS": "Barakhaa ru th aagam",
        "THAAT": "Kafi",
        "FILE_NAME": "G1552 Rupmanjari malhar Roopak(m.laya) Barakhaa ruth aagam.mp3"
      },
      {
        "SL": "G1553",
        "RAAG": "Shreeranjani",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Gunijan karat mel",
        "THAAT": "Kafi",
        "FILE_NAME": "G1553 Shreeranjani Ektal(m.laya) Gunijan karat mel.mp3"
      },
      {
        "SL": "G1554",
        "RAAG": "Abhogi",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Karhar priya mel",
        "THAAT": "Kafi",
        "FILE_NAME": "G1554 Abhogi Jhaptal(m.laya) Karhar priya mel.mp3"
      },
      {
        "SL": "G1555",
        "RAAG": "Abhogi",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Jayati siri raadhike",
        "THAAT": "Kafi",
        "FILE_NAME": "G1555 Abhogi Jhaptal(m.laya) Jayati siri raadhike.mp3"
      },
      {
        "SL": "G1556",
        "RAAG": "Abhogi",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Charan dhar aayeri",
        "THAAT": "Kafi",
        "FILE_NAME": "G1556 Abhogi Jhaptal(m.laya) Charan dhar aayeri.mp3"
      },
      {
        "SL": "G1557",
        "RAAG": "Chandrakouns",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Prem nagar ki dagar",
        "THAAT": "Kafi",
        "FILE_NAME": "G1557 Chandrakouns Sultal(m.laya) Prem nagar ki dagar.mp3"
      },
      {
        "SL": "G1558",
        "RAAG": "Goud",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Shyam chabi dhar phire",
        "THAAT": "Kafi",
        "FILE_NAME": "G1558 Goud Jhaptal(m.laya) Shyam chabi dhar phire.mp3"
      },
      {
        "SL": "G1559",
        "RAAG": "Malgunj",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Aaphee hota aad anth",
        "THAAT": "Kafi",
        "FILE_NAME": "G1559 Malgunj Chowtal(Vilambit) Aaphee hota aad anth.mp3"
      },
      {
        "SL": "H1560",
        "RAAG": "Asavari",
        "TAAL": "Teental(M.laya)",
        "STARTING_WORDS": "re ma pa ni dha dha pa",
        "THAAT": "Asavari",
        "FILE_NAME": "H1560 Asavari Teental(M.laya) re ma pa ni dha dha pa.mp3"
      },
      {
        "SL": "H1561",
        "RAAG": "Asavari",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "ma pa ni dha pa",
        "THAAT": "Asavari",
        "FILE_NAME": "H1561 Asavari Chowtal(Vilambit) ma pa ni dha pa.mp3"
      },
      {
        "SL": "H1562",
        "RAAG": "Asavari",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Chatur raag gaayo",
        "THAAT": "Asavari",
        "FILE_NAME": "H1562 Asavari Chowtal(Vilambit) Chatur raag gaayo.mp3"
      },
      {
        "SL": "H1563",
        "RAAG": "Asavari",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kahna mohe asavari",
        "THAAT": "Asavari",
        "FILE_NAME": "H1563 Asavari Teental(m.laya) Kahna mohe asavari.mp3"
      },
      {
        "SL": "H1564",
        "RAAG": "Asavari",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Morey kaan bhanakava",
        "THAAT": "Asavari",
        "FILE_NAME": "H1564 Asavari Teental(m.laya) Morey kaan bhanakava.mp3"
      },
      {
        "SL": "H1565",
        "RAAG": "Asavari",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Hari bin tera",
        "THAAT": "Asavari",
        "FILE_NAME": "H1565 Asavari Teental(m.laya) Hari bin tera.mp3"
      },
      {
        "SL": "H1566",
        "RAAG": "Asavari",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Akhiya laagi",
        "THAAT": "Asavari",
        "FILE_NAME": "H1566 Asavari Teental(m.laya) Akhiya laagi.mp3"
      },
      {
        "SL": "H1567",
        "RAAG": "Asavari",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Arey mana samazha",
        "THAAT": "Asavari",
        "FILE_NAME": "H1567 Asavari Teental(m.laya) Arey mana samazha.mp3"
      },
      {
        "SL": "H1568",
        "RAAG": "Asavari",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Abato sailee",
        "THAAT": "Asavari",
        "FILE_NAME": "H1568 Asavari Teental(m.laya) Abato sailee.mp3"
      },
      {
        "SL": "H1569",
        "RAAG": "Asavari",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Paharava jaago",
        "THAAT": "Asavari",
        "FILE_NAME": "H1569 Asavari Tilwada(Vilambit) Paharava jaago.mp3"
      },
      {
        "SL": "H1570",
        "RAAG": "Asavari",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Kaari karoo",
        "THAAT": "Asavari",
        "FILE_NAME": "H1570 Asavari Tilwada(Vilambit) Kaari karoo.mp3"
      },
      {
        "SL": "H1571",
        "RAAG": "Asavari",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Kagava bolahi re",
        "THAAT": "Asavari",
        "FILE_NAME": "H1571 Asavari Ektal(Vilambit) Kagava bolahi re.mp3"
      },
      {
        "SL": "H1572",
        "RAAG": "Asavari",
        "TAAL": "Jhumra(Vilambit)",
        "STARTING_WORDS": "Ram jai jai",
        "THAAT": "Asavari",
        "FILE_NAME": "H1572 Asavari Jhumra(Vilambit) Ram jai jai.mp3"
      },
      {
        "SL": "H1573",
        "RAAG": "Asavari",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Ati prataap tero",
        "THAAT": "Asavari",
        "FILE_NAME": "H1573 Asavari Chowtal(Vilambit) Ati prataap tero.mp3"
      },
      {
        "SL": "H1574",
        "RAAG": "Asavari",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Aayore jeetahee raaja",
        "THAAT": "Asavari",
        "FILE_NAME": "H1574 Asavari Chowtal(Vilambit) Aayore jeetahee raaja.mp3"
      },
      {
        "SL": "H1575",
        "RAAG": "Asavari",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Ati alasaniri",
        "THAAT": "Asavari",
        "FILE_NAME": "H1575 Asavari Chowtal(Vilambit) Ati alasaniri.mp3"
      },
      {
        "SL": "H1576",
        "RAAG": "Asavari",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tanana nadre daani",
        "THAAT": "Asavari",
        "FILE_NAME": "H1576 Asavari Teental(m.laya) Tanana nadre daani.mp3"
      },
      {
        "SL": "H1577",
        "RAAG": "Asavari",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Odatana dre taana",
        "THAAT": "Asavari",
        "FILE_NAME": "H1577 Asavari Ektal(m.laya) Odatana dre taana.mp3"
      },
      {
        "SL": "H1578",
        "RAAG": "Asavari(Komal Re)",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Meri akhiyan",
        "THAAT": "Asavari",
        "FILE_NAME": "H1578 Asavari(Komal Re) Teental(m.laya) Meri akhiyan.mp3"
      },
      {
        "SL": "H1579",
        "RAAG": "Asavari(Komal Re)",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Badaiya laavo",
        "THAAT": "Asavari",
        "FILE_NAME": "H1579 Asavari(Komal Re) Teental(m.laya) Badaiya laavo.mp3"
      },
      {
        "SL": "H1580",
        "RAAG": "Asavari(Komal Re)",
        "TAAL": "Roopak(m.laya)",
        "STARTING_WORDS": "Ho nandlaal",
        "THAAT": "Asavari",
        "FILE_NAME": "H1580 Asavari(Komal Re) Roopak(m.laya) Ho nandlaal.mp3"
      },
      {
        "SL": "H1581",
        "RAAG": "Asavari(Komal Re)",
        "TAAL": "Jhumra(Vilambit)",
        "STARTING_WORDS": "Beer baa manvaa",
        "THAAT": "Asavari",
        "FILE_NAME": "H1581 Asavari(Komal Re) Jhumra(Vilambit) Beer baa manvaa.mp3"
      },
      {
        "SL": "H1582",
        "RAAG": "Asavari(Komal Re)",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Laal alasaane ho",
        "THAAT": "Asavari",
        "FILE_NAME": "H1582 Asavari(Komal Re) Jhaptal(m.laya) Laal alasaane ho.mp3"
      },
      {
        "SL": "H1583",
        "RAAG": "Asavari(Komal Re)",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Barasat ghan",
        "THAAT": "Asavari",
        "FILE_NAME": "H1583 Asavari(Komal Re) Chowtal(Vilambit) Barasat ghan.mp3"
      },
      {
        "SL": "H1584",
        "RAAG": "Asavari(Komal Re)",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Nain alasaane",
        "THAAT": "Asavari",
        "FILE_NAME": "H1584 Asavari(Komal Re) Chowtal(Vilambit) Nain alasaane.mp3"
      },
      {
        "SL": "H1585",
        "RAAG": "Asavari(Komal Re)",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Aayee hai khelan",
        "THAAT": "Asavari",
        "FILE_NAME": "H1585 Asavari(Komal Re) Dhamar(Vilambit) Aayee hai khelan.mp3"
      },
      {
        "SL": "H1586",
        "RAAG": "Asavari(Komal Re)",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Aayo phaagun maas",
        "THAAT": "Asavari",
        "FILE_NAME": "H1586 Asavari(Komal Re) Dhamar(Vilambit) Aayo phaagun maas.mp3"
      },
      {
        "SL": "H1587",
        "RAAG": "Jounpuri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "re ma pa ni dha dha pa",
        "THAAT": "Asavari",
        "FILE_NAME": "H1587 Jounpuri Teental(m.laya) re ma pa ni dha dha pa.mp3"
      },
      {
        "SL": "H1588",
        "RAAG": "Jounpuri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Morey saiyaa",
        "THAAT": "Asavari",
        "FILE_NAME": "H1588 Jounpuri Teental(m.laya) Morey saiyaa.mp3"
      },
      {
        "SL": "H1589",
        "RAAG": "Jounpuri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Prabhuko sumar",
        "THAAT": "Asavari",
        "FILE_NAME": "H1589 Jounpuri Teental(m.laya) Prabhuko sumar.mp3"
      },
      {
        "SL": "H1590",
        "RAAG": "Jounpuri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Bhavaa ni dayaani",
        "THAAT": "Asavari",
        "FILE_NAME": "H1590 Jounpuri Teental(m.laya) Bhavaani dayaani.mp3"
      },
      {
        "SL": "H1591",
        "RAAG": "Jounpuri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Pariye paayan",
        "THAAT": "Asavari",
        "FILE_NAME": "H1591 Jounpuri Teental(m.laya) Pariye paayan.mp3"
      },
      {
        "SL": "H1592",
        "RAAG": "Jounpuri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Mori itani binathi",
        "THAAT": "Asavari",
        "FILE_NAME": "H1592 Jounpuri Teental(m.laya) Mori itani binathi.mp3"
      },
      {
        "SL": "H1593",
        "RAAG": "Jounpuri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Hari hari bhaj mana",
        "THAAT": "Asavari",
        "FILE_NAME": "H1593 Jounpuri Teental(m.laya) Hari hari bhaj mana.mp3"
      },
      {
        "SL": "H1594",
        "RAAG": "Jounpuri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Koun rijhavan",
        "THAAT": "Asavari",
        "FILE_NAME": "H1594 Jounpuri Teental(m.laya) Koun rijhavan.mp3"
      },
      {
        "SL": "H1595",
        "RAAG": "Jounpuri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Chom chanana",
        "THAAT": "Asavari",
        "FILE_NAME": "H1595 Jounpuri Teental(m.laya) Chom chanana.mp3"
      },
      {
        "SL": "H1596",
        "RAAG": "Jounpuri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Mana haravaa",
        "THAAT": "Asavari",
        "FILE_NAME": "H1596 Jounpuri Teental(m.laya) Mana haravaa.mp3"
      },
      {
        "SL": "H1597",
        "RAAG": "Jounpuri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kaanan kar mose raar",
        "THAAT": "Asavari",
        "FILE_NAME": "H1597 Jounpuri Teental(m.laya) Kaanan kar mose raar.mp3"
      },
      {
        "SL": "H1598",
        "RAAG": "Jounpuri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Barakatva liye thaado",
        "THAAT": "Asavari",
        "FILE_NAME": "H1598 Jounpuri Teental(m.laya) Barakatva liye thaado.mp3"
      },
      {
        "SL": "H1599",
        "RAAG": "Jounpuri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Pakar pakar banamaali",
        "THAAT": "Asavari",
        "FILE_NAME": "H1599 Jounpuri Teental(m.laya) Pakar pakar banamaali.mp3"
      },
      {
        "SL": "H1600",
        "RAAG": "Jounpuri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Bhor kayi milan",
        "THAAT": "Asavari",
        "FILE_NAME": "H1600 Jounpuri Teental(m.laya) Bhor kayi milan.mp3"
      },
      {
        "SL": "H1601",
        "RAAG": "Jounpuri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Payal kee jhankaar",
        "THAAT": "Asavari",
        "FILE_NAME": "H1601 Jounpuri Teental(m.laya) Payal kee jhankaar.mp3"
      },
      {
        "SL": "H1602",
        "RAAG": "Jounpuri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Phulvanaki gh endh",
        "THAAT": "Asavari",
        "FILE_NAME": "H1602 Jounpuri Teental(m.laya) Phulvanaki ghendh.mp3"
      },
      {
        "SL": "H1603",
        "RAAG": "Jounpuri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Jaat na laagi",
        "THAAT": "Asavari",
        "FILE_NAME": "H1603 Jounpuri Teental(m.laya) Jaat na laagi.mp3"
      },
      {
        "SL": "H1604",
        "RAAG": "Jounpuri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Taan pahale",
        "THAAT": "Asavari",
        "FILE_NAME": "H1604 Jounpuri Teental(m.laya) Taan pahale.mp3"
      },
      {
        "SL": "H1605",
        "RAAG": "Jounpuri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Chatrang rassan gaayi",
        "THAAT": "Asavari",
        "FILE_NAME": "H1605 Jounpuri Teental(m.laya) Chatrang rassan gaayi.mp3"
      },
      {
        "SL": "H1606",
        "RAAG": "Jounpuri",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Kaise ke dharoo",
        "THAAT": "Asavari",
        "FILE_NAME": "H1606 Jounpuri Tilwada(Vilambit) Kaise ke dharoo.mp3"
      },
      {
        "SL": "H1607",
        "RAAG": "Jounpuri",
        "TAAL": "Adachowtal(m.laya)",
        "STARTING_WORDS": "Mereto man kaan",
        "THAAT": "Asavari",
        "FILE_NAME": "H1607 Jounpuri Adachowtal(m.laya) Mereto man kaan.mp3"
      },
      {
        "SL": "H1608",
        "RAAG": "Jounpuri",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Kaise ke aavoo",
        "THAAT": "Asavari",
        "FILE_NAME": "H1608 Jounpuri Tilwada(Vilambit) Kaise ke aavoo.mp3"
      },
      {
        "SL": "H1609",
        "RAAG": "Jounpuri",
        "TAAL": "Jhumra(Vilambit)",
        "STARTING_WORDS": "Baaje jhanan",
        "THAAT": "Asavari",
        "FILE_NAME": "H1609 Jounpuri Jhumra(Vilambit) Baaje jhanan.mp3"
      },
      {
        "SL": "H1610",
        "RAAG": "Jounpuri",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Bhali bajaayi",
        "THAAT": "Asavari",
        "FILE_NAME": "H1610 Jounpuri Tilwada(Vilambit) Bhali bajaayi.mp3"
      },
      {
        "SL": "H1611",
        "RAAG": "Jounpuri",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Maha bhaag",
        "THAAT": "Asavari",
        "FILE_NAME": "H1611 Jounpuri Ektal(Vilambit) Maha bhaag.mp3"
      },
      {
        "SL": "H1612",
        "RAAG": "Jounpuri",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Hunto jaiho",
        "THAAT": "Asavari",
        "FILE_NAME": "H1612 Jounpuri Tilwada(Vilambit) Hunto jaiho.mp3"
      },
      {
        "SL": "H1613",
        "RAAG": "Jounpuri",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Saavarori daiya",
        "THAAT": "Asavari",
        "FILE_NAME": "H1613 Jounpuri Tilwada(Vilambit) Saavarori daiya.mp3"
      },
      {
        "SL": "H1614",
        "RAAG": "Jounpuri",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Ho nahi jaihoo",
        "THAAT": "Asavari",
        "FILE_NAME": "H1614 Jounpuri Ektal(Vilambit) Ho nahi jaihoo.mp3"
      },
      {
        "SL": "H1615",
        "RAAG": "Jounpuri",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Abato kahoo",
        "THAAT": "Asavari",
        "FILE_NAME": "H1615 Jounpuri Ektal(Vilambit) Abato kahoo.mp3"
      },
      {
        "SL": "H1616",
        "RAAG": "Jounpuri",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Yahoo daravajvaa",
        "THAAT": "Asavari",
        "FILE_NAME": "H1616 Jounpuri Tilwada(Vilambit) Yahoo daravajvaa.mp3"
      },
      {
        "SL": "H1617",
        "RAAG": "Jounpuri",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Unasang j aay kaho",
        "THAAT": "Asavari",
        "FILE_NAME": "H1617 Jounpuri Teental(m.laya) Unasang jaay kaho.mp3"
      },
      {
        "SL": "H1618",
        "RAAG": "Jounpuri",
        "TAAL": "Jhumra(Vilambit)",
        "STARTING_WORDS": "So abaroung",
        "THAAT": "Asavari",
        "FILE_NAME": "H1618 Jounpuri Jhumra(Vilambit) So abaroung.mp3"
      },
      {
        "SL": "H1619",
        "RAAG": "Jounpuri",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Koun sikhayi",
        "THAAT": "Asavari",
        "FILE_NAME": "H1619 Jounpuri Jhaptal(m.laya) Koun sikhayi.mp3"
      },
      {
        "SL": "H1620",
        "RAAG": "Jounpuri",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Maano jaraa",
        "THAAT": "Asavari",
        "FILE_NAME": "H1620 Jounpuri Jhaptal(m.laya) Maano jaraa.mp3"
      },
      {
        "SL": "H1621",
        "RAAG": "Jounpuri",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Gokal gochaaran",
        "THAAT": "Asavari",
        "FILE_NAME": "H1621 Jounpuri Chowtal(Vilambit) Gokal gochaaran.mp3"
      },
      {
        "SL": "H1622",
        "RAAG": "Jounpuri",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Aayiye ju aayiye",
        "THAAT": "Asavari",
        "FILE_NAME": "H1622 Jounpuri Chowtal(Vilambit) Aayiye ju aayiye.mp3"
      },
      {
        "SL": "H1623",
        "RAAG": "Jounpuri",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Dekhiyath aanound",
        "THAAT": "Asavari",
        "FILE_NAME": "H1623 Jounpuri Chowtal(Vilambit) Dekhiyath aanound.mp3"
      },
      {
        "SL": "H1624",
        "RAAG": "Jounpuri",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Sab mil khele",
        "THAAT": "Asavari",
        "FILE_NAME": "H1624 Jounpuri Dhamar(Vilambit) Sab mil khele.mp3"
      },
      {
        "SL": "H1625",
        "RAAG": "Jounpuri",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Yeriye mai kaise",
        "THAAT": "Asavari",
        "FILE_NAME": "H1625 Jounpuri Dhamar(Vilambit) Yeriye mai kaise.mp3"
      },
      {
        "SL": "H1626",
        "RAAG": "Jounpuri",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Haare dhaph baajan",
        "THAAT": "Asavari",
        "FILE_NAME": "H1626 Jounpuri Dhamar(Vilambit) Haare dhaph baajan.mp3"
      },
      {
        "SL": "H1627",
        "RAAG": "Jounpuri",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Tananana deem deem",
        "THAAT": "Asavari",
        "FILE_NAME": "H1627 Jounpuri Tilwada(Vilambit) Tananana deem deem.mp3"
      },
      {
        "SL": "H1628",
        "RAAG": "Darbari kanada",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "re re sa ni sa re re ga",
        "THAAT": "Asavari",
        "FILE_NAME": "H1628 Darbari kanada Teental(m.laya) re re sa ni sa re re ga.mp3"
      },
      {
        "SL": "H1629",
        "RAAG": "Darbari kanada",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Darabari ko roop",
        "THAAT": "Asavari",
        "FILE_NAME": "H1629 Darbari kanada Chowtal(Vilambit) Darabari ko roop.mp3"
      },
      {
        "SL": "H1630",
        "RAAG": "Darbari kanada",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Darabari ki surat",
        "THAAT": "Asavari",
        "FILE_NAME": "H1630 Darbari kanada Jhaptal(m.laya) Darabari ki surat.mp3"
      },
      {
        "SL": "H1631",
        "RAAG": "Darbari kanada",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Dashamit thaat",
        "THAAT": "Asavari",
        "FILE_NAME": "H1631 Darbari kanada Teental(m.laya) Dashamit thaat.mp3"
      },
      {
        "SL": "H1632",
        "RAAG": "Darbari kanada",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Jo guru kripaa kare",
        "THAAT": "Asavari",
        "FILE_NAME": "H1632 Darbari kanada Teental(m.laya) Jo guru kripaa kare.mp3"
      },
      {
        "SL": "H1633",
        "RAAG": "Darbari kanada",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Samajhat naa mana",
        "THAAT": "Asavari",
        "FILE_NAME": "H1633 Darbari kanada Teental(m.laya) Samajhat naa mana.mp3"
      },
      {
        "SL": "H1634",
        "RAAG": "Darbari kanada",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Sumaran kar mana",
        "THAAT": "Asavari",
        "FILE_NAME": "H1634 Darbari kanada Ektal(m.laya) Sumaran kar mana.mp3"
      },
      {
        "SL": "H1635",
        "RAAG": "Darbari kanada",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Aour nahi kachu kaam",
        "THAAT": "Asavari",
        "FILE_NAME": "H1635 Darbari kanada Teental(m.laya) Aour nahi kachu kaam.mp3"
      },
      {
        "SL": "H1636",
        "RAAG": "Darbari kanada",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Iname koun raadhika",
        "THAAT": "Asavari",
        "FILE_NAME": "H1636 Darbari kanada Teental(m.laya) Iname koun raadhika.mp3"
      },
      {
        "SL": "H1637",
        "RAAG": "Darbari kanada",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Giridhar brijdhar",
        "THAAT": "Asavari",
        "FILE_NAME": "H1637 Darbari kanada Teental(m.laya) Giridhar brijdhar.mp3"
      },
      {
        "SL": "H1638",
        "RAAG": "Darbari kanada",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ghar jaanede",
        "THAAT": "Asavari",
        "FILE_NAME": "H1638 Darbari kanada Teental(m.laya) Ghar jaanede.mp3"
      },
      {
        "SL": "H1639",
        "RAAG": "Darbari kanada",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tuva saayi",
        "THAAT": "Asavari",
        "FILE_NAME": "H1639 Darbari kanada Teental(m.laya) Tuva saayi.mp3"
      },
      {
        "SL": "H1640",
        "RAAG": "Darbari kanada",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Madhuva bhar laa",
        "THAAT": "Asavari",
        "FILE_NAME": "H1640 Darbari kanada Teental(m.laya) Madhuva bhar laa.mp3"
      },
      {
        "SL": "H1641",
        "RAAG": "Darbari kanada",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Paaras sam",
        "THAAT": "Asavari",
        "FILE_NAME": "H1641 Darbari kanada Teental(m.laya) Paaras sam.mp3"
      },
      {
        "SL": "H1642",
        "RAAG": "Darbari kanada",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tohe darbaar",
        "THAAT": "Asavari",
        "FILE_NAME": "H1642 Darbari kanada Teental(m.laya) Tohe darbaar.mp3"
      },
      {
        "SL": "H1643",
        "RAAG": "Darbari kanada",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Bandhanvaa",
        "THAAT": "Asavari",
        "FILE_NAME": "H1643 Darbari kanada Teental(m.laya) Bandhanvaa.mp3"
      },
      {
        "SL": "H1644",
        "RAAG": "Darbari kanada",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Nainso nain melaaye",
        "THAAT": "Asavari",
        "FILE_NAME": "H1644 Darbari kanada Teental(m.laya) Nainso nain melaaye.mp3"
      },
      {
        "SL": "H1645",
        "RAAG": "Darbari kanada",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Dartan dartan",
        "THAAT": "Asavari",
        "FILE_NAME": "H1645 Darbari kanada Ektal(m.laya) Dartan dartan.mp3"
      },
      {
        "SL": "H1646",
        "RAAG": "Darbari kanada",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Ye tabato toom",
        "THAAT": "Asavari",
        "FILE_NAME": "H1646 Darbari kanada Ektal(Vilambit) Ye tabato toom.mp3"
      },
      {
        "SL": "H1647",
        "RAAG": "Darbari kanada",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Mubarak baandiyaa",
        "THAAT": "Asavari",
        "FILE_NAME": "H1647 Darbari kanada Ektal(Vilambit) Mubarak baandiyaa.mp3"
      },
      {
        "SL": "H1648",
        "RAAG": "Darbari kanada",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Dulhan tori aachi bani",
        "THAAT": "Asavari",
        "FILE_NAME": "H1648 Darbari kanada Ektal(Vilambit) Dulhan tori aachi bani.mp3"
      },
      {
        "SL": "H1649",
        "RAAG": "Darbari kanada",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Hazarat tore kamaan",
        "THAAT": "Asavari",
        "FILE_NAME": "H1649 Darbari kanada Ektal(Vilambit) Hazarat tore kamaan.mp3"
      },
      {
        "SL": "H1650",
        "RAAG": "Darbari kanada",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Suhaagan cholaraa",
        "THAAT": "Asavari",
        "FILE_NAME": "H1650 Darbari kanada Ektal(Vilambit) Suhaagan cholaraa.mp3"
      },
      {
        "SL": "H1651",
        "RAAG": "Darbari kanada",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Suve se haravaa",
        "THAAT": "Asavari",
        "FILE_NAME": "H1651 Darbari kanada Tilwada(Vilambit) Suve se haravaa.mp3"
      },
      {
        "SL": "H1652",
        "RAAG": "Darbari kanada",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Kharaj rikhab",
        "THAAT": "Asavari",
        "FILE_NAME": "H1652 Darbari kanada Chowtal(Vilambit) Kharaj rikhab.mp3"
      },
      {
        "SL": "H1653",
        "RAAG": "Darbari kanada",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Raajat choundra",
        "THAAT": "Asavari",
        "FILE_NAME": "H1653 Darbari kanada Chowtal(Vilambit) Raajat choundra.mp3"
      },
      {
        "SL": "H1654",
        "RAAG": "Darbari kanada",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Aho dinaanaath",
        "THAAT": "Asavari",
        "FILE_NAME": "H1654 Darbari kanada Chowtal(Vilambit) Aho dinaanaath.mp3"
      },
      {
        "SL": "H1655",
        "RAAG": "Darbari kanada",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Dhan dhan tu",
        "THAAT": "Asavari",
        "FILE_NAME": "H1655 Darbari kanada Chowtal(Vilambit) Dhan dhan tu.mp3"
      },
      {
        "SL": "H1656",
        "RAAG": "Darbari kanada",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Mandar dhaph baajan",
        "THAAT": "Asavari",
        "FILE_NAME": "H1656 Darbari kanada Dhamar(Vilambit) Mandar dhaph baajan.mp3"
      },
      {
        "SL": "H1657",
        "RAAG": "Darbari kanada",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Aho tum gaavo",
        "THAAT": "Asavari",
        "FILE_NAME": "H1657 Darbari kanada Dhamar(Vilambit) Aho tum gaavo.mp3"
      },
      {
        "SL": "H1658",
        "RAAG": "Darbari kanada",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Na dredredre",
        "THAAT": "Asavari",
        "FILE_NAME": "H1658 Darbari kanada Teental(m.laya) Na dredredre.mp3"
      },
      {
        "SL": "H1659",
        "RAAG": "Adana",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "re sa re ni sa",
        "THAAT": "Asavari",
        "FILE_NAME": "H1659 Adana Jhaptal(m.laya) re sa re ni sa.mp3"
      },
      {
        "SL": "H1660",
        "RAAG": "Adana",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Gaavat chatur guni",
        "THAAT": "Asavari",
        "FILE_NAME": "H1660 Adana Jhaptal(m.laya) Gaavat chatur guni.mp3"
      },
      {
        "SL": "H1661",
        "RAAG": "Adana",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Gaay adana",
        "THAAT": "Asavari",
        "FILE_NAME": "H1661 Adana Teental(m.laya) Gaay adana.mp3"
      },
      {
        "SL": "H1662",
        "RAAG": "Adana",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Mood abudh",
        "THAAT": "Asavari",
        "FILE_NAME": "H1662 Adana Teental(m.laya) Mood abudh.mp3"
      },
      {
        "SL": "H1663",
        "RAAG": "Adana",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Nike nike laagat",
        "THAAT": "Asavari",
        "FILE_NAME": "H1663 Adana Teental(m.laya) Nike nike laagat.mp3"
      },
      {
        "SL": "H1664",
        "RAAG": "Adana",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Mundari mori kaahe",
        "THAAT": "Asavari",
        "FILE_NAME": "H1664 Adana Teental(m.laya) Mundari mori kaahe.mp3"
      },
      {
        "SL": "H1665",
        "RAAG": "Adana",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Yeri mohe jaanede",
        "THAAT": "Asavari",
        "FILE_NAME": "H1665 Adana Teental(m.laya) Yeri mohe jaanede.mp3"
      },
      {
        "SL": "H1666",
        "RAAG": "Adana",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Hazarat khwaja sung",
        "THAAT": "Asavari",
        "FILE_NAME": "H1666 Adana Teental(m.laya) Hazarat khwaja sung.mp3"
      },
      {
        "SL": "H1667",
        "RAAG": "Adana",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Aayeere karkaraa",
        "THAAT": "Asavari",
        "FILE_NAME": "H1667 Adana Teental(m.laya) Aayeere karkaraa.mp3"
      },
      {
        "SL": "H1668",
        "RAAG": "Adana",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Chaila deho chailaa",
        "THAAT": "Asavari",
        "FILE_NAME": "H1668 Adana Teental(m.laya) Chaila deho chailaa.mp3"
      },
      {
        "SL": "H1669",
        "RAAG": "Adana",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Gagari mori bharan",
        "THAAT": "Asavari",
        "FILE_NAME": "H1669 Adana Teental(m.laya) Gagari mori bharan.mp3"
      },
      {
        "SL": "H1670",
        "RAAG": "Adana",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Achaanak aaye",
        "THAAT": "Asavari",
        "FILE_NAME": "H1670 Adana Tilwada(Vilambit) Achaanak aaye.mp3"
      },
      {
        "SL": "H1671",
        "RAAG": "Adana",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Jo teri razaa",
        "THAAT": "Asavari",
        "FILE_NAME": "H1671 Adana Tilwada(Vilambit) Jo teri razaa.mp3"
      },
      {
        "SL": "H1672",
        "RAAG": "Adana",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Ittave rokh honaa",
        "THAAT": "Asavari",
        "FILE_NAME": "H1672 Adana Tilwada(Vilambit) Ittave rokh honaa.mp3"
      },
      {
        "SL": "H1673",
        "RAAG": "Adana",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Na mose samaz",
        "THAAT": "Asavari",
        "FILE_NAME": "H1673 Adana Tilwada(Vilambit) Na mose samaz.mp3"
      },
      {
        "SL": "H1674",
        "RAAG": "Adana",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Lounka bilounkaa",
        "THAAT": "Asavari",
        "FILE_NAME": "H1674 Adana Jhaptal(m.laya) Lounka bilounkaa.mp3"
      },
      {
        "SL": "H1675",
        "RAAG": "Adana",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Chadi asavaaree",
        "THAAT": "Asavari",
        "FILE_NAME": "H1675 Adana Sultal(m.laya) Chadi asavaaree.mp3"
      },
      {
        "SL": "H1676",
        "RAAG": "Adana",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Tohiko dhyaan lago",
        "THAAT": "Asavari",
        "FILE_NAME": "H1676 Adana Chowtal(Vilambit) Tohiko dhyaan lago.mp3"
      },
      {
        "SL": "H1677",
        "RAAG": "Adana",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Neer cheer mile",
        "THAAT": "Asavari",
        "FILE_NAME": "H1677 Adana Chowtal(Vilambit) Neer cheer mile.mp3"
      },
      {
        "SL": "H1678",
        "RAAG": "Adana",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Gaayan sohi jaane",
        "THAAT": "Asavari",
        "FILE_NAME": "H1678 Adana Chowtal(Vilambit) Gaayan sohi jaane.mp3"
      },
      {
        "SL": "H1679",
        "RAAG": "Adana",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Mohuddeen",
        "THAAT": "Asavari",
        "FILE_NAME": "H1679 Adana Chowtal(Vilambit) Mohuddeen.mp3"
      },
      {
        "SL": "H1680",
        "RAAG": "Adana",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Terori mukhaarbind",
        "THAAT": "Asavari",
        "FILE_NAME": "H1680 Adana Chowtal(Vilambit) Terori mukhaarbind.mp3"
      },
      {
        "SL": "H1681",
        "RAAG": "Adana",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Kaare kunjan kaare",
        "THAAT": "Asavari",
        "FILE_NAME": "H1681 Adana Chowtal(Vilambit) Kaare kunjan kaare.mp3"
      },
      {
        "SL": "H1682",
        "RAAG": "Adana",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Ye aali aab dekhiye",
        "THAAT": "Asavari",
        "FILE_NAME": "H1682 Adana Dhamar(Vilambit) Ye aali aab dekhiye.mp3"
      },
      {
        "SL": "H1683",
        "RAAG": "Adana",
        "TAAL": "Dhamar(Vilambi t)",
        "STARTING_WORDS": "Kaal tum haha",
        "THAAT": "Asavari",
        "FILE_NAME": "H1683 Adana Dhamar(Vilambit) Kaal tum haha.mp3"
      },
      {
        "SL": "H1684",
        "RAAG": "Adana",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Taanom tanana",
        "THAAT": "Asavari",
        "FILE_NAME": "H1684 Adana Teental(m.laya) Taanom tanana.mp3"
      },
      {
        "SL": "H1685",
        "RAAG": "Adana",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Taannom tannom",
        "THAAT": "Asavari",
        "FILE_NAME": "H1685 Adana Ektal(m.laya) Taannom tannom.mp3"
      },
      {
        "SL": "H1686",
        "RAAG": "Desi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Mitava balamuva",
        "THAAT": "Asavari",
        "FILE_NAME": "H1686 Desi Teental(m.laya) Mitava balamuva.mp3"
      },
      {
        "SL": "H1687",
        "RAAG": "Desi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Chatar balamuva",
        "THAAT": "Asavari",
        "FILE_NAME": "H1687 Desi Teental(m.laya) Chatar balamuva.mp3"
      },
      {
        "SL": "H1688",
        "RAAG": "Desi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Gunde gunde laavore",
        "THAAT": "Asavari",
        "FILE_NAME": "H1688 Desi Teental(m.laya) Gunde gunde laavore.mp3"
      },
      {
        "SL": "H1689",
        "RAAG": "Desi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Saachi kahat hai",
        "THAAT": "Asavari",
        "FILE_NAME": "H1689 Desi Teental(m.laya) Saachi kahat hai.mp3"
      },
      {
        "SL": "H1690",
        "RAAG": "Desi",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Nirounjan keeje",
        "THAAT": "Asavari",
        "FILE_NAME": "H1690 Desi Jhaptal(m.laya) Nirounjan keeje.mp3"
      },
      {
        "SL": "H1691",
        "RAAG": "Desi",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Udoundan baaje",
        "THAAT": "Asavari",
        "FILE_NAME": "H1691 Desi Jhaptal(m.laya) Udoundan baaje.mp3"
      },
      {
        "SL": "H1692",
        "RAAG": "Desi",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Naina lageree aali",
        "THAAT": "Asavari",
        "FILE_NAME": "H1692 Desi Jhaptal(m.laya) Naina lageree aali.mp3"
      },
      {
        "SL": "H1693",
        "RAAG": "Desi",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Sakhi maika chain",
        "THAAT": "Asavari",
        "FILE_NAME": "H1693 Desi Tilwada(Vilambit) Sakhi maika chain.mp3"
      },
      {
        "SL": "H1694",
        "RAAG": "Desi",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Naiyaa mori bhayee",
        "THAAT": "Asavari",
        "FILE_NAME": "H1694 Desi Ektal(Vilambit) Naiyaa mori bhayee.mp3"
      },
      {
        "SL": "H1695",
        "RAAG": "Desi",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Mera mana harleenore",
        "THAAT": "Asavari",
        "FILE_NAME": "H1695 Desi Ektal(Vilambit) Mera mana harleenore.mp3"
      },
      {
        "SL": "H1696",
        "RAAG": "Desi",
        "TAAL": "Adachowtal(Vilambit)",
        "STARTING_WORDS": "Piya hama vaari",
        "THAAT": "Asavari",
        "FILE_NAME": "H1696 Desi Adachowtal(Vilambit) Piya hama vaari.mp3"
      },
      {
        "SL": "H1697",
        "RAAG": "Desi",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Avadhapur nagari",
        "THAAT": "Asavari",
        "FILE_NAME": "H1697 Desi Chowtal(Vilambit) Avadhapur nagari.mp3"
      },
      {
        "SL": "H1698",
        "RAAG": "Desi",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Baalam hamare bides",
        "THAAT": "Asavari",
        "FILE_NAME": "H1698 Desi Dhamar(Vilambit) Baalam hamare bides.mp3"
      },
      {
        "SL": "H1699",
        "RAAG": "Desi",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Sakhi thaado s undar",
        "THAAT": "Asavari",
        "FILE_NAME": "H1699 Desi Dhamar(Vilambit) Sakhi thaado sundar.mp3"
      },
      {
        "SL": "H1700",
        "RAAG": "Desi",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Jin chuvo moraa",
        "THAAT": "Asavari",
        "FILE_NAME": "H1700 Desi Dhamar(Vilambit) Jin chuvo moraa.mp3"
      },
      {
        "SL": "H1701",
        "RAAG": "Komal Desi",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Gokul dhoom machayiri",
        "THAAT": "Asavari",
        "FILE_NAME": "H1701 Komal Desi Dhamar(Vilambit) Gokul dhoom machayiri.mp3"
      },
      {
        "SL": "H1702",
        "RAAG": "Gandhari",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Suno nanandiya",
        "THAAT": "Asavari",
        "FILE_NAME": "H1702 Gandhari Teental(m.laya) Suno nanandiya.mp3"
      },
      {
        "SL": "H1703",
        "RAAG": "Gandhari",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Navariyaa jhanjari",
        "THAAT": "Asavari",
        "FILE_NAME": "H1703 Gandhari Tilwada(Vilambit) Navariyaa jhanjari.mp3"
      },
      {
        "SL": "H1704",
        "RAAG": "Gandhari",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Jogi kake meet",
        "THAAT": "Asavari",
        "FILE_NAME": "H1704 Gandhari Tilwada(Vilambit) Jogi kake meet.mp3"
      },
      {
        "SL": "H1705",
        "RAAG": "Gandhari",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Kahiyo udho tum jaane",
        "THAAT": "Asavari",
        "FILE_NAME": "H1705 Gandhari Chowtal(Vilambit) Kahiyo udho tum jaane.mp3"
      },
      {
        "SL": "H1706",
        "RAAG": "Gandhari",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Beerey ba toyee meri",
        "THAAT": "Asavari",
        "FILE_NAME": "H1706 Gandhari Chowtal(Vilambit) Beerey batoyee meri.mp3"
      },
      {
        "SL": "H1707",
        "RAAG": "Gandhari",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Kaanha ju kaan suni",
        "THAAT": "Asavari",
        "FILE_NAME": "H1707 Gandhari Dhamar(Vilambit) Kaanha ju kaan suni.mp3"
      },
      {
        "SL": "H1708",
        "RAAG": "Gandhari",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Hori mey kaise karat",
        "THAAT": "Asavari",
        "FILE_NAME": "H1708 Gandhari Dhamar(Vilambit) Hori mey kaise karat.mp3"
      },
      {
        "SL": "H1709",
        "RAAG": "Gandhari",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Maarat pichkaari",
        "THAAT": "Asavari",
        "FILE_NAME": "H1709 Gandhari Dhamar(Vilambit) Maarat pichkaari.mp3"
      },
      {
        "SL": "H1710",
        "RAAG": "Devgandhar",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Devghandhar",
        "THAAT": "Asavari",
        "FILE_NAME": "H1710 Devgandhar Jhaptal(m.laya) Devghandhar.mp3"
      },
      {
        "SL": "H1711",
        "RAAG": "Devgandhar",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Laadila banaaban aay a",
        "THAAT": "Asavari",
        "FILE_NAME": "H1711 Devgandhar Ektal(m.laya) Laadila banaaban aaya.mp3"
      },
      {
        "SL": "H1712",
        "RAAG": "Devgandhar",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Rainake jaage",
        "THAAT": "Asavari",
        "FILE_NAME": "H1712 Devgandhar Ektal(Vilambit) Rainake jaage.mp3"
      },
      {
        "SL": "H1713",
        "RAAG": "Khat",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Pratham sudh thaat",
        "THAAT": "Asavari",
        "FILE_NAME": "H1713 Khat Jhaptal(m.laya) Pratham sudh thaat.mp3"
      },
      {
        "SL": "H1714",
        "RAAG": "Khat",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Aaj brijraaj",
        "THAAT": "Asavari",
        "FILE_NAME": "H1714 Khat Jhaptal(m.laya) Aaj brijraaj.mp3"
      },
      {
        "SL": "H1715",
        "RAAG": "Khat",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Mukut maathe dhare",
        "THAAT": "Asavari",
        "FILE_NAME": "H1715 Khat Jhaptal(m.laya) Mukut maathe dhare.mp3"
      },
      {
        "SL": "H1716",
        "RAAG": "Khat",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Raavare nound",
        "THAAT": "Asavari",
        "FILE_NAME": "H1716 Khat Jhaptal(m.laya) Raavare nound.mp3"
      },
      {
        "SL": "H1717",
        "RAAG": "Khat",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Aaj nandlaal",
        "THAAT": "Asavari",
        "FILE_NAME": "H1717 Khat Jhaptal(m.laya) Aaj nandlaal.mp3"
      },
      {
        "SL": "H1718",
        "RAAG": "Khat",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Nakhan par ratan lakh",
        "THAAT": "Asavari",
        "FILE_NAME": "H1718 Khat Jhaptal(m.laya) Nakhan par ratan lakh.mp3"
      },
      {
        "SL": "H1719",
        "RAAG": "Khat",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Koun khele tose hori",
        "THAAT": "Asavari",
        "FILE_NAME": "H1719 Khat Dhamar(Vilambit) Koun khele tose hori.mp3"
      },
      {
        "SL": "H1720",
        "RAAG": "Khat",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kaanhar karo nand",
        "THAAT": "Asavari",
        "FILE_NAME": "H1720 Khat Teental(m.laya) Kaanhar karo nand.mp3"
      },
      {
        "SL": "H1721",
        "RAAG": "Khat",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Yeri aaj sakhee",
        "THAAT": "Asavari",
        "FILE_NAME": "H1721 Khat Teental(m.laya) Yeri aaj sakhee.mp3"
      },
      {
        "SL": "H1722",
        "RAAG": "Khat",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Aaho gobind brij",
        "THAAT": "Asavari",
        "FILE_NAME": "H1722 Khat Jhaptal(m.laya) Aaho gobind brij.mp3"
      },
      {
        "SL": "H1723",
        "RAAG": "Khat",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Utho pyare",
        "THAAT": "Asavari",
        "FILE_NAME": "H1723 Khat Chowtal(Vilambit) Utho pyare.mp3"
      },
      {
        "SL": "H1724",
        "RAAG": "Khat",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Tero kaan",
        "THAAT": "Asavari",
        "FILE_NAME": "H1724 Khat Dhamar(Vilambit) Tero kaan.mp3"
      },
      {
        "SL": "H1725",
        "RAAG": "Khat-todi",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Dhetele dhetele",
        "THAAT": "Asavari",
        "FILE_NAME": "H1725 Khat-todi Ektal(m.laya) Dhetele dhetele.mp3"
      },
      {
        "SL": "H1726",
        "RAAG": "Jangalaa",
        "TAAL": "Deepchandi(m.laya)",
        "STARTING_WORDS": "Shounkar dhoom",
        "THAAT": "Asavari",
        "FILE_NAME": "H1726 Jangalaa Deepchandi(m.laya) Shounkar dhoom.mp3"
      },
      {
        "SL": "H1727",
        "RAAG": "Jangalaa",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Bhoop sakhiree",
        "THAAT": "Asavari",
        "FILE_NAME": "H1727 Jangalaa Chowtal(Vilambit) Bhoop sakhiree.mp3"
      },
      {
        "SL": "H1728",
        "RAAG": "Jheelap",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Hazarat ali ghar",
        "THAAT": "Asavari",
        "FILE_NAME": "H1728 Jheelap Teental(m.laya) Hazarat ali ghar.mp3"
      },
      {
        "SL": "H1729",
        "RAAG": "Jheelap",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Maito aiso rang",
        "THAAT": "Asavari",
        "FILE_NAME": "H1729 Jheelap Dhamar(Vilambit) Maito aiso rang.mp3"
      },
      {
        "SL": "H1730",
        "RAAG": "Jheelap",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Dirdir tom naa dirdir",
        "THAAT": "Asavari",
        "FILE_NAME": "H1730 Jheelap Jhaptal(m.laya) Dirdir tom naa dirdir.mp3"
      },
      {
        "SL": "H1731",
        "RAAG": "Gopika basant",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Daata vidhaataa",
        "THAAT": "Asavari",
        "FILE_NAME": "H1731 Gopika basant Jhaptal(m.laya) Daata vidhaataa.mp3"
      },
      {
        "SL": "H1732",
        "RAAG": "Gopika basant",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Vidya daani dayaani",
        "THAAT": "Asavari",
        "FILE_NAME": "H1732 Gopika basant Jhaptal(m.laya) Vidya daani dayaani.mp3"
      },
      {
        "SL": "H1733",
        "RAAG": "Gopika basant",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Ye kaahe ho mohe",
        "THAAT": "Asavari",
        "FILE_NAME": "H1733 Gopika basant Tilwada(Vilambit) Ye kaahe ho mohe.mp3"
      },
      {
        "SL": "H1734",
        "RAAG": "Sindh bhairavi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Mandar pancham",
        "THAAT": "Asavari",
        "FILE_NAME": "H1734 Sindh bhairavi Teental(m.laya) Mandar pancham.mp3"
      },
      {
        "SL": "H1735",
        "RAAG": "Sindh bhairavi",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Gyaan gun daataa",
        "THAAT": "Asavari",
        "FILE_NAME": "H1735 Sindh bhairavi Jhaptal(m.laya) Gyaan gun daataa.mp3"
      },
      {
        "SL": "J1736",
        "RAAG": "Bhairavi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "sa dha pa dha ma pa",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1736 Bhairavi Teental(m.laya) sa dha pa dha ma pa.mp3"
      },
      {
        "SL": "J1737",
        "RAAG": "Bhairavi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "ga ga re sa ni sa dha",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1737 Bhairavi Teental(m.laya) ga ga re sa ni sa dha.mp3"
      },
      {
        "SL": "J1738",
        "RAAG": "Bhairavi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Bharavi kahi mana",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1738 Bhairavi Teental(m.laya) Bharavi kahi mana.mp3"
      },
      {
        "SL": "J1739",
        "RAAG": "Bhairavi",
        "TAAL": "Dadra(m.laya)",
        "STARTING_WORDS": "Gobindko bhajan",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1739 Bhairavi Dadra(m.laya) Gobindko bhajan.mp3"
      },
      {
        "SL": "J1740",
        "RAAG": "Bhairavi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Soch manaa tu koun",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1740 Bhairavi Teental(m.laya) Soch manaa tu koun.mp3"
      },
      {
        "SL": "J1741",
        "RAAG": "Bhairavi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Jaaye tu mana",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1741 Bhairavi Teental(m.laya) Jaaye tu mana.mp3"
      },
      {
        "SL": "J1742",
        "RAAG": "Bhairavi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kaisiye bhalayire",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1742 Bhairavi Teental(m.laya) Kaisiye bhalayire.mp3"
      },
      {
        "SL": "J1743",
        "RAAG": "Bhairavi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ata sande naale galaa",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1743 Bhairavi Teental(m.laya) Ata sande naale galaa.mp3"
      },
      {
        "SL": "J1744",
        "RAAG": "Bhairavi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tore banavaari",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1744 Bhairavi Teental(m.laya) Tore banavaari.mp3"
      },
      {
        "SL": "J1745",
        "RAAG": "Bhairavi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ye ji merey morey aaye",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1745 Bhairavi Teental(m.laya) Ye ji merey moreyaaye.mp3"
      },
      {
        "SL": "J1746",
        "RAAG": "Bhairavi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Raavare kaanhaa",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1746 Bhairavi Teental(m.laya) Raavare kaanhaa.mp3"
      },
      {
        "SL": "J1747",
        "RAAG": "Bhairavi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Aba tori baanki",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1747 Bhairavi Teental(m.laya) Aba tori baanki.mp3"
      },
      {
        "SL": "J1748",
        "RAAG": "Bhairavi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Sakhi aaj pramad bhayi",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1748 Bhairavi Teental(m.laya) Sakhi aaj pramad bhayi.mp3"
      },
      {
        "SL": "J1749",
        "RAAG": "Bhairavi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Babul mora",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1749 Bhairavi Teental(m.laya) Babul mora.mp3"
      },
      {
        "SL": "J1750",
        "RAAG": "Bhairavi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Binaare khivaiya",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1750 Bhairavi Teental(m.laya) Binaare khivaiya.mp3"
      },
      {
        "SL": "J1751",
        "RAAG": "Bhairavi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Naahi parat maika chain",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1751 Bhairavi Teental(m.laya) Naahi parat maika chain.mp3"
      },
      {
        "SL": "J1752",
        "RAAG": "Bhairavi",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Shaarada vidyaadaani",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1752 Bhairavi Jhaptal(m.laya) Shaarada vidyaadaani.mp3"
      },
      {
        "SL": "J1753",
        "RAAG": "Bhairavi",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Bhavani dayaani",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1753 Bhairavi Jhaptal(m.laya) Bhavani dayaani.mp3"
      },
      {
        "SL": "J1754",
        "RAAG": "Bhairavi",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Dhan dhari",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1754 Bhairavi Jhaptal(m.laya) Dhan dhari.mp3"
      },
      {
        "SL": "J1755",
        "RAAG": "Bhairavi",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Jo tu racho saamaan",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1755 Bhairavi Chowtal(Vilambit) Jo tu racho saamaan.mp3"
      },
      {
        "SL": "J1756",
        "RAAG": "Bhairavi",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Bhasma ang",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1756 Bhairavi Chowtal(Vilambit) Bhasma ang.mp3"
      },
      {
        "SL": "J1757",
        "RAAG": "Bhairavi",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Jaa dinate bichure",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1757 Bhairavi Chowtal(Vilambit) Jaa dinate bichure.mp3"
      },
      {
        "SL": "J1758",
        "RAAG": "Bhairavi",
        "TAAL": "Deepchandi(m.laya)",
        "STARTING_WORDS": "Kya g ulaal saiyaa",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1758 Bhairavi Deepchandi(m.laya) Kya gulaal saiyaa.mp3"
      },
      {
        "SL": "J1759",
        "RAAG": "Bhairavi",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Daarat kesar",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1759 Bhairavi Dhamar(Vilambit) Daarat kesar.mp3"
      },
      {
        "SL": "J1760",
        "RAAG": "Bhairavi",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Kanha aisi bansi bajaayi",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1760 Bhairavi Dhamar(Vilambit) Kanha aisi bansi bajaayi.mp3"
      },
      {
        "SL": "J1761",
        "RAAG": "Bhairavi",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Aali dekho",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1761 Bhairavi Dhamar(Vilambit) Aali dekho.mp3"
      },
      {
        "SL": "J1762",
        "RAAG": "Bhairavi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tanana dirdir taanom",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1762 Bhairavi Teental(m.laya) Tanana dirdir taanom.mp3"
      },
      {
        "SL": "J1763",
        "RAAG": "Bhairavi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tadaani dani tom",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1763 Bhairavi Teental(m.laya) Tadaani dani tom.mp3"
      },
      {
        "SL": "J1764",
        "RAAG": "Bhairavi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tanana tana",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1764 Bhairavi Teental(m.laya) Tanana tana.mp3"
      },
      {
        "SL": "J1765",
        "RAAG": "Bhairavi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Dirdir tanana",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1765 Bhairavi Teental(m.laya) Dirdir tanana.mp3"
      },
      {
        "SL": "J1766",
        "RAAG": "Maalkouns",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "sa ni dha ni dha ma",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1766 Maalkouns Teental(m.laya) Sa ni dha ni dha ma.mp3"
      },
      {
        "SL": "J1767",
        "RAAG": "Maalkouns",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tab m aalkouns",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1767 Maalkouns Teental(m.laya) Tab maalkouns.mp3"
      },
      {
        "SL": "J1768",
        "RAAG": "Maalkouns",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Shastra parmaan",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1768 Maalkouns Jhaptal(m.laya) Shastra parmaan.mp3"
      },
      {
        "SL": "J1769",
        "RAAG": "Maalkouns",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Mereto mana",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1769 Maalkouns Teental(m.laya) Mereto mana.mp3"
      },
      {
        "SL": "J1770",
        "RAAG": "Maalkouns",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Rab kab sumare",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1770 Maalkouns Ektal(m.laya) Rab kab sumare.mp3"
      },
      {
        "SL": "J1771",
        "RAAG": "Maalkouns",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Deennaath dukha",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1771 Maalkouns Ektal(m.laya) Deennaath dukha.mp3"
      },
      {
        "SL": "J1772",
        "RAAG": "Maalkouns",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Sunire mana murakh",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1772 Maalkouns Teental(m.laya) Sunire mana murakh.mp3"
      },
      {
        "SL": "J1773",
        "RAAG": "Maalkouns",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tohe aba ram duhayi",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1773 Maalkouns Teental(m.laya) Tohe aba ram duhayi.mp3"
      },
      {
        "SL": "J1774",
        "RAAG": "Maalkouns",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Mori tori peet laagale e",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1774 Maalkouns Teental(m.laya) Mori tori peet laagalee.mp3"
      },
      {
        "SL": "J1775",
        "RAAG": "Maalkouns",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Mukh morey morey",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1775 Maalkouns Teental(m.laya) Mukh morey morey.mp3"
      },
      {
        "SL": "J1776",
        "RAAG": "Maalkouns",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Piya sung larpachtaani",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1776 Maalkouns Teental(m.laya) Piya sung larpachtaani.mp3"
      },
      {
        "SL": "J1777",
        "RAAG": "Maalkouns",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Roung raliyaa",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1777 Maalkouns Teental(m.laya) Roung raliyaa.mp3"
      },
      {
        "SL": "J1778",
        "RAAG": "Maalkouns",
        "TAAL": "Adachowtal(m.laya)",
        "STARTING_WORDS": "Chalo hato piya",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1778 Maalkouns Adachowtal(m.laya) Chalo hato piya.mp3"
      },
      {
        "SL": "J1779",
        "RAAG": "Maalkouns",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Din nike beete",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1779 Maalkouns Teental(m.laya) Din nike beete.mp3"
      },
      {
        "SL": "J1780",
        "RAAG": "Maalkouns",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Durage mah arani",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1780 Maalkouns Teental(m.laya) Durage maharani.mp3"
      },
      {
        "SL": "J1781",
        "RAAG": "Maalkouns",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Kishna maadho raam",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1781 Maalkouns Tilwada(Vilambit) Kishna maadho raam.mp3"
      },
      {
        "SL": "J1782",
        "RAAG": "Maalkouns",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Peeran jaanire",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1782 Maalkouns Ektal(Vilambit) Peeran jaanire.mp3"
      },
      {
        "SL": "J1783",
        "RAAG": "Maalkouns",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Koyaliya bole",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1783 Maalkouns Tilwada(Vilambit) Koyaliya bole.mp3"
      },
      {
        "SL": "J1784",
        "RAAG": "Maalkouns",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Aho pat beejan",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1784 Maalkouns Tilwada(Vilambit) Aho pat beejan.mp3"
      },
      {
        "SL": "J1785",
        "RAAG": "Maalkouns",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Aaj morey ghar",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1785 Maalkouns Ektal(Vilambit) Aaj morey ghar.mp3"
      },
      {
        "SL": "J1786",
        "RAAG": "Maalkouns",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Paanan beeree",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1786 Maalkouns Ektal(Vilambit) Paanan beeree.mp3"
      },
      {
        "SL": "J1787",
        "RAAG": "Maalkouns",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "So aba dhun baasuri",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1787 Maalkouns Ektal(Vilambit) So aba dhun baasuri.mp3"
      },
      {
        "SL": "J1788",
        "RAAG": "Maalkouns",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Pag laagan dey",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1788 Maalkouns Ektal(Vilambit) Pag laagan dey.mp3"
      },
      {
        "SL": "J1789",
        "RAAG": "Maalkouns",
        "TAAL": "Adachowtal(Vilambit)",
        "STARTING_WORDS": "Ye sunath",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1789 Maalkouns Adachowtal(Vilambit) Ye sunath.mp3"
      },
      {
        "SL": "J1790",
        "RAAG": "Maalkouns",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Sundar badan ke",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1790 Maalkouns Jhaptal(m.laya) Sundar badan ke.mp3"
      },
      {
        "SL": "J1791",
        "RAAG": "Maalkouns",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Raajan ke raajaa",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1791 Maalkouns Sultal(m.laya) Raajan ke raajaa.mp3"
      },
      {
        "SL": "J1792",
        "RAAG": "Maalkouns",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Aavan kahe gaye",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1792 Maalkouns Sultal(m.laya) Aavan kahe gaye.mp3"
      },
      {
        "SL": "J1793",
        "RAAG": "Maalkouns",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Damaru daph baaje",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1793 Maalkouns Jhaptal(m.laya) Damaru daph baaje.mp3"
      },
      {
        "SL": "J1794",
        "RAAG": "Maalkouns",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Raajanke raja",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1794 Maalkouns Chowtal(Vilambit) Raajanke raja.mp3"
      },
      {
        "SL": "J1795",
        "RAAG": "Maalkouns",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Naad ko bistaar",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1795 Maalkouns Chowtal(Vilambit) Naad ko bistaar.mp3"
      },
      {
        "SL": "J1796",
        "RAAG": "Maalkouns",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Maalkouns roop",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1796 Maalkouns Chowtal(Vilambit) Maalkouns roop.mp3"
      },
      {
        "SL": "J1797",
        "RAAG": "Maalkouns",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Aho dhun dho nkaar",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1797 Maalkouns Dhamar(Vilambit) Aho dhun dhonkaar.mp3"
      },
      {
        "SL": "J1798",
        "RAAG": "Maalkouns",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Sughar var aayaari",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1798 Maalkouns Dhamar(Vilambit) Sughar var aayaari.mp3"
      },
      {
        "SL": "J1799",
        "RAAG": "Maalkouns",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Aaj ata dhoom",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1799 Maalkouns Dhamar(Vilambit) Aaj ata dhoom.mp3"
      },
      {
        "SL": "J1800",
        "RAAG": "Maalkouns",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Taanno derenaa",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1800 Maalkouns Jhaptal(m.laya) Taanno derenaa.mp3"
      },
      {
        "SL": "J1801",
        "RAAG": "Bilaskhani Todi",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Nike ghungariyaa",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1801 Bilaskhani Todi Tilwada(Vilambit) Nike ghungariyaa.mp3"
      },
      {
        "SL": "J1802",
        "RAAG": "Bilaskhani Todi",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Aba morey kountha",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1802 Bilaskhani Todi Ektal(Vilambit) Aba morey kountha.mp3"
      },
      {
        "SL": "J1803",
        "RAAG": "Bilaskhani Todi",
        "TAAL": "Jhumra(Vilambit)",
        "STARTING_WORDS": "Jabate mana mohan",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1803 Bilaskhani Todi Jhumra(Vilambit) Jabate mana mohan.mp3"
      },
      {
        "SL": "J1804",
        "RAAG": "Bilaskhani Todi",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Anahat shabdha",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1804 Bilaskhani Todi Chowtal(Vilambit) Anahat shabdha.mp3"
      },
      {
        "SL": "J1805",
        "RAAG": "Bilaskhani Todi",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Ye mori angeeyaa",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1805 Bilaskhani Todi Dhamar(Vilambit) Ye mori angeeyaa.mp3"
      },
      {
        "SL": "J1806",
        "RAAG": "Motakee",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Moha liyo",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1806 Motakee Jhaptal(m.laya) Moha liyo.mp3"
      },
      {
        "SL": "J1807",
        "RAAG": "Bhoopal Todi",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Sugoundh malayaagar",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1807 Bhoopal Todi Chowtal(Vilambit) Sugoundh malayaagar.mp3"
      },
      {
        "SL": "J1808",
        "RAAG": "Utaree gunakali",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tanaanaa dirdir tom",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1808 Utaree gunakali Teental(m.laya) Tanaanaa dirdir tom.mp3"
      },
      {
        "SL": "J1809",
        "RAAG": "Vasant mukhaari",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Mel bakulaabharan",
        "THAAT": "Bhairavi",
        "FILE_NAME": "J1809 Vasant mukhaari Jhaptal(m.laya) Mel bakulaabharan.mp3"
      },
      {
        "SL": "K1810",
        "RAAG": "Todi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "dha dha pa pa ma ma",
        "THAAT": "Todi",
        "FILE_NAME": "K1810 Todi Teental(m.laya) dha dha pa pa ma ma.mp3"
      },
      {
        "SL": "K1811",
        "RAAG": "Todi",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Bikarat jab dha ga ri",
        "THAAT": "Todi",
        "FILE_NAME": "K1811 Todi Ektal(m.laya) Bikarat jab dha ga ri.mp3"
      },
      {
        "SL": "K1812",
        "RAAG": "Todi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Neka chaal chaliye",
        "THAAT": "Todi",
        "FILE_NAME": "K1812 Todi Teental(m.laya) Neka chaal chaliye.mp3"
      },
      {
        "SL": "K1813",
        "RAAG": "Todi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kaha nar apano",
        "THAAT": "Todi",
        "FILE_NAME": "K1813 Todi Teental(m.laya) Kaha nar apano.mp3"
      },
      {
        "SL": "K1814",
        "RAAG": "Todi",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Saache suran gaaye",
        "THAAT": "Todi",
        "FILE_NAME": "K1814 Todi Ektal(m.laya) Saache suran gaaye.mp3"
      },
      {
        "SL": "K1815",
        "RAAG": "Todi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Morey mana jaay",
        "THAAT": "Todi",
        "FILE_NAME": "K1815 Todi Teental(m.laya) Morey mana jaay.mp3"
      },
      {
        "SL": "K1816",
        "RAAG": "Todi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kanha karat mosey",
        "THAAT": "Todi",
        "FILE_NAME": "K1816 Todi Teental(m.laya) Kanha karat mosey.mp3"
      },
      {
        "SL": "K1817",
        "RAAG": "Todi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Allaha jaane",
        "THAAT": "Todi",
        "FILE_NAME": "K1817 Todi Teental(m.laya) Allaha jaane.mp3"
      },
      {
        "SL": "K1818",
        "RAAG": "Todi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Langar kankariya",
        "THAAT": "Todi",
        "FILE_NAME": "K1818 Todi Teental(m.laya) Langar kankariya.mp3"
      },
      {
        "SL": "K1819",
        "RAAG": "Todi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Begaliya pathiya",
        "THAAT": "Todi",
        "FILE_NAME": "K1819 Todi Teental(m.laya) Begaliya pathiya.mp3"
      },
      {
        "SL": "K1820",
        "RAAG": "Todi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kalali mhaane",
        "THAAT": "Todi",
        "FILE_NAME": "K1820 Todi Teental(m.laya) Kalali mhaane.mp3"
      },
      {
        "SL": "K1821",
        "RAAG": "Todi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Piya sung khelat",
        "THAAT": "Todi",
        "FILE_NAME": "K1821 Todi Teental(m.laya) Piya sung khelat.mp3"
      },
      {
        "SL": "K1822",
        "RAAG": "Todi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Deo daras morey",
        "THAAT": "Todi",
        "FILE_NAME": "K1822 Todi Teental(m.laya) Deo daras morey.mp3"
      },
      {
        "SL": "K1823",
        "RAAG": "Todi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Maito apane saiyaa",
        "THAAT": "Todi",
        "FILE_NAME": "K1823 Todi Teental(m.laya) Maito apane saiyaa.mp3"
      },
      {
        "SL": "K1824",
        "RAAG": "Todi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Raam sumaran",
        "THAAT": "Todi",
        "FILE_NAME": "K1824 Todi Teental(m.laya) Raam sumaran.mp3"
      },
      {
        "SL": "K1825",
        "RAAG": "Todi",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Manava mora",
        "THAAT": "Todi",
        "FILE_NAME": "K1825 Todi Ektal(m.laya) Manava mora.mp3"
      },
      {
        "SL": "K1826",
        "RAAG": "Todi",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Aba Morey raam",
        "THAAT": "Todi",
        "FILE_NAME": "K1826 Todi Ektal(Vilambit) Aba Morey raam.mp3"
      },
      {
        "SL": "K1827",
        "RAAG": "Todi",
        "TAAL": "Jhumra(Vilambit)",
        "STARTING_WORDS": "Sabnis barajoree",
        "THAAT": "Todi",
        "FILE_NAME": "K1827 Todi Jhumra(Vilambit) Sabnis barajoree.mp3"
      },
      {
        "SL": "K1828",
        "RAAG": "Todi",
        "TAAL": "Ad achowtal(Vilambit)",
        "STARTING_WORDS": "Ja ja re pathikava",
        "THAAT": "Todi",
        "FILE_NAME": "K1828 Todi Adachowtal(Vilambit) Ja ja re pathikava.mp3"
      },
      {
        "SL": "K1829",
        "RAAG": "Todi",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Kirapa karore",
        "THAAT": "Todi",
        "FILE_NAME": "K1829 Todi Ektal(Vilambit) Kirapa karore.mp3"
      },
      {
        "SL": "K1830",
        "RAAG": "Todi",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Jivoo mora chahe",
        "THAAT": "Todi",
        "FILE_NAME": "K1830 Todi Tilwada(Vilambit) Jivoo mora chahe.mp3"
      },
      {
        "SL": "K1831",
        "RAAG": "Todi",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Baajore momadsa",
        "THAAT": "Todi",
        "FILE_NAME": "K1831 Todi Tilwada(Vilambit) Baajore momadsa.mp3"
      },
      {
        "SL": "K1832",
        "RAAG": "Todi",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Daiya batdoobar",
        "THAAT": "Todi",
        "FILE_NAME": "K1832 Todi Ektaal(Vilambit) Daiya batdoobar.mp3"
      },
      {
        "SL": "K1833",
        "RAAG": "Todi",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Tori chabi",
        "THAAT": "Todi",
        "FILE_NAME": "K1833 Todi Ektal(Vilambit) Tori chabi.mp3"
      },
      {
        "SL": "K1834",
        "RAAG": "Todi",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Ko samazhavore",
        "THAAT": "Todi",
        "FILE_NAME": "K1834 Todi Ektal(Vilambit) Ko samazhavore.mp3"
      },
      {
        "SL": "K1835",
        "RAAG": "Todi",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Hamare tho",
        "THAAT": "Todi",
        "FILE_NAME": "K1835 Todi Tilwada(Vilambit) Hamare tho.mp3"
      },
      {
        "SL": "K1836",
        "RAAG": "Todi",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Laal manavan",
        "THAAT": "Todi",
        "FILE_NAME": "K1836 Todi Ektal(Vilambit) Laal manavan.mp3"
      },
      {
        "SL": "K1837",
        "RAAG": "Todi",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Aadi anahat naad",
        "THAAT": "Todi",
        "FILE_NAME": "K1837 Todi Jhaptal(m.laya) Aadi anahat naad.mp3"
      },
      {
        "SL": "K1838",
        "RAAG": "Todi",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Ladey hasan husein",
        "THAAT": "Todi",
        "FILE_NAME": "K1838 Todi Jhaptal(m.laya) Ladey hasan husein.mp3"
      },
      {
        "SL": "K1839",
        "RAAG": "Todi",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Tum samaan",
        "THAAT": "Todi",
        "FILE_NAME": "K1839 Todi Jhaptal(m.laya) Tum samaan.mp3"
      },
      {
        "SL": "K1840",
        "RAAG": "Todi",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Harhar shiv shankar",
        "THAAT": "Todi",
        "FILE_NAME": "K1840 Todi Chowtal(Vilambit) Harhar shiv shankar.mp3"
      },
      {
        "SL": "K1841",
        "RAAG": "Todi",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Mereto allah naam",
        "THAAT": "Todi",
        "FILE_NAME": "K1841 Todi Chowtal(Vilambit) Mereto allah naam.mp3"
      },
      {
        "SL": "K1842",
        "RAAG": "Todi",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Baraneen jaaye",
        "THAAT": "Todi",
        "FILE_NAME": "K1842 Todi Chowtal(Vilambit) Baraneen jaaye.mp3"
      },
      {
        "SL": "K1843",
        "RAAG": "Todi",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Mareto tuhee aan",
        "THAAT": "Todi",
        "FILE_NAME": "K1843 Todi Chowtal(Vilambit) Mareto tuhee aan.mp3"
      },
      {
        "SL": "K1844",
        "RAAG": "Todi",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Meri balaavai",
        "THAAT": "Todi",
        "FILE_NAME": "K1844 Todi Chowtal(Vilambit) Meri balaavai.mp3"
      },
      {
        "SL": "K1845",
        "RAAG": "Todi",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Sambhaar chalat",
        "THAAT": "Todi",
        "FILE_NAME": "K1845 Todi Dhamar(Vilambit) Sambhaar chalat.mp3"
      },
      {
        "SL": "K1846",
        "RAAG": "Todi",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Laal madhmaate",
        "THAAT": "Todi",
        "FILE_NAME": "K1846 Todi Dhamar(Vilambit) Laal madhmaate.mp3"
      },
      {
        "SL": "K1847",
        "RAAG": "Todi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Deem odetanana",
        "THAAT": "Todi",
        "FILE_NAME": "K1847 Todi Teental(m.laya) Deem odetanana.mp3"
      },
      {
        "SL": "K1848",
        "RAAG": "Todi",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Dara tele daara",
        "THAAT": "Todi",
        "FILE_NAME": "K1848 Todi Ektal(m.laya) Dara tele daara.mp3"
      },
      {
        "SL": "K1849",
        "RAAG": "Todi",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Tom tanana tom",
        "THAAT": "Todi",
        "FILE_NAME": "K1849 Todi Ektal(m.laya) Tom tanana tom.mp3"
      },
      {
        "SL": "K1850",
        "RAAG": "Todi",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Tadaare dar",
        "THAAT": "Todi",
        "FILE_NAME": "K1850 Todi Ektal(Vilambit) Tadaare dar.mp3"
      },
      {
        "SL": "K1851",
        "RAAG": "Todi",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Tanana derenaa",
        "THAAT": "Todi",
        "FILE_NAME": "K1851 Todi Tilwada(Vilambit) Tanana derenaa.mp3"
      },
      {
        "SL": "K1852",
        "RAAG": "Multani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "ni sa ma ga pa",
        "THAAT": "Todi",
        "FILE_NAME": "K1852 Multani Teental(m.laya) ni sa ma ga pa.mp3"
      },
      {
        "SL": "K1853",
        "RAAG": "Multani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ajab mulatani",
        "THAAT": "Todi",
        "FILE_NAME": "K1853 Multani Teental(m.laya) Ajab mulatani.mp3"
      },
      {
        "SL": "K1854",
        "RAAG": "Multani",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Todi ko thaat kare",
        "THAAT": "Todi",
        "FILE_NAME": "K1854 Multani Ektal(m.laya) Todi ko thaat kare.mp3"
      },
      {
        "SL": "K1855",
        "RAAG": "Multani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Karee gopalki",
        "THAAT": "Todi",
        "FILE_NAME": "K1855 Multani Teental(m.laya) Karee gopalki.mp3"
      },
      {
        "SL": "K1856",
        "RAAG": "Multani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Arey mana",
        "THAAT": "Todi",
        "FILE_NAME": "K1856 Multani Teental(m.laya) Arey mana.mp3"
      },
      {
        "SL": "K1857",
        "RAAG": "Multani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Achapal naam",
        "THAAT": "Todi",
        "FILE_NAME": "K1857 Multani Teental(m.laya) Achapal naam.mp3"
      },
      {
        "SL": "K1858",
        "RAAG": "Multani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Sundar surjan",
        "THAAT": "Todi",
        "FILE_NAME": "K1858 Multani Teental(m.laya) Sundar surjan.mp3"
      },
      {
        "SL": "K1859",
        "RAAG": "Multani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Aaj bajat badhaayi",
        "THAAT": "Todi",
        "FILE_NAME": "K1859 Multani Teental(m.laya) Aaj bajat badhaayi.mp3"
      },
      {
        "SL": "K1860",
        "RAAG": "Multani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Sundar surjanva",
        "THAAT": "Todi",
        "FILE_NAME": "K1860 Multani Teental(m.laya) Sundar surjanva.mp3"
      },
      {
        "SL": "K1861",
        "RAAG": "Multani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Runakzhunak mori",
        "THAAT": "Todi",
        "FILE_NAME": "K1861 Multani Teental(m.laya) Runakzhunak mori.mp3"
      },
      {
        "SL": "K1862",
        "RAAG": "Multani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Hamse tum raar",
        "THAAT": "Todi",
        "FILE_NAME": "K1862 Multani Teental(m.laya) Hamse tum raar.mp3"
      },
      {
        "SL": "K1863",
        "RAAG": "Multani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Gori tore angiya",
        "THAAT": "Todi",
        "FILE_NAME": "K1863 Multani Teental(m.laya) Gori tore angiya.mp3"
      },
      {
        "SL": "K1864",
        "RAAG": "Multani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Gayi gayi re",
        "THAAT": "Todi",
        "FILE_NAME": "K1864 Multani Teental(m.laya) Gayi gayi re.mp3"
      },
      {
        "SL": "K1865",
        "RAAG": "Multani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "So balmaa morey",
        "THAAT": "Todi",
        "FILE_NAME": "K1865 Multani Teental(m.laya) So balmaa morey.mp3"
      },
      {
        "SL": "K1866",
        "RAAG": "Multani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Kwajaa bounde navaaz",
        "THAAT": "Todi",
        "FILE_NAME": "K1866 Multani Teental(m.laya) Kwajaa bounde navaaz.mp3"
      },
      {
        "SL": "K1867",
        "RAAG": "Multani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Ina durajan logavaa",
        "THAAT": "Todi",
        "FILE_NAME": "K1867 Multani Teental(m.laya) Ina durajan logavaa.mp3"
      },
      {
        "SL": "K1868",
        "RAAG": "Multani",
        "TAAL": "Ektal(m.laya)",
        "STARTING_WORDS": "Nainan mey aan baan",
        "THAAT": "Todi",
        "FILE_NAME": "K1868 Multani Ektal(m.laya) Nainan mey aan baan.mp3"
      },
      {
        "SL": "K1869",
        "RAAG": "Multani",
        "TAAL": "Adachowtal(M.laya)",
        "STARTING_WORDS": "Kavan des",
        "THAAT": "Todi",
        "FILE_NAME": "K1869 Multani Adachowtal(M.laya) Kavan des.mp3"
      },
      {
        "SL": "K1870",
        "RAAG": "Multani",
        "TAAL": "Ektal(Vilambit)",
        "STARTING_WORDS": "Allaha saheb Jamaal",
        "THAAT": "Todi",
        "FILE_NAME": "K1870 Multani Ektal(Vilambit) Allaha saheb Jamaal.mp3"
      },
      {
        "SL": "K1871",
        "RAAG": "Multani",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Hamarare Baalam",
        "THAAT": "Todi",
        "FILE_NAME": "K1871 Multani Tilwada(Vilambit) Hamarare Baalam.mp3"
      },
      {
        "SL": "K1872",
        "RAAG": "Multani",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Kavan des gaye",
        "THAAT": "Todi",
        "FILE_NAME": "K1872 Multani Tilwada(Vilambit) Kavan des gaye.mp3"
      },
      {
        "SL": "K1873",
        "RAAG": "Multani",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Gokul gaavake choraa",
        "THAAT": "Todi",
        "FILE_NAME": "K1873 Multani Tilwada(Vilambit) Gokul gaavake choraa.mp3"
      },
      {
        "SL": "K1874",
        "RAAG": "Multani",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Dolaa jaanam",
        "THAAT": "Todi",
        "FILE_NAME": "K1874 Multani Tilwada(Vilambit) Dolaa jaanam.mp3"
      },
      {
        "SL": "K1875",
        "RAAG": "Multani",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Laadi thaaree baat",
        "THAAT": "Todi",
        "FILE_NAME": "K1875 Multani Tilwada(Vilambit) Laadi thaaree baat.mp3"
      },
      {
        "SL": "K1876",
        "RAAG": "Multani",
        "TAAL": "Tilwada(Vilambit)",
        "STARTING_WORDS": "Ka jaanere",
        "THAAT": "Todi",
        "FILE_NAME": "K1876 Multani Tilwada(Vilambit) Ka jaanere.mp3"
      },
      {
        "SL": "K1877",
        "RAAG": "Multani",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Taal sur ko byori",
        "THAAT": "Todi",
        "FILE_NAME": "K1877 Multani Sultal(m.laya) Taal sur ko byori.mp3"
      },
      {
        "SL": "K1878",
        "RAAG": "Multani",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Nitya shudha budhha",
        "THAAT": "Todi",
        "FILE_NAME": "K1878 Multani Chowtal(Vilambit) Nitya shudha budhha.mp3"
      },
      {
        "SL": "K1879",
        "RAAG": "Multani",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Jaake mirg chaal",
        "THAAT": "Todi",
        "FILE_NAME": "K1879 Multani Chowtal(Vilambit) Jaake mirg chaal.mp3"
      },
      {
        "SL": "K1880",
        "RAAG": "Multani",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Dhanya dhanya",
        "THAAT": "Todi",
        "FILE_NAME": "K1880 Multani Chowtal(Vilambit) Dhanya dhanya.mp3"
      },
      {
        "SL": "K1881",
        "RAAG": "Multani",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Dekh sab aayee",
        "THAAT": "Todi",
        "FILE_NAME": "K1881 Multani Dhamar(Vilambit) Dekh sab aayee.mp3"
      },
      {
        "SL": "K1882",
        "RAAG": "Multani",
        "TAAL": "Dhamar(Vilambit)",
        "STARTING_WORDS": "Baare naa soung",
        "THAAT": "Todi",
        "FILE_NAME": "K1882 Multani Dhamar(Vilambit) Baare naa soung.mp3"
      },
      {
        "SL": "K1883",
        "RAAG": "Multani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tanana tan",
        "THAAT": "Todi",
        "FILE_NAME": "K1883 Multani Teental(m.laya) Tanana tan.mp3"
      },
      {
        "SL": "K1884",
        "RAAG": "Multani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tananana nana",
        "THAAT": "Todi",
        "FILE_NAME": "K1884 Multani Teental(m.laya) Tananana nana.mp3"
      },
      {
        "SL": "K1885",
        "RAAG": "Multani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Tadeem tan",
        "THAAT": "Todi",
        "FILE_NAME": "K1885 Multani Teental(m.laya) Tadeem tan.mp3"
      },
      {
        "SL": "K1886",
        "RAAG": "Multani",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Diyanaare tanom",
        "THAAT": "Todi",
        "FILE_NAME": "K1886 Multani Teental(m.laya) Diyanaare tanom.mp3"
      },
      {
        "SL": "K1887",
        "RAAG": "Multani",
        "TAAL": "Jhumra(Vilambit)",
        "STARTING_WORDS": "Dara dar tanoom",
        "THAAT": "Todi",
        "FILE_NAME": "K1887 Multani Jhumra(Vilambit) Dara dar tanoom.mp3"
      },
      {
        "SL": "K1888",
        "RAAG": "Gujari Todi",
        "TAAL": "Brahmtal(m.laya)",
        "STARTING_WORDS": "Narayan kar",
        "THAAT": "Todi",
        "FILE_NAME": "K1888 Gujari Todi Brahmtal(m.laya) Narayan kar.mp3"
      },
      {
        "SL": "K1889",
        "RAAG": "Laachari Todi",
        "TAAL": "Teental(m.laya)",
        "STARTING_WORDS": "Hame dekhilo",
        "THAAT": "Todi",
        "FILE_NAME": "K1889 Laachari Todi Teental(m.laya) Hame dekhilo.mp3"
      },
      {
        "SL": "K1890",
        "RAAG": "Laachari Todi",
        "TAAL": "Adachowtal(Vilambit)",
        "STARTING_WORDS": "Laagohi aave",
        "THAAT": "Todi",
        "FILE_NAME": "K1890 Laachari Todi Adachowtal(Vilambit) Laagohi aave.mp3"
      },
      {
        "SL": "K1891",
        "RAAG": "Lakshmi Todi",
        "TAAL": "Sultal(m.laya)",
        "STARTING_WORDS": "Roop bounti",
        "THAAT": "Todi",
        "FILE_NAME": "K1891 Lakshmi Todi Sultal(m.laya) Roop bounti.mp3"
      },
      {
        "SL": "K1892",
        "RAAG": "Lakshmi Todi",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Maan manaavan",
        "THAAT": "Todi",
        "FILE_NAME": "K1892 Lakshmi Todi Chowtal(Vilambit) Maan manaavan.mp3"
      },
      {
        "SL": "K1893",
        "RAAG": "Bahaduri Todi",
        "TAAL": "Jhaptal(m.laya)",
        "STARTING_WORDS": "Vaaru mukhachandra",
        "THAAT": "Todi",
        "FILE_NAME": "K1893 Bahaduri Todi Jhaptal(m.laya) Vaaru mukhachandra.mp3"
      },
      {
        "SL": "K1894",
        "RAAG": "Anjani Todi",
        "TAAL": "Chowtal(Vilambit)",
        "STARTING_WORDS": "Nidra hu nahi aaveri",
        "THAAT": "Todi",
        "FILE_NAME": "K1894 Anjani Todi Chowtal(Vilambit) Nidra hu nahi aaveri.mp3"
      },
      {
        "SL": "L1895",
        "RAAG": "Des",
        "TAAL": "Teental(Kawwalli)",
        "STARTING_WORDS": "Jara nainse nain",
        "THAAT": "Khamaj",
        "FILE_NAME": "L1895 Des Teental(kawwaali) Jara nainse nain.mp3"
      },
      {
        "SL": "L1896",
        "RAAG": "Des",
        "TAAL": "Pastu(Gazal)",
        "STARTING_WORDS": "Jo ke naam",
        "THAAT": "Khamaj",
        "FILE_NAME": "L1896 Des Pastu(Gazal) Jo ke naam.mp3"
      }
    ],
    displayData: [],
    displayRAAG: [],

    OpenModal: false,
  }

  componentDidMount() {
    var uniqueNames = [];
    for (var i = 0; i < this.state.data.length; i++) {
      if (uniqueNames.indexOf(this.state.data[i].RAAG) === -1) {
        uniqueNames.push(this.state.data[i].RAAG);
      }
    }

    this.setState({ displayData: this.state.data, displayRAAG: uniqueNames })
  }

  updateMenu = (e) => {
    const THAAT = document.getElementById("THATT").value !== "" ? document.getElementById("THATT").value : "All";
    const RAAG = document.getElementById("RAAG").value !== "" ? document.getElementById("RAAG").value : "All";
    const WORD = document.getElementById("WORD").value !== "" ? document.getElementById("WORD").value : "All";
    const SLNO = document.getElementById("SLNO").value !== "" ? document.getElementById("SLNO").value : "All";

    var dataFilter = this.state.data.filter(element =>
      (element.THAAT.toUpperCase().includes(THAAT.toUpperCase()) || THAAT === "All") && (element.RAAG.toUpperCase().includes(RAAG.toUpperCase()) || RAAG === "All") &&
      (element.STARTING_WORDS.toUpperCase().includes(WORD.toUpperCase()) || WORD === "All") && (element.SL.toUpperCase().includes(SLNO.toUpperCase()) || SLNO === "All")
    )

    var uniqueNames = [];
    for (var i = 0; i < dataFilter.length; i++) {
      if (uniqueNames.indexOf(dataFilter[i].RAAG) === -1) {
        uniqueNames.push(dataFilter[i].RAAG);
      }
    }

    this.setState({ displayData: dataFilter, displayRAAG: uniqueNames })
  }

  resetMenu = (e) => {
    var uniqueNames = [];
    for (var i = 0; i < this.state.data.length; i++) {
      if (uniqueNames.indexOf(this.state.data[i].RAAG) === -1) {
        uniqueNames.push(this.state.data[i].RAAG);
      }
    }

    this.setState({ displayData: this.state.data, displayRAAG: uniqueNames })

    document.getElementById("THATT").value = "";
    document.getElementById("RAAG").value = "";
    document.getElementById("WORD").value = "";
    document.getElementById("SLNO").value = "";
  }

  //Open/Close Modal
  modal = (e) => {
    this.setState({
      OpenModal: !this.state.OpenModal,
    })
  }

  CloseModal = (e) => {
    this.setState({
      OpenModal: !this.state.OpenModal,
    })
    document.getElementById("myAudio").pause();
  }

  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Bhatkhande Audio" image="banners/swarasankula04.jpg" />

        {/* Audio Data */}
        <div className="container">
          <div className="row paddingtop-2">
            <div className="col-lg-3">
              <div className="widget widget-nav-menu">
                <ul className="widget-menu">
                  <li>
                    <h6>Search by Thaat</h6><br />
                    <input list="ThaatList" name="browser" id="THATT" style={{ width: "100%", height: "2.5rem" }} onChange={this.updateMenu} />
                    <datalist id="ThaatList">
                      <option value="All" />
                      <option value="Kalyan" />
                      <option value="Bilawal" />
                      <option value="Khamaj" />
                      <option value="Bhairav" />
                      <option value="Poorvi" />
                      <option value="Marwa" />
                      <option value="Kafi" />
                      <option value="Asavari" />
                      <option value="Bhairavi" />
                      <option value="Todi" />
                    </datalist>
                  </li>
                  <br />
                  <li>
                    <h6>Search by Raag</h6><br />
                    <input list="RAAGList" id="RAAG" style={{ width: "100%", height: "2.5rem" }} onChange={this.updateMenu} />
                    <datalist id="RAAGList">{this.state.displayRAAG.map((e, index) => { return <option value={e} /> })}</datalist>
                  </li>
                  <br />
                  <li>
                    <h6>Search by Starting Words</h6><br />
                    <input list="WORDList" name="browser" id="WORD" style={{ width: "100%", height: "2.5rem" }} onChange={this.updateMenu} />
                    <datalist id="WORDList">{this.state.displayData.map((e, key) => { return <option value={e.STARTING_WORDS} />; })}</datalist>
                  </li>
                  <br />
                  <li>
                    <h6>Search by Serial No</h6><br />
                    <input list="SLList" name="browser" id="SLNO" style={{ width: "100%", height: "2.5rem" }} onChange={this.updateMenu} />
                    <datalist id="SLList">{this.state.displayData.map((e, key) => { return <option value={e.SL} />; })}</datalist>
                  </li>
                  <br /><hr /><br />
                  <li>
                    <h6>Others</h6><br />
                    <button style={{ width: "100%", color: "white", background: "maroon" }} className="swara-button" onClick={this.resetMenu}><a style={{ color: "white" }}  href="javascript:void(0)" rel="noopener noreferrer">Reset Menu</a></button>
                    <br /><br />
                    <button style={{ width: "100%", color: "white", background: "maroon" }} className="swara-button"><a style={{ color: "white" }} target="_blank" download href={"http://swarasankula.org/swarasankula-bhatkande/Index_File.pdf"} rel="noopener noreferrer">Download Index File</a></button>
                    <br /><br />
                    <button style={{ width: "100%", color: "white", background: "maroon" }} className="swara-button"><a style={{ color: "white" }} target="_blank" download href={"http://swarasankula.org/swarasankula-bhatkande/Lyrics_File.pdf"} rel="noopener noreferrer">Download Lyrics Pdf</a></button>
                  </li>
                  <br />
                </ul>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="mob-only"><br /><hr /><br /></div>
              {
                this.state.displayData.length !== 0 ?
                  this.state.displayData.map((data, i) => (
                    <div key={i}>
                      <div className="card">
                        <div className="card-header" style={{ backgroundColor: "#98002e" }}><a style={{ color: "white" }} target="_blank" href={process.env.PUBLIC_URL + "/Bhatkhande-Audio-Specific/" + data.SL + "/" + data.RAAG + "/" + data.TAAL + "/" + data.STARTING_WORDS + "/" + data.THAAT + "/" + data.FILE_NAME} rel="noopener noreferrer">Play Audio &ensp;<i className="fas fa-play"></i></a></div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-6">
                              <table class="table">
                                <tbody>
                                  <tr>
                                    <th>SERIAL NO</th>
                                    <td>{data.SL}</td>
                                  </tr>
                                  <tr>
                                    <th>RAAG</th>
                                    <td>{data.RAAG}</td>
                                  </tr>
                                  <tr>
                                    <th>STARTING WORDS</th>
                                    <td>{data["STARTING_WORDS"]}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className="col-md-6">
                              <table class="table">
                                <tbody>
                                  <tr>
                                    <th>THAAT</th>
                                    <td>{data.THAAT}</td>
                                  </tr>
                                  <tr>
                                    <th>TAAL</th>
                                    <td>{data.TAAL}</td>
                                  </tr>
                                  <tr>
                                    <th>DOWNLOAD AUDIO</th>
                                    <td><a target="_blank" download href={"http://swarasankula.org/swarasankula-bhatkande/" + data.FILE_NAME.trim()} rel="noopener noreferrer">Download <i className="fas fa-download"> </i></a></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                    </div>
                  ))
                  : <h4 style={{ textAlign: "center", padding: "2rem" }}>No Record Found</h4>
              }
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    )
  }

}