import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import { Styles } from './styles/landerBlock.js';

export default class LanderBlock extends Component {

    state = {
        progressValue: "0%",
    }

    render() {

        const settings = {
            slidesPerView: 1,
            loop: true,
            speed: 2000,
            effect: "fade",
            autoplay: {
                delay: 4000,
                disableOnInteraction: false
            },
            watchSlidesVisibility: true,
            breakpoints: {
                0: {
                    slidesPerView: 1
                }
            }
        };

        const Datas = {
            "dataList": [
                {
                    "id": "1",
                    "landerImage": "home/lander-1.jpg",
                    "landerImageMob": "home/lander-1-mob.jpg",
                    "landerTitle": "Ustad Bade Ghulam Ali Khan",
                    "class": "left",
                },
                {
                    "id": "2",
                    "landerImage": "home/lander-2.jpg",
                    "landerImageMob": "home/lander-2-mob.jpg",
                    "landerTitle": "Pt. Balakrishna Buva",
                    "class": "right",
                },
                {
                    "id": "3",
                    "landerImage": "home/lander-3.jpg",
                    "landerImageMob": "home/lander-3-mob.jpg",
                    "landerTitle": "Pt. V N Bhatkhande",
                    "class": "left",
                },
                {
                    "id": "4",
                    "landerImage": "home/lander-4.jpg",
                    "landerImageMob": "home/lander-4-mob.jpg",
                    "landerTitle": "Ustad Vilayat Hussain Khan",
                    "class": "right",
                },
                {
                    "id": "5",
                    "landerImage": "home/lander-5.jpg",
                    "landerImageMob": "home/lander-5-mob.jpg",
                    "landerTitle": "Pt. V D Paluskar",
                    "class": "left",
                },
                {
                    "id": "6",
                    "landerImage": "home/lander-6.jpg",
                    "landerImageMob": "home/lander-6-mob.jpg",
                    "landerTitle": "Pt. Krishnarao Shankar Pandit",
                    "class": "right",
                },
                {
                    "id": "7",
                    "landerImage": "home/lander-7.jpg",
                    "landerImageMob": "home/lander-7-mob.jpg",
                    "landerTitle": "Ustad Faiyaz khan",
                    "class": "left",
                },
                {
                    "id": "8",
                    "landerImage": "home/lander-8.jpg",
                    "landerImageMob": "home/lander-8-mob.jpg",
                    "landerTitle": "Ustad Mushtaq Hussain Khan",
                    "class": "right",
                },
                {
                    "id": "9",
                    "landerImage": "home/lander-9.jpg",
                    "landerImageMob": "home/lander-9-mob.jpg",
                    "landerTitle": "Ustad Ata Hussain Khan",
                    "class": "left",
                },
                {
                    "id": "10",
                    "landerImage": "home/lander-10.jpg",
                    "landerImageMob": "home/lander-10-mob.jpg",
                    "landerTitle": "Dr. S N Ratanjankar",
                    "class": "right",
                },
                {
                    "id": "11",
                    "landerImage": "home/lander-11.jpg",
                    "landerImageMob": "home/lander-11-mob.jpg",
                    "landerTitle": "Ustad Abdul Karim Khan",
                    "class": "right",
                }
            ]
        }

        return (
            <Styles>
                <div className="progress" style={{ height: "0.3rem" }}><div className="progress-bar bg-warning" role="progressbar" style={{ width: "50%" }}></div></div>
                <div className="desk-only">
                    <Swiper {...settings}>
                        {
                            Datas.dataList.map((data, i) => (
                                <section key={i} className="bitHeader-area" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${data.landerImage})` }}>
                                    <Container>
                                        <Row>
                                            <Col md="12" className={data.class}>
                                                <h4 className="swaratitle">{data.landerTitle}</h4>
                                            </Col>
                                        </Row>
                                    </Container>
                                </section>
                            ))
                        }
                    </Swiper>
                </div>
                <div className="mob-only">
                    <Swiper {...settings}>
                        {
                            Datas.dataList.map((data, i) => (
                                <div key={i}>
                                    <section className="bitHeader-area-1" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${data.landerImageMob})` }}>
                                        <h4 className="swaratitle">{data.landerTitle}</h4>
                                    </section>
                                </div>
                            ))
                        }
                    </Swiper>
                </div>
            </Styles>
        )
    }
}

