import React, { Component } from 'react';
import Banner from '../components/common/Banner';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';

export default class BhatkhandeAudioSpecific extends Component {

    render() {

        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Bhatkhande Audio" image="banners/swarasankula04.jpg" />

                {/* Audio Data */}
                <div className="container paddingtop-2">
                    <div className="card">
                        <div class="card-header">
                            <audio id="audio" autoplay controls="controls" style={{width:"100%"}}>
                                <source id="audioSource" src={"http://swarasankula.org/swarasankula-bhatkande/" + this.props.match.params.FILE_NAME.trim()}></source>
                                Your browser does not support the audio format.
                            </audio>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <th>SERIAL NO</th>
                                                <td>{this.props.match.params.SL}</td>
                                            </tr>
                                            <tr>
                                                <th>RAAG</th>
                                                <td>{this.props.match.params.RAAG}</td>
                                            </tr>
                                            <tr>
                                                <th>STARTING WORDS</th>
                                                <td>{this.props.match.params.STARTING_WORDS}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-md-6">
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <th>THAAT</th>
                                                <td>{this.props.match.params.THAAT}</td>
                                            </tr>
                                            <tr>
                                                <th>TAAL</th>
                                                <td>{this.props.match.params.TAAL}</td>
                                            </tr>
                                            <tr>
                                                <th>DOWNLOAD AUDIO</th>
                                                <td><a target="_blank" download href={"http://swarasankula.org/swarasankula-bhatkande/" + this.props.match.params.FILE_NAME.trim()} rel="noopener noreferrer">Download <i className="fas fa-download"> </i></a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <br/><Footer />
            </div>
        )
    }

}