import React, { Component } from 'react';
import Banner from '../components/common/Banner';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';

export default class TheSchool extends Component {
  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="The School" image="banners/swarasankula06.jpg" />

        <div className="container ttm-service-description">
          <br /><br />
          <div className="row">
            <div className="col-md-5">
              <div className="ttm_single_image-wrapper">
                <img className="swaraimage img-fluid" src={process.env.PUBLIC_URL + "/assets/images/school/main1.jpg"} title="single-img-twenty" alt="single-img-twenty" />
              </div>
            </div>
            <div className="mob-only"><br /></div>
            <div className="col-md-7 justify" style={{ margin: "auto" }}>
              <div className="section-title with-desc text-left clearfix">
                <div className="title-header" style={{ borderTopColor: "red" }}>
                  <h4 className="title">About Music School</h4>
                </div>
              </div>
              <p className="swaratext1">Swarasankula Music School, Mysore imparts training in Hindustani Classical Music</p><br />
              <ui>
                <li className="swaratext1">Regular and Special Classes for Hindustani Classical Music</li>
                <li className="swaratext1">Structured Approach</li>
                <li className="swaratext1">Under Guidance of various Stalwarts of Hindustani Classical Music</li>
                <li className="swaratext1">Support for performances and improvements</li>
                <li className="swaratext1">Open for all age, sex, caste and creed</li>
                <li className="swaratext1">Transfering Cultural Heritage</li>
                <li className="swaratext1">Swarasankula shale 13th Anniversary. <a href="https://www.youtube.com/watch?v=O8tQ1GvayKs" rel="noopener noreferrer" target="_blank" className="swaratext1">Click Here</a></li>
                <li className="swaratext1">Know more about school. <a href="https://swarasankulamusicschool.org/" rel="noopener noreferrer" target="_blank" className="swaratext1">https://swarasankulamusicschool.org</a></li>
              </ui>
            </div>
          </div>
          <br /><br />
        </div>

        {/* Footer */}
        <Footer />
      </div>
    )
  }
}
