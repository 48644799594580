import React, { Component } from 'react';
import Banner from '../components/common/Banner';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';

export default class History extends Component {

  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="History" image="banners/swarasankula01.jpg" />

        <div className="container ttm-service-description">
          <br /><br />
          <div className="row">
            <div className="col-md-5">
              <div className="ttm_single_image-wrapper">
                <img className="swaraimage img-fluid" src={process.env.PUBLIC_URL + "/assets/images/history/main-1.jpg"} title="single-img-twenty" alt="single-img-twenty" />
              </div>
            </div>
            <div className="mob-only"><br /></div>
            <div className="col-md-7 justify" style={{ margin: "auto" }}>
              <p className="swaratext1">The genesis of Swaransankula Sangeetha Sabha goes back to the year 2002.  It was during this year, that Swarasankula Sangeetha Vidyalaya was started in Mysore by Pt. Veerabhadraih Hiremath with the goal of  imparting  training to those interested  in Hindustani Classical Music . The Vidyalaya became a platform for Hindustani Music lovers and Music Connoisseurs to come together and share their mutual love and respect for this genre of  Music. Over a period of time, the need for a forum  dedicated to the cause of Hindustani Classical Music was felt.  Thus Swarasankula Sangeetha Sabha was visualized as a response to this long felt need and hence established in the year 2005.</p><br/>
              <p className="swaratext1">It is a registered trust and the donations made to it are exempted under 80G of Income Tax Act 1961.</p>
            </div>
          </div>
          <br /><br />
        </div>

        {/* Footer */}
        <Footer />
      </div>
    )
  }
  
}
