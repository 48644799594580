import React, { Component } from 'react';
import Banner from '../components/common/Banner';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';

export default class BhatkhandeProject extends Component {
  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Bhatkhande Project" image="banners/swarasankula04.jpg" />

        <div className="container ttm-service-description">
          <br /><br />
          <div className="row">
            <div className="col-md-5">
              <div className="ttm_single_image-wrapper">
                <img className="swaraimage img-fluid" src={process.env.PUBLIC_URL + "/assets/images/bathAbout/main1.jpg"} title="single-img-twenty" alt="single-img-twenty" />
              </div>
            </div>
            <div className="mob-only"><br /></div>
            <div className="col-lg-7 justify" style={{ margin: "auto" }}>
              <p className="swaratext1">Pandit Vishnu Narayan Bhatkhande was the greatest Musicologist of the 20th Century. Apart from his other Musical works, he had compiled several Hindustani Musical Compositions and published them under the head ‘Hindustani Sangeet Paddathi – Kramik Pustaka Malika’ (6 volumes). All connoisseurs of Hindustani Classical Music would be aware that these were collected by him in the early part of the last Century from several established Musicians of that period, and they contain Chota Khayals, Bada Khayals, Dhrupads, Hori Dhamars, Taranas, Thumris, Holis etc. The books also contain Lakshan-Geets, Surawats (Sargams) composed by Panditji himself. The total number of such compositions is 1,896.</p><br />
              <p className="swaratext1">With the advancement of technical facilities of audio recording it was felt that availability of audio recordings of these compositions would help to</p>
            </div>
            <div className="col-lg-12 justify paddingtop-2">
              <p className="swaratext1">appreciate and assimilate these compositions better. With this in view Acharya KG Ginde, a stalwart in Hindustani Classical Music and descendant in the musical lineage of Pt.</p>
              <p className="swaratext1 paddingtop-1">Bhatkhande had embarked on this project at Sangeet Research Academy (ITC) Kolkata. But due to his sudden sad demise, this work could not be completed. We, the Swarasankula Sangeetha Sabha (Regd) Mysore took up this project afresh and it has been made possible due to the dedicated efforts put in by some of the Musicians of Mysore, basically by Pandit Indudhar Nirody, disciple of Acharya KG Ginde, a Top grade Artiste of All India Radio who has rendered all these compositions. Harmonium accompaniment has been provided by Pt. Veerabhadraiah Hiremath. Pt. Bhimashankar Bidnoor has provided the Tabla accompaniment for the first 1000 compositions and Pt. Ramesh Dhannur for the remaining 896. The latter three are the products of Veereshwara Punyashram of Gadag (Karnataka, India).</p>
            </div>
            <div className="col-lg-12 justify paddingtop-2">
              <h5>Compositions :</h5>
              <p className="swaratext1 paddingtop-1">Together with the audio recordings, we would be providing a list and lyrics of all compositions– categorised Thaat-wise. The composing work of the wordings was attended to by Pt. Vyasmurti Katti, (an established Harmonium player and a student of Pt. Nirody), and his two students, Smt. Soumya Bhat and Smt. Asavari Waikar. The onerous work of proof reading of the material was done by Pt.Nirody’s Gurubhandhu, Pt.Yeshwant M. Mahale, a well known Musician and Musicologist of Mumbai. The recording work was conducted at the “Resonators” Music Studios, Mysore, under the supervision of Vid. A.P. Srinivas, a renowned Karnatic Musician.</p>
              <p className="swaratext1 paddingtop-1">It is our firm belief that this work will be very useful both to the students and teachers of Hindustani Classical Music. Even the established performing Musicians will have the opportunity of enriching their repertoire. The Rasikas of music will also benefit as they will be able to enjoy by listening to these compositions hitherto rarely heard. This project work is also a unique material worth retaining in the archives for the benefit of posterity. We are hopeful that these objectives will be fulfilled with the earnest cooperation from the public in general and music lovers in particular.</p>
              <p className="swaratext1 paddingtop-1">You will surely realise that we have incurred expenditure to accomplish this herculean project. It is also our desire that eventually, when sufficient funds are raised, we would like to upload all the recordings on the internet for the benefit of each and every listener who is interested in music. To convert all these ideas into reality, we need funds. We therefore make an earnest appeal to all to contribute generously to the fund.</p>
              <p className="swaratext1 paddingtop-1">Those who contribute Rs.3000 or more will also receive a set of Audio CDs of the recordings as complimentary. The Cheques/Bank DDs may please be made out in favour of <b>Swarasankula Sangeetha Sabha (Regd) Mysore</b>. Alternatively funds can be credited to the account directly. A/C no1512101018041 IFSC CNRB0001512 canara bank Kuvempunagar Mysuru 570023</p>
            </div>
            <div className="col-lg-12 paddingtop-1">
              <hr />
              <h6 className="paddingtop-1">We are pleased to inform you that donations to the Trust are eligible for deductions under Section 80-G of the Indian Income Tax Act 1961.</h6><br /><hr /><br />
              <h6 className="swaratext1">Dr.MS Bhaskar</h6>
              <p className="swaratext1">President</p>
            </div>
          </div>
          <br /><br />
        </div>

        {/* Footer */}
        <Footer />
      </div>
    )
  }
}
